import { useState, useEffect } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import Input from '../../../components/shared/Input';
import SelectInput from '../../shared/SelectInput';
import {
  REQUIREMENT_TYPE,
  UPLOAD_TYPE,
  TWO_WAY_UPLOAD_TYPE
} from '../../../constants/common';

import './styles.scss';

const UpdateRequirementModal = ({
  onUpdate,
  onCancel,
  isOpen,
  requirement
}) => {
  const [requirementState, setRequirementState] = useState({
    ...requirement
  });

  const currentUploadType =
    requirementState?.requirement_type === REQUIREMENT_TYPE.TWO_WAY.value
      ? TWO_WAY_UPLOAD_TYPE
      : UPLOAD_TYPE;

  useEffect(() => {
    if (requirementState !== requirement) {
      setRequirementState({ ...requirement });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirement]);

  return (
    <Modal
      isOpen={isOpen}
      className="update-requirement-modal"
      width="25rem">
      <div className="requirement-name-input-container">
        <Input
          label="Requirement"
          placeholder="Requirement name"
          value={requirementState.requirement}
          onChange={(e) => {
            setRequirementState({
              ...requirementState,
              requirement: e.target.value
            });
          }}
        />
      </div>
      <div className="requirement-info-input-container">
        <SelectInput
          label="Requirement type"
          onChange={(e) => {
            setRequirementState({
              ...requirementState,
              requirement_type: e.target.value
            });
          }}
          options={Object.values(REQUIREMENT_TYPE).map((item) => ({
            value: item.value,
            text: item.text,
            selected: requirementState.requirement_type === item.value
          }))}
        />
        <SelectInput
          label="Upload by"
          onChange={(e) =>
            setRequirementState({
              ...requirementState,
              upload_by: parseInt(e.target.value)
            })
          }
          options={Object.values(currentUploadType).map((item) => ({
            value: item.value,
            text: item.text,
            selected: requirementState.upload_by === item.value
          }))}
        />
      </div>
      <div className="actions-container">
        <Button
          onClick={() => {
            onUpdate(requirementState);
          }}>
          UPDATE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </div>
    </Modal>
  );
};

export default UpdateRequirementModal;
