import { useQuery } from 'react-query';
import ProjectService from './../services/Project';
import queryStore from '.';

export const PROJECT_QUERY_KEY = 'project';

queryStore.setQueryDefaults(PROJECT_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetClientProjectsQuery = (queryOptions) =>
  useQuery(
    PROJECT_QUERY_KEY,
    async () => {
      const res = await ProjectService.getClientProjects();
      return res;
    },
    queryOptions
  );

export const useGetClientProjectDetailsQuery = (
  queryParams,
  queryOptions
) =>
  useQuery(
    PROJECT_QUERY_KEY,
    async () => {
      const res =
        await ProjectService.getClientProjectDetails(queryParams);
      return res;
    },
    queryOptions
  );
