import Spline from '@splinetool/react-spline';

const AnimatedFormBackground = () => {
  return (
    <div className="absolute top-0 left-0 w-[100dvw] h-[100dvh]">
      <div className="w-full h-full bg-[#042A48]">
        <Spline scene="https://prod.spline.design/tmfGQrBBrTiAPQw3/scene.splinecode" />
      </div>
    </div>
  );
};

export default AnimatedFormBackground;
