import request, { attachQueryParams } from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';
const UPLOAD_FILE = 'user/upload/file';

const ComplianceService = {
  getMyTemplates: () =>
    request({
      url: `${RECRUITER_API_URL}/getMyTemplates`,
      method: 'get'
    }),
  getTemplateDetail: (templateId) =>
    request({
      url: attachQueryParams(`${RECRUITER_API_URL}/getTemplateDetail`, {
        template_id: templateId
      }),
      method: 'get'
    }),
  updateTemplate: ({
    template_id,
    requirements,
    to_remove,
    template_name
  }) => {
    const data = {
      template_id,
      requirements,
      to_remove
    };

    if (template_name) {
      data.template_name = template_name;
    }

    return request({
      url: `${RECRUITER_API_URL}/updateTemplate`,
      method: 'put',
      data
    });
  },
  deleteTemplate: ({ template_id }) => {
    const data = {
      template_id
    };

    return request({
      url: attachQueryParams(`${RECRUITER_API_URL}/deleteTemplate`, {
        template_id
      }),
      method: 'delete',
      data
    });
  },
  updateResponseDoc: ({ to_remove = [], documents = [] }) => {
    const data = {
      to_remove,
      documents
    };

    return request({
      url: `${RECRUITER_API_URL}/updateResponseDoc`,
      method: 'put',
      data
    });
  },
  createTemplate: ({ template_name, requirements }) =>
    request({
      url: `${RECRUITER_API_URL}/createTemplate`,
      method: 'post',
      data: {
        template_name,
        requirements: requirements ?? []
      }
    }),
  uploadRequirementFile: (file) => {
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('type', file.type);

    return request({
      url: `${UPLOAD_FILE}/Compliance`,
      method: 'put',
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default ComplianceService;
