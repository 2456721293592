import * as ReactDOM from 'react-dom';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import {
  useUpdateCampaignArchival,
  useToggleCampaignStatusQuery
} from '../../../queries/Campaign';

import './styles/campaign-card-menu.scss';
import PermissionChecker from '@src/components/shared/PermissionChecker';

export const CampaignCardMenu = ({
  campaignId,
  isPaused,
  isPaid,
  isArchived,
  onDeleteClick,
  onPayDepositClick,
  onViewCampaignDetailsClick,
  updateActivationState
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [openMenu, setMenuOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  const { mutate: toggleCampaignStatus } = useToggleCampaignStatusQuery();
  const { mutate: updateCampaignArchival } = useUpdateCampaignArchival();

  const handleSwitchState = (event) => {
    event.stopPropagation();

    setMenuOpen(false);

    toggleCampaignStatus({
      campaign_id: campaignId,
      status: isPaused ? 0 : 1
    });
    updateActivationState();
  };

  const handleRequestArchival = (event) => {
    event.stopPropagation();

    setMenuOpen(false);

    updateCampaignArchival({
      campaign_id: campaignId,
      is_archived: !isArchived
    });
  };

  const isRunningCampaign = isPaid && !isArchived;

  return (
    <>
      <div
        className="d-flex"
        onMouseEnter={() => setMenuOpen(true)}
        onMouseLeave={() => setMenuOpen(false)}
        ref={setReferenceElement}>
        <span className="dots pointer" />
        {openMenu &&
          ReactDOM.createPortal(
            <div
              className="campaign-card-menu"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}>
              {!isPaid && !isArchived && (
                <PermissionChecker
                  permissions={['dashboard.campaign.write']}>
                  <p
                    className="py-2 m-0 menu-item pointer ps-2"
                    onClick={(event) => {
                      event.stopPropagation();

                      onPayDepositClick();
                    }}>
                    Pay deposit
                  </p>
                </PermissionChecker>
              )}
              <p
                className="py-2 m-0 menu-item pointer ps-2"
                onClick={(event) => {
                  event.stopPropagation();

                  onViewCampaignDetailsClick();
                }}>
                View
              </p>
              {isRunningCampaign && (
                <PermissionChecker
                  permissions={['dashboard.campaign.write']}>
                  <p
                    className="py-2 m-0 menu-item pointer ps-2"
                    onClick={handleSwitchState}>
                    {isPaused ? 'Resume' : 'Pause'}
                  </p>
                </PermissionChecker>
              )}

              {isArchived ? (
                <PermissionChecker
                  permissions={['dashboard.campaign.write']}>
                  <p
                    className="py-2 m-0 menu-item pointer ps-2"
                    onClick={handleRequestArchival}>
                    Unarchive
                  </p>
                </PermissionChecker>
              ) : (
                <PermissionChecker
                  permissions={['dashboard.campaign.write']}>
                  <p
                    className="py-2 m-0 menu-item pointer ps-2"
                    onClick={handleRequestArchival}>
                    Archive
                  </p>
                </PermissionChecker>
              )}
              {!isPaid && (
                <PermissionChecker
                  permissions={['dashboard.campaign.write']}>
                  <p
                    className="py-2 m-0 menu-item pointer ps-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDeleteClick();
                    }}>
                    Delete
                  </p>
                </PermissionChecker>
              )}
            </div>,
            document.body
          )}
      </div>
    </>
  );
};
