import { useQuery, useMutation, useQueryClient } from 'react-query';
import { logSuccess } from '../utils/logging';
import InvoiceService from './../services/Invoice';
import queryStore from '.';

export const INVOICE_QUERY_KEY = 'invoice';
export const INVOICE_STAT_QUERY_KEY = 'invoice_stat';

queryStore.setQueryDefaults(INVOICE_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

queryStore.setQueryDefaults(INVOICE_STAT_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetInvoicesQuery = (queryParams, queryOptions) =>
  useQuery(
    [INVOICE_QUERY_KEY, queryParams],
    async () => {
      const res = await InvoiceService.getInvoices(queryParams);
      return transformInvoices(res.client_invoices);
    },
    queryOptions
  );

export const useGetInvoiceStatQuery = (queryParams, queryOptions) =>
  useQuery(
    INVOICE_STAT_QUERY_KEY,
    async () => {
      const res = await InvoiceService.getInvoiceStat();
      return res.client_invoices;
    },
    queryOptions
  );

export const usePayInvoicesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (invoices) => {
      const paymentMethods = await InvoiceService.getPaymentMethods();

      if (!paymentMethods?.[0]) {
        throw new Error('Please provide payment method');
      }

      return await Promise.allSettled(
        invoices.map((invoice) =>
          InvoiceService.payForAInvoice({
            invoice_id: invoice.id,
            payment_method_id: paymentMethods[0].id
          })
        )
      );
    },
    {
      onSuccess: (data) => {
        const numberOfFailures = data.filter(
          ({ status }) => status === 'rejected'
        ).length;

        logSuccess(
          `Invoices successfully paid. ${numberOfFailures} ${
            numberOfFailures > 1 ? 'were' : 'was'
          } failed.`
        );

        queryClient.invalidateQueries(INVOICE_QUERY_KEY);
        queryClient.invalidateQueries(INVOICE_STAT_QUERY_KEY);
      }
    }
  );
};

const transformInvoices = (invoices) =>
  invoices
    .filter((invoice) => invoice.status !== 'void')
    .map((invoice) => ({
      name: invoice.number,
      pdf: invoice.invoice_pdf,
      amount: invoice.total / 100,
      dueDate: invoice.due_date ? new Date(invoice.due_date * 1000) : null,
      status: getInvoiceStatus(invoice),
      raw: invoice
    }));

const getInvoiceStatus = (invoice) => {
  if (invoice.status === 'paid') return 'Paid';

  if (invoice.status === 'draft') return 'Draft';

  if (invoice.due_date && invoice.due_date * 1000 < new Date().getTime())
    return 'Past Due';

  return 'Unpaid';
};
