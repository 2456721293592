import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createRole,
  deleteRole,
  getManagedUsers,
  getPermissions,
  getRolePermissions,
  getRoles,
  inviteUserWithRole,
  updateManagedUserRole,
  updateRole,
  updateRolePermissions
} from '../services/PermissionRole';

export const PERMISSION_QUERY_KEY = 'permissions';
export const ROLE_QUERY_KEY = 'roles';
export const ROLE_PERMISSION_QUERY_KEY = 'rolePermissions';

export const MANAGED_USERS_QUERY_KEY = 'managedUsers';

export const useGetPermissionsQuery = (queryOptions) =>
  useQuery(
    PERMISSION_QUERY_KEY,
    async () => {
      const { permissions } = await getPermissions();
      return permissions;
    },
    queryOptions
  );

export const useGetRolePermissionsQuery = (roleId, queryOptions) =>
  useQuery(
    [ROLE_PERMISSION_QUERY_KEY, roleId],
    async () => {
      const data = await getRolePermissions(roleId);
      return data;
    },
    queryOptions
  );

export const useGetRolesQuery = (queryOptions) =>
  useQuery(
    ROLE_QUERY_KEY,
    async () => {
      const { roles } = await getRoles();
      return roles;
    },
    queryOptions
  );

export const useCreateRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROLE_QUERY_KEY);
    }
  });
};

export const useDeleteRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROLE_QUERY_KEY);
    }
  });
};

export const useUpdateRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROLE_QUERY_KEY);
    }
  });
};

export const useUpdateRolePermissionsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRolePermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROLE_PERMISSION_QUERY_KEY);
    }
  });
};

export const useGetManagedUsersQuery = (queryOptions) =>
  useQuery(
    MANAGED_USERS_QUERY_KEY,
    async () => {
      const { managedUsers } = await getManagedUsers();
      return managedUsers;
    },
    queryOptions
  );

export const useUpdateManagedUserRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateManagedUserRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(MANAGED_USERS_QUERY_KEY);
    }
  });
};

export const useInviteUserWithRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(inviteUserWithRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(MANAGED_USERS_QUERY_KEY);
    }
  });
};
