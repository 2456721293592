import { ReactComponent as TrustedDevLogo } from '@src/assets/images/td-logo.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import UserService from '@src/services/User';
import { routes } from '@src/router/routes';
import { navigate } from '@src/utils/history';
import { setItem } from '@src/utils/localStorage';
import { setCookie } from '@src/utils/cookies';

const PasswordCreationForm = ({ invitationToken }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  const submitPasswordCreationForm = async () => {
    try {
      setIsSubmitting(true);
      if (!password) {
        toast.error('Password is required');
        setIsSubmitting(false);
        return;
      }
      if (!confirmedPassword) {
        toast.error('Password confirmation is required');
        setIsSubmitting(false);
        return;
      }
      if (password !== confirmedPassword) {
        toast.error('The password confirmation does not match');
        setIsSubmitting(false);
        return;
      }

      const { access_token } =
        await UserService.createNewUserWithInvitationToken({
          invitation_token: invitationToken,
          password: password,
          confirm_password: confirmedPassword
        });

      setCookie('token', access_token);
      setItem('newLogin', true);
      navigate(routes.home);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="absolute top-0 left-0 z-1 w-[100dvw] h-[100dvh] flex items-center justify-center">
      <div className="bg-gradient-to-b from-[#042A48] to-[#3281C7]/50 p-6 rounded-2xl shadow-md shadow-black/30 max-w-[650px] w-full mx-4">
        <div className="flex justify-center mt-6">
          <TrustedDevLogo width="100rem" height="2.875rem" />
        </div>
        <div className="mt-5 text-center">
          <h1 className="text-3xl font-bold text-white">
            Password creation form
          </h1>
          <h1 className="text-3xl font-bold text-white">
            Create your password to log in
          </h1>
        </div>
        <div className="p-5">
          <div className="flex flex-col mt-3">
            <label className="text-sm font-medium text-[#6D94AD] mb-2">
              Password
            </label>
            <div className="relative w-full">
              <input
                className="bg-[#72BAED]/20 text-white py-3 pl-3 pr-8 rounded-lg w-full"
                type={isPasswordShown ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className="absolute mt-3 right-2"
                onClick={() => setIsPasswordShown((prev) => !prev)}>
                {isPasswordShown ? (
                  <VisibilityOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                )}
              </i>
            </div>
          </div>
          <div className="flex flex-col mt-3">
            <label className="text-sm font-medium text-[#6D94AD] mb-2">
              Confirm Password
            </label>
            <div className="relative w-full">
              <input
                className="bg-[#72BAED]/20 text-white py-3 pl-3 pr-8 rounded-lg w-full"
                type={isPasswordShown ? 'text' : 'password'}
                onChange={(e) => setConfirmedPassword(e.target.value)}
              />
              <i
                className="absolute mt-3 right-2"
                onClick={() => setIsPasswordShown((prev) => !prev)}>
                {isPasswordShown ? (
                  <VisibilityOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                )}
              </i>
            </div>
          </div>
          <Button
            variant="contained"
            className="mt-8 h-[55px] w-full"
            disabled={isSubmitting}
            onClick={submitPasswordCreationForm}>
            CREATE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordCreationForm;
