import './BasicInformationForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { selectBasicInfo } from '../../../../store/selectors/Auth';
import { registerRequest } from '../../../../store/slices/Auth';
import UnderlinedInputField from '../../../shared/inputs/UnderlinedInputField/UnderlinedInputField';
import Button from '../../../shared/Button/Button';
import Option from '../../../shared/inputs/Option/Option';
import { registerValidationRules } from '../../../../validation/register';
import { saveBasicInfoField } from '../../../../store/saga/actions/Auth';

const animatedFormOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.35 }
};

const BasicInformationForm = () => {
  const basicInfo = useSelector(selectBasicInfo);
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => dispatch(registerRequest(values));
  const handleCustomBlur = (e, handleBlur) => {
    handleBlur(e);

    const { name, value } = e.target;

    if (!!name && !!value) {
      dispatch(saveBasicInfoField({ [name]: value }));
    }
  };

  return (
    <Formik
      initialValues={basicInfo}
      validationSchema={registerValidationRules}
      onSubmit={handleFormSubmit}>
      {(form) => (
        <Form className="d-flex flex-column flex-grow-1" noValidate>
          <motion.div
            {...animatedFormOptions}
            className="flex-grow-1 register-form-content">
            <h3 className="pb-4">
              Please fill out the form below to get started
            </h3>
            <Field
              label="First Name"
              type="text"
              name="first_name"
              placeholder="Enter your first name"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Last Name"
              type="text"
              name="last_name"
              placeholder="Enter your last name"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Phone"
              type="text"
              name="phone_number"
              placeholder="Enter your phone number"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Company Name"
              type="text"
              name="company_name"
              placeholder="Enter name of your company"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Company URL"
              type="url"
              name="company_url"
              placeholder="Enter your company url"
              component={UnderlinedInputField}
              autoCapitalize="off"
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Job Title"
              type="text"
              name="job_title"
              placeholder="Enter your job title"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Field
              label="Email Address"
              type="email"
              name="email_id"
              placeholder="Enter your email address"
              component={UnderlinedInputField}
              onBlur={(e) => handleCustomBlur(e, form.handleBlur)}
            />
            <Option key="accept" type="checkbox" id="accept" name="accept">
              I accept the&nbsp;
              <u>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="https://dcg.dev/terms"
                  className="no-tap-highlight">
                  Terms of Service
                </a>
              </u>
              &nbsp;and&nbsp;
              <u>
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="https://dcg.dev/privacy"
                  className="no-tap-highlight">
                  Privacy Policy
                </a>
              </u>
            </Option>
            {form.errors.accept && form.touched.accept && (
              <div className="error-msg-accept">{form.errors.accept}</div>
            )}
          </motion.div>
          <div className="px-4 align-self-stretch d-flex justify-content-center footer">
            <Button
              type="submit"
              title="CONTINUE >"
              backgroundColorVar="--color-super-rare-jade"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BasicInformationForm;
