import './styles.scss';
import config from './../../../config';

export { default as TristateToggleMobile } from './TristateToggleMobile';

const TristateToggle = ({ setTimelineType, timelineType }) => (
  <div className="tristate-toggle">
    <input
      id="full"
      type="radio"
      name="tristate-toggle"
      hidden
      checked={config.projectTimelineType.FULL === timelineType}
      onChange={() => setTimelineType(config.projectTimelineType.FULL)}
    />
    <label htmlFor="full">FULL</label>

    <input
      id="monthly"
      type="radio"
      name="tristate-toggle"
      hidden
      checked={config.projectTimelineType.MONTHLY === timelineType}
      onChange={() => setTimelineType(config.projectTimelineType.MONTHLY)}
    />
    <label htmlFor="monthly">MONTHLY</label>

    <input
      id="weekly"
      type="radio"
      name="tristate-toggle"
      hidden
      checked={config.projectTimelineType.WEEKLY === timelineType}
      onChange={() => setTimelineType(config.projectTimelineType.WEEKLY)}
    />
    <label htmlFor="weekly">WEEKLY</label>

    <span></span>
  </div>
);

export default TristateToggle;
