import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  previewCandidate: null,
  candidatePreviewCardPosition: null,
  showNewMatches: false,
  newMatchesAvailable: false
};

const CandidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setPreviewCandidate(state, { payload }) {
      state.previewCandidate = payload;
    },
    setCandidatePreviewCardPosition(state, { payload }) {
      state.candidatePreviewCardPosition = payload;
    },
    setShowNewMatches(state, { payload }) {
      state.showNewMatches = payload;
    },
    setNewMatchesAvailable(state, { payload }) {
      state.newMatchesAvailable = payload;
    }
  }
});

export const {
  setPreviewCandidate,
  setCandidatePreviewCardPosition,
  setShowNewMatches,
  setNewMatchesAvailable
} = CandidateSlice.actions;
export default CandidateSlice.reducer;
