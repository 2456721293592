import { toast } from 'react-toastify';
import { useState } from 'react';
import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button/Button';

import './styles.scss';
import Dropdown from '../../shared/Dropdown';
import { useInviteUserWithRoleMutation } from '../../../queries/PermissionRole';

const DEFAULT_FORM_DATA = {
  email: '',
  role_id: null
};

const UserInvitationBottomBar = ({ roles }) => {
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
  const { mutateAsync: inviteUserWithRole } =
    useInviteUserWithRoleMutation();

  const sendUserInvitation = async () => {
    if (!formData.email || !formData.role_id) {
      toast.error('Please fill all the fields');
      return;
    }

    await inviteUserWithRole(formData);

    setFormData(DEFAULT_FORM_DATA);
    toast.success('User invitation sent successfully');
  };

  return (
    <div className="requirement-bottom-bar">
      <div className="flex-items w-full max-w-[700px]">
        <Input
          label="User email"
          isSideLabel
          value={formData.email}
          onChange={(event) => {
            setFormData({ ...formData, email: event.target.value });
          }}
          className="w-full"
          inputClassName="w-full"
          labelClassName="w-32"
        />
        <div className="flex items-center space-x-2">
          <label className="w-32">Permission Type</label>
          <Dropdown
            options={roles}
            selectedOption={roles?.find(
              (role) => role.id === formData.role_id
            )}
            onChange={(option) => {
              setFormData({ ...formData, role_id: option.id });
            }}
            className="w-40"
            labelKey="role_name"
          />
        </div>
        <Button onClick={sendUserInvitation}>INVITE</Button>
      </div>
    </div>
  );
};

export default UserInvitationBottomBar;
