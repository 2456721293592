import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import Button from '../../components/shared/Button/Button';
import { Link } from 'react-router-dom';
import { ReactComponent as TrustedDevLogo } from '../../assets/images/td-logo.svg';
import { routes } from '../../router/routes';
import AuthService from '../../services/Auth';

const LoginForm = ({ onSubmitted }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitLoginForm = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);
      const data = await AuthService.login({
        email_id: email,
        password,
        user_role: 1
      });
      onSubmitted(data);
    } finally {
      setIsSubmitting(false);

      setPassword('');
    }
  };

  return (
    <div className="absolute top-0 left-0 z-1 w-[100dvw] h-[100dvh] flex items-center justify-center">
      <div className="bg-gradient-to-b from-[#042A48] to-[#3281C7]/50 p-6 rounded-2xl shadow-md shadow-black/30 max-w-[650px] w-full mx-4">
        <div className="flex justify-center mt-6">
          <TrustedDevLogo width="100rem" height="2.875rem" />
        </div>
        <div className="mt-5 text-center">
          <h1 className="text-3xl font-bold text-white">Welcome again!</h1>
          <h1 className="text-3xl font-bold text-white">
            You must sign in to continue
          </h1>
        </div>
        <form onSubmit={submitLoginForm} className="p-5">
          <div className="flex flex-col">
            <label className="text-sm font-medium text-[#6D94AD] mb-2">
              Email
            </label>
            <input
              className="bg-[#72BAED]/20 text-white p-3 rounded-lg"
              autoFocus
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col mt-3">
            <label className="text-sm font-medium text-[#6D94AD] mb-2">
              Password
            </label>
            <div className="relative w-full">
              <input
                className="bg-[#72BAED]/20 text-white py-3 pl-3 pr-8 rounded-lg w-full"
                type={isPasswordShown ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <i
                className="absolute mt-3 right-2"
                onClick={() => setIsPasswordShown((prev) => !prev)}>
                {isPasswordShown ? (
                  <VisibilityOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      fill: '#97C4DB'
                    }}
                  />
                )}
              </i>
            </div>
          </div>
          <div>
            <div className="flex justify-end mt-3">
              <Link to={routes.forgotPassword} className="text-[#6D94AD]">
                Forgot your password?
              </Link>
            </div>
          </div>
          <Button
            type="submit"
            className="mt-8 h-[55px] w-full"
            disabled={isSubmitting}>
            SIGN IN TO YOUR ACCOUNT
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
