import './SearchFilterBar.css';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { ReactComponent as BriefCaseIcon } from '../../../assets/images/briefcase-icon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/images/check-circle-icon.svg';
import { ReactComponent as HeartIcon } from '../../../assets/images/heart-icon.svg';
import {
  setCandidateFilterProfileStatus,
  setCandidateSearchText,
  toggleCandidateFilterFavorites
} from '../../../store/slices/CandidateFilter';
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
  selectCandidateSearchText
} from '../../../store/selectors/CandidateFilter';
import config from './../../../config';
import Tooltip from '@src/components/Tooltip';

const SearchFilterBar = ({ containerClassName, total = 0 }) => {
  const activeProfileStatus = useSelector(
    selectCandidateFilterProfileStatus
  );
  const isFavorites = useSelector(selectCandidateFilterFavorites);
  const searchText = useSelector(selectCandidateSearchText);
  const dispatch = useDispatch();

  const setProfileStatus = (status) =>
    dispatch(setCandidateFilterProfileStatus(status));
  const toggleFavorites = () => dispatch(toggleCandidateFilterFavorites());
  const setSearchText = (e) =>
    dispatch(setCandidateSearchText(e?.target?.value));

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      className={
        'search-filter-bar' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      <span className="search-filter-bar__total">{total} people</span>
      <div className="search-filter-bar__search">
        <SearchIcon className="search-icon" />
        <input
          value={searchText}
          onChange={setSearchText}
          className="state-transition"
        />
      </div>
      <div className="search-filter-bar__filters">
        <Tooltip content="Active Hire">
          <BriefCaseIcon
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.HIRED)
            }
            className={`filter-icon state-transition${
              activeProfileStatus === config.candidate.profileStatus.HIRED
                ? ' selected'
                : ''
            }`}
          />
        </Tooltip>

        <Tooltip content="Interviewed">
          <CheckCircleIcon
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.INTERVIEWED)
            }
            className={`filter-icon state-transition${
              activeProfileStatus ===
              config.candidate.profileStatus.INTERVIEWED
                ? ' selected'
                : ''
            }`}
          />
        </Tooltip>
        <span className="ml-4 vertical-spacer" />
        <Tooltip content="Favorites">
          <HeartIcon
            onClick={toggleFavorites}
            className={`filter-icon state-transition${
              isFavorites ? ' selected' : ''
            }`}
          />
        </Tooltip>
      </div>
    </div>
  );
};

SearchFilterBar.propTypes = {
  containerClassName: PropTypes.string,
  total: PropTypes.number
};

export default SearchFilterBar;
