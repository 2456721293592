import { Route, Switch, Redirect } from 'react-router-dom';
import Navigation from '@src/components/shared/Navigation/Navigation';
import Account from '@src/containers/Account/Account';
import NotFound from '@src/containers/NotFound/NotFound';
import { getAccessibleRoutes, routes } from './routes';
import { PrivateRoute } from './PrivateRoute';
import { UserProvider, useUserContext } from '@src/contexts/UserContext';

const InternalRouter = () => {
  const { userData, isUserDataFetched } = useUserContext();

  const accessibleRoutes = getAccessibleRoutes(userData);

  const hasHomePage = accessibleRoutes.some(
    ({ route }) => route === routes.home
  );

  return (
    <>
      <Navigation
        userData={userData}
        isUserDataFetched={isUserDataFetched}
      />
      <Switch>
        <PrivateRoute exact path={routes.account} component={Account} />
        {accessibleRoutes.map(({ route, Component }) => (
          <PrivateRoute
            exact
            path={route}
            component={Component}
            key={route}
          />
        ))}
        {hasHomePage || !accessibleRoutes.length ? (
          <Route path="*" component={NotFound} />
        ) : (
          <Route path="*">
            <Redirect to={accessibleRoutes[0].route} />
          </Route>
        )}
      </Switch>
    </>
  );
};

const InternalRouterWrapper = () => (
  <UserProvider>
    <InternalRouter />
  </UserProvider>
);

export default InternalRouterWrapper;
