import ReactApexChart from 'react-apexcharts';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend
} from 'recharts';
import { createRoot } from 'react-dom';
import { useGetCampaignDetailQuery } from '../../../queries/Campaign';
import { convertMinutesToHours } from '../../../utils/dateTime';
import { HORIZONTAL_BAR_COLORS } from '../../../constants/common';

import './styles/campaign-management-metrics-tab.scss';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';

const hourChartOptions = {
  chart: {
    stacked: true,
    toolbar: {
      show: false
    }
  },
  colors: HORIZONTAL_BAR_COLORS,
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  dataLabels: {
    formatter: (value) => `${convertMinutesToHours(value)}h`
  },
  tooltip: {
    custom: ({ seriesIndex, dataPointIndex, w }) => {
      const info =
        w.globals.initialSeries[seriesIndex].data[dataPointIndex].x;

      const tooltipContainer = document.getElementById('tooltip');
      const root = createRoot(tooltipContainer); // create a root once

      setTimeout(() =>
        root.render(
          <Box margin="0.5rem">
            <Grid container alignItems="center" gap={1} flexWrap="nowrap">
              <Grid item>
                <Avatar alt={info.full_name} src={info.avatar} />
              </Grid>
              <Grid item>
                <Typography fontSize={14}>{info.full_name}</Typography>
              </Grid>
            </Grid>
          </Box>,
          document.getElementById('tooltip')
        )
      );

      return `<div id="tooltip"></div>`;
    }
  },
  legend: {
    show: false
  },
  xaxis: {
    labels: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  grid: {
    show: false
  }
};

export const CampaignManagementMetricsTab = ({ campaign }) => {
  const currentCampaignId = campaign.id;

  const {
    data: campaignData,
    isLoading,
    isError
  } = useGetCampaignDetailQuery({
    campaign_id: currentCampaignId
  });

  if (isLoading)
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Box>
    );

  if (isError) return null;

  const {
    candidates,
    campaign: campaignDetails,
    active_resources
  } = campaignData;

  const PIE_CHART_COLORS = ['#509EC6', '#ff7bac'];

  const voteChartData = [
    { name: 'Voted Up', value: candidates.voted_up_count },
    { name: 'Voted Down', value: candidates.voted_down_count }
  ];

  const interviewChartData = [
    { name: 'Positive', value: candidates.positive_interview_count },
    { name: 'Negative', value: candidates.negative_interview_count }
  ];

  const hoursChart = {
    series: active_resources.map((resource) => ({
      data: [
        {
          x: {
            full_name: resource.full_name,
            avatar: resource.avatar
          },
          y: resource.total_minutes
        }
      ]
    })),
    options: hourChartOptions
  };

  const CustomPieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const color = payload[0].payload.fill;

      return (
        <div
          style={{
            backgroundColor: color,
            borderRadius: '10px',
            padding: '4px 8px',
            color: '#fff'
          }}>
          <p>{`${name}: ${value}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent === 0) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="campaign-management-metrics-tab">
      <div className="top-total d-flex justify-content-between">
        <div className="gap-3 number-figure d-flex align-items-center fw-bold">
          <p className="m-0 label">
            Historical
            <br />
            matches
          </p>
          <p className="m-0 number">
            {candidates.voted_down_count + candidates.voted_up_count}
          </p>
        </div>
        <div className="gap-3 number-figure d-flex align-items-center fw-bold">
          <p className="m-0 label">
            Interviewed
            <br />
            candidates
          </p>
          <p className="m-0 number">
            {candidates.negative_interview_count +
              candidates.positive_interview_count}
          </p>
        </div>
      </div>
      <div className="mx-4 d-flex">
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="h-[200px]">
          <PieChart width={350} height={350}>
            <Tooltip content={<CustomPieTooltip />} />
            <Legend iconType="circle" />
            <Pie
              data={voteChartData}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={-270}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              label={renderCustomizedLabel}
              dataKey="value">
              {voteChartData.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="h-[200px]">
          <PieChart width={350} height={350}>
            <Tooltip content={<CustomPieTooltip />} />
            <Legend iconType="circle" />
            <Pie
              data={interviewChartData}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={-270}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              label={renderCustomizedLabel}
              dataKey="value">
              {interviewChartData.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <hr className="w-auto mx-4 my-4" />
      <div className="bottom-section d-flex">
        <div className="gap-3 number-figure d-flex align-self-center align-items-center fw-bold">
          <p className="m-0 label">
            Total
            <br />
            Hours
          </p>
          <p className="m-0 number">
            {convertMinutesToHours(campaignDetails?.total_minutes)}
          </p>
        </div>
        <ReactApexChart
          type="bar"
          options={hoursChart.options}
          series={hoursChart.series}
          width={380}
          height={140}
        />
      </div>
    </div>
  );
};
