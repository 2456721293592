import './styles.scss';

import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip
} from 'recharts';

import {
  countCompletedMilestones,
  countCompletedTasks
} from '../../../utils/common';

import { PIE_CHART_COLORS } from '../../../constants/common';

import { mapMilestoneChartData } from '../../../utils/chartHelper';

import ProjectFigure from '../ProjectFigure';

const ProjectMetrics = ({ milestones, resourceCount }) => {
  const chartData = mapMilestoneChartData(milestones);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        style={{
          textShadow: 'rgb(0 0 0) 3px 2px 7px',
          fontSize: '12px',
          fontWeight: 'bold'
        }}
        dominantBaseline="central">
        {value}
      </text>
    );
  };

  return (
    <div className="project-metrics-container">
      <div className="headline">Project metrics</div>
      <div className="project-metrics">
        <div>
          <div className="chart-title">
            <span>Project Milestone WBS Distribution</span>
          </div>
          <div className="chart milestone-chart" id="milestone-chart">
            <div className="chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    strokeWidth={2}
                    innerRadius={65}
                    outerRadius={96}
                    startAngle={90}
                    endAngle={-270}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8">
                    {chartData.map((_entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value, name) => [
                      `${value} ${Number(value) === 1 ? 'day' : 'days'}`,
                      `Milestone ${name}`
                    ]}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className="project-figures">
          <ProjectFigure
            description="Active Project Resources"
            numberString={(resourceCount ?? 0).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}></ProjectFigure>
          <ProjectFigure
            description="Tasks Completed"
            numberString={countCompletedTasks(milestones).toLocaleString(
              'en-US',
              {
                minimumIntegerDigits: 2,
                useGrouping: false
              }
            )}
          />
          <ProjectFigure
            description="Milestones Completed"
            numberString={countCompletedMilestones(
              milestones
            ).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectMetrics;
