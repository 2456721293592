import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import './styles.scss';

const TruncatedTypography = ({
  children,
  lineCount = 5,
  onReadMore,
  ...props
}) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current.scrollHeight > textRef.current.clientHeight) {
      setIsTruncated(true);
    }
  }, [children]);

  return (
    <>
      <Typography
        {...props}
        ref={textRef}
        className="truncated-typography"
        sx={{ WebkitLineClamp: lineCount }}>
        {children}
      </Typography>
      {isTruncated && onReadMore && (
        <Typography
          onClick={() => onReadMore()}
          sx={{
            color: '#2e9dd7',
            cursor: 'pointer',
            marginTop: '4px',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}>
          Read More
        </Typography>
      )}
    </>
  );
};

export default TruncatedTypography;
