import { useState, useRef } from 'react';
import './styles.scss';
import classNames from 'classnames';
import TimelineBar from '../../../components/project-management/TimelineBar';
import config from '../../../config';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left-arrow-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right-arrow-icon.svg';
import {
  getMonthYear,
  getDayMonthYear,
  getDays,
  checkToday,
  getDisplayedTimeFrame
} from '../../../utils/dateTimeHelper';
import { useIfMobile } from '../../../utils/responsive';

const isTouchingScreen =
  'ontouchstart' in window || navigator.msMaxTouchPoints;

const TimelineBox = ({
  timelineType,
  timeline,
  setTimeline,
  currentTimeFrame,
  displayedMilestones,
  isSectionOpened,
  openedMilestones,
  projectDetails,
  milestoneSectionRef,
  timelineSectionRef
}) => {
  const isMobile = useIfMobile();

  const milestoneBodyPosition = useRef({
    startX: 0,
    startY: 0,
    scrollTop: 0,
    scrollLeft: 0,
    isStartedScrolling: false
  });

  const [dayContainerElementWidth, setDayContainerElementWidth] =
    useState(0);

  const [
    horizontalDayContainerPosition,
    setHorizontalDayContainerPosition
  ] = useState(0);

  if (!timeline) {
    return null;
  }

  const displayedTimeFrame = getDisplayedTimeFrame(
    timeline.startDate,
    timeline.endDate,
    timelineType
  );

  return (
    <div
      className={classNames('timeline', {
        'mobile-view': isMobile
      })}>
      <div className="timeline-header">
        <div className="month-switcher">
          {timelineType === config.projectTimelineType.MONTHLY && (
            <>
              <div
                className="arrow-container"
                onClick={() => {
                  const previousDate = new Date(
                    timeline.startDate.getFullYear(),
                    timeline.startDate.getMonth() - 1,
                    1
                  );
                  setTimeline({
                    startDate: previousDate,
                    endDate: new Date(
                      previousDate.getFullYear(),
                      previousDate.getMonth(),
                      getDays(previousDate)
                    )
                  });
                }}>
                <LeftArrowIcon></LeftArrowIcon>
              </div>
              <div>{getMonthYear(timeline.startDate)}</div>
              <div
                className="arrow-container"
                onClick={() => {
                  const nextDate = new Date(
                    timeline.startDate.getFullYear(),
                    timeline.startDate.getMonth() + 1,
                    1
                  );
                  setTimeline({
                    startDate: nextDate,
                    endDate: new Date(
                      nextDate.getFullYear(),
                      nextDate.getMonth(),
                      getDays(nextDate)
                    )
                  });
                }}>
                <RightArrowIcon></RightArrowIcon>
              </div>
            </>
          )}
          {timelineType === config.projectTimelineType.WEEKLY && (
            <>
              <div
                className="arrow-container"
                onClick={() => {
                  const startDate = new Date(
                    new Date(timeline.startDate).setDate(
                      timeline.startDate.getDate() - 7
                    )
                  );
                  const endDate = new Date(
                    new Date(timeline.startDate).setDate(
                      timeline.startDate.getDate() - 1
                    )
                  );
                  setTimeline({
                    startDate,
                    endDate
                  });
                }}>
                <LeftArrowIcon></LeftArrowIcon>
              </div>
              <div>{`${getDayMonthYear(
                timeline.startDate,
                true
              )} - ${getDayMonthYear(timeline.endDate, true)}`}</div>
              <div
                className="arrow-container"
                onClick={() => {
                  const startDate = new Date(
                    new Date(timeline.endDate).setDate(
                      timeline.endDate.getDate() + 1
                    )
                  );
                  const endDate = new Date(
                    new Date(timeline.endDate).setDate(
                      timeline.endDate.getDate() + 7
                    )
                  );
                  setTimeline({
                    startDate,
                    endDate
                  });
                }}>
                <RightArrowIcon></RightArrowIcon>
              </div>
            </>
          )}
          {timelineType === config.projectTimelineType.FULL && (
            <div>
              {projectDetails
                ? `${getDayMonthYear(
                    projectDetails?.project_start_date,
                    true
                  )} - ${getDayMonthYear(
                    projectDetails?.project_end_date,
                    true
                  )}`
                : 'Loading...'}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('days-container', {
          'mobile-view': isMobile
        })}
        style={{
          '--day-container-horizontal-position': `-${horizontalDayContainerPosition}px`
        }}>
        {!projectDetails && (
          <div className={classNames('time-indicator')}>Loading...</div>
        )}
        {displayedTimeFrame?.map(({ value, text }) => (
          <div
            key={text}
            className={classNames('time-indicator', {
              'highlighted-current-date': checkToday(value),
              'full-timeline':
                timelineType === config.projectTimelineType.FULL,
              'weekly-timeline':
                timelineType === config.projectTimelineType.WEEKLY
            })}
            ref={(node) => {
              if (node) {
                setDayContainerElementWidth(
                  node.getBoundingClientRect().width *
                    displayedTimeFrame.length
                );
              }
            }}>
            <div>{text}</div>
            {checkToday(value) && (
              <div
                className={classNames('current-date-line', {
                  closed: isSectionOpened,
                  'mobile-view': isMobile
                })}
                style={{
                  '--number-of-date-in-time-period': Array.isArray(value)
                    ? value.length
                    : 2,
                  '--current-date': Array.isArray(value)
                    ? new Date().getDate()
                    : 1
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}></div>
            )}
          </div>
        ))}
      </div>
      <div
        className={classNames('timeline-body-container', {
          'project-info-closed': !isSectionOpened,
          'mobile-view': isMobile
        })}
        ref={(node) => (timelineSectionRef.current = node)}
        onScroll={(e) => {
          milestoneSectionRef.current.scrollTop = e.target.scrollTop;
          const extraGap = isMobile ? 4 : 8;
          setHorizontalDayContainerPosition(
            e.target.scrollLeft === 0
              ? e.target.scrollLeft
              : e.target.scrollLeft + extraGap
          );
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          const isTouchingScreen =
            'ontouchstart' in window || navigator.msMaxTouchPoints;
          if (isTouchingScreen) {
            return;
          }

          milestoneBodyPosition.current = {
            startX: e.pageX - timelineSectionRef.current.offsetLeft,
            startY: e.pageY - timelineSectionRef.current.offsetTop,
            scrollLeft: timelineSectionRef.current.scrollLeft,
            scrollTop: timelineSectionRef.current.scrollTop,
            isStartedScrolling: true
          };
          //don't use state to reduce UI re-renderings unexpectedly
          timelineSectionRef.current.style.cursor = 'grab';
        }}
        onMouseMove={(e) => {
          e.preventDefault();
          if (isTouchingScreen) {
            return;
          }

          if (!milestoneBodyPosition.current.isStartedScrolling) {
            return;
          }

          const x = e.pageX - timelineSectionRef.current.offsetLeft;
          const xChange = x - milestoneBodyPosition.current.startX;
          timelineSectionRef.current.scrollLeft =
            milestoneBodyPosition.current.scrollLeft - xChange;

          const y = e.pageY - timelineSectionRef.current.offsetTop;
          const yChange = y - milestoneBodyPosition.current.startY;
          timelineSectionRef.current.scrollTop =
            milestoneBodyPosition.current.scrollTop - yChange;
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          if (isTouchingScreen) {
            return;
          }
          milestoneBodyPosition.current.isStartedScrolling = false;
          //don't use state to reduce UI re-renderings unexpectedly
          timelineSectionRef.current.style.cursor = 'auto';
        }}
        onMouseOut={(e) => {
          e.preventDefault();
          if (isTouchingScreen) {
            return;
          }
          milestoneBodyPosition.current.isStartedScrolling = false;
          //don't use state to reduce UI re-renderings unexpectedly
          timelineSectionRef.current.style.cursor = 'auto';
        }}>
        <div
          className="timeline-body"
          style={{
            '--day-container-element-width': `${dayContainerElementWidth}px`
          }}>
          {displayedMilestones?.map((milestone) => (
            <TimelineBar
              milestone={milestone}
              currentTimeFrame={currentTimeFrame}
              key={milestone.milestone_id}
              isTaskOpened={openedMilestones.includes(
                milestone.milestone_id
              )}
              elementWidthGap={
                dayContainerElementWidth / currentTimeFrame.length
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimelineBox;
