import classNames from 'classnames';
import './styles.scss';

const Table = ({ children, className }) => (
  <table
    className={classNames('table', {
      [className]: !!className
    })}>
    {children}
  </table>
);

const TableHeader = ({ children }) => (
  <thead className="table-header">{children}</thead>
);

const TableBody = ({ children }) => (
  <tbody className="table-body">{children}</tbody>
);

Table.Header = TableHeader;
Table.Body = TableBody;

export default Table;
