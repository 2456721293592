import './OptionGroup.css';
import PropTypes from 'prop-types';
import React from 'react';
import Option from '../Option/Option';
import CustomOption from '../CustomOption/CustomOption';

const OptionGroup = ({
  options,
  onChange,
  onCustomChange,
  index = '',
  isError = false,
  type = 'checkbox',
  ...question
}) => (
  <div className="option-group">
    {!!question?.title && (
      <div className={`title${isError ? ' wiggle-once' : ''}`}>
        {`${index}. ${question?.title}`}
        {isError && (
          <span
            style={{ color: 'var(--color-raspberry-glaze)' }}
            className="pulse">
            *
          </span>
        )}
      </div>
    )}
    {Object.values(options).map((option) =>
      option?.isCustom ? (
        <CustomOption
          key={option?.id}
          disabled={option?.disabled}
          type={type}
          {...option}
          onChange={(e) =>
            onCustomChange(e, question?.id, option?.id, type)
          }
        />
      ) : (
        <Option
          key={option?.id}
          disabled={option?.disabled}
          type={type}
          {...option}
          onChange={(e) =>
            onChange(e, question?.id, option?.id, option?.disableAll)
          }
        />
      )
    )}
  </div>
);

OptionGroup.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCustomChange: PropTypes.func.isRequired,
  index: PropTypes.string,
  isError: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio'])
};

export default React.memo(OptionGroup);
