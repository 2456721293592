export const centralContentVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.15
    }
  }
};
export const sidebarVariants = {
  show: {
    width: '20rem',
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    overflow: 'hidden',
    width: '0px',
    transition: {
      delay: 0.15,
      duration: 0.5
    }
  }
};

export const sidebarContentVariants = {
  show: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.15
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.15
    }
  }
};

export const newMatchesVariants = {
  show: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5
    }
  },
  hidden: {
    opacity: 0
  }
};

export const tabContentMobileVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: 'beforeChildren'
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      when: 'afterChildren'
    }
  }
};

export const filtersMobileVariants = {
  normal: {
    scale: 1,
    transition: {
      duration: 0.2,
      type: 'just'
    }
  },
  selected: {
    scale: 1.33,
    transition: {
      duration: 0.2,
      type: 'just'
    }
  }
};

export const activeCampaignIndicatorMobileVariants = {
  initial: {
    y: 50,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  },
  exit: {
    scale: 2,
    opacity: 0
  }
};

export const modalBackdropVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { when: 'afterChildren' } }
};

export const modalContentVariants = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0 }
};
