import { useState } from 'react';
import { ACTIVITY_FEED_TABS } from '../../constants/common';
import HeaderTabs from './header-tabs';
import FeedList from './feed-list';
import HistoryList from './history-list';

const HomeDesktopActivityFeed = () => {
  const [activeTab, setActiveTab] = useState(ACTIVITY_FEED_TABS.FEED);

  return (
    <div className="flex flex-col h-full">
      <HeaderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === ACTIVITY_FEED_TABS.FEED && <FeedList />}
      {activeTab === ACTIVITY_FEED_TABS.HISTORY && <HistoryList />}
    </div>
  );
};

export default HomeDesktopActivityFeed;
