import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import UpcomingInterviewList from '../../components/interviews/UpcomingInterviewList/UpcomingInterviewList';
import StatsMobile from '../../components/stats/StatsMobile/StatsMobile';
import CandidateListMobile from '../../components/candidates/CandidateList/Mobile/CandidateListMobile';
import { HOME_TABS } from '../../utils/misc';
import { NewMatchesListMobile } from '../../components/newMatches/NewMatchesList/Mobile/NewMatchesListMobile';
import {
  selectShowNewMatches,
  selectPreviewCandidate
} from '../../store/selectors/Candidate';
import {
  activeCampaignIndicatorMobileVariants,
  tabContentMobileVariants
} from '../../utils/animation';
import NewMatchAnimation from '../../components/animation';
import NewMatchBar from '../../components/candidates/NewMatchBar/NewMatchBar';
import { useGetNewMatchCandidatesQuery } from '../../queries/NewMatches';
import { ReactComponent as CampaignIcon } from '@src/assets/images/campaign-icon.svg';
import { ReactComponent as CandidateIcon } from '@src/assets/images/candidate-icon.svg';
import { ReactComponent as InterviewIcon } from '@src/assets/images/interview-icon.svg';
import { ReactComponent as ChartIcon } from '@src/assets/images/chart-icon.svg';

import './HomeMobileLayout.scss';
import CampaignList from '../../components/campaign/CampaignList/CampaignList';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const tabs = [
  HOME_TABS.CAMPAIGNS,
  HOME_TABS.INTERVIEWS,
  HOME_TABS.CANDIDATES,
  HOME_TABS.STATS
];

const HomeMobileLayout = ({
  selectedCampaign,
  setSelectedCampaign,
  campaigns
}) => {
  const [activeTab, setActiveTab] = useState(HOME_TABS.CANDIDATES);
  const showNewMatches = useSelector(selectShowNewMatches);
  const previewCandidate = useSelector(selectPreviewCandidate);
  const { data: newMatches } = useGetNewMatchCandidatesQuery();
  const activeTabIndex = useRef(2);

  const getActiveTabContent = () => {
    switch (activeTab) {
      case HOME_TABS.CAMPAIGNS:
        return (
          <CampaignList
            key="campaign-list"
            campaigns={campaigns}
            setSelectedCampaign={(campaign) => {
              setSelectedCampaign(campaign);
              setActiveTab(HOME_TABS.CANDIDATES);
            }}
            selectedCampaign={selectedCampaign}
          />
        );
      case HOME_TABS.INTERVIEWS:
        return <UpcomingInterviewList key="interviews" />;
      case HOME_TABS.CANDIDATES:
        return (
          <CandidateListMobile
            key="candidates"
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
          />
        );
      case HOME_TABS.STATS:
        return (
          <StatsMobile key="stats" selectedCampaign={selectedCampaign} />
        );
      default:
        return (
          <CandidateListMobile
            key="candidates"
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
          />
        );
    }
  };

  const handleGesture = useCallback((event) => {
    if (event.dir === 'Left') {
      if (activeTabIndex.current === tabs.length - 1) {
        activeTabIndex.current = 0;
      } else {
        activeTabIndex.current = activeTabIndex.current + 1;
      }
    }

    if (event.dir === 'Right') {
      if (activeTabIndex.current === 0) {
        activeTabIndex.current = tabs.length - 1;
      } else {
        activeTabIndex.current = activeTabIndex.current - 1;
      }
    }
    setActiveTab(tabs[activeTabIndex.current]);
  }, []);

  // Change bottom tabs when swiping
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => handleGesture(eventData),
    onSwipedRight: (eventData) => handleGesture(eventData),
    delta: {
      left: 20,
      right: 20
    }
  });

  return (
    <AnimatePresence initial={false} mode="wait">
      {!showNewMatches ? (
        <motion.div
          key="home-content-mobile"
          variants={tabContentMobileVariants}
          initial={'hidden'}
          animate={'show'}
          exit={'hidden'}
          id="homeMobileContent"
          className="home-mobile d-flex flex-column"
          {...handlers}>
          <AnimatePresence initial={false} mode="wait">
            {getActiveTabContent()}
          </AnimatePresence>
          <PermissionChecker permissions={['dashboard.candidate.write']}>
            {!previewCandidate && (
              <NewMatchBar
                data={newMatches}
                containerClassName="new-match-bar-container"
              />
            )}
          </PermissionChecker>
          <div className="reserved-bottom-navigation"></div>
          <div className="bottom-navigation d-flex justify-content-between align-items-center">
            <PermissionChecker permissions={['dashboard.campaign.read']}>
              <div className="d-flex justify-content-center">
                <button
                  className={classNames('tab-btn', {
                    'tab-btn__active': activeTab === HOME_TABS.CAMPAIGNS
                  })}
                  onClick={() => setActiveTab(HOME_TABS.CAMPAIGNS)}>
                  <CampaignIcon
                    className={classNames({
                      'fill-td-primary': activeTab === HOME_TABS.CAMPAIGNS,
                      'fill-td-grey': activeTab !== HOME_TABS.CAMPAIGNS
                    })}
                  />
                </button>
                <AnimatePresence>
                  {selectedCampaign && (
                    <motion.span
                      variants={activeCampaignIndicatorMobileVariants}
                      initial={'initial'}
                      animate={'animate'}
                      exit={'exit'}
                      className="selected-campaign-indicator"
                    />
                  )}
                </AnimatePresence>
              </div>
            </PermissionChecker>
            <button
              className={classNames('tab-btn', {
                'tab-btn__active': activeTab === HOME_TABS.INTERVIEWS
              })}
              onClick={() => setActiveTab(HOME_TABS.INTERVIEWS)}>
              <InterviewIcon
                className={classNames({
                  'fill-td-primary': activeTab === HOME_TABS.INTERVIEWS,
                  'fill-td-grey': activeTab !== HOME_TABS.INTERVIEWS
                })}
              />
            </button>
            <button
              className={classNames('tab-btn', {
                'tab-btn__active': activeTab === HOME_TABS.CANDIDATES
              })}
              onClick={() => setActiveTab(HOME_TABS.CANDIDATES)}>
              <CandidateIcon
                className={classNames({
                  'fill-td-primary': activeTab === HOME_TABS.CANDIDATES,
                  'fill-td-grey': activeTab !== HOME_TABS.CANDIDATES
                })}
              />
            </button>
            <button
              className={classNames('tab-btn', {
                'tab-btn__active': activeTab === HOME_TABS.STATS
              })}
              onClick={() => setActiveTab(HOME_TABS.STATS)}>
              <ChartIcon
                className={classNames({
                  'fill-td-primary': activeTab === HOME_TABS.STATS,
                  'fill-td-grey': activeTab !== HOME_TABS.STATS
                })}
              />
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="new-matches-mobile"
          variants={tabContentMobileVariants}
          initial={'hidden'}
          animate={'show'}
          exit={'hidden'}
          className="d-flex flex-column flex-grow-1 h-full w-full fixed left-0 top-0 z-[2]">
          <NewMatchAnimation />
          <NewMatchesListMobile />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HomeMobileLayout;
