import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import {
  modalContentVariants,
  modalBackdropVariants
} from '../../../utils/animation';

import './styles.scss';
import classNames from 'classnames';

const Modal = ({
  isOpen,
  className,
  children,
  width,
  bodyClassName,
  maxWidth = 'auto'
}) =>
  createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={modalBackdropVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className={`modal ${className}`}>
          <motion.div
            variants={modalContentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={classNames(
              'd-flex flex-column justify-content-center text-center content',
              bodyClassName
            )}
            style={{ width, maxWidth }}>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.querySelector('body')
  );

export default Modal;
