import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  constructLocationString,
  linkSocialIcon
} from '../../../../candidates/helper';
import { ReactComponent as GithubIcon } from '../../../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../../../assets/images/portfolio-icon.svg';
import PlagiarismRoundedIcon from '@mui/icons-material/PlagiarismRounded';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/chevron-icon.svg';
import Avatar from '../../../../shared/Avatar/Avatar';
import './CandidateProfileTab.scss';

const CandidateProfileTab = ({ interviewData }) => {
  const {
    full_name,
    tech_stack,
    city,
    state,
    country,
    assessment_score,
    avatar,
    resume,
    experience,
    linkedin_url,
    portfolio_url,
    github_url,
    culture_fit,
    skills,
    educations,
    hobbies,
    resume_highlights,
    motto,
    companies
  } = interviewData;

  const parsedCultureFit = culture_fit ? parseFloat(culture_fit) : 0;

  const firstEducation = educations?.[0];

  return (
    <Box className="candidate-profile-tab">
      <Box padding="1rem 2rem">
        <Grid container gap="1rem">
          <Grid item>
            <Typography
              textAlign="center"
              color="#4d565c"
              fontWeight="600">
              Culture Fit
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="0.5rem">
              <StarRoundedIcon
                sx={parsedCultureFit > 1 ? { fill: '#14b9b8' } : {}}
              />
              <StarRoundedIcon
                sx={parsedCultureFit > 2 ? { fill: '#14b9b8' } : {}}
              />
              <StarRoundedIcon
                sx={parsedCultureFit > 2.5 ? { fill: '#14b9b8' } : {}}
              />
            </Box>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item textAlign="center">
            <Typography
              color="#4d565c"
              fontWeight="600"
              marginBottom="0.5rem">
              Experience
            </Typography>
            <Typography textAlign="center">
              {experience
                ? experience > 1
                  ? `${experience} years`
                  : `${experience} year`
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Grid
              container
              gap="0.5rem"
              justifyContent="center"
              alignItems="center"
              height="100%">
              {/* <Grid item>
                {linkSocialIcon('test.com', StackoverflowIcon, 'Stackoverflow')}
              </Grid> */}
              <Grid item>
                {linkSocialIcon(github_url, GithubIcon, 'Github')}
              </Grid>
              <Grid item>
                {linkSocialIcon(linkedin_url, LinkedinIcon, 'Linkedin')}
              </Grid>
              <Grid item>
                {linkSocialIcon(portfolio_url, PortfolioIcon, 'Portfolio')}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item display="flex" alignItems="center">
            <a
              href={resume}
              className="candidate-no-underline"
              target="_blank"
              rel="noopener noreferrer nofollow">
              <Button variant="contained" color="primary">
                <PlagiarismRoundedIcon color="#fff" />
                VIEW FULL RESUME
              </Button>
            </a>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container padding="1.5rem 2rem" gap="2rem">
        <Grid item className="d-flex personal-info-row">
          <Box className="photo-info-container">
            <Box className="photo-container">
              {!!assessment_score && <ChevronIcon className="chevron" />}
              <Avatar
                src={avatar}
                alt={`${full_name}'s Profile`}
                width={6}
                height={6}
              />
            </Box>
          </Box>
          <Box className="d-flex flex-column justify-content-between">
            <Box className="d-flex flex-column">
              <Typography
                className="d-inline-block align-self-start"
                fontSize="18px"
                fontWeight="700">
                {full_name}
              </Typography>
              <Typography
                className="d-inline-block align-self-start"
                fontSize="16px"
                fontWeight="700">
                {tech_stack}
              </Typography>
            </Box>
            <Box className="d-flex flex-column">
              <span className="location d-inline-block align-self-start">
                {constructLocationString(city, state, country)}
              </span>
            </Box>
          </Box>
        </Grid>
        {Boolean(companies?.length) && (
          <Grid item>
            <Typography
              color="#2e9dd7"
              fontSize={16}
              fontWeight={600}
              marginBottom="16px"
              minWidth={100}>
              Worked For
            </Typography>
            <Box>
              <Grid container gap="16px">
                {companies.map(({ company_logo_url, company_name }) => (
                  <Grid item key={company_name}>
                    <img
                      src={company_logo_url}
                      className="w-10"
                      alt={company_name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Box padding="1rem 2rem" height="250px" overflow="auto">
        <Typography
          color="#2e9dd7"
          fontSize={16}
          fontWeight={600}
          marginBottom="4px">
          Education
        </Typography>
        {firstEducation && (
          <>
            <Typography fontSize={14}>
              {firstEducation.university}
            </Typography>
            <Typography fontSize={14} fontWeight={600}>
              {firstEducation?.specialization +
                ', ' +
                firstEducation.course}
            </Typography>
          </>
        )}
        {Boolean(resume_highlights) && (
          <>
            <Typography
              color="#2e9dd7"
              fontSize={16}
              fontWeight={600}
              marginBottom="4px"
              marginTop="16px">
              Resume Highlights
            </Typography>
            <Typography fontSize={14} lineHeight={1.75}>
              {resume_highlights}
            </Typography>
          </>
        )}
        {Boolean(motto) && (
          <div className="text-[#509ec6] font-semibold my-4">
            “{motto}”
          </div>
        )}
        <Typography
          color="#2e9dd7"
          fontSize={16}
          fontWeight={600}
          marginBottom="4px"
          marginTop="16px">
          Skills
        </Typography>
        <ul className="ml-4 list-disc">
          {skills.map(({ skill_name }) => (
            <li key={skill_name}>{skill_name}</li>
          ))}
        </ul>
        <Typography
          color="#2e9dd7"
          fontSize={16}
          fontWeight={600}
          marginBottom="4px"
          marginTop="16px">
          Hobbies
        </Typography>
        <ul className="ml-4 list-disc">
          {hobbies.map(({ hobby_name }) => (
            <li key={hobby_name}>{hobby_name}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default CandidateProfileTab;
