import { useState, useRef, useEffect, useCallback } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useHistory } from 'react-router-dom';
import MilestoneInfo from '../../components/project-management/MilestoneInfo';
import ProjectMetrics from '../../components/project-management/ProjectMetrics';
import ProjectHighlights from '../../components/project-management/ProjectHighlights';
import BottomNavigation from '../../components/project-management/BottomNavigation/BottomNavigation';
import ProjectInfoModal from '../../components/project-management/ProjectInfoModal';
import HeaderButtons from '../../components/project-management/HeaderButtons';
import ProjectService from '../../services/Project';
import TristateToggle, {
  TristateToggleMobile
} from '../../components/project-management/TristateToggle';
import TimelineBox from '../../components/project-management/TimelineBox';
import SearchBox from '../../components/project-management/SearchBox';
import config from '../../config';
import { useGetClientProjectsQuery } from '../../queries/Project';
import { ReactComponent as OpeningSectionIcon } from '../../assets/images/opening-section.svg';
import { ReactComponent as ClosingSectionIcon } from '../../assets/images/closing-section.svg';
import {
  getDays,
  getTimeFrame,
  getDateWithoutTime,
  getCurrentWeekDateRange
} from '../../utils/dateTimeHelper';
import { useIfMobile } from '../../utils/responsive';

import {
  filterOutBasedOnConditions,
  getUrlParamsFromQueryString
} from '../../utils/common';

import { ReactComponent as ArrowIcon } from '../../assets/images/right-arrow-icon.svg';

const TODAY_DATE_RANGE = {
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    getDays(new Date())
  )
};

const ProjectManagement = () => {
  const isMobile = useIfMobile();
  const { search } = useLocation();
  const history = useHistory();

  const urlParams = getUrlParamsFromQueryString(search);

  const { data } = useGetClientProjectsQuery();

  const [projectDetails, setProjectDetails] = useState();

  const {
    milestones,
    project_name,
    project_start_date,
    project_end_date,
    project_notes,
    resource_count
  } = projectDetails || {};

  const [displayedMilestones, setDisplayedMilestones] = useState();
  const [isSectionOpened, setIsSectionOpened] = useState(true);
  const [isProjectNavigationShown, setIsProjectNavigationShown] =
    useState(false);
  const [openedMilestones, setOpenedMilestones] = useState([]);
  const [isProjectInfoModalOpened, setIsProjectInfoModalOpened] =
    useState(false);
  const [filterOuts, setFilterOuts] = useState({
    searchValue: '',
    statuses: [],
    assignees: [],
    milestones: []
  });
  const [isSearchBoxOpened, setIsSearchBoxOpened] = useState(false);
  const [timelineType, setTimelineType] = useState(
    config.projectTimelineType.FULL
  );
  const [timeline, setTimeline] = useState({
    startDate: new Date(
      new Date(project_start_date).getFullYear(),
      new Date(project_start_date).getMonth(),
      1
    ),
    endDate: new Date(
      new Date(project_end_date).getFullYear(),
      new Date(project_end_date).getMonth(),
      getDays(project_end_date)
    )
  });
  const [currentTimeFrame, setCurrentTimeFrame] = useState(
    getTimeFrame(timeline.startDate, timeline.endDate)
  );

  const milestoneSectionRef = useRef();
  const timelineSectionRef = useRef();

  const rerenderTimeline = useCallback(() => {
    if (timelineType === config.projectTimelineType.FULL) {
      setTimeline({
        startDate: new Date(
          new Date(project_start_date).getFullYear(),
          new Date(project_start_date).getMonth(),
          1
        ),
        endDate: new Date(
          new Date(project_end_date).getFullYear(),
          new Date(project_end_date).getMonth(),
          getDays(project_end_date)
        )
      });
    }
    if (timelineType === config.projectTimelineType.MONTHLY) {
      setTimeline(TODAY_DATE_RANGE);
    }

    if (timelineType === config.projectTimelineType.WEEKLY) {
      setTimeline(getCurrentWeekDateRange());
    }
  }, [project_start_date, project_end_date, timelineType]);

  const fetchProjectDetails = async (projectId) => {
    const { project_detail } =
      await ProjectService.getClientProjectDetails({
        project_id: projectId
      });
    setProjectDetails(project_detail);
  };

  useEffect(() => {
    if (data?.project_list?.length > 0) {
      const firstProjectId = data.project_list[0].id;

      fetchProjectDetails(urlParams.id || firstProjectId);
    }
  }, [data, urlParams.id]);

  useEffect(() => {
    rerenderTimeline();
  }, [timelineType, projectDetails, rerenderTimeline]);

  useEffect(() => {
    const timeFrame = getTimeFrame(timeline.startDate, timeline.endDate);
    setCurrentTimeFrame(timeFrame);
  }, [timeline]);

  useEffect(() => {
    setDisplayedMilestones(
      filterOutBasedOnConditions(milestones, filterOuts)
    );
  }, [filterOuts, milestones]);

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        className="project-management"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <div className="header">
          <div
            className={classNames('project-title-container', {
              active: isProjectNavigationShown,
              invisible: !project_name
            })}
            onClick={() => {
              setIsProjectNavigationShown(!isProjectNavigationShown);
            }}>
            <span className="project-title">{project_name}</span>
            <span
              className={classNames('arrow-container', {
                active: isProjectNavigationShown
              })}>
              <ArrowIcon></ArrowIcon>
            </span>
            <div
              className={classNames('project-switcher-backdrop', {
                'project-menu-active': isProjectNavigationShown
              })}
              onClick={() => {
                setIsProjectNavigationShown(false);
              }}>
              <div
                className="project-navigation"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                {data?.project_list?.map(({ id, project_name }) => (
                  <button
                    key={id}
                    className={classNames('button project-button', {
                      active: id === projectDetails?.id
                    })}
                    onClick={() => {
                      fetchProjectDetails(id);
                      setIsProjectNavigationShown(false);
                      history.push({
                        search: new URLSearchParams({ id }).toString()
                      });
                    }}>
                    {project_name.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="project-legend-container">
              <div className="flex">
                <span className="legend-icon on-track"></span>
                <span className="legend-title">On track</span>
              </div>
              <div className="flex">
                <span className="legend-icon at-risk"></span>
                <span className="legend-title">At risk</span>
              </div>
              <div className="flex">
                <span className="legend-icon behind-schedule"></span>
                <span className="legend-title">Behind schedule</span>
              </div>
              <div className="flex">
                <span className="legend-icon completed"></span>
                <span className="legend-title">Completed</span>
              </div>
            </div>
          )}
          <HeaderButtons
            setIsProjectInfoModalOpened={setIsProjectInfoModalOpened}
            setIsSearchBoxOpened={setIsSearchBoxOpened}
            isProjectInfoModalOpened={isProjectInfoModalOpened}
            isSearchBoxOpened={isSearchBoxOpened}></HeaderButtons>
        </div>
        <div className="full-timeline-body">
          {isProjectInfoModalOpened && (
            <ProjectInfoModal
              onClose={() => {
                setIsProjectInfoModalOpened(false);
              }}
              projectDetails={projectDetails}></ProjectInfoModal>
          )}
          {isSearchBoxOpened && (
            <SearchBox
              closeSearchBox={() => setIsSearchBoxOpened(false)}
              filterOuts={filterOuts}
              milestones={milestones}
              filterOutValues={(value) =>
                setFilterOuts({
                  ...filterOuts,
                  ...value
                })
              }></SearchBox>
          )}
          <div className="timeline-container">
            <div
              className={classNames('milestones', {
                'mobile-view': isMobile
              })}>
              <div className="milestones-header">
                {!isMobile && (
                  <>
                    <button
                      className={classNames(
                        'button',
                        'custom-button',
                        'today-button',
                        {
                          active: currentTimeFrame?.some(
                            ({ value }) =>
                              value.toDateString() ===
                              getDateWithoutTime(new Date()).toDateString()
                          )
                        }
                      )}
                      onClick={rerenderTimeline}>
                      TODAY
                    </button>
                    <TristateToggle
                      timelineType={timelineType}
                      setTimelineType={setTimelineType}></TristateToggle>
                  </>
                )}
                {isMobile && (
                  <TristateToggleMobile
                    timelineType={timelineType}
                    setTimelineType={
                      setTimelineType
                    }></TristateToggleMobile>
                )}
              </div>

              <div
                className={classNames('milestones-body', {
                  'project-info-closed': !isSectionOpened,
                  'mobile-view': isMobile
                })}
                ref={(node) => (milestoneSectionRef.current = node)}
                onScroll={(e) => {
                  timelineSectionRef.current.scrollTop =
                    e.target.scrollTop;
                }}>
                {displayedMilestones?.map((milestone) => (
                  <MilestoneInfo
                    milestone={milestone}
                    key={milestone.milestone_id}
                    isTaskOpened={openedMilestones.includes(
                      milestone.milestone_id
                    )}
                    toggle={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      if (
                        openedMilestones.includes(milestone.milestone_id)
                      ) {
                        setOpenedMilestones(
                          openedMilestones.filter(
                            (value) => value !== milestone.milestone_id
                          )
                        );
                      } else {
                        setOpenedMilestones([
                          ...openedMilestones,
                          milestone.milestone_id
                        ]);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            <TimelineBox
              timelineType={timelineType}
              timeline={timeline}
              setTimeline={setTimeline}
              currentTimeFrame={currentTimeFrame}
              displayedMilestones={displayedMilestones}
              isSectionOpened={isSectionOpened}
              openedMilestones={openedMilestones}
              projectDetails={projectDetails}
              milestoneSectionRef={milestoneSectionRef}
              timelineSectionRef={timelineSectionRef}></TimelineBox>
          </div>
          {!isMobile && (
            <div
              className={classNames('project-info', {
                closed: !isSectionOpened
              })}>
              <button
                className="project-info-action"
                onClick={() => {
                  setIsSectionOpened(!isSectionOpened);
                }}>
                {isSectionOpened ? (
                  <ClosingSectionIcon></ClosingSectionIcon>
                ) : (
                  <OpeningSectionIcon></OpeningSectionIcon>
                )}
              </button>
              <ProjectMetrics
                milestones={milestones}
                resourceCount={resource_count}></ProjectMetrics>
              <ProjectHighlights
                projectNotes={project_notes}></ProjectHighlights>
            </div>
          )}
          {isMobile && (
            <BottomNavigation
              projectNotes={project_notes}
              milestones={milestones}
              resourceCount={resource_count}></BottomNavigation>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectManagement;
