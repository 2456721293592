import { createSelector } from 'reselect';

const authSlice = (state) => state.AuthSlice;

export const selectRegistrationStep = createSelector(
  authSlice,
  (state) => state.registration.step
);
export const selectIsRegistrationTouched = createSelector(
  authSlice,
  (state) => state.registration.isTouched
);
export const selectIsChangePassword = createSelector(
  authSlice,
  (state) => state.isChangePassword
);
export const selectIsCreateNewPassword = createSelector(
  authSlice,
  (state) => state.isCreateNewPassword
);
export const selectIsOTPResendPending = createSelector(
  authSlice,
  (state) => state.otp.isResendPending
);
export const selectOTPPhone = createSelector(
  authSlice,
  (state) => state.otp.phone
);
export const selectCurrentQuestionnaire = createSelector(
  authSlice,
  (state) =>
    state.registration.step === 1
      ? state.questionnaire.partOne
      : state.questionnaire.partTwo
);
export const selectQuestionnaire = createSelector(
  authSlice,
  (state) => state.questionnaire
);

export const selectBasicInfo = createSelector(
  authSlice,
  (state) => state.basicInfo
);
