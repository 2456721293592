import './styles.scss';

const SelectInput = ({ onChange, options, label, name, value }) => (
  <div className="select-input">
    <label>{label}</label>
    <select className="form-control" onChange={onChange} name={name}>
      {options?.map((option) => (
        <option
          key={option.value}
          value={option.value}
          selected={
            option.selected || String(value) === String(option.value)
          }>
          {option.text}
        </option>
      ))}
    </select>
  </div>
);

export default SelectInput;
