import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Pause } from '../../../assets/images/campaign-management/pause.svg';
import { ReactComponent as SourcingIcon } from '../../../assets/images/campaign-management/sourcing.svg';
import { ReactComponent as HiredCandidateIcon } from '../../../assets/images/campaign-management/hired-candidate.svg';
import { ReactComponent as PendingCandidateIcon } from '../../../assets/images/campaign-management/pending-candidate.svg';
import { ReactComponent as PendingInterviewIcon } from '../../../assets/images/campaign-management/pending-interview.svg';
import { ReactComponent as DepositUnpaidIcon } from '../../../assets/images/campaign-management/original-deposit-unpaid.svg';
import { ReactComponent as ActiveCandidateIcon } from '../../../assets/images/campaign-management/active-candidate.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/images/campaign-management/archive.svg';
import { CampaignCardMenu } from './campaign-card-menu';

import CampaignPaymentFormModal from '../CampaignPaymentFormModal';
import { useEffect, useState } from 'react';
import ConfirmationModal from '../../shared/ConfirmationModal';
import { useDeleteCampaign } from '../../../queries/Campaign';
import CampaignDetailsModal from '../CampaignDetailsModal';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import Tooltip from '@src/components/Tooltip';
import { cn } from '@src/utils';

import './styles/campaign-card.scss';

export const CampaignCard = ({ campaign, isSelected, selectCampaign }) => {
  const [currentCampaign, setCurrentCampaign] = useState(
    cloneDeep(campaign)
  );

  const { mutate: deleteCampaign } = useDeleteCampaign();

  useEffect(() => {
    setCurrentCampaign(cloneDeep(campaign));
  }, [campaign]);

  const [
    isCampaignPaymentFormModalOpened,
    setIsCampaignPaymentFormModalOpened
  ] = useState(false);
  const [
    isDeletionConfirmationModalOpened,
    setIsDeletionConfirmationModalOpened
  ] = useState(false);
  const [isCampaignDetailsModalOpened, setIsCampaignDetailsModalOpened] =
    useState(false);

  const {
    id,
    campaign_name,
    campaign_start_date,
    campaign_description,
    deposit_paid,
    is_active,
    is_archived,
    campaign_statuses
  } = currentCampaign;

  const isPaused = Boolean(!is_active);
  const isPaid = Boolean(deposit_paid);
  const isArchived = Boolean(is_archived);

  const isStatusDisplayed = !isPaused && isPaid;

  const isStatusDisplayedWithSourcing =
    isStatusDisplayed && !isArchived && campaign_statuses?.sourcing_status;

  const handleDeleteCampaign = () => {
    deleteCampaign({ campaign_id: id });
    setIsDeletionConfirmationModalOpened(false);
    setIsCampaignDetailsModalOpened(false);
  };

  return (
    <>
      <div
        className={classNames('campaign-card state-transition relative', {
          selected: isSelected,
          disabled: isArchived
        })}
        onClick={() => {
          selectCampaign(campaign);
        }}>
        <div
          className={cn(
            'absolute -right-2 top-1/2 w-2 h-8 rounded-r-[4px] -translate-y-1/2',
            {
              'bg-[#14b9b8]': is_active && !isArchived,
              'bg-[#cd5480]': isPaused && !isArchived,
              'bg-[#f9d24d]': !isPaid && !isArchived
            }
          )}
        />
        <div className="statuses d-flex flex-column w-9 justify-content-center align-items-center gap-2 bg-td-brig">
          {isArchived && (
            <Tooltip content="Campaign is archived" placement="top">
              <ArchiveIcon fill="#D3E9F6" className="status-display" />
            </Tooltip>
          )}
          {!isArchived && (
            <>
              {!isArchived &&
                campaign_statuses &&
                !campaign_statuses.deposit_paid && (
                  <Tooltip content="Deposit unpaid" placement="top">
                    <DepositUnpaidIcon
                      fill="#fff"
                      width={24}
                      height={24}
                      className="status-display"
                    />
                  </Tooltip>
                )}
              {isStatusDisplayed && campaign_statuses?.sourcing_status && (
                <Tooltip content="Sourcing is happening" placement="top">
                  <SourcingIcon
                    fill="#D3E9F6"
                    width={24}
                    height={24}
                    className="status-display"
                  />
                </Tooltip>
              )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_pending_candidates && (
                  <Tooltip
                    content="Has pending candidate(s)"
                    placement="top">
                    <PendingCandidateIcon
                      fill="#D3E9F6"
                      width={24}
                      height={24}
                      className="status-display"
                    />
                  </Tooltip>
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_pending_interviews && (
                  <Tooltip
                    content="Interview(s) is happening"
                    placement="top">
                    <PendingInterviewIcon
                      fill="#D3E9F6"
                      width={24}
                      height={24}
                      className="status-display"
                    />
                  </Tooltip>
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_hired_candidates && (
                  <Tooltip content="Candidate is hired" placement="top">
                    <HiredCandidateIcon
                      fill="#D3E9F6"
                      width={24}
                      height={24}
                      className="status-display"
                    />
                  </Tooltip>
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_active_candidates && (
                  <Tooltip
                    content="Candidate is hired and working"
                    placement="top">
                    <ActiveCandidateIcon
                      fill="#D3E9F6"
                      width={24}
                      height={24}
                      className="status-display"
                    />
                  </Tooltip>
                )}
              {isPaused && isPaid && (
                <Tooltip content="Campaign is paused" placement="top">
                  <Pause className="status-display" />
                </Tooltip>
              )}
            </>
          )}
        </div>
        <div className="p-2 pe-1 w-100 card-content min-h-[63px]">
          <div className="gap-1 mb-2 d-flex align-items-center justify-content-between">
            <div className="name text-one-line">{campaign_name}</div>
            {campaign_start_date && (
              <span className="date">
                {new Date(campaign_start_date).toLocaleDateString()}
              </span>
            )}
            <CampaignCardMenu
              campaignId={id}
              isPaused={isPaused}
              isPaid={isPaid}
              isArchived={isArchived}
              onDeleteClick={() =>
                setIsDeletionConfirmationModalOpened(true)
              }
              onPayDepositClick={() => {
                setIsCampaignPaymentFormModalOpened(true);
              }}
              onViewCampaignDetailsClick={() =>
                setIsCampaignDetailsModalOpened(true)
              }
              updateActivationState={() => {
                setCurrentCampaign((prev) => ({
                  ...prev,
                  is_active: is_active ? 0 : 1
                }));
              }}
            />
          </div>
          <p className="m-0 description">{campaign_description}</p>
        </div>
      </div>
      {isCampaignPaymentFormModalOpened && (
        <CampaignPaymentFormModal
          campaignId={id}
          onClose={() => setIsCampaignPaymentFormModalOpened(false)}
          onSuccess={() => {
            setCurrentCampaign((prev) => ({
              ...prev,
              deposit_paid: true,
              campaign_statuses: {
                ...prev.campaign_statuses,
                deposit_paid: true,
                is_active: true,
                sourcing_status: true
              },
              campaign_start_date: new Date(),
              is_campaign_start: true,
              is_active: true,
              sourcing_status: true
            }));
            setIsCampaignPaymentFormModalOpened(false);
            toast.success('The payment is successful');
          }}
        />
      )}
      {isDeletionConfirmationModalOpened && (
        <ConfirmationModal
          message="Your are about deleting your campaign. Are you sure you want to delete?"
          isOpen={isDeletionConfirmationModalOpened}
          onCancel={() => setIsDeletionConfirmationModalOpened(false)}
          onConfirm={() => handleDeleteCampaign()}
        />
      )}
      {isCampaignDetailsModalOpened && (
        <CampaignDetailsModal
          campaignId={currentCampaign.id}
          onClose={() => setIsCampaignDetailsModalOpened(false)}
        />
      )}
    </>
  );
};

CampaignCard.propTypes = {
  campaign: PropTypes.object
};
