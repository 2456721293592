import axios from 'axios';
import config from '../config';
import { store } from '../store';
import { routes } from '../router/routes';
import { logoutSuccess } from '../store/slices/Auth';
import queryStore from '../queries';
import { navigate } from './history';
import { getCookie, deleteCookie } from './cookies';

export const attachQueryParams = (endpoint, params) => {
  if (params !== undefined) {
    let queryString = Object.keys(params)
      .reduce((a, k) => {
        if (params[k] !== null && params[k] !== undefined) {
          a.push(k + '=' + encodeURIComponent(params[k]));
        }
        return a;
      }, [])
      .join('&');

    return queryString ? `${endpoint}?${queryString}` : endpoint;
  }

  return endpoint;
};

const api = axios.create({
  baseURL: config.api.baseUrl
});

api.interceptors.request.use(
  (apiConfig) => {
    const token = getCookie('token');

    if (token) {
      apiConfig.headers.Authorization = `Bearer ${token}`;
    }

    /* 'upload/file' endpoint can't be encrypted
     * formData boundary can't be calculated when payload is encrypted
     */
    if (!apiConfig.url.includes('upload/file')) {
      apiConfig.data = { data: { newData: apiConfig.data } };
    } else {
      apiConfig.headers['Content-Type'] = 'multipart/form-data';
    }

    return apiConfig;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  async function (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.message === 'jwt expired' ||
      error?.response?.data?.message === 'invalid token'
    ) {
      deleteCookie('token');
      store.dispatch(logoutSuccess());
      navigate(routes.login);
      queryStore.removeQueries();

      return Promise.reject({ ...error, errorId: 401 });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

export const getAxiosError = (errorResponse) => {
  return {
    error:
      errorResponse?.response?.data?.error?.errorMessage ||
      errorResponse?.response?.data?.error?.message ||
      errorResponse?.response?.data?.message ||
      errorResponse?.response?.data?.errorMessage ||
      errorResponse?.response?.data?.error ||
      errorResponse?.response?.data ||
      errorResponse?.errorMessage ||
      errorResponse?.error ||
      errorResponse?.message ||
      errorResponse ||
      'Something went wrong',
    status: errorResponse?.status
  };
};

export const getAxiosErrorMessage = (errorResponse) => {
  if (typeof errorResponse === 'string') {
    return errorResponse;
  }

  return getAxiosError(errorResponse).error;
};

export default async function request({
  url,
  method,
  data,
  headers = {}
}) {
  const { data: responseData } = await api({
    method,
    url,
    headers,
    data
  });

  return responseData;
}
