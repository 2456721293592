import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const navigate = (route = '/') => history.push(route);

export const navigateBack = () => history.goBack();

export const handleBrowserReload = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

export default history;
