import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Table from '../../compliance-management/Table';
import SingleFileSelector from '../../shared/SingleFileSelector';
import { ReactComponent as FilledTrashBinIcon } from '../../../assets/images/filled-trash-bin.svg';
import RequirementBottomBar from '../RequirementBottomBar';
import {
  REQUIREMENT_TYPE,
  TWO_WAY_UPLOAD_TYPE,
  UPLOAD_TYPE
} from '../../../constants/common';
import Input from '../../../components/shared/Input';
import ConfirmationModal from '../../../components/shared/ConfirmationModal';
import UpdateRequirementModal from '../UpdateRequirementModal';
import { ReactComponent as CogIcon } from '../../../assets/images/cog-icon.svg';
import { ReactComponent as GreenCircularTickIcon } from '../../../assets/images/green-circular-tick-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { useUploadRequirementFileQuery } from '../../../queries/Compliance';

import './styles.scss';
import { Typography } from '@mui/material';
import PermissionChecker, {
  usePermissionChecker
} from '@src/components/shared/PermissionChecker';

const TemplateTable = ({
  requirements,
  isLoading,
  updateRequirements,
  isChecklist
}) => {
  const hasWritePermission = usePermissionChecker([
    'compliance_management.templates.write'
  ]);
  const [currentRequirements, setCurrentRequirements] =
    useState(requirements);
  const [upcomingDeletedItem, setUpcomingDeletedItem] = useState();
  const [upcomingUpdatedRequirement, setUpcomingUpdatedRequirement] =
    useState();
  const newTempIdRef = useRef(0);

  const { mutateAsync: uploadRequirementFile } =
    useUploadRequirementFileQuery();

  useEffect(() => {
    if (requirements) {
      setCurrentRequirements(requirements);
    }
  }, [requirements]);

  const onAdd = (newRequirement) => {
    const isExistingRequirement = currentRequirements.some(
      (item) =>
        item.requirement === newRequirement.requirement && !item.isDeleted
    );
    if (isExistingRequirement) {
      toast.error('Cannot add another requirement with the same name');
      return;
    }

    const updatedRequirements = [
      ...currentRequirements,
      {
        ...newRequirement,
        id: `new_${newTempIdRef.current}`
      }
    ];
    setCurrentRequirements(updatedRequirements);
    updateRequirements(updatedRequirements);
    newTempIdRef.current += 1;
  };
  const onRemove = (removedRequirement) => {
    const isNewlyCreatedRequirement = String(
      removedRequirement?.id
    ).includes('new_');
    const updatedRequirements = isNewlyCreatedRequirement
      ? requirements.filter((item) => item !== removedRequirement)
      : requirements.map((item) =>
          removedRequirement === item ? { ...item, isDeleted: true } : item
        );
    setCurrentRequirements(updatedRequirements);
    updateRequirements(updatedRequirements);
    setUpcomingDeletedItem(null);
  };

  const onUpdate = (updatedRequirement, originalItem) => {
    const updatedRequirements = currentRequirements.map((item) =>
      originalItem !== item
        ? item
        : {
            ...item,
            ...updatedRequirement,
            requirement_content:
              updatedRequirement.requirement_type &&
              updatedRequirement.requirement_type !== item.requirement_type
                ? ''
                : updatedRequirement.requirement_content ??
                  item.requirement_content
          }
    );

    setCurrentRequirements(updatedRequirements);
    updateRequirements(updatedRequirements);
    setUpcomingUpdatedRequirement(null);
  };

  const renderTwoWayRequirement = (item) => {
    if (item.upload_by === TWO_WAY_UPLOAD_TYPE.MYSELF.value) {
      return (
        <>
          <SingleFileSelector
            defaultFileName={item.requirement_content}
            onChange={async (file) => {
              const data = await uploadRequirementFile(file);
              onUpdate(
                {
                  requirement_content: data?.file_url
                },
                item
              );
            }}
            disabled={!hasWritePermission}
            onRemove={() => {
              onUpdate(
                {
                  requirement_content: ''
                },
                item
              );
            }}
          />
          {item.requirement_response ? (
            <p className="mt-1">{item.requirement_response}</p>
          ) : (
            <p className="mt-1">
              Link to file uploaded by admin will appear here
            </p>
          )}
        </>
      );
    }

    if (
      item.upload_by === TWO_WAY_UPLOAD_TYPE.ADMIN.value &&
      !item.requirement_content
    ) {
      return <p>Link to file uploaded by admin will appear here</p>;
    }

    return (
      <>
        <SingleFileSelector
          defaultFileName={item.requirement_response}
          onChange={async (file) => {
            const data = await uploadRequirementFile(file);
            onUpdate(
              {
                requirement_response: data?.file_url
              },
              item
            );
          }}
          disabled={!hasWritePermission}
          onRemove={() => {
            onUpdate(
              {
                requirement_response: ''
              },
              item
            );
          }}
        />
        <Typography
          marginTop={1}
          display="block"
          width="260px"
          as="a"
          target="_blank"
          href={item.requirement_content}>
          {item.requirement_content}
        </Typography>
      </>
    );
  };

  const renderUrlRequirement = (item) => {
    if (item.upload_by === TWO_WAY_UPLOAD_TYPE.MYSELF.value) {
      return (
        <Input
          inputClassName="custom-input-size"
          placeholder="URL"
          value={item.requirement_content}
          disabled={!hasWritePermission}
          onChange={(e) => {
            onUpdate(
              {
                requirement_content: e.target.value
              },
              item
            );
          }}
        />
      );
    }

    if (
      item.upload_by === TWO_WAY_UPLOAD_TYPE.ADMIN.value &&
      item.requirement_content
    ) {
      return (
        <Typography
          display="block"
          width="260px"
          as="a"
          target="_blank"
          href={item.requirement_content}>
          {item.requirement_content}
        </Typography>
      );
    }

    return <p>URL uploaded by admin will appear here</p>;
  };

  return (
    <div className="template-table-container">
      <ConfirmationModal
        message='Your are about removing your requirement, are you sure you want to do that? You still can find available files which are attached with this requirement in the "FILES" section.'
        isOpen={Boolean(upcomingDeletedItem)}
        onCancel={() => setUpcomingDeletedItem(null)}
        onConfirm={() => onRemove(upcomingDeletedItem)}
      />
      <div className="table-wrapper">
        <UpdateRequirementModal
          isOpen={upcomingUpdatedRequirement}
          requirement={upcomingUpdatedRequirement}
          onUpdate={(updatedRequirement) => {
            onUpdate(updatedRequirement, upcomingUpdatedRequirement);
            setUpcomingUpdatedRequirement(null);
          }}
          onCancel={() =>
            setUpcomingUpdatedRequirement(null)
          }></UpdateRequirementModal>
        <Table className="template-table">
          <Table.Header>
            <tr>
              <th>Requirement</th>
              <th>Type</th>
              <th>Content</th>
              <th>Comment</th>
              <PermissionChecker
                permissions={['compliance_management.templates.write']}>
                <th></th>
              </PermissionChecker>
            </tr>
          </Table.Header>
          <Table.Body>
            {isLoading ? (
              <tr>
                <td colSpan={5}>Loading...</td>
              </tr>
            ) : (
              currentRequirements
                ?.filter((item) => !item.isDeleted)
                .map((item) => (
                  <tr key={item.id || item.display_id}>
                    <td className="text-align-left">
                      <span className="requirement-name">
                        <b>{item.requirement}</b>
                        {isChecklist && (
                          <i>
                            {item.is_answered ? (
                              <GreenCircularTickIcon />
                            ) : (
                              <ClockIcon />
                            )}
                          </i>
                        )}
                      </span>
                    </td>
                    <td>
                      <span>
                        {REQUIREMENT_TYPE[item.requirement_type]?.text}
                      </span>
                    </td>
                    <td>
                      <div className="file-upload-container">
                        <div>
                          {item.requirement_type ===
                            REQUIREMENT_TYPE.ONE_WAY.value && (
                            <>
                              {item.upload_by ===
                              UPLOAD_TYPE.MYSELF.value ? (
                                <SingleFileSelector
                                  defaultFileName={
                                    item.requirement_content
                                  }
                                  disabled={!hasWritePermission}
                                  onChange={async (file) => {
                                    const data =
                                      await uploadRequirementFile(file);
                                    onUpdate(
                                      {
                                        requirement_content: data?.file_url
                                      },
                                      item
                                    );
                                  }}
                                  onRemove={() => {
                                    onUpdate(
                                      {
                                        requirement_content: ''
                                      },
                                      item
                                    );
                                  }}
                                />
                              ) : item.requirement_content ? (
                                <Typography
                                  marginTop={1}
                                  display="block"
                                  width="260px"
                                  as="a"
                                  target="_blank"
                                  href={item.requirement_content}>
                                  {item.requirement_content}
                                </Typography>
                              ) : (
                                <span>
                                  Link to file uploaded by admin will
                                  appear here
                                </span>
                              )}
                            </>
                          )}
                          {item.requirement_type ===
                            REQUIREMENT_TYPE.TWO_WAY.value &&
                            renderTwoWayRequirement(item)}
                          {item.requirement_type ===
                            REQUIREMENT_TYPE.URL.value &&
                            renderUrlRequirement(item)}
                        </div>
                        <PermissionChecker
                          permissions={[
                            'compliance_management.templates.write'
                          ]}>
                          <i
                            onClick={() =>
                              setUpcomingUpdatedRequirement(item)
                            }>
                            <CogIcon></CogIcon>
                          </i>
                        </PermissionChecker>
                      </div>
                    </td>
                    <td>
                      <Input
                        type="textarea"
                        className="custom-textarea"
                        value={item.client_comment}
                        disabled={!hasWritePermission}
                        onChange={(e) => {
                          onUpdate(
                            {
                              client_comment: e.target.value
                            },
                            item
                          );
                        }}
                      />
                    </td>
                    <PermissionChecker
                      permissions={[
                        'compliance_management.templates.write'
                      ]}>
                      <td>
                        <FilledTrashBinIcon
                          onClick={() => {
                            if (
                              typeof item.id === 'string' &&
                              item.id?.includes('new_')
                            ) {
                              onRemove(item);
                            } else {
                              setUpcomingDeletedItem(item);
                            }
                          }}></FilledTrashBinIcon>
                      </td>
                    </PermissionChecker>
                  </tr>
                ))
            )}
          </Table.Body>
        </Table>
      </div>
      <PermissionChecker
        permissions={['compliance_management.templates.write']}>
        <RequirementBottomBar onAdd={onAdd} />
      </PermissionChecker>
    </div>
  );
};

export default TemplateTable;
