import config from '../config';
import request, { attachQueryParams } from '../utils/request';

export const getPermissions = () => {
  return request({
    url: `${config.routePrefix.recruiter}/getPermissions`,
    method: 'get'
  });
};

export const getRolePermissions = (roleId) => {
  return request({
    url: attachQueryParams(
      `${config.routePrefix.recruiter}/getRolePermissions`,
      {
        role_id: roleId
      }
    ),
    method: 'get'
  });
};

export const updateRolePermissions = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/updateRolePermissions`,
    method: 'post',
    data
  });
};

export const createRole = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/createRole`,
    method: 'post',
    data
  });
};

export const getRoles = () => {
  return request({
    url: `${config.routePrefix.recruiter}/getRoles`,
    method: 'get'
  });
};

export const updateRole = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/updateRole`,
    method: 'put',
    data
  });
};

export const deleteRole = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/deleteRole`,
    method: 'delete',
    data
  });
};

export const getManagedUsers = () => {
  return request({
    url: `${config.routePrefix.recruiter}/getManagedUsers`,
    method: 'get'
  });
};

export const updateManagedUserRole = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/updateManagedUserRole`,
    method: 'put',
    data
  });
};

export const inviteUserWithRole = (data) => {
  return request({
    url: `${config.routePrefix.recruiter}/inviteUserWithRole`,
    method: 'post',
    data
  });
};
