import { useState, useEffect } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import SelectInput from '../../../components/shared/SelectInput';
import './styles.scss';
import { useGetMyTemplatesQuery } from '../../../queries/Compliance';

const LoadTemplateModal = ({
  onUpdate,
  onCancel,
  isOpen,
  defaultTemplateId
}) => {
  const [templateId, setTemplateId] = useState(defaultTemplateId);

  const getMyTemplatesRequest = useGetMyTemplatesQuery();

  const { data } = getMyTemplatesRequest || {};

  useEffect(() => {
    if (data?.length && !templateId) {
      setTemplateId(data[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMyTemplatesRequest]);

  return (
    <Modal
      isOpen={isOpen}
      className="load-template-modal"
      width="18.75rem">
      <div className="content-container">
        <SelectInput
          label="Load a checklist template"
          value={templateId}
          onChange={(e) => setTemplateId(e.target.value)}
          options={data?.map((item) => ({
            value: item.id,
            text: item.template_name
          }))}
        />
      </div>
      <div className="actions-container">
        <Button
          onClick={() => {
            onUpdate(templateId);
          }}
          disabled={!templateId}>
          LOAD
        </Button>
        <Button
          onClick={() => {
            setTemplateId(null);
            onCancel();
          }}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

export default LoadTemplateModal;
