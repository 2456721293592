import React from 'react';
import { useGetPermissionsQuery } from '../../../queries/PermissionRole';
import Table from '../../compliance-management/Table';
import config from '../../../config';

const groupPermissionsByPage = (permissions) => {
  return permissions?.reduce((acc, permission) => {
    const { page_name } = permission;
    if (!acc[page_name]) {
      acc[page_name] = [];
    }
    acc[page_name].push(permission);
    return acc;
  }, {});
};

const RoleTable = ({ rolePermissions, onUpdate }) => {
  const {
    data: permissions,
    isFetching,
    isRefetching
  } = useGetPermissionsQuery();

  const groupedPermissions = groupPermissionsByPage(permissions);

  return (
    <Table>
      <Table.Header>
        <tr>
          <th></th>
          <th>Permission</th>
          <th>Description</th>
        </tr>
      </Table.Header>
      <Table.Body>
        {isFetching && !isRefetching ? (
          <tr>
            <td colSpan={3}>Loading...</td>
          </tr>
        ) : (
          Object.entries(groupedPermissions).map(([page, permissions]) => (
            <React.Fragment key={page}>
              <tr
                key={page}
                className=" border-y-[1px] border-[#aecbdb] bg-[#DDE8ED] h-6">
                <td
                  colSpan={3}
                  className="px-4 py-2 font-semibold text-left uppercase">
                  {config.pagePermissions[page]}
                </td>
              </tr>
              <>
                {permissions.map((permission) => (
                  <tr key={permission.permission_id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={rolePermissions?.includes(
                          permission.permission_name
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onUpdate([
                              ...rolePermissions,
                              permission.permission_name
                            ]);
                          } else {
                            onUpdate(
                              rolePermissions.filter(
                                (name) =>
                                  name !== permission.permission_name
                              )
                            );
                          }
                        }}
                      />
                    </td>
                    <td className="font-semibold text-left">
                      {permission.permission_title}
                    </td>
                    <td className="text-left">
                      {permission.permission_description}
                    </td>
                  </tr>
                ))}
              </>
            </React.Fragment>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default RoleTable;
