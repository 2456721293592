import Lottie from 'lottie-react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import animationInitial from '../../assets/animations/Full.json';

import './index.css';

const NewMatchAnimation = () => {
  const [removeAnimation, setRemoveAnimation] = useState(false);

  return (
    <AnimatePresence>
      {!removeAnimation && (
        <motion.button
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => {
            setRemoveAnimation(true);
          }}
          className="animation__button">
          <Lottie
            className="animation__svg-wrapper"
            animationData={animationInitial}
            loop={false}
            initialSegment={[0, 222]}
            onComplete={() => {
              setRemoveAnimation(true);
            }}
          />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default NewMatchAnimation;
