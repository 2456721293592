import Spline from '@splinetool/react-spline';
import { useRef, useState } from 'react';
import CampaignCreationModal from '../campaign/CampaignCreationModal';

const EmptyDashboard = () => {
  const [isCampaignCreationModalOpened, setIsCampaignCreationModalOpened] =
    useState(false);
  const splineObjectRef = useRef();
  const isFirstTimeRunRef = useRef(true);

  function onLoad(spline) {
    splineObjectRef.current = spline.findObjectById(
      'c363aef7-c808-49a7-beea-8a2fc2d805e9'
    );
  }

  function onMouseDown(e) {
    if (e.target.name === 'Button') {
      if (isFirstTimeRunRef.current) {
        setTimeout(() => {
          isFirstTimeRunRef.current = false;
          setIsCampaignCreationModalOpened(true);
        }, 1000);
      } else {
        setIsCampaignCreationModalOpened(true);
      }
    }
  }

  return (
    <div className="flex flex-col w-full h-full min-h-0">
      <div className="w-full h-full">
        <Spline
          scene="https://prod.spline.design/SRJDpWFxavJsdPTL/scene.splinecode"
          onLoad={onLoad}
          onMouseDown={onMouseDown}
        />
      </div>
      <div className="absolute flex justify-center w-full p-2 bottom-8">
        {/* <Button
          title="CREATE YOUR FIRST CAMPAIGN"
          onClick={() => setIsCampaignCreationModalOpened(true)}
        /> */}
        {isCampaignCreationModalOpened && (
          <CampaignCreationModal
            isOpen={isCampaignCreationModalOpened}
            onClose={() => setIsCampaignCreationModalOpened(false)}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyDashboard;
