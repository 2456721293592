import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import queryStore from '../../../queries';
import AuthService from '../../../services/Auth';
import { logError, logSuccess } from '../../../utils/logging';
import { deleteCookie, setCookie } from '../../../utils/cookies';
import {
  createNewPasswordError,
  createNewPasswordRequest,
  createNewPasswordSuccess,
  createPasswordError,
  createPasswordRequest,
  createPasswordSuccess,
  logoutError,
  logoutRequest,
  logoutSuccess,
  registerError,
  registerRequest,
  registerSuccess,
  resendOTPError,
  resendOTPRequest,
  resendOTPSuccess,
  resetAuthData,
  resetPasswordOTPError,
  resetPasswordOTPRequest,
  resetPasswordOTPSuccess,
  markRegistrationAsTouched,
  submitQuestionnaireError,
  submitQuestionnaireRequest,
  submitQuestionnaireSuccess,
  verifyResetPasswordOTPError,
  verifyResetPasswordOTPRequest,
  verifyResetPasswordOTPSuccess,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError
} from '../../slices/Auth';
import { navigate } from '../../../utils/history';
import { routes } from '../../../router/routes';
import { prepareQuestionnaireForAPI } from '../../../utils/questionnaire';
import { saveBasicInfoField } from '../actions/Auth';
import {
  selectBasicInfo,
  selectQuestionnaire
} from './../../selectors/Auth';

export function* resendOTPSaga({ payload }) {
  try {
    yield call(AuthService.resendOTP, payload);

    logSuccess('New code has been sent');
    yield put(resendOTPSuccess());
  } catch (e) {
    logError(e);
    yield put(resendOTPError());
  }
}

export function* saveBasicInfoFieldSaga({ payload }) {
  try {
    yield put(markRegistrationAsTouched());
    yield call(AuthService.register, { ...payload, user_role: 1 });
  } catch (e) {
    logError(e);
    yield put(registerError());
  }
}

export function* registerSaga({ payload }) {
  const {
    first_name,
    last_name,
    phone_number,
    company_name,
    company_url,
    job_title,
    email_id,
    user_role
  } = payload;
  try {
    const { access_token } = yield call(AuthService.register, {
      first_name,
      last_name,
      phone_number,
      company_name,
      company_url,
      job_title,
      email_id,
      user_role
    });

    yield call(setCookie, 'token', access_token);

    yield put(registerSuccess({ ...payload, access_token }));
  } catch (e) {
    logError(e);
    yield put(registerError());
  }
}

export function* submitQuestionnaireSaga() {
  try {
    const { access_token } = yield select(selectBasicInfo);
    const { partOne, partTwo } = yield select(selectQuestionnaire);

    const survey_data = [
      ...prepareQuestionnaireForAPI(partOne),
      ...prepareQuestionnaireForAPI(partTwo)
    ];

    yield call(AuthService.userSurvey, { survey_data }, access_token);
    yield put(submitQuestionnaireSuccess());
  } catch (e) {
    logError(e);
    yield put(submitQuestionnaireError());
  }
}

export function* createPasswordSaga({ payload: { password } }) {
  try {
    const { email_id, access_token } = yield select(selectBasicInfo);

    yield call(
      AuthService.setUpUserPassword,
      { email_id, password },
      access_token
    );
    yield put(createPasswordSuccess());
  } catch (e) {
    logError(e);
    yield put(createPasswordError());
  }
}

export function* logoutSaga() {
  try {
    yield call(deleteCookie, 'token');
    yield put(logoutSuccess());
    navigate(routes.login);
    queryStore.removeQueries();
  } catch (e) {
    logError(e);
    yield put(logoutError());
  }
}

export function* resetPasswordOTPSaga({ payload }) {
  try {
    yield call(AuthService.sendResetPasswordOTP, payload);

    yield put(resetPasswordOTPSuccess());
  } catch (e) {
    logError(e);
    yield put(resetPasswordOTPError());
  }
}

export function* verifyResetPasswordOTPSaga({ payload }) {
  try {
    yield call(AuthService.verifyResetPasswordOTP, payload);

    yield put(verifyResetPasswordOTPSuccess());
  } catch (e) {
    logError(e);
    yield put(verifyResetPasswordOTPError());
  }
}

export function* changePasswordSaga({
  payload: { reset_password_otp, phone_number, password }
}) {
  try {
    yield call(AuthService.verifyResetPasswordOTP, {
      reset_password_otp,
      phone_number
    });
    yield call(AuthService.createNewPassword, { password, phone_number });

    yield put(changePasswordSuccess());
    logSuccess('Password changed successfully!');
  } catch (e) {
    logError(e);
    yield put(changePasswordError());
  }
}

export function* createNewPasswordSaga({
  payload: { password, phone_number }
}) {
  try {
    yield call(AuthService.createNewPassword, { password, phone_number });

    yield put(createNewPasswordSuccess());
    navigate(routes.login);
    logSuccess('Password changed successfully!');
  } catch (e) {
    logError(e);
    yield put(createNewPasswordError());
  }
}

export function* resetAuthSaga() {
  try {
    yield navigate(routes.login);
  } catch (e) {
    logError(e);
  }
}

export const authSagas = [
  takeLatest(resendOTPRequest, resendOTPSaga),
  takeLatest(saveBasicInfoField, saveBasicInfoFieldSaga),
  takeLatest(registerRequest, registerSaga),
  takeLatest(logoutRequest, logoutSaga),
  takeLatest(resetPasswordOTPRequest, resetPasswordOTPSaga),
  takeLatest(verifyResetPasswordOTPRequest, verifyResetPasswordOTPSaga),
  takeLatest(changePasswordRequest, changePasswordSaga),
  takeLatest(createNewPasswordRequest, createNewPasswordSaga),
  takeLatest(submitQuestionnaireRequest, submitQuestionnaireSaga),
  takeLatest(createPasswordRequest, createPasswordSaga),
  takeLatest(resetAuthData, resetAuthSaga)
];
