import './styles.scss';

import { ReactComponent as PaintBrushIcon } from '../../../assets/images/paint-brush.svg';

const ProjectHighlights = ({ projectNotes }) => (
  <div className="project-highlights-container">
    <div className="headline">
      <PaintBrushIcon></PaintBrushIcon>
      <span>Project highlights</span>
    </div>
    {projectNotes && (
      <div className="project-highlights">
        {projectNotes.split('\n').map((note) => (
          <div key={note}>{note}</div>
        ))}
      </div>
    )}
  </div>
);

export default ProjectHighlights;
