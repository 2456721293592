import './styles.scss';
import { useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import UnsavedChangeHandler from '../../components/shared/UnsavedChangeHandler';
import Tab from '../../components/compliance-management/Tab';
import Button from '../../components/shared/Button/Button';
import ConfirmationModal from '../../components/shared/ConfirmationModal';
import { ReactComponent as PenIcon } from '../../assets/images/pen.svg';
import { ReactComponent as FilledTrashBinIcon } from '../../assets/images/filled-trash-bin.svg';
import Dropdown from '../../components/shared/Dropdown';
import RoleTable from '../../components/permission-role-management/RoleTable';
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetRolePermissionsQuery,
  useGetRolesQuery,
  useUpdateRoleMutation,
  useUpdateRolePermissionsMutation
} from '../../queries/PermissionRole';
import NewRoleModal from '../../components/permission-role-management/NewRoleModal';
import { toast } from 'react-toastify';
import RoleUpdateModal from '../../components/permission-role-management/RoleUpdateModal';
import ManagedUserTable from '../../components/permission-role-management/ManagedUserTable';

const TABS = {
  Users: 'users',
  Roles: 'roles'
};

const BASE_PATH = '/permission-role-management';

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PermissionRoleManagement = () => {
  const { tabName } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tabName ?? TABS.Users);
  const queryParams = useQueryParams();
  const [selectedRoleId, setSelectedRoleId] = useState(
    queryParams.get('roleId')
  );
  const [
    unsavedChangeWithSelectedTemplateId,
    setUnsavedChangeWithSelectedTemplateId
  ] = useState(false);
  const [isNewRoleModalOpened, setIsNewRoleModalOpened] = useState(false);
  const [isRoleUpdateModalOpened, setIsRoleUpdateModalOpened] =
    useState(false);
  const [isDeleteTemplateModalOpened, setIsDeleteTemplateModalOpened] =
    useState(false);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [currentRolePermissions, setCurrentRolePermissions] = useState([]);
  const { mutateAsync: createRole } = useCreateRoleMutation();
  const { mutateAsync: updateRole } = useUpdateRoleMutation();
  const { mutateAsync: deleteRole } = useDeleteRoleMutation();
  const { mutateAsync: updateRolePermission } =
    useUpdateRolePermissionsMutation();

  const { data: rolePermissionData } = useGetRolePermissionsQuery(
    selectedRoleId,
    {
      enabled: !!selectedRoleId
    }
  );
  const { permissions: rolePermissions } = rolePermissionData || {};

  const { data: roles } = useGetRolesQuery();

  const updateCurrentRoleId = (id) => {
    setSelectedRoleId(id);
    if (id) {
      const queryString = new URLSearchParams({
        roleId: id
      }).toString();
      history.push({
        search: queryString
      });
    }
  };

  useEffect(() => {
    if (rolePermissions) {
      setCurrentRolePermissions(rolePermissions);
    }
  }, [rolePermissions]);

  useEffect(() => {
    if (location.pathname === `${BASE_PATH}/${TABS.Users}`) {
      setActiveTab(TABS.Users);
    }
    if (location.pathname === `${BASE_PATH}/${TABS.Roles}`) {
      setActiveTab(TABS.Roles);
    }
  }, [location]);

  const unsavedChangeMessage =
    'You have unsaved changes in your requirements. You still want to leave the page without saving changes?';

  const unsavedChangeWithTemplateChangeMessage =
    'You have unsaved changes in your requirements. You still want to change the template?';

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        className="permission-role-management"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <ConfirmationModal
          key="unsaved-change-confirmation-modal"
          message={unsavedChangeWithTemplateChangeMessage}
          isOpen={unsavedChangeWithSelectedTemplateId}
          onCancel={() => {
            setUnsavedChangeWithSelectedTemplateId(null);
          }}
          onConfirm={() => {
            updateCurrentRoleId(unsavedChangeWithSelectedTemplateId);
            setUnsavedChangeWithSelectedTemplateId(null);
            setHasUnsavedData(false);
          }}
        />
        <UnsavedChangeHandler
          onConfirm={() => {
            setHasUnsavedData(false);
          }}
          shouldBlockNavigation={() => true}
          when={hasUnsavedData}
          message={unsavedChangeMessage}
        />
        <div className="header">
          <Tab.Label
            isActive={activeTab === TABS.Users}
            onClick={() => {
              if (activeTab !== TABS.Users) {
                history.push({
                  pathname: `${BASE_PATH}/${TABS.Users}`
                });
              }
            }}>
            {TABS.Users.toUpperCase()}
          </Tab.Label>
          <Tab.Label
            isActive={activeTab === TABS.Roles}
            onClick={() => {
              if (activeTab !== TABS.Roles) {
                let queryString = '';
                if (selectedRoleId) {
                  queryString = new URLSearchParams({
                    templateId: selectedRoleId
                  }).toString();
                }
                history.push({
                  pathname: `${BASE_PATH}/${TABS.Roles}`,
                  search: queryString
                });
              }
            }}>
            {TABS.Roles.toUpperCase()}
          </Tab.Label>
          {activeTab === TABS.Roles && (
            <div className="button-container">
              <div className="select-input">
                <label>Role</label>
                <Dropdown
                  options={roles}
                  selectedOption={roles?.find(
                    (role) => String(selectedRoleId) === String(role.id)
                  )}
                  onChange={(option) => {
                    if (hasUnsavedData) {
                      setUnsavedChangeWithSelectedTemplateId(option.id);
                    } else {
                      updateCurrentRoleId(option.id);
                    }
                  }}
                  className="w-40"
                  labelKey="role_name"
                />
              </div>
              <Button
                disabled={!selectedRoleId}
                onClick={() => {
                  setIsRoleUpdateModalOpened(true);
                }}>
                <PenIcon></PenIcon>
              </Button>
              <Button
                disabled={!selectedRoleId}
                onClick={() => {
                  setIsDeleteTemplateModalOpened(true);
                }}>
                <FilledTrashBinIcon></FilledTrashBinIcon>
              </Button>
              <Button onClick={() => setIsNewRoleModalOpened(true)}>
                NEW ROLE
              </Button>
              <div className="vertical-divider"></div>
              <Button
                disabled={!selectedRoleId}
                onClick={async () => {
                  const data = await updateRolePermission({
                    role_id: Number(selectedRoleId),
                    permissions: currentRolePermissions
                  });
                  if (data) {
                    toast.success('Role permissions updated successfully');
                  }
                }}>
                UPDATE
              </Button>
              {isNewRoleModalOpened && (
                <NewRoleModal
                  isOpen={isNewRoleModalOpened}
                  onCancel={() => setIsNewRoleModalOpened(false)}
                  onCreate={async (name) => {
                    setIsNewRoleModalOpened(false);
                    const data = await createRole({ role_name: name });

                    if (data) {
                      toast.success('Role created successfully');
                    }
                  }}
                />
              )}
              {isRoleUpdateModalOpened && (
                <RoleUpdateModal
                  isOpen={isRoleUpdateModalOpened}
                  defaultRoleName={
                    roles?.find(
                      (role) => String(selectedRoleId) === String(role.id)
                    )?.role_name ?? ''
                  }
                  onCancel={() => setIsRoleUpdateModalOpened(false)}
                  onUpdate={async (name) => {
                    setIsRoleUpdateModalOpened(false);
                    const data = await updateRole({
                      role_name: name,
                      id: Number(selectedRoleId)
                    });
                    if (data) {
                      toast.success('Role updated successfully');
                    }
                  }}
                />
              )}
              <ConfirmationModal
                message="Your are about removing this role, are you sure you want to do that? All assigned permissions will be deleted and accounts will be affected."
                isOpen={isDeleteTemplateModalOpened}
                onCancel={() => setIsDeleteTemplateModalOpened(false)}
                onConfirm={async () => {
                  setIsDeleteTemplateModalOpened(false);
                  const data = await deleteRole({
                    id: Number(selectedRoleId)
                  });
                  if (data) {
                    toast.success('Role deleted successfully');
                  }
                  updateCurrentRoleId(null);
                }}
              />
            </div>
          )}
        </div>
        <div className="main-content">
          {activeTab === TABS.Users && <ManagedUserTable />}
          {activeTab === TABS.Roles && (
            <RoleTable
              rolePermissions={currentRolePermissions}
              onUpdate={(updatedRolePermissions) =>
                setCurrentRolePermissions(updatedRolePermissions)
              }
            />
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PermissionRoleManagement;
