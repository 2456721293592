import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  parseISO,
  add,
  sub,
  format,
  differenceInYears
} from 'date-fns';
import config from '../config';
import {
  GRAPH_PERIOD,
  thisWeekFragments,
  thisMonthFragments,
  thisYearFragments
} from '../constants/horizontal-graph';

export const getChartDataByVote = (period, candidates) => {
  const {
    candidate: { profileStatus }
  } = config;

  let series = [];

  if (candidates.length === 0) {
    return {
      series: []
    };
  }

  switch (period) {
    case GRAPH_PERIOD.LAST_3_DAYS: {
      const startDay = startOfDay(sub(new Date(), { days: 2 }));

      series = Array.from(Array(3).keys()).map((day) => {
        const currentDay = add(startDay, { days: day });

        const candidatesInDay = candidates.filter((candidate) => {
          const isInDay =
            parseISO(candidate.date) > currentDay &&
            parseISO(candidate.date) < add(currentDay, { days: 1 });
          return isInDay;
        });

        const name = format(currentDay, 'EEE');

        return candidatesInDay.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_WEEK: {
      const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

      series = thisWeekFragments.map((fragment) => {
        const currentFragmentTime = add(startWeek, fragment);

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { days: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        const name = format(sub(currentFragmentTime, { days: 1 }), 'EEE');

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_MONTH: {
      const startMonth = startOfMonth(new Date());

      series = thisMonthFragments.map((fragment) => {
        const currentFragmentTime = add(startMonth, fragment);

        const name = format(sub(currentFragmentTime, { days: 1 }), 'd');

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { days: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_YEAR: {
      const startYear = startOfYear(new Date());

      series = thisYearFragments.map((fragment) => {
        const currentFragmentTime = add(startYear, fragment);

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { months: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        const name = format(
          sub(currentFragmentTime, { months: 1 }),
          'LLL'
        );

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          {
            name,
            votedUp: 0,
            votedDown: 0
          }
        );
      });

      break;
    }
    case GRAPH_PERIOD.ALL_TIME: {
      const sortedCandidatesByTime = candidates.sort((a, b) =>
        a.date > b.date ? 1 : -1
      );

      const startYear = startOfYear(
        parseISO(sortedCandidatesByTime[0].date)
      );

      const lastYear = startOfYear(
        parseISO(
          sortedCandidatesByTime[sortedCandidatesByTime.length - 1].date
        )
      );

      const yearDiff =
        Math.abs(differenceInYears(lastYear, startYear)) + 1;

      if (yearDiff > 3) {
        series = Array.from({ length: yearDiff }, (_, i) => {
          const currentYear = add(startYear, { years: i });

          const candidatesInYear = candidates.filter((candidate) => {
            const isInYear =
              parseISO(candidate.date) > currentYear &&
              parseISO(candidate.date) < add(currentYear, { years: 1 });
            return isInYear;
          });

          const name = format(currentYear, 'yyyy');

          return candidatesInYear.reduce(
            (acc, candidate) => {
              switch (candidate.profile_status) {
                case profileStatus.VOTED_UP:
                  acc.votedUp++;
                  break;
                case profileStatus.VOTED_DOWN:
                  acc.votedDown++;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              name,
              votedUp: 0,
              votedDown: 0
            }
          );
        });
      } else {
        const quarterDiff = yearDiff * 4;

        series = Array.from({ length: quarterDiff }, (_, i) => {
          const currentQuarter = add(startYear, { months: i * 3 });

          const candidatesInQuarter = candidates.filter((candidate) => {
            const isInQuarter =
              parseISO(candidate.date) > currentQuarter &&
              parseISO(candidate.date) <
                add(currentQuarter, { months: 3 });
            return isInQuarter;
          });

          const name = format(currentQuarter, 'yyyy QQQ');

          return candidatesInQuarter.reduce(
            (acc, candidate) => {
              switch (candidate.profile_status) {
                case profileStatus.VOTED_UP:
                  acc.votedUp++;
                  break;
                case profileStatus.VOTED_DOWN:
                  acc.votedDown++;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              name,
              votedUp: 0,
              votedDown: 0
            }
          );
        });
      }

      break;
    }
    default:
      series = [];
  }

  return {
    series
  };
};

// timesheets is: Object.entries(data.timesheets) = ['2024-04-12',660]. Calculate sume of value in a period
export const getChartDataByWorkedHours = (period, timesheets) => {
  let series = [];

  if (timesheets.length === 0) {
    return {
      series
    };
  }

  switch (period) {
    case GRAPH_PERIOD.LAST_3_DAYS: {
      const startDay = startOfDay(sub(new Date(), { days: 2 }));

      series = Array.from(Array(3).keys()).map((day) => {
        const currentDay = add(startDay, { days: day });

        const currentDayString = format(currentDay, 'yyyy-MM-dd');

        const workedHoursInDay = timesheets.reduce(
          (acc, [date, value]) => {
            if (date === currentDayString) {
              acc += value;
            }
            return acc;
          },
          0
        );

        return {
          name: format(currentDay, 'EEE'),
          workedHours: workedHoursInDay
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_WEEK: {
      const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

      series = Array.from(Array(7).keys()).map((day) => {
        const currentDay = add(startWeek, { days: day });

        const workedHoursInDay = timesheets.reduce(
          (acc, [date, value]) => {
            if (date.startsWith(format(currentDay, 'yyyy-MM'))) {
              acc += value;
            }
            return acc;
          },
          0
        );

        return {
          name: format(currentDay, 'EEE'),
          workedHours: workedHoursInDay
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_MONTH: {
      const startMonth = startOfMonth(new Date());

      series = Array.from(Array(31).keys()).map((day) => {
        const currentDay = add(startMonth, { days: day });

        const workedHoursInDay = timesheets.reduce(
          (acc, [date, value]) => {
            if (date.startsWith(format(currentDay, 'yyyy-MM'))) {
              acc += value;
            }
            return acc;
          },
          0
        );

        return {
          name: format(currentDay, 'd'),
          workedHours: workedHoursInDay
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_YEAR: {
      const startYear = startOfYear(new Date());

      series = Array.from(Array(12).keys()).map((month) => {
        const currentMonth = add(startYear, { months: month });

        const workedHoursInMonth = timesheets.reduce(
          (acc, [date, value]) => {
            if (date.startsWith(format(currentMonth, 'yyyy-MM'))) {
              acc += value;
            }
            return acc;
          },
          0
        );

        return {
          name: format(currentMonth, 'LLL'),
          workedHours: workedHoursInMonth
        };
      });

      break;
    }

    case GRAPH_PERIOD.ALL_TIME: {
      const sortedTimesheetsByTime = timesheets.sort((a, b) =>
        a[0] > b[0] ? 1 : -1
      );

      const startYear = startOfYear(
        parseISO(sortedTimesheetsByTime[0][0])
      );

      const lastYear = startOfYear(
        parseISO(
          sortedTimesheetsByTime[sortedTimesheetsByTime.length - 1][0]
        )
      );

      const yearDiff =
        Math.abs(differenceInYears(lastYear, startYear)) + 1;

      if (yearDiff > 3) {
        series = Array.from({ length: yearDiff }, (_, i) => {
          const currentYear = add(startYear, { years: i });

          const workedHoursInYear = timesheets.reduce(
            (acc, [date, value]) => {
              if (date.startsWith(format(currentYear, 'yyyy'))) {
                acc += value;
              }
              return acc;
            },
            0
          );

          return {
            name: format(currentYear, 'yyyy'),
            workedHours: workedHoursInYear
          };
        });
      } else {
        const quarterDiff = yearDiff * 4;

        series = Array.from({ length: quarterDiff }, (_, i) => {
          const currentQuarter = add(startYear, { months: i * 3 });

          const workedHoursInQuarter = timesheets.reduce(
            (acc, [date, value]) => {
              if (
                date.startsWith(format(currentQuarter, 'yyyy-MM')) ||
                date.startsWith(
                  format(add(currentQuarter, { months: 1 }), 'yyyy-MM') ||
                    date.startsWith(
                      format(add(currentQuarter, { months: 2 }), 'yyyy-MM')
                    )
                )
              ) {
                acc += value;
              }
              return acc;
            },
            0
          );

          return {
            name: format(currentQuarter, 'yyyy QQQ'),
            workedHours: workedHoursInQuarter
          };
        });
      }

      break;
    }

    default:
      series = [];
  }

  return {
    series
  };
};
