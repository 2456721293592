import PasswordCreationForm from '@src/components/PasswordCreationForm';
import AnimatedFormBackground from '@src/components/spline/AnimatedFormBackground';
import { routes } from '@src/router/routes';
import UserService from '@src/services/User';
import { navigate } from '@src/utils/history';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UserInvitation = () => {
  const params = useParams();
  const { invitationToken } = params;

  useEffect(() => {
    if (!invitationToken) {
      navigate(routes.login);
      return;
    }

    (async () => {
      const { is_valid } =
        await UserService.validateInvitationToken(invitationToken);

      if (!is_valid) {
        navigate(routes.login);
      }
    })();
  }, []);

  return (
    <div>
      <AnimatedFormBackground />
      <PasswordCreationForm invitationToken={invitationToken} />
    </div>
  );
};

export default UserInvitation;
