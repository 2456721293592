export const ScrollThumbVertical = ({ color = '#B2C8D8' }) => (
  <div
    style={{
      backgroundColor: color,
      position: 'relative',
      display: 'block',
      width: ' 100%',
      cursor: 'pointer',
      borderRadius: 'inherit'
    }}
  />
);

export const ScrollTrackVertical = ({
  color = '#DAE8F2',
  width = '0.25rem',
  borderRadius = '0.1875rem',
  top = '0.25rem',
  right = '0.3125rem',
  bottom = '0.25rem'
}) => (
  <div
    style={{
      backgroundColor: color,
      position: 'absolute',
      borderRadius,
      width,
      top,
      right,
      bottom
    }}
  />
);
