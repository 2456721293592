import Tippy from '@tippyjs/react';
import { ReactComponent as BriefcaseIcon } from '../../assets/images/briefcase-icon.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/check-circle-icon.svg';
import config from './../../config';

export const renderCandidateStatusIcon = (status) => {
  switch (status) {
    case config.candidate.profileStatus.INTERVIEWED:
      return (
        <Tippy content={'Interviewed'} placement="top">
          <CheckCircleIcon className={'action-icon'} />
        </Tippy>
      );
    case config.candidate.profileStatus.HIRED:
      return (
        <Tippy content={'Active Hire'} placement="top">
          <BriefcaseIcon className={'action-icon briefcase-icon'} />
        </Tippy>
      );
    default:
      return null;
  }
};

export const linkSocialIcon = (url, SocialIcon, social) => {
  const renderIcon = (
    <SocialIcon
      fill={`var(${url ? '--color-brig' : '--color-big-sur'})`}
    />
  );

  if (url && !url.startsWith('http')) {
    url = `https://${url}`;
  }

  return (
    <Tippy content={`View ${social}`} placement="top">
      {url ? (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer nofollow"
          onClick={(e) => e.stopPropagation()}>
          {renderIcon}
        </a>
      ) : (
        renderIcon
      )}
    </Tippy>
  );
};

export const constructLocationString = (city, state, country) =>
  [city, state, country].filter(Boolean).join(', ');
