import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { motion } from 'framer-motion';
import _remove from 'lodash/remove';
import { CampaignCard } from '../CampaignCard/campaign-card';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../../shared/CustomScrollBar/CustomScrollBar';
import { sidebarContentVariants } from '../../../utils/animation';
import { logError } from '../../../utils/logging';
import Button from '../../shared/Button/Button';

import './CampaignList.css';
import CampaignCreationModal from '../CampaignCreationModal';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const SEEN_CAMPAIGN_KEY = 'seenCampaigns';

const CampaignList = ({
  setSelectedCampaign,
  selectedCampaign,
  campaigns
}) => {
  const [isCampaignCreationModalOpened, setIsCampaignCreationModalOpened] =
    useState(false);
  const hasShownPauseNotice = useRef(false);

  useEffect(() => {
    if (hasShownPauseNotice.current) return;

    const seenCampaignNotificationStorage =
      localStorage.getItem(SEEN_CAMPAIGN_KEY);

    const seenCampaignNotifications = seenCampaignNotificationStorage
      ? seenCampaignNotificationStorage.split(',')
      : [];

    if (campaigns?.length > 0) {
      campaigns.forEach((campaign) => {
        if (campaign.status === 0) {
          if (
            seenCampaignNotifications.every((campaignId) => {
              return String(campaignId) !== String(campaign.campaign_id);
            })
          ) {
            logError(
              `Your ${campaign.campaign_name} campaign has been paused. Please contact your Trusted Dev concierge if you need assistance.`
            );
            seenCampaignNotifications.push(campaign.campaign_id);
          }
        } else {
          _remove(seenCampaignNotifications, String(campaign.campaign_id));
        }
      });

      localStorage.setItem(
        SEEN_CAMPAIGN_KEY,
        seenCampaignNotifications.join(',')
      );

      hasShownPauseNotice.current = true;
    }
  }, [campaigns]);

  const handleSelectCampaign = (campaign) => {
    if (selectedCampaign?.id === campaign.id) {
      setSelectedCampaign(null);

      return;
    }

    setSelectedCampaign(campaign);
  };

  return (
    <motion.div
      layout
      variants={sidebarContentVariants}
      initial={'hidden'}
      animate={'show'}
      exit={'hidden'}
      className="campaign-list">
      {isCampaignCreationModalOpened && (
        <CampaignCreationModal
          isOpen={isCampaignCreationModalOpened}
          onClose={() => setIsCampaignCreationModalOpened(false)}
        />
      )}
      <PermissionChecker permissions={['dashboard.campaign.write']}>
        <div className="request-campaign">
          <Button
            title="CREATE CAMPAIGN"
            onClick={() => setIsCampaignCreationModalOpened(true)}
          />
        </div>
      </PermissionChecker>
      <Scrollbars
        id="campaignList"
        className="content"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}>
        <div className="wrapper">
          {campaigns?.map((campaign) => (
            <CampaignCard
              key={campaign.id}
              campaign={campaign}
              isSelected={campaign === selectedCampaign}
              selectCampaign={handleSelectCampaign}
            />
          ))}
        </div>
      </Scrollbars>
    </motion.div>
  );
};

export default CampaignList;
