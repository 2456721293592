import { useState } from 'react';
import './EmailSettings.scss';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch';

const EmailSettings = () => {
  const [isWeeklyDigest, setIsWeeklyDigest] = useState(false);
  const [isNewMatch, setIsNewMatch] = useState(false);

  return (
    <SectionWrapper title={'Email Settings'}>
      <div className="email-settings">
        <div className="toggle-container">
          <span>Weekly Digest</span>
          <ToggleSwitch
            active={isWeeklyDigest}
            onToggle={() => setIsWeeklyDigest(!isWeeklyDigest)}
            key="Weekly Digest"
            label="Weekly Digest"
          />
        </div>
        <div className="toggle-container">
          <span>New Match</span>
          <ToggleSwitch
            active={isNewMatch}
            onToggle={() => setIsNewMatch(!isNewMatch)}
            key="New Match"
            label="New Match"
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default EmailSettings;
