import PropTypes from 'prop-types';

import CampaignPaymentFormModal from '../CampaignPaymentFormModal';
import { useState } from 'react';
import ConfirmationModal from '../../shared/ConfirmationModal';
import {
  useDeleteCampaign,
  useGetCampaignDetailQuery
} from '../../../queries/Campaign';
import CampaignManagementModal from '../campaign-management-modal/campaign-management-modal';
import { toast } from 'react-toastify';

const CampaignDetailsModal = ({ campaignId, onClose }) => {
  const { data: currentCampaignData, refetch: refetchCampaign } =
    useGetCampaignDetailQuery({ campaign_id: campaignId });

  const { campaign: currentCampaign } = currentCampaignData || {};
  const { id } = currentCampaign || {};

  const { mutate: deleteCampaign } = useDeleteCampaign();

  const [
    isCampaignPaymentFormModalOpened,
    setIsCampaignPaymentFormModalOpened
  ] = useState(false);
  const [
    isDeletionConfirmationModalOpened,
    setIsDeletionConfirmationModalOpened
  ] = useState(false);
  const [isCampaignDetailsModalOpened, setIsCampaignDetailsModalOpened] =
    useState(true);
  const handleDeleteCampaign = () => {
    deleteCampaign({ campaign_id: id });
    onClose();
  };

  return (
    <>
      {isCampaignPaymentFormModalOpened && (
        <CampaignPaymentFormModal
          campaignId={id}
          onClose={onClose}
          onSuccess={async () => {
            await refetchCampaign();
            toast.success('The payment is successful');
            onClose();
          }}
        />
      )}
      {isDeletionConfirmationModalOpened && (
        <ConfirmationModal
          message="Your are about deleting your campaign. Are you sure you want to delete?"
          isOpen={isDeletionConfirmationModalOpened}
          onCancel={onClose}
          onConfirm={() => handleDeleteCampaign()}
        />
      )}
      {isCampaignDetailsModalOpened && (
        <CampaignManagementModal
          campaign={currentCampaign}
          closeModal={onClose}
          onPayDepositClick={() => {
            setIsCampaignPaymentFormModalOpened(true);
            setIsCampaignDetailsModalOpened(false);
          }}
          onDeleteCampaignClick={() => {
            setIsDeletionConfirmationModalOpened(true);
            setIsCampaignDetailsModalOpened(false);
          }}
        />
      )}
    </>
  );
};

CampaignDetailsModal.propTypes = {
  campaign: PropTypes.object,
  onClose: PropTypes.func
};

export default CampaignDetailsModal;
