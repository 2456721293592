import { cn } from '@src/utils';
import PropTypes from 'prop-types';
import { ACTIVITY_FEED_TABS } from '../../constants/common';

const HeaderTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex flex-shrink-0 h-[38px] border-b border-td-big-sur bg-[#edf4f8]">
      <div
        className={cn(
          'border-b-[3px] cursor-pointer -mb-[2px] w-[162px] border-transparent opacity-60 text-[#4b6e88] flex items-center justify-center',
          {
            'border-td-super-rare-jade opacity-100':
              activeTab === ACTIVITY_FEED_TABS.FEED
          }
        )}
        onClick={() => setActiveTab(ACTIVITY_FEED_TABS.FEED)}>
        <p className="uppercase text-sm font-semibold">Feed</p>
      </div>
      <div
        className={cn(
          'border-b-[3px] cursor-pointer -mb-[2px] w-[162px] border-transparent opacity-60 text-[#4b6e88] flex items-center justify-center',
          {
            'border-td-super-rare-jade opacity-100':
              activeTab === ACTIVITY_FEED_TABS.HISTORY
          }
        )}
        onClick={() => setActiveTab(ACTIVITY_FEED_TABS.HISTORY)}>
        <p className="uppercase text-sm font-semibold">History</p>
      </div>
    </div>
  );
};

HeaderTabs.prototype = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func
};

export default HeaderTabs;
