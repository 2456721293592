import './ForgotPasswordForm.css';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { forgotPasswordRules } from '../../../../validation/auth';
import { UnderlinedTelephoneField } from '../../../shared/inputs/UnderlinedInputField/UnderlinedInputField';
import Button from '../../../shared/Button/Button';
import { resetPasswordOTPRequest } from '../../../../store/slices/Auth';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => {
    dispatch(resetPasswordOTPRequest(values));
  };

  return (
    <Formik
      initialValues={{
        phone_number: ''
      }}
      onSubmit={handleFormSubmit}
      validationSchema={forgotPasswordRules}>
      {() => (
        <Form className="forgot-password-form">
          <div className="d-flex flex-column">
            <Field
              label="Mobile Number"
              type="text"
              name="phone_number"
              autoComplete="off"
              component={UnderlinedTelephoneField}
            />
            <Button
              type="submit"
              title="CONTINUE"
              backgroundColorVar="--color-super-rare-jade"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
