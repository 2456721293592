import { useQuery } from 'react-query';
import StatsService from '../services/Stats';
import queryStore from '.';

export const STATS_QUERY_KEY = 'stats';

queryStore.setQueryDefaults(STATS_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetStatsQuery = (queryParams, queryOptions) =>
  useQuery(
    [STATS_QUERY_KEY, queryParams],
    async () => {
      let { client_graph_data, ...rest } =
        await StatsService.getMyDashboardData(queryParams);

      /*
       * 1. Reading the last 12 months of data
       * 2. Formatting `month` (month name) to match the original design
       * 3. Inverting the array (ordering the data from the first to the last month)
       */
      if (client_graph_data.length) {
        client_graph_data = client_graph_data
          .slice(0, 12)
          .map(({ month, ...other }) => ({
            ...other,
            month: month.substring(0, 3).toUpperCase()
          }));
      }

      return { ...rest, client_graph_data };
    },
    queryOptions
  );
