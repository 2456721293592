import { ACTIVE_TAB } from '../../../constants/campaign-management';
import { CampaignManagementActivityFeedTab } from './campaign-management-activity-feed-tab';
import { CampaignManagementDetailsTab } from './campaign-management-details-tab';
import { CampaignManagementMetricsTab } from './campaign-management-metrics-tab';
import { CampaignManagementNotesTab } from './campaign-management-notes-tab';

export const CampaignManagementTabs = ({
  activeTab,
  onCloseModal,
  campaign
}) => {
  switch (activeTab) {
    case ACTIVE_TAB.DETAILS:
      return (
        <CampaignManagementDetailsTab
          onCloseModal={onCloseModal}
          campaign={campaign}
        />
      );
    case ACTIVE_TAB.METRICS:
      return <CampaignManagementMetricsTab campaign={campaign} />;
    case ACTIVE_TAB.ACTIVITY_FEED:
      return <CampaignManagementActivityFeedTab campaign={campaign} />;
    case ACTIVE_TAB.CAMPAIGN_NOTES:
      return <CampaignManagementNotesTab campaign={campaign} />;
    default:
      return null;
  }
};
