import React, { useState, useMemo } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import './styles.scss';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import CampaignExistingPaymentMethods from '../CampaignExistingPaymentMethods';
import { useConfirmCampaignPayment } from '../../../queries/Campaign';

const useOptions = () => {
  const options = useMemo(
    () => ({
      paymentMethodOrder: ['us_bank_account', 'card'],
      style: {
        base: {
          fontSize: '16px',
          color: '#265d84',
          fontFamily:
            '"Lato", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    []
  );

  return options;
};

const formatCardBrandName = (brandValue) => {
  switch (brandValue) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'discover':
      return 'Discover';
    case 'american_express':
      return 'American Express';
    case 'jcb':
      return 'JCB';
    case 'diners_club':
      return 'Diners Club';
    case 'unionpay':
      return 'UnionPay';
    default:
      return brandValue;
  }
};

const formatPaymentMethods = (paymentMethods) =>
  paymentMethods
    .map((item) => {
      if (item.type === 'us_bank_account') {
        return {
          id: item.id,
          name: `${item[item.type].bank_name} - ••••${
            item[item.type].last4
          }`,
          type: item.type
        };
      }

      if (item.type === 'card') {
        return {
          id: item.id,
          name: `${formatCardBrandName(item[item.type].brand)} - ••••${
            item[item.type].last4
          }`,
          type: item.type
        };
      }

      return null;
    })
    .filter(Boolean);

const CampaignPaymentForm = ({
  onSuccess,
  onCancel,
  campaignPayment,
  campaignId
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const existingPaymentMethods = campaignPayment?.payment_methods || [];

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isNewPaymentMethod, setIsNewPaymentMethod] = useState(false);
  const [selectedNewPaymentMethodType, setSelectedNewPaymentMethodType] =
    useState(null);

  const { mutateAsync: confirmCampaignPayment } =
    useConfirmCampaignPayment();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const handleMessageAfterFormSubmission = (error) => {
      if (error) {
        setMessage(error.message);
      } else {
        onSuccess();
      }
      setIsLoading(false);
    };

    let selectedPaymentMethodId = selectedPaymentMethod?.id;
    if (!selectedPaymentMethodId) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleMessageAfterFormSubmission(submitError);
        return;
      }

      const response = await stripe.confirmSetup({
        elements,
        confirmParams: {},
        redirect: 'if_required'
      });
      const { error, setupIntent } = response;
      selectedPaymentMethodId = setupIntent.payment_method;

      if (error) {
        handleMessageAfterFormSubmission(error);
        setIsLoading(false);
        return;
      }
    }

    try {
      const data = await confirmCampaignPayment({
        campaign_id: campaignId,
        payment_method: selectedPaymentMethodId
      });

      if (data.status === 'requires_action') {
        const { error } = await stripe.handleNextAction({
          clientSecret: data.client_secret
        });

        if (error) {
          handleMessageAfterFormSubmission(error);
          setIsLoading(false);
          return;
        }
      }

      handleMessageAfterFormSubmission();
    } catch (error) {
      handleMessageAfterFormSubmission(error);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleChangePaymentMethods = (e) => {
    setIsNewPaymentMethod(false);
    setSelectedPaymentMethod(
      existingPaymentMethods.find((item) => item.id === e.target.value)
    );
  };

  const totalAmount =
    (isNewPaymentMethod && selectedNewPaymentMethodType === 'card') ||
    selectedPaymentMethod?.type === 'card'
      ? campaignPayment.amount *
        (1 + campaignPayment.card_additional_fee_percent)
      : campaignPayment.amount;

  return (
    <form className="campaign-payment-form" onSubmit={handleSubmit}>
      <Typography marginBottom={1}>
        This campaign deposit is <b>USD 500</b>. Note that the additional
        fee <b>2.9%</b> will be applied with the card payment.
      </Typography>
      <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
        Payment details
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%'
        }}>
        <CampaignExistingPaymentMethods
          selectedPaymentMethod={selectedPaymentMethod}
          paymentMethods={formatPaymentMethods(existingPaymentMethods)}
          onChange={handleChangePaymentMethods}
        />
        <Box
          border={1}
          borderRadius={2}
          borderColor="rgba(25, 118, 210, 0.5)"
          overflow="hidden"
          sx={{
            maxHeight: isNewPaymentMethod ? '700px' : '45px',
            minHeight: '45px',
            transition: 'max-height 300ms ease-in-out'
          }}>
          <FormControlLabel
            sx={{ width: '100%' }}
            control={<Radio />}
            checked={isNewPaymentMethod}
            onChange={(e) => {
              e.preventDefault();
              setIsNewPaymentMethod(true);
              setSelectedPaymentMethod(null);
            }}
            label={
              <Box display="flex" alignItems="center">
                <span style={{ marginLeft: 8 }}>
                  Add new payment method
                </span>
              </Box>
            }
          />
          <PaymentElement
            options={options}
            onChange={(result) =>
              setSelectedNewPaymentMethodType(result?.value?.type)
            }
          />
        </Box>
      </Box>

      {message && <p className="error-message">{message}</p>}
      <div className="payment-action-container">
        <Button
          variant="outlined"
          type="button"
          disabled={isLoading}
          onClick={onCancel}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={
            isLoading || (!isNewPaymentMethod && !selectedPaymentMethod)
          }>
          {isLoading ? 'PAYING...' : `PAY DEPOSIT $${totalAmount}`}
        </Button>
      </div>
    </form>
  );
};

export default CampaignPaymentForm;
