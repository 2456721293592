import classnames from 'classnames';
import styles from './Select.module.scss';

const Select = ({
  label,
  options = [],
  className,
  fullWidth = false,
  value,
  onChange
}) => {
  if (!options.length) return null;

  const controlled = value != null && onChange != null;

  return (
    <div className={classnames(styles.select, className)}>
      {label ? <label>{label}</label> : null}
      <select
        className={classnames({
          [styles.fullWidth]: fullWidth
        })}
        {...(controlled
          ? {
              value,
              onChange: (event) => onChange(event.target.value)
            }
          : {})}>
        <option disabled value="">
          Please select
        </option>
        {options?.map(({ label, value }) => (
          <option value={value} key={`select-option-${value}`}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
