import Scrollbars from 'react-custom-scrollbars-2';
import LoadingOverlay from '@src/components/budget-management/LoadingOverlay/LoadingOverlay';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../shared/CustomScrollBar/CustomScrollBar';
import { useSelector } from 'react-redux';
import { useGetCampaignActivityList } from '@src/queries/Campaign';
import dayjs from 'dayjs';
import { selectActivityFeedFilter } from '@src/store/selectors/Dashboard';

const HistoryList = () => {
  const { data, isLoading } = useGetCampaignActivityList();

  const activityFeedFilter = useSelector(selectActivityFeedFilter);

  const dateRange = activityFeedFilter.dateRange;
  const campaignId = activityFeedFilter.campaignId;

  const transformType = (type) => {
    const words = type.split('_');
    return words
      .map(
        (word) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  };

  const filteredList = (() => {
    if (!data) return [];

    let filteredList = [...data];

    if (dateRange.from && dateRange.to) {
      const startDate = dayjs(dateRange.from, 'YYYY-MM-DD');
      const endDate = dayjs(dateRange.to, 'YYYY-MM-DD');

      filteredList = filteredList.filter((activity) => {
        const createdDate = dayjs(activity.created_datetime);

        return (
          createdDate.isAfter(startDate) && createdDate.isBefore(endDate)
        );
      });
    }

    if (campaignId) {
      filteredList = filteredList.filter(
        (activity) => activity.campaign_id === campaignId
      );
    }

    return filteredList;
  })();

  return (
    <div className="h-full w-full flex flex-col bg-white">
      <div className="flex w-full h-9 items-center px-10 gap-6 shadow-md">
        <p className="flex-[1.25] text-td-brig font-semibold">Event</p>
        <p className="flex-1 text-td-brig font-semibold">Campaign</p>
        <p className="flex-1 text-td-brig font-semibold">Time</p>
        <p className="flex-[0.5] text-td-brig font-semibold">By</p>
      </div>
      <Scrollbars
        className="-mb-[12px] relative"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}>
        <LoadingOverlay open={isLoading} />
        {filteredList.map((activity, index) => (
          <div key={index} className="flex px-10 gap-6">
            <p className="text-base font-semibold flex-[1.25] py-3 text-td-submarine-grey">
              {transformType(activity.activity_type)}
            </p>
            <p className="text-base flex-1 py-3 text-td-submarine-grey">
              {activity.campaign_name}
            </p>
            <p className="text-base flex-1 py-3 text-td-submarine-grey">
              {dayjs(activity.created_datetime).format('MM/DD/YY HH:MM')}
            </p>
            <p className="text-base flex-[0.5] py-3 text-td-submarine-grey">
              J. Crawford
            </p>
          </div>
        ))}
      </Scrollbars>
    </div>
  );
};

export default HistoryList;
