import React, { useState, useRef, useEffect } from 'react';
import VideoPlayerThumbnail from './VideoPlayerThumbnail';
import FullscreenVideoPlayer from './FullscreenVideoPlayer';

const VideoPlayer = ({ src }) => {
  const [isVideoPlayerOpened, setIsVideoPlayerOpened] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const fullscreenRef = useRef(null);

  const toggleMedia = () => {
    if (videoPlaying) {
      handleVideoEnded();
    } else {
      setIsVideoPlayerOpened(true);
    }
  };

  const handleVideoEnded = () => {
    setVideoPlaying(false);
    setIsVideoPlayerOpened(false);
  };

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      setIsVideoPlayerOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener(
        'fullscreenchange',
        handleFullscreenChange
      );
    };
  }, []);

  const exitFullscreen = () => {
    handleVideoEnded();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const requestFullscreen = () => {
    const element = fullscreenRef.current;

    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  return (
    <div ref={fullscreenRef}>
      {isVideoPlayerOpened ? (
        <FullscreenVideoPlayer
          onPlay={() => setVideoPlaying(true)}
          requestFullscreen={requestFullscreen}
          exitFullscreen={exitFullscreen}
          src={src}
        />
      ) : (
        <VideoPlayerThumbnail
          toggleMedia={toggleMedia}
          requestFullscreen={requestFullscreen}
          exitFullscreen={exitFullscreen}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
