import './ActiveCampaignBar.css';
import Button from './../../shared/Button/Button';
import { ReactComponent as FilterIcon } from './../../../assets/images/filter-icon.svg';

const ActiveCampaignBar = ({ campaign, showAllCampaigns }) => {
  return (
    <div className="active-campaign-bar d-flex align-items-center justify-content-between">
      <FilterIcon className="flex-shrink-0" />
      <span className="title text-one-line flex-grow-1">
        {campaign?.campaign_name}
      </span>
      <Button onClick={showAllCampaigns} title="SHOW ALL" />
    </div>
  );
};

export default ActiveCampaignBar;
