import { useState } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { useIfMobile } from '../../../utils/responsive';
import { ReactComponent as ProjectSearchIcon } from '../../../assets/images/project-search-icon.svg';
import { ReactComponent as ExtensionIcon } from '../../../assets/images/extension-icon.svg';
import ProjectRequestModal from '../ProjectRequestModal';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const ButtonList = ({
  isProjectInfoModalOpened,
  setIsProjectInfoModalOpened,
  setIsSearchBoxOpened,
  setIsMobileHeaderButtonShown,
  setIsProjectRequestModalShown
}) => (
  <>
    <button
      className={classNames('button', 'custom-button', {
        active: isProjectInfoModalOpened
      })}
      onClick={() => {
        setIsProjectInfoModalOpened(true);
        setIsSearchBoxOpened(false);
        setIsMobileHeaderButtonShown(false);
      }}>
      PROJECT SCOPE
    </button>
    <PermissionChecker permissions={['project_management.common.write']}>
      <button
        className="button custom-button"
        onClick={() => {
          setIsProjectRequestModalShown(true);
          setIsMobileHeaderButtonShown(false);
        }}>
        REQUEST A PROJECT
      </button>
    </PermissionChecker>
  </>
);

const HeaderButtons = ({
  setIsSearchBoxOpened,
  setIsProjectInfoModalOpened,
  isProjectInfoModalOpened,
  isSearchBoxOpened
}) => {
  const isMobile = useIfMobile();

  const [isProjectRequestModalShown, setIsProjectRequestModalShown] =
    useState(false);
  const [isMobileHeaderButtonShown, setIsMobileHeaderButtonShown] =
    useState(false);

  return (
    <>
      <div className="header-buttons">
        <button
          className={classNames('button', 'icon-button', {
            active: isSearchBoxOpened
          })}
          onClick={() => {
            setIsSearchBoxOpened(true);
            setIsProjectInfoModalOpened(false);
          }}>
          <ProjectSearchIcon></ProjectSearchIcon>
        </button>
        {isMobile && (
          <button
            className="button icon-button"
            onClick={() => {
              setIsSearchBoxOpened(false);
              setIsProjectInfoModalOpened(false);
              setIsMobileHeaderButtonShown(true);
            }}>
            <ExtensionIcon></ExtensionIcon>
          </button>
        )}
        {!isMobile && (
          <ButtonList
            setIsSearchBoxOpened={setIsSearchBoxOpened}
            setIsProjectInfoModalOpened={setIsProjectInfoModalOpened}
            isProjectInfoModalOpened={isProjectInfoModalOpened}
            setIsMobileHeaderButtonShown={setIsMobileHeaderButtonShown}
            setIsProjectRequestModalShown={
              setIsProjectRequestModalShown
            }></ButtonList>
        )}
      </div>
      {isMobile && (
        <div
          className={classNames('mobile-header-buttons-backdrop', {
            active: isMobileHeaderButtonShown
          })}
          onClick={() => {
            setIsMobileHeaderButtonShown(false);
          }}>
          <div
            className="mobile-header-buttons-container"
            onClick={(e) => e.stopPropagation()}>
            <ButtonList
              setIsSearchBoxOpened={setIsSearchBoxOpened}
              setIsProjectInfoModalOpened={setIsProjectInfoModalOpened}
              isProjectInfoModalOpened={isProjectInfoModalOpened}
              setIsMobileHeaderButtonShown={setIsMobileHeaderButtonShown}
              setIsProjectRequestModalShown={
                setIsProjectRequestModalShown
              }></ButtonList>
          </div>
        </div>
      )}
      {isProjectRequestModalShown && (
        <ProjectRequestModal
          close={() =>
            setIsProjectRequestModalShown(false)
          }></ProjectRequestModal>
      )}
    </>
  );
};

export default HeaderButtons;
