import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activityFeedFilter: {
    dateRange: {
      from: null,
      to: null
    },
    campaignId: null,
    candidateId: null
  }
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setActivityFeedFilter(state, { payload }) {
      state.activityFeedFilter = {
        ...state.activityFeedFilter,
        ...payload
      };
    }
  }
});

export const { setActivityFeedFilter } = DashboardSlice.actions;

export default DashboardSlice.reducer;
