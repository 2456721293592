import './index.css';

import { ReactComponent as InfoIcon } from '../../../assets/images/information-symbol.svg';
import { ReactComponent as QuoteMark } from '../../../assets/images/quote-mark.svg';

const cardsCopy = [
  {
    type: 'quote',
    text: 'It is better to fail in originality than to succeed in imitation.',
    creator: 'Herman Melville'
  },
  {
    type: 'quote',
    text: 'I never dreamed about success. I worked for it.',
    creator: 'Estée Lauder'
  },
  {
    type: 'quote',
    text: 'Don’t let yesterday take up too much of today.',
    creator: 'Will Rogers'
  },
  {
    type: 'quote',
    text: 'Goal setting is the secret to a compelling future.',
    creator: 'Tony Robbins'
  },
  {
    type: 'quote',
    text: 'Either you run the day or the day runs you.',
    creator: 'Jim Rohn'
  },
  {
    type: 'quote',
    text: "Opportunities don't happen, you create them.",
    creator: 'Chris Grosser'
  },
  {
    type: 'quote',
    text: 'If you’re not positive energy, you’re negative energy.',
    creator: 'Mark Cuban'
  }
  // {
  //     type: 'info',
  //     accent: "14 hours",
  //     text: "Average amount of time per week lost to manually completing tasks.",
  //     subText: "12 Revealing Stats on How Recruiters Feel About Ai, Ji-A Min"
  // }
];

const PlaceholderListItem = ({ numbListCars }) => {
  const minNumberOfCardsPerScreen = 4;
  const numberOfCars =
    minNumberOfCardsPerScreen < numbListCars
      ? 0
      : minNumberOfCardsPerScreen - numbListCars;
  const cards = cardsCopy
    .sort(() => 0.5 - Math.random())
    .slice(0, numberOfCars);

  return (
    <>
      {cards?.map((card) => (
        <div className="placeholder-list-item" key={card.text}>
          <div className="placeholder-list-item-inner">
            {card.type === 'quote' ? (
              <>
                <span className="placeholder-list-item-quote">
                  <QuoteMark />
                </span>
                <div className="placeholder-list-item-text">
                  {card.text}
                </div>
                <div className="placeholder-list-item-creator">
                  {card.creator}
                </div>
              </>
            ) : (
              <>
                <span className="placeholder-list-item-info">
                  <InfoIcon />
                </span>
                <div className="placeholder-list-item-accent">
                  {card.accent}
                </div>
                <div className="placeholder-list-item-main-text">
                  {card.text}
                </div>
                <div className="placeholder-list-item-sub-text">
                  {card.subText}
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default PlaceholderListItem;
