import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './DatePicker.module.scss';

const DatePicker = ({ label, value, onChange }) => (
  <div className={styles.datePicker}>
    <label>{label}</label>
    <ReactDatePicker
      selected={value}
      onChange={onChange}
      enableTabLoop={false}
    />
  </div>
);
export default DatePicker;
