import { ReactComponent as BluePenIcon } from '@src/assets/images/blue-pen.svg';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RATING } from '../../../constants/campaign-management';
import {
  useGetCampaignDetailQuery,
  useGetCampaignFeedList,
  useUpdateCampaign,
  useUpdateCampaignFeedback
} from '../../../queries/Campaign';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material';
import PermissionChecker from '@src/components/shared/PermissionChecker';
import {
  convertMinutesToHours,
  formatTimeDisplayByMinutes
} from '@src/utils/dateTime';
import { setShowNewMatches } from '../../../store/slices/Candidate';
import CampaignForm from '../CampaignForm';
import './styles/campaign-management-details-tab.scss';

const TooltipWrapper = ({ children, tooltipTitle, hasNoTooltip }) => {
  if (hasNoTooltip) {
    return children;
  }

  return (
    <Tooltip title={tooltipTitle} placement="top">
      {children}
    </Tooltip>
  );
};

export const CampaignManagementDetailsTab = ({
  onCloseModal,
  campaign
}) => {
  const [feedback, setFeedback] = useState();
  const [selectingRate, setSelectingRate] = useState();
  const [isCampaignEdit, setIsCampaignEdit] = useState(false);
  const [currentCampaignData, setCurrentCampaignData] = useState();

  const dispatch = useDispatch();
  const showNewMatches = () => dispatch(setShowNewMatches(true));

  const {
    data: campaignData,
    isLoading: isDetailLoading,
    isError: detailError
  } = useGetCampaignDetailQuery({
    campaign_id: campaign.id
  });

  useEffect(() => {
    setCurrentCampaignData(campaignData);
  }, [campaignData]);

  const {
    campaign: campaignInfo,
    candidates,
    active_resources
  } = currentCampaignData || {};

  const { data: feedList } = useGetCampaignFeedList({
    campaign_id: campaign.id
  });

  const { mutate: onUpdateCampaign } = useUpdateCampaign();
  const { mutate: onUpdateFeedback } = useUpdateCampaignFeedback();

  const handleUpdateCampaign = () => {
    onUpdateCampaign({
      campaign_id: campaign.id,
      payload: campaignInfo
    });

    setIsCampaignEdit(false);
  };

  const handleSaveFeedback = () => {
    onUpdateFeedback({
      campaign_id: campaign.id,
      rating: selectingRate,
      feedback
    });

    setSelectingRate();
  };

  const isActiveCampaign =
    Boolean(campaignInfo?.is_active) &&
    Boolean(campaignInfo?.deposit_paid);

  if (isDetailLoading)
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Box>
    );

  if (detailError) return null;

  const [latestFeed] = feedList || [];

  if (isCampaignEdit) {
    return (
      <Box
        className="campaign-management-details-tab"
        margin="0 2rem 1rem 2rem">
        <CampaignForm
          campaignInfo={campaignInfo}
          updateCampaignInfo={(updatedCampaign) => {
            setCurrentCampaignData((prev) => ({
              ...prev,
              campaign: updatedCampaign
            }));
          }}
        />
        <div className="gap-4 action-buttons d-flex justify-content-center">
          <Button
            className="w-25"
            onClick={() => {
              setCurrentCampaignData((prev) => ({
                ...prev,
                campaign: campaignData?.campaign
              }));
              setIsCampaignEdit(false);
            }}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateCampaign}
            className="w-25"
            variant="contained">
            Update
          </Button>
        </div>
      </Box>
    );
  }

  return (
    <div className="campaign-management-details-tab">
      {selectingRate && (
        <>
          <p className="mt-4 text-center fw-bold">
            Tell Us Please why do you feel this way?
          </p>
          <div className="d-flex">
            <textarea
              className="edit-rating-input w-100"
              rows={14}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>
          <div className="gap-4 action-buttons d-flex justify-content-center">
            <Button
              className="w-25"
              onClick={() => setSelectingRate()}
              variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={!feedback}
              onClick={handleSaveFeedback}
              className="w-25"
              variant="contained">
              Submit
            </Button>
          </div>
        </>
      )}
      {!selectingRate && (
        <>
          <p className="flex items-center description-title fw-bold">
            Campaign Description
            {!isCampaignEdit && (
              <PermissionChecker
                permissions={['dashboard.campaign.write']}>
                <BluePenIcon
                  className="ms-2 pointer"
                  onClick={() => setIsCampaignEdit(true)}
                />
              </PermissionChecker>
            )}
          </p>
          <p className="mb-2 description-content max-h-[120px] overflow-y-scroll">
            {campaignInfo?.campaign_description}
          </p>

          <div className="latest-update">
            <p className="vertical-label fw-bold">Latest Update</p>
            {latestFeed && (
              <div className="px-6 py-3">
                <div className="d-flex">
                  <div className="avatar" />
                  <p className="m-0 name fw-bold">
                    {latestFeed.candidate_full_name}
                  </p>
                </div>
                <p className="my-2 quote fw-bold">
                  {latestFeed.description}
                </p>
                <p className="mt-auto mb-0 time fw-bold">
                  {format(
                    parseISO(latestFeed.created_datetime),
                    'h:mm a MMM d, yyyy'
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="active-resources">
            <div className="px-4 header d-flex justify-content-between fw-bold">
              <p>Active Resources</p>
              <p>
                Total hours:{' '}
                <span className="fw-normal">
                  {`${convertMinutesToHours(
                    campaignInfo?.total_minutes
                  )}h`}
                </span>
              </p>
            </div>
            <table className="table w-100">
              <thead>
                <tr className="header-row">
                  <th>Name</th>
                  <th style={{ width: '150px' }}>Hired</th>
                  <th>Last Week</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Boolean(active_resources?.length) &&
                  active_resources?.map((resource) => (
                    <tr
                      key={resource.candidate_id}
                      className={
                        resource.is_active ? '' : 'inactive-resource-row'
                      }>
                      <td className="gap-2 fw-bold d-flex align-items-center">
                        <Avatar
                          src={resource.avatar}
                          alt={resource.full_name}
                        />
                        <span>{resource.full_name}</span>
                      </td>
                      <td>
                        {resource.is_active ? (
                          new Date(
                            resource.hired_datetime
                          ).toLocaleDateString()
                        ) : (
                          <span className="inactive-resource">
                            The resource is currently inactive
                          </span>
                        )}
                      </td>
                      <td>
                        {formatTimeDisplayByMinutes(
                          resource.last_week_minutes
                        )}
                      </td>
                      <td>
                        {formatTimeDisplayByMinutes(
                          resource.total_minutes
                        )}
                      </td>
                    </tr>
                  ))}
                {(!campaignInfo || !campaignInfo.deposit_paid) && (
                  <tr>
                    <td colSpan={4}>
                      <Box display="flex" justifyContent="center">
                        <Typography width="28rem" textAlign="center">
                          This section will consist of data associated with
                          your active and inactive campaign resources
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                )}
                {Boolean(campaignInfo?.deposit_paid) &&
                  !active_resources?.length &&
                  Boolean(campaignInfo?.sourcing_status) &&
                  !candidates?.matched_count && (
                    <tr>
                      <td colSpan={4}>
                        <Box display="flex" justifyContent="center">
                          <Typography width="28rem" textAlign="center">
                            Sourcing for this campaign is in progress. Soon
                            you will be presented with vetted matches to
                            voter on for this campaign!
                          </Typography>
                        </Box>
                      </td>
                    </tr>
                  )}
                {Boolean(campaignInfo?.deposit_paid) &&
                  !active_resources?.length &&
                  Boolean(campaignInfo?.sourcing_status) &&
                  Boolean(candidates?.matched_count) && (
                    <tr>
                      <td colSpan={4}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          gap="8px">
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center">
                            <Typography width="28rem" textAlign="center">
                              You have {candidates?.matched_count} new
                              vetted matches for this campaign.
                              <br />
                              Vote now to review your curated matches!
                            </Typography>
                          </Box>
                          <Box display="flex" justifyContent="center">
                            <Button
                              sx={{ width: '8rem' }}
                              variant="contained"
                              onClick={() => {
                                onCloseModal();
                                showNewMatches();
                              }}>
                              VOTE NOW!
                            </Button>
                          </Box>
                        </Box>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </>
      )}
      <PermissionChecker permissions={['dashboard.campaign.write']}>
        <div className="items-center gap-4 footer d-flex justify-content-center fw-bold">
          <p>Are you happy with this campaign?</p>
          <TooltipWrapper
            tooltipTitle="You will be able to provide feedback on this campaign once activated."
            hasNoTooltip={isActiveCampaign}>
            <span
              className={classNames('rating-icon smiling-face', {
                active: [selectingRate].includes(RATING.GOOD)
              })}
              onClick={() =>
                isActiveCampaign && setSelectingRate(RATING.GOOD)
              }
            />
          </TooltipWrapper>
          <TooltipWrapper
            tooltipTitle="You will be able to provide feedback on this campaign once activated."
            hasNoTooltip={isActiveCampaign}>
            <span
              className={classNames('rating-icon meh-face', {
                active: [selectingRate].includes(RATING.NORMAL)
              })}
              onClick={() =>
                isActiveCampaign && setSelectingRate(RATING.NORMAL)
              }
            />
          </TooltipWrapper>
          <TooltipWrapper
            tooltipTitle="You will be able to provide feedback on this campaign once activated."
            hasNoTooltip={isActiveCampaign}>
            <span
              className={classNames('rating-icon sad-face', {
                active: [selectingRate].includes(RATING.BAD)
              })}
              onClick={() =>
                isActiveCampaign && setSelectingRate(RATING.BAD)
              }
            />
          </TooltipWrapper>
        </div>
      </PermissionChecker>
    </div>
  );
};
