import { cn } from '@src/utils';
import PropTypes from 'prop-types';
import { HOME_TABS } from '../../constants/common';
import HomeDesktopActivityFeedFilters from '@src/containers/Home/HomeDesktopActivityFeedFilters';

const HomeDesktopTabHeader = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex items-center justify-between mx-6">
      <div className="flex gap-3 mt-4">
        <div
          className={cn(
            'h-[40px] cursor-pointer w-[135px] font-semibold rounded-t-md bg-[#ccdce6] text-[#88a6b8] flex items-center justify-center',
            {
              'bg-td-super-rare-jade text-white':
                activeTab === HOME_TABS.CANDIDATES
            }
          )}
          onClick={() => setActiveTab(HOME_TABS.CANDIDATES)}>
          <p className="font-semibold">Candidate</p>
        </div>
        <div
          className={cn(
            'h-[40px] cursor-pointer w-[135px] font-semibold rounded-t-md text-[#88a6b8] flex items-center justify-center bg-[#ccdce6]',
            {
              'bg-td-super-rare-jade text-white':
                activeTab === HOME_TABS.ACTIVITY_FEED
            }
          )}
          onClick={() => setActiveTab(HOME_TABS.ACTIVITY_FEED)}>
          <p className="font-semibold">Activity Feed</p>
        </div>
      </div>
      {activeTab === HOME_TABS.ACTIVITY_FEED && (
        <HomeDesktopActivityFeedFilters />
      )}
    </div>
  );
};

HomeDesktopTabHeader.prototype = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func
};

export default HomeDesktopTabHeader;
