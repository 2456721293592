import { Button, Tooltip, Typography } from '@mui/material';
import { ReactComponent as LinkOut } from '../../../assets/images/link-out-icon.svg';

const LaunchMeetingButton = ({ isDisabled, onClick }) => {
  const button = () => (
    <Button
      sx={{ width: '100%', opacity: isDisabled ? 0.65 : 1 }}
      variant="contained"
      onClick={() => {
        if (isDisabled) {
          return;
        }

        onClick?.();
      }}>
      <LinkOut
        style={{
          width: '1rem',
          height: '1rem',
          fill: '#fff'
        }}
      />
      <Typography color="#fff" fontWeight={700} fontSize={12}>
        LAUNCH INTERVIEW
      </Typography>
    </Button>
  );

  if (!isDisabled) {
    return button();
  }

  return (
    <Tooltip
      title="This button is only available before the meeting 30 mins"
      placement="top">
      {button()}
    </Tooltip>
  );
};

export default LaunchMeetingButton;
