import { createSelector } from 'reselect';

const appSlice = (state) => state.AppSlice;

export const selectIsAuthenticated = createSelector(
  appSlice,
  (state) => state.isAuth
);

export const selectActiveMobileTab = createSelector(
  appSlice,
  (state) => state.activeMobileTab
);
