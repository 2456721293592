import { useState } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import Input from '../../../components/shared/Input';

const RoleUpdateModal = ({
  onUpdate,
  onCancel,
  isOpen,
  defaultRoleName
}) => {
  const [name, setName] = useState(defaultRoleName);
  return (
    <Modal isOpen={isOpen} className="new-role-modal" width="18.75rem">
      <Input
        label="Update your role name"
        placeholder="Role Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex justify-center gap-2 mt-2">
        <Button
          onClick={() => {
            onUpdate(name);
            setName(name);
          }}
          disabled={!name}>
          UPDATE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </div>
    </Modal>
  );
};

export default RoleUpdateModal;
