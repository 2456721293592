import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CampaignsStatsCardListItem from '../CampaignsStatsCardListItem/CampaignsStatsCardListItem';
import { setCampaignFilter } from '../../../../store/slices/Campaign';
import { selectCampaignFilter } from '../../../../store/selectors/Campaign';

import './CampaignsStatsCardList.css';

const CampaignsStatsCardList = ({
  containerClassName,
  data = undefined
}) => {
  const dispatch = useDispatch();

  const campaignFilter = useSelector(selectCampaignFilter);

  return (
    <div
      className={
        'campaigns-stats__card-list' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {data && (
        <>
          <CampaignsStatsCardListItem
            key={0}
            figure={data?.total_active_campaigns}
            backgroundColorVar="--color-super-rare-jade"
            title="Active"
            onClick={() =>
              dispatch(
                setCampaignFilter({
                  onlyActive: !campaignFilter.onlyActive,
                  onlyPaused: false,
                  actionRequired: false
                })
              )
            }
            selected={campaignFilter.onlyActive}
          />
          <CampaignsStatsCardListItem
            key={2}
            figure={data?.total_paused_campaigns}
            backgroundColorVar="--color-batch-blue"
            title="Paused"
            onClick={() =>
              dispatch(
                setCampaignFilter({
                  onlyPaused: !campaignFilter.onlyPaused,
                  onlyActive: false,
                  actionRequired: false
                })
              )
            }
            selected={campaignFilter.onlyPaused}
          />
          <CampaignsStatsCardListItem
            key={1}
            figure={data?.total_action_required_campaigns}
            backgroundColorVar="--color-brig"
            title="Action Required"
            onClick={() =>
              dispatch(
                setCampaignFilter({
                  actionRequired: !campaignFilter.actionRequired,
                  onlyActive: false,
                  onlyPaused: false
                })
              )
            }
            selected={campaignFilter.actionRequired}
          />
        </>
      )}
    </div>
  );
};

CampaignsStatsCardList.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.object
};

export default CampaignsStatsCardList;
