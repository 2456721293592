import './styles.scss';

import { useState, useRef } from 'react';
import classNames from 'classnames';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box } from '@mui/material';

const SingleFileSelector = ({
  onChange,
  onRemove,
  defaultFileName,
  disabled
}) => {
  const [fileName, setFileName] = useState(defaultFileName || '');
  const fileInputRef = useRef();

  const fileNameParts = fileName.split('/');
  const displayedFileName = fileNameParts[fileNameParts.length - 1];

  return (
    <div className="file-selector">
      <input
        type="file"
        onChange={(e) => {
          const {
            target: { files }
          } = e;

          const [file] = files;

          setFileName(file.name);
          onChange && onChange(file);
        }}
        disabled={disabled}
        ref={fileInputRef}
      />
      <label
        className={classNames('file-custom', {
          'cursor-default bg-td-disabled-input': disabled
        })}
        onClick={() => !disabled && fileInputRef.current.click()}>
        <input
          type="text"
          value={displayedFileName ?? 'Upload file'}
          className={classNames('file-text', {
            empty: !displayedFileName,
            'bg-td-disabled-input': disabled
          })}
          disabled
        />
        {!disabled && onRemove && fileName && (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
            padding="0 0.5rem"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFileName('');
              onRemove();
            }}>
            <DeleteRoundedIcon
              sx={{ fill: '#A6C0CE', width: '1.25rem', height: '1.25rem' }}
            />
          </Box>
        )}
        <span
          className={classNames('file-button', {
            'cursor-default': disabled
          })}>
          {displayedFileName ? 'REPLACE' : 'UPLOAD'}
        </span>
      </label>
    </div>
  );
};

export default SingleFileSelector;
