import './Questionnaire.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveQuestionnaire,
  submitQuestionnaireRequest
} from '../../../store/slices/Auth';
import {
  selectCurrentQuestionnaire,
  selectRegistrationStep
} from '../../../store/selectors/Auth';
import QuestionnaireForm from './../../../components/forms/register/Questionnaire/QuestionnaireForm';

const Questionnaire = () => {
  const step = useSelector(selectRegistrationStep);
  const questionnaire = useSelector(selectCurrentQuestionnaire);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(saveQuestionnaire({ partialQuestionnaire: values }));

    if (step === 2) {
      dispatch(submitQuestionnaireRequest());
    }
  };

  const handleGoBack = (values) =>
    dispatch(
      saveQuestionnaire({ partialQuestionnaire: values, goBack: true })
    );

  return (
    <QuestionnaireForm
      questions={questionnaire}
      onSubmit={handleSubmit}
      onBack={handleGoBack}
      step={step}
    />
  );
};

export default Questionnaire;
