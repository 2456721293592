import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

const ToggleSwitch = ({
  active = false,
  onToggle = () => {},
  className,
  hasNoDisabledState,
  label = 'Toggle'
}) => (
  <div
    className={`toggle-switch ${
      hasNoDisabledState ? 'no-disabled-state' : ''
    } ${className}`}>
    <input
      type="checkbox"
      id={label}
      checked={active}
      onChange={onToggle}
    />
    <label htmlFor={label}>{label}</label>
  </div>
);

ToggleSwitch.propTypes = {
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  hasNoDisabledState: PropTypes.bool
};

export default ToggleSwitch;
