import './NewMatchesList.css';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnimatePresence,
  motion,
  useAnimationControls
} from 'framer-motion';

import DesktopNewMatchCard from '../DesktopNewMatchCard';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import {
  setNewMatchesAvailable,
  setShowNewMatches
} from '../../../store/slices/Candidate';
import {
  useGetNewMatchCandidatesQuery,
  useVoteDownCandidateQuery,
  useVoteUpCandidateQuery
} from './../../../queries/NewMatches';
import NewMatch from '../../spline/NewMatch';

const wiggleAnimation = {
  rotate: [0, 40, -40, 40, -40, 0],
  transition: {
    delay: 1,
    duration: 1.5,
    ease: 'easeInOut',
    times: [0, 0.15, 0.3, 0.45, 0.75, 1],
    repeat: Infinity,
    repeatDelay: 2
  }
};

const scaleAnimation = {
  scale: 1.2,
  rotate: 0,
  transition: {
    delay: 0,
    duration: 0.5
  }
};

export const NewMatchesListV2 = () => {
  const dislikeControls = useAnimationControls();
  const likeControls = useAnimationControls();
  const { data: candidates } = useGetNewMatchCandidatesQuery();
  const { mutate: voteUp } = useVoteUpCandidateQuery();
  const { mutate: voteDown } = useVoteDownCandidateQuery();
  const [index, setIndex] = useState(0);
  const [exitX, setExitX] = useState('100%');
  const [exitY, setExitY] = useState(0);
  const dispatch = useDispatch();
  const [isAnimationStopped, setIsAnimationStopped] = useState(false);

  const onBack = useCallback(
    () => dispatch(setShowNewMatches(false)),
    [dispatch]
  );

  const scaleIconAnimation = (controls) => {
    controls.stop();
    controls.start(scaleAnimation);
  };

  const resetIconAnimation = (controls) => {
    controls.set({
      scale: 1
    });
    controls.stop();
    controls.start(wiggleAnimation);
  };

  useEffect(() => {
    if (isAnimationStopped) {
      dislikeControls.start(wiggleAnimation);
      likeControls.start(wiggleAnimation);
    }
  }, [isAnimationStopped]);

  const handleDislike = () => {
    setIndex(index + 1);
    voteDown(candidates[0]);
  };

  const handleLike = () => {
    setIndex(index + 1);
    voteUp(candidates[0]);
  };

  useEffect(() => {
    if (candidates?.length === 0) {
      setTimeout(() => {
        onBack();
        dispatch(setNewMatchesAvailable(false));
      }, 400);
    }
  }, [candidates, dispatch, onBack]);

  if (!isAnimationStopped) {
    return (
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        className="fixed top-0 left-0 w-full h-full z-[10]"
        onClick={() => setIsAnimationStopped(true)}>
        <NewMatch />
      </motion.div>
    );
  }

  return (
    <motion.div className="flex flex-col new-matches-list">
      <div
        className="back-button state-transition pointer"
        onClick={onBack}
      />
      <div className="angle-background">
        <svg preserveAspectRatio="none" viewBox="0 0 100 100">
          <polygon
            fill="#133b58"
            points="100,100 0,0 0,100"
            opacity="1"></polygon>
        </svg>
      </div>
      <div className="w-full flex z-2 justify-center mx-auto my-3 p-6 text-white text-[20px] border-2 border-[#265d84] rounded-2xl max-w-[700px] relative mb-16 mt-10">
        <span className="absolute top-[-26px] bg-[#0e2f49] p-2">
          Congratulations, you have new pre-vetted matches to vote on!
        </span>
        <span className="font-bold truncate">
          {candidates?.[0]?.campaign_name}
        </span>
      </div>
      <div className="card-list-container">
        <AnimatePresence>
          {candidates?.length && (
            <motion.div
              className="relative mr-8 pointer"
              animate={dislikeControls}
              exit={{ opacity: 0 }}
              onClick={handleDislike}
              onMouseEnter={() => {
                scaleIconAnimation(dislikeControls);
                if (exitX !== -2000) setExitX(-2000);
                if (exitY !== 0) setExitY(0);
              }}
              onMouseLeave={() => resetIconAnimation(dislikeControls)}>
              <ThumbDownRoundedIcon className="fill-[#d782a2] w-16 h-16" />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="cards">
          <div className="cards-container w-[928px]">
            <AnimatePresence initial={false}>
              {candidates?.length >= 6 && (
                <DesktopNewMatchCard
                  key={index + 5}
                  initial={{ scale: 0, y: 200 }}
                  animate={{ scale: 0.9, y: 40 }}
                  transition={{
                    y: { duration: 0.5 },
                    scale: { duration: 0.5 }
                  }}
                  data={candidates[5]}
                />
              )}
              {candidates?.length >= 5 && (
                <DesktopNewMatchCard
                  key={index + 4}
                  initial={{ scale: 0, y: 200 }}
                  animate={{ scale: 0.9, y: 40 }}
                  transition={{
                    y: { duration: 0.5 },
                    scale: { duration: 0.5 }
                  }}
                  data={candidates[4]}
                />
              )}
              {candidates?.length >= 4 && (
                <DesktopNewMatchCard
                  key={index + 3}
                  initial={{ scale: 0, y: 200 }}
                  animate={{ scale: 0.9, y: 40 }}
                  transition={{
                    y: { duration: 0.5 },
                    scale: { duration: 0.5 }
                  }}
                  data={candidates[3]}
                />
              )}
              {candidates?.length >= 3 && (
                <DesktopNewMatchCard
                  key={index + 2}
                  initial={{ scale: 0, y: 200 }}
                  animate={{ scale: 0.9, y: 40 }}
                  transition={{
                    y: { duration: 0.5 },
                    scale: { duration: 0.5 }
                  }}
                  data={candidates[2]}
                />
              )}
              {candidates?.length >= 2 && (
                <DesktopNewMatchCard
                  key={index + 1}
                  initial={{ scale: 0, y: 100 }}
                  animate={{ scale: 0.95, y: 20 }}
                  transition={{
                    y: { duration: 0.5 },
                    scale: { duration: 0.5 }
                  }}
                  data={candidates[1]}
                />
              )}
              {candidates?.length >= 1 && (
                <DesktopNewMatchCard
                  key={index}
                  animate={{
                    scale: 1,
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    y: { duration: 0.5 },
                    opacity: { duration: 0.2 }
                  }}
                  exitX={exitX}
                  setExitX={setExitX}
                  exitY={exitY}
                  setExitY={setExitY}
                  index={index}
                  setIndex={setIndex}
                  isLast={candidates?.length === 1}
                  drag={true}
                  data={candidates[0]}
                  onDislike={voteDown}
                  onLike={voteUp}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
        <AnimatePresence>
          {candidates?.length && (
            <motion.div
              className="relative ml-8 pointer"
              transition={{
                delay: 2,
                duration: 1.5,
                ease: 'easeInOut',
                times: [0, 0.15, 0.3, 0.45, 0.75, 1],
                repeat: Infinity,
                repeatDelay: 4
              }}
              animate={likeControls}
              exit={{ opacity: 0 }}
              onClick={handleLike}
              onMouseEnter={() => {
                scaleIconAnimation(likeControls);
                if (exitX !== 2000) setExitX(2000);
                if (exitY !== 0) setExitY(0);
              }}
              onMouseLeave={() => resetIconAnimation(likeControls)}>
              <ThumbUpRoundedIcon className="fill-[#7ad7d7] w-16 h-16" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex items-center justify-center gap-4 mt-24 z-1">
        <span className="font-bold text-[25px] py-2 px-5 border-2 border-[#fff] rounded-full bg-[#13b9b7] text-white">
          {candidates?.length || 0}
        </span>
        <span className="text-[21px] text-white font-bold">
          pre-vetted matches left to vote on!
        </span>
      </div>
    </motion.div>
  );
};

export default NewMatchesListV2;
