import { format } from 'date-fns';

import './styles.scss';
import { Link } from 'react-router-dom';
import Table from '../../compliance-management/Table';
import Button from '../../shared/Button/Button';
import Avatar from '../../shared/Avatar/Avatar';
import { ReactComponent as GreenTickIcon } from '../../../assets/images/green-tick-icon.svg';

const ContractorTable = ({ contractors = [], isFetching }) => (
  <Table>
    <Table.Header>
      <tr>
        <th>Name</th>
        <th>Progress</th>
        <th>Hired Date</th>
        <th>Campaign</th>
        <th>Job Position</th>
        <th>Location</th>
        <th>Updated</th>
        <th>Updated By</th>
        <th></th>
      </tr>
    </Table.Header>
    <Table.Body>
      {isFetching ? (
        <tr>
          <td colSpan={10}>Loading...</td>
        </tr>
      ) : (
        contractors?.map((contractor) => (
          <tr key={contractor.candidate_id}>
            <td className="flex items-center gap-2 text-left">
              <Avatar src={contractor.avatar} alt="User Avatar" />
              <b>{`${contractor.first_name} ${contractor.last_name}`}</b>
            </td>
            <td>
              {contractor.total_count === 0 ? (
                'New'
              ) : contractor.answered_count === contractor.total_count ? (
                <GreenTickIcon className="custom-green-tick" />
              ) : (
                `${contractor.answered_count}/${contractor.total_count}`
              )}
            </td>
            <td>
              <span>
                {contractor.hired_datetime
                  ? format(
                      new Date(contractor.hired_datetime),
                      'MM/dd/yyyy'
                    )
                  : 'N/A'}
              </span>
            </td>
            <td>
              <b>{contractor.campaign_name}</b>
            </td>
            <td>
              <span>{contractor.tech_stack}</span>
            </td>
            <td>
              <span>
                {contractor.city}, {contractor.state}
              </span>
            </td>
            <td>
              <span>
                {contractor.updated_datetime
                  ? format(
                      new Date(contractor.updated_datetime),
                      'MM/dd/yyyy'
                    )
                  : 'N/A'}
              </span>
            </td>
            <td>
              <span>{contractor.client_full_name}</span>
            </td>
            <td>
              <Link
                to={`/compliance-management/contractors/${contractor.candidate_profile_status_id}/checklist`}
                className="no-underline">
                <Button className="custom-button">VIEW</Button>
              </Link>
            </td>
          </tr>
        ))
      )}
    </Table.Body>
  </Table>
);

export default ContractorTable;
