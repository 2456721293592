import classNames from 'classnames';
import './styles.scss';
import { useEffect, useState } from 'react';

const Input = ({
  name,
  label,
  placeholder,
  onChange,
  value,
  className,
  inputClassName,
  labelClassName,
  isSideLabel,
  disabled,
  type
}) => {
  return (
    <div
      className={classNames('input-field form-field', className, {
        'side-label': isSideLabel
      })}>
      {label && (
        <label
          className={classNames(labelClassName, {
            'mb-1': !isSideLabel
          })}>
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          placeholder={placeholder ?? ''}
          className={classNames('form-control', inputClassName)}
          name={name ?? ''}
          onChange={onChange}
          disabled={disabled}
          value={value}></textarea>
      ) : (
        <input
          type={type ?? 'text'}
          placeholder={placeholder ?? ''}
          className={classNames('form-control', inputClassName)}
          name={name ?? ''}
          onChange={onChange}
          disabled={disabled}
          value={value}></input>
      )}
    </div>
  );
};

export default Input;
