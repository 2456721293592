import { useQuery, useMutation, useQueryClient } from 'react-query';
import ComplianceService from './../services/Compliance';
import queryStore from '.';

export const COMPLIANCE_QUERY_KEY = 'compliance';

queryStore.setQueryDefaults(COMPLIANCE_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useGetMyTemplatesQuery = (queryOptions) =>
  useQuery(
    COMPLIANCE_QUERY_KEY,
    async () => {
      const { template_list } = await ComplianceService.getMyTemplates();
      return template_list;
    },
    queryOptions
  );

export const useGetTemplateDetailQuery = (templateId, queryOptions) =>
  useQuery(
    [COMPLIANCE_QUERY_KEY, templateId],
    async () => await ComplianceService.getTemplateDetail(templateId),
    queryOptions
  );

export const useUpdateTemplateQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ template_id, requirements, to_remove, template_name }) =>
      await ComplianceService.updateTemplate({
        template_name,
        template_id,
        requirements,
        to_remove
      }),
    {
      onSuccess: (_data) => {
        queryClient.invalidateQueries([COMPLIANCE_QUERY_KEY]);
      }
    }
  );
};

export const useUpdateResponseDocQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ to_remove, documents }) =>
      ComplianceService.updateResponseDoc({
        documents,
        to_remove
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COMPLIANCE_QUERY_KEY);
      }
    }
  );
};

export const useDeleteTemplateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (template_id) =>
      ComplianceService.deleteTemplate({
        template_id
      }),
    {
      onSuccess: (_data, variables) => {
        queryClient.removeQueries([
          COMPLIANCE_QUERY_KEY,
          variables.template_id
        ]);
      }
    }
  );
};

export const useUploadRequirementFileQuery = () =>
  useMutation((file) => ComplianceService.uploadRequirementFile(file));

export const useCreateTemplateQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ template_name, requirements }) =>
      ComplianceService.createTemplate({
        requirements,
        template_name
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COMPLIANCE_QUERY_KEY, {
          exact: true
        });
      }
    }
  );
};
