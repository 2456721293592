import { useFormContext } from 'react-hook-form';

import './PersonalDetails.scss';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import OutlinedInputWithLabel from '../OutlinedInputWithLabel/OutlinedInputWithLabel';
import { ReactComponent as UserPhotoIcon } from '../../../assets/images/user-photo-icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/upload-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash-icon.svg';

import Button from '../../../components/shared/Button/Button';
import { useUpdateUserMutation } from '../../../queries/User';

const PersonalDetails = ({ personalDetailsFormMethods }) => {
  const { getValues, setValue, register } = useFormContext();

  const { mutate: updateProfile } = useUpdateUserMutation();

  const values = getValues();

  const handleChoosePhoto = (event) =>
    event.currentTarget.files[0] &&
    setValue('avatar', event.currentTarget.files[0]);

  const handleRemovePhoto = (event) => {
    event.preventDefault();
    setValue('avatar', null);
  };

  const handleClearFileChooser = (event) => (event.target.value = null);

  const handleSubmitPersonalDetails =
    personalDetailsFormMethods.handleSubmit((formData) => {
      updateProfile({
        avatar: formData.avatar,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber,
        email_id: formData.email,
        job_title: formData.jobTitle,
        company_name: formData.companyName,
        company_url: formData.companyUrl
      });
    });

  return (
    <SectionWrapper title={'Personal Details'}>
      <div className="personal-details">
        <div className="personal-details__profile-photo-container">
          {values?.avatar ? (
            <>
              <label htmlFor="upload-user-photo-field" className="pointer">
                <img
                  src={
                    values?.avatar?.name
                      ? URL.createObjectURL(values.avatar)
                      : values.avatar
                  }
                  alt="User Avatar"
                  className="personal-details__user-photo-container"
                />
              </label>
              <label
                htmlFor="upload-user-photo-field"
                className="personal-details__user-photo-action-icon pointer no-tap-highlight">
                <TrashIcon
                  className="personal-details__remove-icon clickable state-transition"
                  onClick={(event) => handleRemovePhoto(event)}
                />
              </label>
            </>
          ) : (
            <>
              <label
                htmlFor="upload-user-photo-field"
                className="personal-details__user-photo-container">
                <UserPhotoIcon />
              </label>
              <label
                htmlFor="upload-user-photo-field"
                className="personal-details__user-photo-action-icon pointer no-tap-highlight">
                <UploadIcon className="personal-details__upload-icon clickable state-transition" />
              </label>
            </>
          )}
          <input
            id="upload-user-photo-field"
            type="file"
            name="avatar"
            accept="image/png, image/jpg, image/jpeg"
            onChange={(event) => handleChoosePhoto(event)}
            onClick={handleClearFileChooser}
          />
        </div>
        <OutlinedInputWithLabel
          label="First Name"
          name="firstName"
          register={register}
          required
          className="personal-details__field-first-name"
        />
        <OutlinedInputWithLabel
          label="Last Name"
          name="lastName"
          register={register}
          required
          className="personal-details__field-last-name"
        />
        <OutlinedInputWithLabel
          label="Phone"
          name="phoneNumber"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Email"
          name="email"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Job Title"
          name="jobTitle"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Company Name"
          name="companyName"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Company URL"
          name="companyUrl"
          register={register}
        />

        <div className="personal-details__submit">
          <Button
            title="UPDATE"
            onClick={handleSubmitPersonalDetails}
            width="10rem"
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default PersonalDetails;
