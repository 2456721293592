import { Button } from '@mui/material';
import Modal from '../../../components/shared/Modal';
import './styles.scss';

const ConfirmationModal = ({ onConfirm, onCancel, isOpen, message }) => (
  <Modal
    isOpen={isOpen}
    className="deletion-confirmation-modal"
    width="18.75rem">
    <div>
      <p className="header">INFORMATION</p>
    </div>
    <div className="message-container">{message}</div>
    <div className="actions-container">
      <Button variant="outlined" onClick={onCancel}>
        CANCEL
      </Button>
      <Button variant="contained" onClick={onConfirm}>
        CONFIRM
      </Button>
    </div>
  </Modal>
);

export default ConfirmationModal;
