import './styles.scss';
import { useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import UnsavedChangeHandler from '../../components/shared/UnsavedChangeHandler';
import Tab from '../../components/compliance-management/Tab';
import Button from '../../components/shared/Button/Button';
import TemplateTable from '../../components/compliance-management/TemplateTable';
import ContractorTable from '../../components/compliance-management/ContractorTable';
import NewTemplateModal from '../../components/compliance-management/NewTemplateModal';
import UpdateTemplateModal from '../../components/compliance-management/UpdateTemplateModal';
import ConfirmationModal from '../../components/shared/ConfirmationModal';
import { ReactComponent as PenIcon } from '../../assets/images/pen.svg';
import { ReactComponent as FilledTrashBinIcon } from '../../assets/images/filled-trash-bin.svg';
import { useGetCandidatesQuery } from '../../queries/Candidate';
import {
  useUpdateTemplateQuery,
  useCreateTemplateQuery,
  useGetMyTemplatesQuery,
  useGetTemplateDetailQuery,
  useDeleteTemplateMutation
} from '../../queries/Compliance';
import { extractUpdatePayloadFromRequirements } from '../../utils/common';
import Dropdown from '../../components/shared/Dropdown';
import { useUserContext } from '@src/contexts/UserContext';
import PermissionChecker, {
  usePermissionChecker
} from '@src/components/shared/PermissionChecker';

const COMPLIANCE_MANAGEMENT_TABS = {
  Contractors: 'contractors',
  Templates: 'templates'
};

const HIRED_PROFILE_STATUS = 6;

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ComplianceManagement = () => {
  const { tabName } = useParams();
  const history = useHistory();
  const { userData } = useUserContext();
  const hasContractorPermission = usePermissionChecker([
    'compliance_management.contractors.read',
    'compliance_management.contractors.write'
  ]);
  const hasTemplatePermission = usePermissionChecker([
    'compliance_management.templates.read',
    'compliance_management.templates.write'
  ]);

  const getAccessibleTabName = (tab) => {
    let currentTab = tab;
    if (
      currentTab === COMPLIANCE_MANAGEMENT_TABS.Contractors &&
      hasContractorPermission
    ) {
      return COMPLIANCE_MANAGEMENT_TABS.Contractors;
    }

    currentTab = COMPLIANCE_MANAGEMENT_TABS.Templates;

    if (
      currentTab === COMPLIANCE_MANAGEMENT_TABS.Templates &&
      hasTemplatePermission
    ) {
      return COMPLIANCE_MANAGEMENT_TABS.Templates;
    }
  };

  const [activeTab, setActiveTab] = useState(
    getAccessibleTabName(tabName ?? COMPLIANCE_MANAGEMENT_TABS.Contractors)
  );
  const queryParams = useQueryParams();
  const [isNewTemplateModalOpened, setIsNewTemplateModalOpened] =
    useState();
  const [isUpdateTemplateModalOpened, setIsUpdateTemplateModalOpened] =
    useState();
  const [isDeleteTemplateModalOpened, setIsDeleteTemplateModalOpened] =
    useState();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    queryParams.get('templateId')
  );
  const [
    unsavedChangeWithSelectedTemplateId,
    setUnsavedChangeWithSelectedTemplateId
  ] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  const { isFetching, data: contractors } = useGetCandidatesQuery({
    profile_status: HIRED_PROFILE_STATUS
  });

  const { mutateAsync: deleteTemplate } = useDeleteTemplateMutation();

  const getMyTemplatesRequest = useGetMyTemplatesQuery();

  const { mutateAsync: updateTemplate } = useUpdateTemplateQuery();

  const { mutateAsync: createTemplate } = useCreateTemplateQuery();

  const { data: selectedTemplate, isFetching: isFetchingTemplate } =
    useGetTemplateDetailQuery(selectedTemplateId, {
      enabled: !!selectedTemplateId
    });

  const updateCurrentTemplateId = (id) => {
    setSelectedTemplateId(id);
    if (id) {
      const queryString = new URLSearchParams({
        templateId: id
      }).toString();
      history.push({
        search: queryString
      });
    }
  };

  useEffect(() => {
    const tab = getAccessibleTabName(
      activeTab === COMPLIANCE_MANAGEMENT_TABS.Templates
        ? COMPLIANCE_MANAGEMENT_TABS.Templates
        : COMPLIANCE_MANAGEMENT_TABS.Contractors
    );
    if (tab !== activeTab) {
      history.push({
        pathname: `/compliance-management/${tab}`
      });
    }
    setActiveTab(tab);
  }, [userData]);

  useEffect(() => {
    setActiveTab(
      getAccessibleTabName(
        tabName ?? COMPLIANCE_MANAGEMENT_TABS.Contractors
      )
    );
  }, [tabName]);

  useEffect(() => {
    if (selectedTemplate) {
      setRequirements(selectedTemplate?.requirements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useEffect(() => {
    if (getMyTemplatesRequest?.data) {
      setTemplates(getMyTemplatesRequest.data);
      if (getMyTemplatesRequest.data.length && !selectedTemplateId) {
        updateCurrentTemplateId(getMyTemplatesRequest.data[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMyTemplatesRequest?.data]);

  const submitUpdateRequirements = async () => {
    const payload = {
      ...extractUpdatePayloadFromRequirements(requirements),
      template_id: selectedTemplateId
    };
    await updateTemplate(payload);
    toast.success('Updated requirements successfully');
    setHasUnsavedData(false);
  };

  const unsavedChangeMessage =
    'You have unsaved changes in your requirements. You still want to leave the page without saving changes?';

  const unsavedChangeWithTemplateChangeMessage =
    'You have unsaved changes in your requirements. You still want to change the template?';

  if (!userData) {
    return null;
  }

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        className="compliance-management"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <ConfirmationModal
          key="unsaved-change-confirmation-modal"
          message={unsavedChangeWithTemplateChangeMessage}
          isOpen={unsavedChangeWithSelectedTemplateId}
          onCancel={() => {
            setUnsavedChangeWithSelectedTemplateId(null);
          }}
          onConfirm={() => {
            setTimeout(() => {
              updateCurrentTemplateId(unsavedChangeWithSelectedTemplateId);
              setRequirements(selectedTemplate?.requirements);
              setUnsavedChangeWithSelectedTemplateId(null);
            }, 100);
            setHasUnsavedData(false);
          }}
        />
        <UnsavedChangeHandler
          onConfirm={() => {
            setHasUnsavedData(false);
            setRequirements(selectedTemplate?.requirements);
          }}
          shouldBlockNavigation={() => true}
          when={hasUnsavedData}
          message={unsavedChangeMessage}
        />
        <div className="header">
          <PermissionChecker
            permissions={['compliance_management.contractors.read']}>
            <Tab.Label
              isActive={
                activeTab === COMPLIANCE_MANAGEMENT_TABS.Contractors
              }
              onClick={() => {
                if (activeTab !== COMPLIANCE_MANAGEMENT_TABS.Contractors) {
                  history.push({
                    pathname: `/compliance-management/${COMPLIANCE_MANAGEMENT_TABS.Contractors}`
                  });
                }
              }}>
              {COMPLIANCE_MANAGEMENT_TABS.Contractors.toUpperCase()}
            </Tab.Label>
          </PermissionChecker>
          <PermissionChecker
            permissions={['compliance_management.templates.read']}>
            <Tab.Label
              isActive={activeTab === COMPLIANCE_MANAGEMENT_TABS.Templates}
              onClick={() => {
                if (activeTab !== COMPLIANCE_MANAGEMENT_TABS.Templates) {
                  let queryString = '';
                  if (selectedTemplateId) {
                    queryString = new URLSearchParams({
                      templateId: selectedTemplateId
                    }).toString();
                  }
                  history.push({
                    pathname: `/compliance-management/${COMPLIANCE_MANAGEMENT_TABS.Templates}`,
                    search: queryString
                  });
                }
              }}>
              {COMPLIANCE_MANAGEMENT_TABS.Templates.toUpperCase()}
            </Tab.Label>
          </PermissionChecker>
          {activeTab === COMPLIANCE_MANAGEMENT_TABS.Templates && (
            <div className="button-container">
              <div className="select-input">
                <label>Template</label>
                <Dropdown
                  options={templates}
                  selectedOption={templates.find(
                    (template) =>
                      String(selectedTemplateId) === String(template.id)
                  )}
                  onChange={(option) => {
                    if (hasUnsavedData) {
                      setUnsavedChangeWithSelectedTemplateId(option.id);
                    } else {
                      updateCurrentTemplateId(option.id);
                    }
                  }}
                  className="w-40"
                  labelKey="template_name"
                />
              </div>

              <PermissionChecker
                permissions={['compliance_management.templates.write']}>
                <>
                  <Button
                    onClick={() => setIsUpdateTemplateModalOpened(true)}>
                    <PenIcon></PenIcon>
                  </Button>
                  <Button
                    onClick={() => setIsDeleteTemplateModalOpened(true)}>
                    <FilledTrashBinIcon></FilledTrashBinIcon>
                  </Button>
                  <Button
                    onClick={() => setIsNewTemplateModalOpened(true)}>
                    NEW TEMPLATE
                  </Button>
                  <div className="vertical-divider"></div>
                  <Button onClick={submitUpdateRequirements}>
                    UPDATE
                  </Button>
                </>
              </PermissionChecker>

              <ConfirmationModal
                message='Your are about removing your template, are you sure you want to do that? All requirements will be deleted, but you still can find available files in the "FILES" section.'
                isOpen={isDeleteTemplateModalOpened}
                onCancel={() => setIsDeleteTemplateModalOpened(false)}
                onConfirm={async () => {
                  await deleteTemplate(selectedTemplateId);
                  updateCurrentTemplateId(1); //the default template can't be deleted
                  setIsDeleteTemplateModalOpened(false);
                }}
              />
              <NewTemplateModal
                isOpen={isNewTemplateModalOpened}
                onCancel={() => setIsNewTemplateModalOpened(false)}
                onCreate={async (templateName) => {
                  if (!templateName) {
                    toast.error('Please enter a template name');
                    return false;
                  }
                  if (
                    templates.some(
                      (template) => template.template_name === templateName
                    )
                  ) {
                    toast.error('Template name already exists');
                    return false;
                  }
                  const result = await createTemplate({
                    template_name: templateName
                  });
                  setSelectedTemplateId(result.template_id);
                  setIsNewTemplateModalOpened(false);
                  return true;
                }}></NewTemplateModal>
              <UpdateTemplateModal
                isOpen={isUpdateTemplateModalOpened}
                onCancel={() => setIsUpdateTemplateModalOpened(false)}
                templateName={
                  selectedTemplate?.template_detail?.template_name
                }
                onUpdate={(updatedTemplateName) => {
                  const payload = {
                    template_id: selectedTemplateId,
                    template_name: updatedTemplateName,
                    ...extractUpdatePayloadFromRequirements(requirements)
                  };
                  updateTemplate(payload);
                  setIsUpdateTemplateModalOpened(false);
                }}></UpdateTemplateModal>
            </div>
          )}
        </div>
        <div className="main-content">
          {activeTab === COMPLIANCE_MANAGEMENT_TABS.Contractors && (
            <ContractorTable
              contractors={contractors}
              isFetching={isFetching}
            />
          )}
          {activeTab === COMPLIANCE_MANAGEMENT_TABS.Templates && (
            <TemplateTable
              requirements={requirements}
              isLoading={isFetchingTemplate}
              updateRequirements={(updatedRequirements) => {
                setRequirements(updatedRequirements);
                setHasUnsavedData(true);
              }}></TemplateTable>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ComplianceManagement;
