import './CandidateListItem.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { ReactComponent as GithubIcon } from '../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../assets/images/portfolio-icon.svg';
import { ReactComponent as BriefcaseIcon } from '../../../assets/images/briefcase-icon.svg';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { linkSocialIcon, constructLocationString } from '../helper';
import { Button } from '@mui/material';
import config from '../../../config';
import InterviewSchedulingModal from '../../interviews/InterviewSchedulingModal';
import { useState } from 'react';
import {
  useInitializeInterviewMutation,
  useRescheduleInterviewMutation
} from '../../../queries/Interview';
import { INTERVIEW_STATUSES } from '../../../constants/common';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CandidateService from '../../../services/Candidate';
import InterviewModal from '../../interviews/InterviewModal';
import CampaignDetailsModal from '../../campaign/CampaignDetailsModal';

const CandidateListItem = ({ data, onClick }) => {
  const [isInterviewSetupModalOpen, setIsInterviewSetupModalOpen] =
    useState(false);
  const [isInterviewModalOpened, setIsInterviewModalOpened] =
    useState(false);
  const [isCampaignDetailsModalOpened, setIsCampaignDetailsModalOpened] =
    useState(false);
  const [
    isInterviewReschedulingModalOpen,
    setIsInterviewReschedulingModalOpen
  ] = useState();
  const {
    first_name,
    last_name,
    tech_stack,
    city,
    state,
    country,
    hired_datetime,
    profile_status,
    experience = 0,
    avatar = undefined,
    github_url,
    linkedin_url,
    portfolio_url,
    campaign_id,
    candidate_id,
    interview_status,
    interview_id,
    campaign_name,
    candidate_profile_status_id
  } = data || {};
  const [interviewStatus, setInterviewStatus] = useState(interview_status);

  const { mutateAsync: initializeInterview } =
    useInitializeInterviewMutation();
  const { mutateAsync: rescheduleInterview } =
    useRescheduleInterviewMutation();

  return (
    <>
      {isInterviewSetupModalOpen && (
        <InterviewSchedulingModal
          onClose={() => {
            setIsInterviewSetupModalOpen(false);
          }}
          noOfDates={3}
          title="SETUP THE INTERVIEW"
          candidateName={`${first_name} ${last_name}`}
          onSubmit={async (dates) => {
            return await initializeInterview({
              dates,
              candidate_id,
              campaign_id
            });
          }}
        />
      )}
      {isInterviewReschedulingModalOpen && (
        <InterviewSchedulingModal
          onClose={() => {
            setIsInterviewReschedulingModalOpen(false);
          }}
          noOfDates={2}
          primaryCtaText="RESCHEDULE"
          title="RESCHEDULE THE INTERVIEW"
          candidateName={`${first_name} ${last_name}`}
          onSubmit={async (dates) => {
            return await rescheduleInterview({
              dates,
              candidate_id,
              campaign_id
            });
          }}
        />
      )}
      <motion.div
        id="candidate-list-item"
        className="candidate-list-item pointer no-tap-highlight"
        onClick={onClick}>
        <div className="items-center pl-3 mb-1 d-flex justify-content-between min-h-12">
          <p className="mr-2 text-xs font-semibold truncate text-td-brig">
            {campaign_name}
          </p>
          {profile_status === config.candidate.profileStatus.HIRED && (
            <div className="flex items-center p-3 space-x-2 rounded-bl-md bg-td-anti-flash-white min-w-[120px] justify-center shadow-td-inset">
              <div>
                <BriefcaseIcon className="fill-td-primary" />
              </div>
              <div className="font-semibold text-td-happy-days">Hired</div>
            </div>
          )}
          {profile_status === config.candidate.profileStatus.VOTED_UP && (
            <div className="flex items-center p-3 space-x-2 rounded-bl-md bg-td-anti-flash-white min-w-[120px] shadow-td-inset">
              <div>
                <ThumbUpRoundedIcon className="fill-td-primary" />
              </div>
              <div className="font-semibold text-td-happy-days">
                Voted Up
              </div>
            </div>
          )}
          {profile_status === config.candidate.profileStatus.INTERVIEWED &&
            interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN && (
              <div className="flex items-center p-3 space-x-2 rounded-bl-md bg-td-anti-flash-white min-w-[120px] shadow-td-inset">
                <div className="flex items-center justify-center p-1 rounded-full bg-td-deep-mauve">
                  <CloseRoundedIcon
                    sx={{ width: '18px', height: '18px' }}
                    className="fill-white"
                  />
                </div>
                <div className="font-semibold text-td-happy-days">
                  Declined
                </div>
              </div>
            )}
          {profile_status === config.candidate.profileStatus.INTERVIEWED &&
            interviewStatus === INTERVIEW_STATUSES.VOTED_UP && (
              <div className="flex items-center p-3 space-x-2 rounded-bl-md bg-td-anti-flash-white min-w-[120px] shadow-td-inset">
                <div className="flex items-center justify-center p-1 rounded-full bg-td-primary">
                  <CheckRoundedIcon
                    sx={{ width: '18px', height: '18px' }}
                    className="fill-white"
                  />
                </div>
                <div className="font-semibold text-td-happy-days">
                  Pending
                </div>
              </div>
            )}
        </div>
        <div className="flex px-3 mb-4">
          <div className="photo-info-container">
            <motion.div className="rounded-md relative w-[104px] h-[128px] overflow-hidden">
              <img
                src={avatar}
                alt={`${first_name}'s Profile`}
                className="w-full h-full rounded-lg"
              />
            </motion.div>
          </div>
          <div className="pl-4">
            <div className="d-flex flex-column">
              <motion.span className="font-semibold text-td-happy-days">
                {first_name}
              </motion.span>
              <motion.span className="font-semibold text-td-happy-days">
                {last_name}
              </motion.span>
            </div>
            <div className="mt-2">
              <motion.p className="text-sm font-semibold">
                {tech_stack}
              </motion.p>
              <motion.p className="text-xs">
                {constructLocationString(city, state, country)}
              </motion.p>
            </div>
            <div className="mt-4">
              <p className="text-xs font-semibold">{`${experience} ${
                experience == 1 ? 'Year' : 'Years'
              } Of Experience`}</p>
            </div>
          </div>
        </div>
        <div className="items-center w-full px-3 pb-3 d-flex justify-content-between">
          <motion.div className="flex space-x-3">
            {linkSocialIcon(github_url, GithubIcon, 'Github')}
            {linkSocialIcon(linkedin_url, LinkedinIcon, 'Linkedin')}
            {linkSocialIcon(portfolio_url, PortfolioIcon, 'Portfolio')}
          </motion.div>
          <div className="ml-auto">
            {profile_status === config.candidate.profileStatus.VOTED_UP &&
              (!interviewStatus ||
                [
                  INTERVIEW_STATUSES.AWAITING,
                  INTERVIEW_STATUSES.RESCHEDULED,
                  INTERVIEW_STATUSES.SCHEDULED,
                  INTERVIEW_STATUSES.SLOT_REQUESTED,
                  INTERVIEW_STATUSES.NO_SHOW,
                  INTERVIEW_STATUSES.CANCELLED
                ].includes(interviewStatus)) && (
                <div className="setup-interview-button__container">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      width: '148px'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        !interviewStatus ||
                        interviewStatus === INTERVIEW_STATUSES.AWAITING ||
                        interviewStatus ===
                          INTERVIEW_STATUSES.SLOT_REQUESTED
                      ) {
                        setIsInterviewSetupModalOpen(true);
                      } else {
                        setIsInterviewReschedulingModalOpen(true);
                      }
                    }}>
                    {!interview_id ? 'SETUP INTERVIEW' : 'RESCHEDULE'}
                  </Button>
                </div>
              )}
            {profile_status ===
              config.candidate.profileStatus.INTERVIEWED &&
              interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN && (
                <div className="setup-interview-button__container">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      width: '148px'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsInterviewModalOpened(true);
                    }}>
                    VIEW THE RESULTS
                  </Button>
                </div>
              )}
            {profile_status ===
              config.candidate.profileStatus.INTERVIEWED &&
              interviewStatus === INTERVIEW_STATUSES.VOTED_UP && (
                <div className="setup-interview-button__container">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      width: '148px'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      CandidateService.updateCandidateProfileStatus(
                        candidate_profile_status_id,
                        config.candidate.profileStatus.HIRED
                      );
                    }}>
                    HIRE
                  </Button>
                </div>
              )}
            {profile_status === config.candidate.profileStatus.HIRED &&
              hired_datetime && (
                <p className="space-x-1 text-xs">
                  <span>Hired Date:</span>
                  <span className="font-semibold">
                    {new Date(hired_datetime).toLocaleDateString()}
                  </span>
                </p>
              )}
            {isInterviewModalOpened && (
              <InterviewModal
                onClose={() => setIsInterviewModalOpened(false)}
                interviewId={interview_id}
                openCampaignDetails={() => {
                  setIsInterviewModalOpened(false);
                  setIsCampaignDetailsModalOpened(true);
                }}
                updateInterviewStatus={(status) =>
                  setInterviewStatus(status)
                }
              />
            )}
            {isCampaignDetailsModalOpened && (
              <CampaignDetailsModal
                campaignId={campaign_id}
                onClose={() => setIsCampaignDetailsModalOpened(false)}
              />
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

CandidateListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired
};

export default CandidateListItem;
