import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from '../../../components/login/LoginForm';
import OtpForm from '../../../components/login/OtpForm';
import AnimatedFormBackground from '../../../components/spline/AnimatedFormBackground';
import { routes } from '../../../router/routes';
import { setCookie } from '../../../utils/cookies';
import { setItem } from '../../../utils/localStorage';
import { setIsAuthenticated } from '../../../store/slices/App';
import { useDispatch } from 'react-redux';

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const Login = () => {
  const [isLoginFormSubmitted, setIsLoginFormSubmitted] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState();

  const dispatch = useDispatch();

  const history = useHistory();

  const submittedLoginForm = (data) => {
    if (!data) {
      return;
    }
    setCurrentPhoneNumber(data.phone_number);
    setIsLoginFormSubmitted(true);
  };

  const submittedOtpForm = (data) => {
    if (!data) {
      return;
    }

    const { access_token } = data;

    setCookie('token', access_token);

    setItem('newLogin', true);

    dispatch(setIsAuthenticated(true));

    history.push(routes.home);
  };

  return (
    <div>
      <AnimatedFormBackground />
      <AnimatePresence mode="wait">
        {!isLoginFormSubmitted ? (
          <motion.div>
            <LoginForm onSubmitted={submittedLoginForm} />
          </motion.div>
        ) : (
          <motion.div
            key="otp"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}>
            <OtpForm
              phoneNumber={currentPhoneNumber}
              onSubmitted={submittedOtpForm}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Login;
