import './ProjectRequestModal.scss';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { PopupModal } from 'react-calendly';

import Button from '../../shared/Button/Button';
import { useGetUserQuery } from '../../../queries/User';
import config from '../../../config';

const ProjectRequestModal = ({ close }) => {
  const { isSuccess, data } = useGetUserQuery();
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState();

  const showCalendly = () => {
    setIsCalendlyModalOpen(true);
    // openPopupWidget({
    //   url: config.calendly.url,
    //   prefill: {
    //     name:
    //       isSuccess && !!data?.first_name && !!data?.last_name
    //         ? data?.first_name + ' ' + data?.last_name
    //         : '',
    //     email: data?.email_id,
    //   },
    // })
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="project-request-modal"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          close();
        }
      }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center d-flex flex-column justify-content-center content">
        <h1>Project Requested</h1>
        <p>
          Thank you for requesting a new project. Your Trusted Dev
          concierge will be in touch shortly
        </p>
        <Button
          className="align-self-center"
          title="SCHEDULE A ZOOM MEETING WITH YOUR CONCIERGE"
          onClick={showCalendly}
        />
        <PopupModal
          url={config.calendly.url}
          pageSettings={{}}
          utm={{}}
          prefill={{
            name:
              isSuccess && !!data?.first_name && !!data?.last_name
                ? data?.first_name + ' ' + data?.last_name
                : '',
            email: data?.email_id
          }}
          onModalClose={() => setIsCalendlyModalOpen(false)}
          open={isCalendlyModalOpen}
          rootElement={document.getElementById('root')}
        />
      </motion.div>
    </motion.div>
  );
};

export default ProjectRequestModal;
