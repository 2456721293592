import request, { attachQueryParams } from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';

const StatsService = {
  getMyDashboardData: (queryParams) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getMyDashboardData`,
        queryParams
      ),
      method: 'get'
    })
};

export default StatsService;
