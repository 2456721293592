import { ReactComponent as UserPhotoIcon } from '@src/assets/images/user-photo-icon.svg';
import { ReactComponent as UploadIcon } from '@src/assets/images/upload-icon.svg';
import { ReactComponent as TrashIcon } from '@src/assets/images/trash-icon.svg';
import { useUpdateUserMutation } from '@src/queries/User';
import { useState } from 'react';
import Input from '../Input';
import Modal from '../Modal';
import Button from '../Button/Button';

const BasicInfoUpdateModal = ({ isOpen, userData, onClose }) => {
  const [profilePictureFile, setProfilePictureFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: updateProfile } = useUpdateUserMutation();
  const handleChoosePhoto = (event) =>
    event.currentTarget.files[0] &&
    setProfilePictureFile(event.currentTarget.files[0]);

  const handleRemovePhoto = (event) => {
    event.preventDefault();
    setProfilePictureFile(null);
  };

  const handleClearFileChooser = (event) => (event.target.value = null);

  const submitForm = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      const formData = new FormData(event.target);
      const formValues = Object.fromEntries(formData.entries());

      delete formValues.email_id;
      delete formValues.job_title;

      formValues.avatar = profilePictureFile ? profilePictureFile : null;

      formValues.is_registered = true;

      await updateProfile(formValues);
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="basic-info-update-modal"
      bodyClassName="p-0"
      width="100%"
      maxWidth="40rem">
      <form onSubmit={submitForm}>
        <div className="px-5 py-3 border-b border-td-grey">
          <p className="font-bold">Update your basic info</p>
        </div>
        <div className="grid grid-cols-2 gap-4 p-5">
          <div className="space-y-3">
            <div className="relative w-auto">
              {profilePictureFile ? (
                <div className="flex">
                  <label
                    htmlFor="upload-user-photo-field"
                    className="relative justify-start inline-block pointer">
                    <img
                      src={URL.createObjectURL(profilePictureFile)}
                      alt="User Avatar"
                      className="personal-details__user-photo-container"
                    />
                    <TrashIcon
                      className="absolute bottom-0 right-0 z-1 pointer no-tap-highlight"
                      onClick={(event) => handleRemovePhoto(event)}
                    />
                  </label>
                </div>
              ) : (
                <div className="flex">
                  <label
                    htmlFor="upload-user-photo-field"
                    className="relative justify-start inline-block pointer">
                    <div className="personal-details__user-photo-container">
                      <UserPhotoIcon />
                    </div>
                    <UploadIcon className="absolute bottom-0 right-0 z-1 pointer no-tap-highlight" />
                  </label>
                </div>
              )}
              <input
                id="upload-user-photo-field"
                type="file"
                name="avatar"
                className="hidden"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(event) => handleChoosePhoto(event)}
                onClick={handleClearFileChooser}
              />
            </div>
            <Input label="First name" name="first_name" />
            <Input label="Last name" name="last_name" />
          </div>
          <div className="mt-12 space-y-3">
            <Input label="Phone" name="phone_number" />
            <Input
              label="Email"
              name="email_id"
              disabled
              value={userData?.email_id ?? ''}
            />
            <Input
              label="Job title"
              name="job_title"
              disabled
              value={userData?.job_title ?? ''}
            />
          </div>
        </div>

        <div className="flex justify-center w-full px-5 py-3 border-t border-td-grey">
          <Button
            variant="contained"
            width="10rem"
            type="submit"
            className="w-40"
            disabled={isSubmitting}>
            CONTINUE
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BasicInfoUpdateModal;
