import './styles.scss';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import format from 'date-fns/format';

import config from '../../../config';
import { extractCandidatesFromTasks } from '../../../utils/common';
import Avatar from '../../shared/Avatar/Avatar';

const MilestoneContent = ({ milestone, ...popoverProps }) => {
  const {
    tasks,
    milestone_order,
    milestone_name,
    milestone_start_date,
    milestone_end_date,
    milestone_duration,
    milestone_status
  } = milestone;

  const candidates = extractCandidatesFromTasks(milestone?.tasks);

  const startDate = new Date(milestone_start_date ?? Date.now());
  const endDate = new Date(milestone_end_date ?? Date.now());

  return (
    <div
      className="time-frame-bar-popover"
      {...popoverProps}
      ref={popoverProps.setPopperElement}>
      <div className="headline">
        <p className="milestone-name">
          <b>Milestone {milestone_order}</b>
        </p>
        <p>
          <b>{milestone_name}</b>
        </p>
      </div>
      <div className="milestone-date-range">
        <p>
          <b>{`${format(
            startDate,
            startDate.getFullYear() !== endDate.getFullYear()
              ? 'MMMM d, yyyy'
              : 'MMMM d'
          )} - ${format(endDate, 'MMMM d, yyyy')}`}</b>
        </p>
      </div>
      <div>
        <div>
          <span className="title">
            <b>Tasks:</b>
          </span>
          <span>{tasks.length}</span>
        </div>
        <div>
          <span className="title">
            <b>Duration:</b>
          </span>
          <span>{`${milestone_duration} days`}</span>
        </div>
        <div className="status-container">
          <span className="title">
            <b>Status:</b>
          </span>
          <span className="status">
            <i className={classNames('legend-icon', milestone_status)}></i>
            <span>
              {config.projectStatus[milestone_status?.toUpperCase()]?.text}
            </span>
          </span>
        </div>
      </div>
      {candidates?.length > 0 && (
        <div>
          <div>
            <p>
              <b>Resources:</b>
            </p>
          </div>
          <div className="resources">
            {candidates.map((candidate) => (
              <div key={candidate.candidate_id} className="candidate">
                <Avatar src={candidate.avatar} width={1.5} height={1.5} />
                <span>{candidate.full_name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MilestoneInfoPopover = ({ referenceElement, milestone }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  return ReactDOM.createPortal(
    <MilestoneContent
      milestone={milestone}
      setPopperElement={setPopperElement}
      style={styles?.popper}
      {...attributes?.popper}></MilestoneContent>,
    document.body
  );
};

MilestoneInfoPopover.Content = MilestoneContent;

export default MilestoneInfoPopover;
