import React from 'react';
import classNames from 'classnames';

import './OutlinedInputWithLabel.scss';

const OutlinedInputWithLabel = ({
  label,
  name,
  register,
  required,
  className = '',
  type = 'text'
}) => (
  <div className={classNames('outlined-input-with-label', className)}>
    <label>
      {label} {!required ? '(optional)' : ''}
    </label>
    <input type={type} {...register(name, { required })} />
  </div>
);

export default OutlinedInputWithLabel;
