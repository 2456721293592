import { Box } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

const DatePicker = (props) => (
  <Box className="date-picker">
    <ReactDatePicker {...props} />
  </Box>
);

export default DatePicker;
