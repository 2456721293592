import { endOfDay, startOfDay } from 'date-fns';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';

export const dateTimeLocale = 'en-US';

export const dateFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit'
};

export const timeFormatOptions = {
  minute: '2-digit',
  hour: 'numeric',
  hour12: true
};

export const getStartAndEndTimeOfDay = (date) => {
  const selectedDate = new Date(date);
  const startOfToday = startOfDay(selectedDate);
  const endOfToday = endOfDay(selectedDate);

  return {
    startDate: startOfToday,
    endDate: endOfToday
  };
};

export const getDateRangeOfWeek = (date) => {
  const selectedDate = new Date(date);
  const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });

  return {
    startDate,
    endDate
  };
};

export const generateWeekDaysByDateRange = ({ startDate, endDate }) => {
  const weekdaysInRange = eachDayOfInterval({
    start: startDate,
    end: endDate
  });

  const formattedWeekdays = weekdaysInRange.map((date) => ({
    shortDayName: format(date, 'E'),
    date
  }));

  return formattedWeekdays;
};

export const sumMinutesInDateRange = (timesheets, startDate, endDate) =>
  timesheets.reduce((result, current) => {
    const spentDate = new Date(current.spent_date);
    let updatedResult = result;

    if (
      spentDate >= startDate &&
      spentDate <= endDate &&
      !isNaN(current.spent_minutes)
    ) {
      updatedResult += parseInt(current.spent_minutes);
    }

    return updatedResult;
  }, 0);

export const formatTimeDisplayByMinutes = (minutes) => {
  if (!minutes) {
    return '00:00';
  }

  const calculatedHours = Math.floor(minutes / 60);
  const calculatedMinutes = minutes % 60;

  const formattedHours =
    calculatedHours >= 10 ? calculatedHours : '0' + calculatedHours;
  const formattedMinutes =
    calculatedMinutes >= 10 ? calculatedMinutes : '0' + calculatedMinutes;

  return `${formattedHours}:${formattedMinutes}`;
};

export const compareDates = (date1, date2) =>
  format(new Date(date1), 'yyyy-MM-dd') ===
  format(new Date(date2), 'yyyy-MM-dd');

export const findTimesheet = (timesheets, date) =>
  timesheets.find((timesheet) => compareDates(timesheet.spent_date, date));

export const getResourceTimesheetMinutesByDate = (date, timesheets) => {
  if (!timesheets || !date) {
    return null;
  }

  const foundTimesheet = findTimesheet(timesheets, date);

  if (!foundTimesheet) {
    return null;
  }

  return foundTimesheet.spent_minutes;
};

export const convertTimeDisplayToMinutes = (timeDisplay) => {
  const [hours, minutes] = timeDisplay.split(':');

  let totalMinutes = 0;

  if (hours && !isNaN(hours)) {
    totalMinutes += parseInt(hours) * 60;

    if (minutes && !isNaN(minutes)) {
      totalMinutes += parseInt(minutes);
    }
  }

  return totalMinutes;
};

export const convertMinutesToHours = (minutes) => {
  if (!minutes) {
    return 0;
  }

  return Math.floor(minutes / 60);
};
