import PropTypes from 'prop-types';

import './styles/graph-filter-select.scss';

export const GraphFilterSelect = ({ options, value, onChange }) => (
  <select
    className="graph-filter-select border-1 min-w-40"
    value={value}
    onChange={onChange}>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

GraphFilterSelect.propTypes = {
  containerClassName: PropTypes.array
};
