import { createSelector } from 'reselect';

const candidateSlice = (state) => state.CandidateSlice;

export const selectPreviewCandidate = createSelector(
  candidateSlice,
  (state) => state.previewCandidate
);

export const selectShowNewMatches = createSelector(
  candidateSlice,
  (state) => state.showNewMatches
);

export const selectNewMatchesAvailable = createSelector(
  candidateSlice,
  (state) => state.newMatchesAvailable
);
