import request, { attachQueryParams } from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';
const CAMPAIGN_API_URL = 'campaign/api';

const CampaignService = {
  getCampaigns: () =>
    request({
      url: `${RECRUITER_API_URL}/getMyCampaigns`,
      method: 'get'
    }),
  getCampaignDetail: (queryParams) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getCampaignDetail`,
        queryParams
      ),
      method: 'get'
    }),
  updateCampaignStatus: (campaign_id, status) =>
    request({
      url: `${RECRUITER_API_URL}/updateCampaignStatus/${campaign_id}`,
      method: 'put',
      data: { status }
    }),
  getCampaignActivityList: (queryParams) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/getCampaignActivityList`,
        queryParams
      ),
      method: 'get'
    }),
  getCampaignFeedList: (queryParams) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/getCampaignFeedList`,
        queryParams
      ),
      method: 'get'
    }),
  getCampaignNoteList: (queryParams) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/getCampaignNoteList`,
        queryParams
      ),
      method: 'get'
    }),

  updateCampaignDescription: (campaign_id, description) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/updateCampaignDescription/${campaign_id}`
      ),
      method: 'put',
      data: { description }
    }),

  updateCampaign: (campaign_id, payload) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/editClientCampaign/${campaign_id}`
      ),
      method: 'put',
      data: payload
    }),
  createCampaign: (payload) =>
    request({
      url: attachQueryParams(`${CAMPAIGN_API_URL}/createClientCampaign`),
      method: 'post',
      data: payload
    }),

  updateCampaignFeedback: (campaign_id, rating, feedback) =>
    request({
      url: attachQueryParams(`${CAMPAIGN_API_URL}/updateCampaignFeedback`),
      method: 'put',
      data: { campaign_id, rating, feedback }
    }),

  createCampaignNote: (campaign_id, note) =>
    request({
      url: attachQueryParams(`${CAMPAIGN_API_URL}/createCampaignNote`),
      method: 'post',
      data: { campaign_id, note }
    }),

  updateCampaignNote: (noteId, note) =>
    request({
      url: attachQueryParams(`${CAMPAIGN_API_URL}/updateCampaignNote`),
      method: 'put',
      data: { campaign_note_id: noteId, note }
    }),

  deleteCampaignNote: (queryParams) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/deleteCampaignNote`,
        queryParams
      ),
      method: 'delete'
    }),
  deleteCampaign: ({ campaign_id }) =>
    request({
      url: attachQueryParams(
        `${CAMPAIGN_API_URL}/deleteCampaign/${campaign_id}`
      ),
      method: 'delete'
    }),
  requestArchival: (campaign_id) =>
    request({
      url: `${CAMPAIGN_API_URL}/requestArchival`,
      data: { campaign_id },
      method: 'post'
    }),
  updateCampaignArchival: (campaign_id, is_archived) =>
    request({
      url: `${CAMPAIGN_API_URL}/updateCampaignArchival`,
      data: { campaign_id, is_archived },
      method: 'post'
    }),
  confirmCampaignPayment: ({ campaign_id, payment_method }) =>
    request({
      url: `${RECRUITER_API_URL}/confirmCampaignPayment`,
      data: { campaign_id, payment_method },
      method: 'post'
    }),
  setupCampaignPayment: ({ campaign_id }) =>
    request({
      url: `${RECRUITER_API_URL}/setupCampaignPayment`,
      data: { campaign_id },
      method: 'post'
    })
};

export default CampaignService;
