import { useState } from 'react';
import Button from '../../components/shared/Button/Button';
import { ReactComponent as TrustedDevLogo } from '../../assets/images/td-logo.svg';
import AuthService from '../../services/Auth';

const OtpForm = ({ onSubmitted, phoneNumber }) => {
  const [otp, setOtp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitOtpForm = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);

      const data = await AuthService.verifyOTP({
        phone_number: phoneNumber,
        verification_otp: otp,
        user_role: 1
      });

      onSubmitted(data);
    } finally {
      setOtp('');

      setIsSubmitting(false);
    }
  };
  return (
    <div className="">
      <div className="absolute top-0 left-0 z-1 w-[100dvw] h-[100dvh] flex items-center justify-center">
        <form
          onSubmit={submitOtpForm}
          className="bg-gradient-to-b from-[#042A48] to-[#3281C7]/50 p-6 rounded-2xl shadow-md shadow-black/30 max-w-[650px] w-full mx-4">
          <div className="flex justify-center mt-6">
            <TrustedDevLogo width="100rem" height="2.875rem" />
          </div>
          <div className="mt-5 text-center">
            <h1 className="text-3xl font-bold text-white">
              Almost there!
            </h1>
            <h1 className="text-3xl font-bold text-white">
              Enter your OTP code
            </h1>
          </div>
          <div className="p-5">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-[#6D94AD] mb-2">
                OTP
              </label>
              <input
                autoFocus
                className="bg-[#72BAED]/20 text-white p-3 rounded-lg"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
            </div>
            <Button
              type="submit"
              className="mt-8 h-[55px] w-full"
              disabled={isSubmitting}>
              LOGIN
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OtpForm;
