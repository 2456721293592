import { useState } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import Input from '../../../components/shared/Input';

const NewRoleModal = ({ onCreate, onCancel, isOpen }) => {
  const [name, setName] = useState('');
  return (
    <Modal isOpen={isOpen} className="new-role-modal" width="18.75rem">
      <Input
        label="Name your new role"
        placeholder="Role Name"
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex justify-center gap-2 mt-2">
        <Button
          onClick={() => {
            onCreate(name);
            setName('');
          }}
          disabled={!name}>
          CREATE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </div>
    </Modal>
  );
};

export default NewRoleModal;
