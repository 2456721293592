import './Home.css';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectNewMatchesAvailable,
  selectShowNewMatches
} from '../../store/selectors/Candidate';
import {
  setCandidatePreviewCardPosition,
  setPreviewCandidate
} from '../../store/slices/Candidate';
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus
} from '../../store/selectors/CandidateFilter';
import { useIfMobile, desktopMinWidth } from './../../utils/responsive';
import HomeMobileLayout from './HomeMobileLayout';
import HomeDesktopLayout from './HomeDesktopLayout';
import EmptyDashboard from '../../components/spline/EmptyDashboard';
import { selectCampaignFilter } from '../../store/selectors/Campaign';
import { useGetCampaignsQuery } from '../../queries/Campaign';

const Home = () => {
  const { data: campaigns, isLoading } = useGetCampaignsQuery();
  const showNewMatches = useSelector(selectShowNewMatches);
  const newMatchesAvailable = useSelector(selectNewMatchesAvailable);
  const isFilterFavorites = useSelector(selectCandidateFilterFavorites);
  const isFilterProfileStatus = useSelector(
    selectCandidateFilterProfileStatus
  );
  const campaignFilter = useSelector(selectCampaignFilter);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const isMobile = useIfMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPreviewCandidate(null));
  }, [
    showNewMatches,
    isFilterFavorites,
    isFilterProfileStatus,
    isMobile,
    dispatch
  ]);

  useEffect(() => {
    let timeout;

    let handleResize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const candidateListElem = document.getElementById('candidateList');
        const homeMobileContentElem = document.getElementById(
          'homeMobileContent'
        );

        const minItemWidth = '100%';
        const largeScreensMinItemWidth = 750;
        const xLargeScreensMinItemWidth = 1250;

        if (candidateListElem) {
          const { offsetLeft, offsetWidth, offsetHeight } =
            candidateListElem;
          let width = offsetWidth;
          let left = offsetLeft;
          let top = 0;
          let height = offsetHeight + 1;

          const isLargeBreakpoints = window.offsetWidth > 1920;
          const isXLargeBreakpoints = window.offsetWidth > 3800;
          const minWidth = isXLargeBreakpoints
            ? xLargeScreensMinItemWidth
            : isLargeBreakpoints
              ? largeScreensMinItemWidth
              : minItemWidth;

          width = minWidth;
          height = 'auto';
          left = 0;
          top = 0;

          if (window.offsetWidth < desktopMinWidth) {
            width = '100%';
            height = 'calc(100vh - 3.125rem)';
            top = '3.125rem';
          }

          dispatch(
            setCandidatePreviewCardPosition({
              top,
              left,
              width,
              height
            })
          );
        } else if (homeMobileContentElem && isMobile) {
          const { offsetLeft, offsetTop, offsetWidth } =
            homeMobileContentElem;

          dispatch(
            setCandidatePreviewCardPosition({
              top: offsetTop,
              left: offsetLeft,
              width: offsetWidth,
              height: `calc(100% - ${offsetTop}px)`
            })
          );
        }
      }, 1000);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [
    showNewMatches,
    newMatchesAvailable,
    isMobile,
    dispatch,
    window.offsetWidth
  ]);

  if (isLoading) {
    return null;
  }

  if (!campaigns || campaigns.length === 0) {
    return <EmptyDashboard />;
  }

  const filteredCampaign = (() => {
    return campaigns.filter((campaign) => {
      switch (true) {
        case campaignFilter.onlyActive:
          return campaign.is_active === 1 && campaign.is_archived === 0;
        case campaignFilter.onlyPaused:
          return campaign.is_active === 0 && campaign.is_archived === 0;
        case campaignFilter.actionRequired:
          return (
            campaign.is_archived === 0 &&
            (campaign.deposit_paid === 0 ||
              campaign.total_matched_candidate !== 0)
          );
        default:
          return true;
      }
    });
  })();

  return isMobile ? (
    <HomeMobileLayout
      campaigns={filteredCampaign}
      selectedCampaign={selectedCampaign}
      setSelectedCampaign={setSelectedCampaign}
    />
  ) : (
    <HomeDesktopLayout
      campaigns={filteredCampaign}
      selectedCampaign={selectedCampaign}
      setSelectedCampaign={setSelectedCampaign}
    />
  );
};

export default Home;
