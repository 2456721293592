import { useRef, useState, memo } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { getTimePositionFromTimeFrame } from '../../../utils/dateTimeHelper';
import { ReactComponent as GreenTickIcon } from '../../../assets/images/green-tick-icon.svg';
import config from '../../../config';
import TaskInfoPopover from '../../project-management/TaskInfoPopover';
import { useIfMobile } from '../../../utils/responsive';
import MilestoneInfoPopover from '../MilestoneInfoPopover';

const calculatedPositionStyles = ({
  startPosition,
  endPosition,
  elementWidthGap
}) => ({
  '--start-position': `${startPosition * elementWidthGap + 2}px`,
  '--timeline-length': `${
    (endPosition - startPosition + 1) * elementWidthGap - 2
  }px`
});

const TaskBar = ({
  task,
  taskRef,
  milestoneOrder,
  currentTimeFrame,
  elementWidthGap
}) => {
  const isMobile = useIfMobile();

  const [referenceElement, setReferenceElement] = useState(null);

  const [isTaskPopoverOpened, setIsTaskPopoverOpened] = useState(false);

  const { candidates, start_date, end_date, status } = task;

  const [startPosition, endPosition] = getTimePositionFromTimeFrame(
    currentTimeFrame,
    start_date,
    end_date
  );

  const candidateCount = isMobile ? 1 : candidates.length;

  return (
    <div
      className="sub-timeline"
      ref={(node) => taskRef.current.push(node)}
      style={{
        minHeight: `${
          (candidateCount * 24 + //candidate's element height
            (candidateCount - 1) * 8 + //gap
            12 * 2 + //padding
            2) / //border lines
          16 //convert px to rem
        }rem`
      }}>
      <div
        className="time-frame"
        onMouseEnter={() => setIsTaskPopoverOpened(true)}
        onMouseLeave={() => setIsTaskPopoverOpened(false)}>
        {isTaskPopoverOpened && (
          <TaskInfoPopover
            task={task}
            referenceElement={referenceElement}
            milestoneOrder={milestoneOrder}></TaskInfoPopover>
        )}
        <div
          ref={setReferenceElement}
          className={classNames('time-frame-bar', status)}
          style={calculatedPositionStyles({
            startPosition,
            endPosition,
            elementWidthGap
          })}>
          {status === 'complete' && <GreenTickIcon></GreenTickIcon>}
        </div>
      </div>
    </div>
  );
};

const TimelineBar = ({
  milestone,
  currentTimeFrame,
  elementWidthGap,
  isTaskOpened
}) => {
  const taskRef = useRef([]);
  const [isMilestonePopoverOpened, setIsMilestonePopoverOpened] =
    useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const {
    tasks,
    milestone_order,
    milestone_start_date,
    milestone_end_date,
    milestone_id
  } = milestone;

  const [startPosition, endPosition] = getTimePositionFromTimeFrame(
    currentTimeFrame,
    milestone_start_date,
    milestone_end_date
  );

  return (
    <div className="timeline-bar">
      <div className="primary-timeline">
        <div
          className="time-frame"
          onMouseEnter={() => setIsMilestonePopoverOpened(true)}
          onMouseLeave={() => setIsMilestonePopoverOpened(false)}>
          {isMilestonePopoverOpened && (
            <MilestoneInfoPopover
              milestone={milestone}
              referenceElement={referenceElement}></MilestoneInfoPopover>
          )}
          <div
            ref={setReferenceElement}
            className={classNames(
              'time-frame-bar',
              milestone.milestone_status
            )}
            style={calculatedPositionStyles({
              startPosition,
              endPosition,
              elementWidthGap
            })}>
            {milestone.milestone_status ===
              config.projectStatus.COMPLETE.value && (
              <GreenTickIcon></GreenTickIcon>
            )}
          </div>
        </div>
      </div>
      <div
        className="sub-timelines"
        style={{
          maxHeight: isTaskOpened
            ? taskRef.current.reduce(
                (result, element) =>
                  element?.offsetHeight
                    ? result + element.offsetHeight
                    : result,
                0
              )
            : 0
        }}>
        {tasks?.map((task) => (
          <TaskBar
            key={`${milestone_id}-${task.task_id}`}
            task={task}
            taskRef={taskRef}
            currentTimeFrame={currentTimeFrame}
            elementWidthGap={elementWidthGap}
            milestoneOrder={milestone_order}></TaskBar>
        ))}
      </div>
    </div>
  );
};

export default memo(TimelineBar);
