import { Router, Switch, Route } from 'react-router-dom';
import history from '../utils/history';
import Register from '../containers/Register/Register';
import Interview from '../containers/Interview';

import { PrivateRoute } from './PrivateRoute';
import { routes, routesPrefix } from './routes';
import InternalRouter from './InternalRouter';
import InterviewConfirmation from '../containers/InterviewConfirmation';
import InterviewReschedulingRequest from '../containers/InterviewReschedulingRequest';
import ForgotPassword from '../containers/auth/ForgotPassword/ForgotPassword';
import Login from '../containers/auth/Login';
import UserInvitation from '@src/containers/UserInvitation';

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route exact path={routes.login} component={Login} />
      <Route
        exact
        path={routes.forgotPassword}
        component={ForgotPassword}
      />
      <Route exact path={routesPrefix.register} component={Register} />
      <Route exact path={routes.interview} component={Interview} />
      <Route
        exact
        path={routes.interviewConfirmation}
        component={InterviewConfirmation}
      />
      <Route
        exact
        path={routes.interviewReschedulingRequest}
        component={InterviewReschedulingRequest}
      />
      <Route
        exact
        path={routes.userInvitation}
        component={UserInvitation}
      />
      <PrivateRoute path={routes.home} component={InternalRouter} />
    </Switch>
  </Router>
);

export default AppRouter;
