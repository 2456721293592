import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import AppSlice from './slices/App';
import AuthSlice, { logoutSuccess } from './slices/Auth';
import CandidateSlice from './slices/Candidate';
import CandidateFilterSlice from './slices/CandidateFilter';
import CampaignSlice from './slices/Campaign';
import DashboardSlice from './slices/Dashboard';
import rootSaga from './saga';

const combinedReducers = combineReducers({
  AppSlice,
  AuthSlice,
  CandidateSlice,
  CandidateFilterSlice,
  CampaignSlice,
  DashboardSlice
});

const rootReducer = (state, action) => {
  if (action.type === logoutSuccess.type) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false
    }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export { store };
