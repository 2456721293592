import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useQueryParams from '../../hooks/useQueryParams';
import { useConfirmInterviewDateMutation } from '../../queries/Interview';
import { ReactComponent as Logo } from '../../assets/images/td-logo-original.svg';

const Container = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100vw"
    height="100vh"
    padding="16px"
    flexDirection="column"
    gap="2rem">
    <Box display="flex" justifyContent="center" width="300px">
      <Logo className="logo w-75" />
    </Box>
    <Typography>{children}</Typography>
  </Box>
);

const InterviewConfirmation = () => {
  const queryParams = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [countDown, setCountDown] = useState(5);
  const intervalRef = useRef();

  const { mutateAsync: confirmInterviewDate } =
    useConfirmInterviewDateMutation();

  useEffect(() => {
    const handleConfirmInterviewDate = async () => {
      const { error } = await confirmInterviewDate({
        client_id: queryParams.get('client_id'),
        campaign_id: queryParams.get('campaign_id'),
        candidate_id: queryParams.get('candidate_id'),
        timeslot: queryParams.get('timeslot')
      });

      setIsLoading(false);
      if (error) {
        setError(error);
        return;
      }

      intervalRef.current = setInterval(() => {
        setCountDown((prev) => {
          if (prev === 0) {
            window.close();
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };
    handleConfirmInterviewDate();
  }, []);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return <Container>{error}</Container>;
  }

  return (
    <Container>
      Your interview has been scheduled successfully. This tab will be
      closed in {countDown} seconds...
    </Container>
  );
};

export default InterviewConfirmation;
