import { useUserContext } from '@src/contexts/UserContext';

export const usePermissionChecker = (permissions) => {
  const { userData } = useUserContext();
  const { permissions: userPermissions, master_client_id } =
    userData || {};

  return (
    !master_client_id ||
    userPermissions.some((permission) => permissions.includes(permission))
  );
};

const PermissionChecker = ({ children, permissions }) => {
  const hasPermission = usePermissionChecker(permissions);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionChecker;
