import BudgetManagement from '@src/containers/BudgetManagement/BudgetManagement';
import config from '../config';
import ProjectManagement from '@src/containers/ProjectManagement';
import Home from '@src/containers/Home/Home';
import PermissionRoleManagement from '@src/containers/PermissionRoleManagement';
import ComplianceManagement from '@src/containers/ComplianceManagement';
import NewMatchData from '@src/components/newMatches/NewMatchData';
import { pagePermission } from '@src/utils/permissionVariables';
import ContractorDetail from '@src/containers/ContractorDetail';

export const routesPrefix = { auth: '/auth', register: '/register' };

export const routes = {
  website: config.common.tdWebsiteUrl,
  home: '/',
  account: '/account',
  candidateProfileCards: '/candidate-profile-cards',
  details: '/candidate-details',
  projectManagement: '/project-management',
  complianceManagement: '/compliance-management/:tabName',
  permissionRoleManagement: '/permission-role-management/:tabName',
  contractorDetail:
    '/compliance-management/contractors/:contractorId/:tabName',
  budgetManagement: '/budget-management',
  interview: '/interview/:roomCode',
  interviewConfirmation: '/interview-confirmation',
  interviewReschedulingRequest: '/interview-rescheduling-request',
  login: `${routesPrefix.auth}/login`,
  forgotPassword: `${routesPrefix.auth}/forgot-password`,
  register: routesPrefix.register,
  userInvitation: '/user-invitation/:invitationToken'
};

export const navigationPaths = [
  {
    text: 'Dashboard',
    defaultNavigatedPath: '/',
    Component: Home,
    route: routes.home,
    pagePermission: pagePermission.dashboard
  },
  {
    text: 'Project Management',
    defaultNavigatedPath: '/project-management',
    Component: ProjectManagement,
    route: routes.projectManagement,
    pagePermission: pagePermission.projectManagement
  },
  {
    text: 'Budget Management',
    defaultNavigatedPath: '/budget-management',
    Component: BudgetManagement,
    route: routes.budgetManagement,
    pagePermission: pagePermission.budgetManagement
  },
  {
    text: 'Compliance Management',
    defaultNavigatedPath: '/compliance-management/contractors',
    primaryPath: 'compliance-management',
    Component: ComplianceManagement,
    route: routes.complianceManagement,
    pagePermission: pagePermission.complianceManagement
  },
  {
    text: 'Permission & Role Management',
    defaultNavigatedPath: '/permission-role-management/users',
    primaryPath: 'permission-role-management',
    Component: PermissionRoleManagement,
    route: routes.permissionRoleManagement
  },
  {
    text: 'Contractor Details',
    primaryPath: 'compliance-management',
    Component: ContractorDetail,
    route: routes.contractorDetail,
    pagePermission: pagePermission.complianceManagement
  },
  {
    text: 'Candidate Profile Cards',
    Component: NewMatchData,
    route: routes.candidateProfileCards
  }
];

export const getAccessibleRoutes = (userData) => {
  const userPermissions = userData?.permissions || [];

  return userData && !userData.master_client_id
    ? navigationPaths
    : navigationPaths.filter(
        ({ route, pagePermission }) =>
          route !== routes.permissionRoleManagement &&
          userPermissions.some((userPermission) => {
            const currentPagePermission = userPermission.split('.')[0];
            return pagePermission === currentPagePermission;
          })
      );
};
