import { useState } from 'react';
import classNames from 'classnames';
import ProjectMetrics from '../ProjectMetrics';
import ProjectHighlights from '../ProjectHighlights';

import './BottomNavigation.scss';

const TABS = {
  METRICS: 'METRICS',
  TIMELINE: 'TIMELINE',
  PROJECT: 'PROJECT'
};

const BottomNavigation = ({ projectNotes, milestones, resourceCount }) => {
  const [activeTab, setActiveTab] = useState(TABS.TIMELINE);
  return (
    <>
      <div className="reserved-bottom-navigation"></div>
      <div className="bottom-navigation">
        {activeTab === TABS.METRICS && (
          <ProjectMetrics
            milestones={milestones}
            resourceCount={resourceCount}></ProjectMetrics>
        )}
        {activeTab === TABS.PROJECT && (
          <ProjectHighlights
            projectNotes={projectNotes}></ProjectHighlights>
        )}
        <div className="bottom-navigation-bar">
          <button
            className={classNames('tab-btn', {
              'tab-btn__active': activeTab === TABS.METRICS
            })}
            onClick={() => setActiveTab(TABS.METRICS)}>
            {TABS.METRICS}
          </button>
          <button
            className={classNames('tab-btn', {
              'tab-btn__active': activeTab === TABS.TIMELINE
            })}
            onClick={() => setActiveTab(TABS.TIMELINE)}>
            {TABS.TIMELINE}
          </button>
          <button
            className={classNames('tab-btn', {
              'tab-btn__active': activeTab === TABS.PROJECT
            })}
            onClick={() => setActiveTab(TABS.PROJECT)}>
            {TABS.PROJECT}
          </button>
        </div>
      </div>
    </>
  );
};

export default BottomNavigation;
