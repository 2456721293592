import request from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';

const InvoiceService = {
  getInvoices: (data = {}) =>
    request({
      url: `${RECRUITER_API_URL}/getMyInvoices`,
      method: 'post',
      data
    }),
  getInvoiceStat: (data = {}) =>
    request({
      url: `${RECRUITER_API_URL}/getMyInvoiceStat`,
      method: 'get',
      data
    }),
  getPaymentMethods: (data = {}) =>
    request({
      url: `${RECRUITER_API_URL}/payments`,
      method: 'get',
      data
    }),
  payForAInvoice: (data = {}) =>
    request({
      url: `${RECRUITER_API_URL}/payForInvoice`,
      method: 'post',
      data
    })
};

export default InvoiceService;
