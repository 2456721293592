import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { logoutRequest } from '../../../store/slices/Auth';

import { navigate } from '../../../utils/history';
import { routes } from '../../../router/routes';

import './HeaderPopover.scss';

const HeaderPopover = ({ referenceElement, setIsHeaderPopoverOpened }) => {
  const dispatch = useDispatch();
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  const handleLogout = () => {
    dispatch(logoutRequest());
    navigate(routes.login);
    setIsHeaderPopoverOpened(false);
  };

  return ReactDOM.createPortal(
    <div
      ref={setPopperElement}
      className="header-popover"
      style={styles.popper}
      {...attributes.popper}>
      <p
        onClick={() => {
          navigate(routes.account);
          setIsHeaderPopoverOpened(false);
        }}>
        Account settings
      </p>
      <p className="logout" onClick={handleLogout}>
        Logout
      </p>
    </div>,
    document.body
  );
};

const Wrapper = ({ children }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [isHeaderPopoverOpened, setIsHeaderPopoverOpened] =
    useState(false);

  return (
    <div
      onMouseEnter={() => setIsHeaderPopoverOpened(true)}
      onMouseLeave={() => setIsHeaderPopoverOpened(false)}
      ref={setReferenceElement}
      className="header-popover-container">
      {children}
      {isHeaderPopoverOpened && (
        <HeaderPopover
          referenceElement={referenceElement}
          setIsHeaderPopoverOpened={
            setIsHeaderPopoverOpened
          }></HeaderPopover>
      )}
    </div>
  );
};

HeaderPopover.Wrapper = Wrapper;

export default HeaderPopover;
