import React from 'react';
import classNames from 'classnames';

import './OutlinedSelectWithLabel.scss';

const OutlinedSelectWithLabel = ({
  label,
  name,
  register,
  required,
  options = [],
  className = ''
}) => (
  <div className={classNames('outlined-select-with-label', className)}>
    <label>
      {label} {!required ? '(optional)' : ''}
    </label>
    <select {...register(name, { required })}>
      <option disabled value=""></option>
      {options.map(({ label, value }) => (
        <option value={value} key={`select-option-${value}`}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default OutlinedSelectWithLabel;
