import './styles.scss';

const ProjectFigure = ({ description, numberString }) => (
  <div className="project-figure">
    <div className="number-box-shadow">
      <div className="number-box">
        <span>{numberString}</span>
      </div>
    </div>
    <div className="description">{description}</div>
  </div>
);

export default ProjectFigure;
