import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './Account.scss';
import PersonalDetails from '../../components/account/PersonalDetails/PersonalDetails';
import ChangePassword from '../../components/account/ChangePassword/ChangePassword';
import EmailSettings from '../../components/account/EmailSettings/EmailSettings';
import PaymentDetails from '../../components/account/PaymentDetails/PaymentDetails';
import Button from '../../components/shared/Button/Button';
import { useGetUserQuery } from '../../queries/User';
import {
  resetPasswordOTPRequest,
  changePasswordRequest,
  setIsChangePassword,
  logoutRequest
} from '../../store/slices/Auth';
import { selectIsChangePassword } from '../../store/selectors/Auth';
import { changePasswordValidationRules as changePasswordSchema } from '../../validation/account';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const Account = () => {
  const [newPassword, setNewPassword] = useState(null);
  const isChangePassword = useSelector(selectIsChangePassword);
  const { isFetched, data } = useGetUserQuery({ staleTime: 0 });
  const dispatch = useDispatch();
  const paymentDetailsFormMethods = useForm({
    values: {
      billingName: '',
      billingEmail: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zip: '',
      state: '',
      ein: ''
    }
  });
  const changePasswordFormMethods = useForm({
    resolver: yupResolver(changePasswordSchema)
  });

  const handleLogout = () => dispatch(logoutRequest());

  const sendOTP = () =>
    dispatch(
      resetPasswordOTPRequest({ phone_number: data?.phone_number })
    );

  const savePasswordAndProceed = ({ password }) => {
    sendOTP();
    setNewPassword(password);
    dispatch(setIsChangePassword(false));
  };

  const personalDetailsFormMethods = useForm({
    values: {
      avatar: data.avatar,
      firstName: data.first_name,
      lastName: data.last_name,
      phoneNumber: data.phone_number,
      email: data.email_id,
      jobTitle: data.job_title,
      companyName: data.company_name,
      companyUrl: data.company_url
    }
  });

  const verifyOTPAndSetNewPassword = ({ code }) =>
    dispatch(
      changePasswordRequest({
        reset_password_otp: code,
        phone_number: data?.phone_number,
        password: newPassword
      })
    );

  if (!isFetched) return null;

  return (
    <div className="account">
      <div className="account__container">
        <FormProvider {...personalDetailsFormMethods}>
          <PersonalDetails
            data={data}
            personalDetailsFormMethods={personalDetailsFormMethods}
          />
        </FormProvider>

        <PermissionChecker
          permissions={['budget_management.payment.write']}>
          <FormProvider {...paymentDetailsFormMethods}>
            <PaymentDetails />
          </FormProvider>
        </PermissionChecker>

        <div>
          <FormProvider {...changePasswordFormMethods}>
            <ChangePassword
              isChangePassword={isChangePassword}
              phone={data?.phone_number}
              onSubmitVerification={verifyOTPAndSetNewPassword}
              onResendVerification={sendOTP}
              onSubmit={savePasswordAndProceed}
            />
          </FormProvider>
          <div className="mt-10">
            <EmailSettings />
          </div>
          <div className="mt-4 account__logout">
            <Button
              title="LOG OUT"
              width="11.5rem"
              onClick={handleLogout}></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
