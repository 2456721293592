import classNames from 'classnames';
import './styles.scss';

const TabPanel = ({ children }) => (
  <div className="tab-panel">{children}</div>
);

const TabLabel = ({ onClick, isActive, children }) => (
  <button
    className={classNames('tab-label', {
      active: isActive
    })}
    onClick={onClick}>
    {children}
  </button>
);

const Tab = {
  Panel: TabPanel,
  Label: TabLabel
};

export default Tab;
