import './CandidateListMobile.css';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';

import {
  useGetCandidatesQuery,
  useToggleCandidateFavoriteStatusQuery
} from '../../../../queries/Candidate';
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
  selectCandidateSearchText
} from '../../../../store/selectors/CandidateFilter';
import { clearWhiteSpacesAndConvertToLowerCase } from '../../../../utils/misc';
import { selectPreviewCandidate } from '../../../../store/selectors/Candidate';
import { setPreviewCandidate } from '../../../../store/slices/Candidate';
import SearchFilterBarMobile from '../../SearchFilterBar/Mobile/SearchFilterBarMobile';
import ActiveCampaignBar from '../../ActiveCampaignBar/ActiveCampaignBar';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../../../shared/CustomScrollBar/CustomScrollBar';
import { tabContentMobileVariants } from './../../../../utils/animation';
import CandidatePreviewCardMobile from './../../CandidatePreviewCard/Mobile/CandidatePreviewCardMobile';
import CandidateListItems from '../CandidateItems';

const CandidateListMobile = ({
  selectedCampaign,
  setSelectedCampaign
}) => {
  const isFavorites = useSelector(selectCandidateFilterFavorites);
  const profileStatus = useSelector(selectCandidateFilterProfileStatus);
  const searchText = useSelector(selectCandidateSearchText);
  const previewCandidate = useSelector(selectPreviewCandidate);
  const [previewExited, setPreviewExited] = useState(true);
  const dispatch = useDispatch();

  const candidateFilterParams = {
    campaign_id: selectedCampaign?.campaign_id || null,
    profile_status: profileStatus,
    is_favourite: isFavorites
  };

  const { data } = useGetCandidatesQuery(candidateFilterParams);
  const [localData, setLocalData] = useState(data);

  const { mutate: toggleFavoriteStatus } =
    useToggleCandidateFavoriteStatusQuery(candidateFilterParams);

  const selectCandidate = useCallback(
    (candidate) => {
      dispatch(setPreviewCandidate(candidate));
    },
    [dispatch]
  );

  const onFavorite = (event, candidate) => {
    event.stopPropagation();
    toggleFavoriteStatus(candidate);
  };

  const onCandidatePreview = (candidate) => {
    if (previewExited && !window.getSelection().toString().length) {
      setPreviewExited(false);
      selectCandidate(candidate);
    }
  };

  useEffect(() => {
    setLocalData(
      data?.filter((candidate) => {
        const search = clearWhiteSpacesAndConvertToLowerCase(searchText);
        const firstName = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.first_name
        );
        const lastName = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.last_name
        );
        const techStack = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.tech_stack
        );
        const state = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.state
        );
        const city = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.city
        );
        const country = clearWhiteSpacesAndConvertToLowerCase(
          candidate?.country
        );

        return (
          (firstName + lastName).includes(search) ||
          (lastName + firstName).includes(search) ||
          techStack.includes(search) ||
          state.includes(search) ||
          city.includes(search) ||
          country.includes(search)
        );
      })
    );
  }, [searchText, data, setLocalData]);

  useEffect(
    () =>
      // Exit preview when tab changed
      () =>
        selectCandidate(null),
    [selectCandidate]
  );

  return (
    <motion.div
      variants={tabContentMobileVariants}
      initial={'hidden'}
      animate={'show'}
      exit={'hidden'}
      className="candidate-list-mobile__wrapper d-flex flex-column flex-grow-1">
      {selectedCampaign && (
        <ActiveCampaignBar
          campaign={selectedCampaign}
          showAllCampaigns={() => setSelectedCampaign(null)}
        />
      )}

      <SearchFilterBarMobile />
      <LayoutGroup id="candidateGridLayoutInMobile" type="crossfade">
        <Scrollbars
          id="candidateList"
          className="candidate-list-mobile"
          renderThumbHorizontal={ScrollThumbVertical}
          renderTrackHorizontal={ScrollTrackVertical}>
          <motion.div
            id="candidateGrid"
            className="candidate-list-mobile__grid">
            <CandidateListItems
              showData={searchText ? localData : data}
              onCandidatePreview={onCandidatePreview}
              onFavorite={onFavorite}
            />
          </motion.div>
        </Scrollbars>
        <AnimatePresence onExitComplete={() => setPreviewExited(true)}>
          {previewCandidate && (
            <CandidatePreviewCardMobile
              key={previewCandidate?.candidate_profile_status_id}
              data={previewCandidate}
              onClose={() => selectCandidate(null)}
              onFavorite={(event) => onFavorite(event, previewCandidate)}
            />
          )}
        </AnimatePresence>
      </LayoutGroup>
    </motion.div>
  );
};

export default CandidateListMobile;
