import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import config from '../../../../../config';
import ManageComplianceButton from './ManageComplianceButton';
import PendingRequirementTooltip from '../shared/PendingRequirementTooltip';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const NextStepsTab = ({
  interviewData,
  openCampaignDetails,
  requirements
}) => {
  const {
    campaign_name,
    full_name,
    campaign_id,
    profile_status,
    candidate_profile_status_id
  } = interviewData;

  const pendingRequirements =
    requirements?.filter(({ is_answered }) => !is_answered) || [];

  const isComplianceRequirementCompleted =
    pendingRequirements.length === 0 && requirements?.length > 0;

  return (
    <Box className="next-steps-tab">
      <Box padding="1rem 2rem">
        <Grid
          container
          gap="1rem"
          justifyContent="center"
          alignItems="center"
          flexDirection="column">
          <Grid item>
            <Typography textAlign="center" as="p">
              We&apos;re happy that you experienced a positive interview
              with{' '}
              <Typography as="span" color="#2e9dd7" fontWeight={700}>
                {full_name}
              </Typography>{' '}
              for your{' '}
              <Typography as="span" color="#2e9dd7" fontWeight={700}>
                {campaign_name}
              </Typography>{' '}
              Campaign!
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Please complete each step below to start work.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box padding="1rem 2rem">
        <Grid container flexDirection="column" gap="1.5rem">
          <Grid item>
            <Grid container gap="0.5rem">
              <Grid item width="100%">
                <Typography
                  color="#2e9dd7"
                  fontWeight="600"
                  marginBottom="6px">
                  STEP 1. Hire Candidate
                </Typography>
                <Typography>
                  Mauris sodales, metus non suscipit scelerisque, magna leo
                  suscipit eros, at pulvinar ligula metus sit amet metus.
                  Quisque mi leo, porta nec ipsum eget, consequat.
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item minWidth="200px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%">
                  {profile_status ===
                  config.candidate.profileStatus.HIRED ? (
                    <Box
                      bgcolor="#14b9b8"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="100%"
                      width="44px"
                      height="44px">
                      <DoneRoundedIcon sx={{ color: '#fff' }} />
                    </Box>
                  ) : (
                    <Button sx={{ width: '200px' }} variant="contained">
                      HIRE CANDIDATE
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container gap="0.5rem">
              <Grid item width="100%">
                <Typography
                  color="#2e9dd7"
                  fontWeight="600"
                  marginBottom="6px">
                  STEP 2. Manage Compliance
                </Typography>
                <Typography>
                  Mauris sodales, metus non suscipit scelerisque, magna leo
                  suscipit eros, at pulvinar ligula metus sit amet metus.
                  Quisque mi leo, porta nec ipsum eget, consequat.
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                minWidth="200px"
                justifyContent="center"
                alignItems="center"
                display="flex">
                <Box>
                  {profile_status ===
                    config.candidate.profileStatus.HIRED &&
                    !isComplianceRequirementCompleted && (
                      <PendingRequirementTooltip
                        pendingRequirements={pendingRequirements}
                        totalRequirementCount={requirements?.length}>
                        <div className="mb-1 text-[#4b6e88] font-semibold text-center">
                          <span className="py-1 px-2 hover:bg-[#ccdce6] rounded-4">
                            {`Pending ${
                              pendingRequirements?.length || 0
                            }/${requirements?.length || 0}`}
                          </span>
                        </div>
                      </PendingRequirementTooltip>
                    )}
                  {!isComplianceRequirementCompleted && (
                    <ManageComplianceButton
                      profileStatus={profile_status}
                      candidateProfileStatusId={
                        candidate_profile_status_id
                      }
                    />
                  )}
                  {profile_status ===
                    config.candidate.profileStatus.HIRED &&
                    isComplianceRequirementCompleted && (
                      <Box
                        bgcolor="#14b9b8"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="100%"
                        width="44px"
                        height="44px">
                        <DoneRoundedIcon sx={{ color: '#fff' }} />
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <PermissionChecker
            permissions={[
              'dashboard.campaign.read',
              'dashboard.campaign.write'
            ]}>
            <Grid item>
              <Grid container gap="0.5rem">
                <Grid item width="100%">
                  <Typography
                    color="#2e9dd7"
                    fontWeight="600"
                    marginBottom="6px">
                    STEP 3. Start Work
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid
                  item
                  minWidth="200px"
                  justifyContent="center"
                  alignItems="center"
                  display="flex">
                  <Box>
                    <Button
                      sx={{ width: '200px' }}
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        openCampaignDetails(campaign_id);
                      }}>
                      VIEW CAMPAIGN
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </PermissionChecker>
        </Grid>
      </Box>
      <Divider />
      <Grid
        container
        padding="1rem 2rem"
        gap="0.5rem"
        justifyContent="center">
        <Grid item>
          <Typography color="#2e9dd7" fontWeight={700}>
            Any questions?
          </Typography>
        </Grid>
        <Grid item>
          <a href="mailto:hello@trusteddev.ai">
            <Typography sx={{ textDecoration: 'underline' }}>
              Please let us know
            </Typography>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NextStepsTab;
