import { useEffect } from 'react';

const ViewportUnitProvider = ({ children }) => {
  useEffect(() => {
    // vh unit fix
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
    function updateVhUnitCSSVariable() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    function updateVwUnitCSSVariable() {
      const vw = document.documentElement.clientWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

    function updateViewportUnitsCssVariables() {
      updateVhUnitCSSVariable();
      updateVwUnitCSSVariable();
    }

    updateViewportUnitsCssVariables();
    window.addEventListener('resize', updateViewportUnitsCssVariables);

    return () => {
      window.removeEventListener(
        'resize',
        updateViewportUnitsCssVariables
      );
    };
  }, []);
  return children;
};

export default ViewportUnitProvider;
