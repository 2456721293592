import './NewMatchesListMobile.css';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import NewMatchesCard from '../../NewMatchesCard/NewMatchesCard';
import {
  setNewMatchesAvailable,
  setShowNewMatches
} from '../../../../store/slices/Candidate';
import {
  useGetNewMatchCandidatesQuery,
  useVoteDownCandidateQuery,
  useVoteUpCandidateQuery
} from './../../../../queries/NewMatches';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export const NewMatchesListMobile = () => {
  const { data: candidates } = useGetNewMatchCandidatesQuery();
  const { mutate: voteUp } = useVoteUpCandidateQuery();
  const { mutate: voteDown } = useVoteDownCandidateQuery();
  const [index, setIndex] = useState(0);
  const [exitX, setExitX] = useState('100%');
  const [exitY, setExitY] = useState(0);
  const dispatch = useDispatch();

  const onBack = useCallback(
    () => dispatch(setShowNewMatches(false)),
    [dispatch]
  );

  useEffect(() => {
    if (candidates?.length === 0) {
      setTimeout(() => {
        onBack();
        dispatch(setNewMatchesAvailable(false));
      }, 400);
    }
  }, [candidates, dispatch, onBack]);

  return (
    <div className="new-matches-list-mobile">
      <div className="bg-[#134067] px-5 py-3">
        <div onClick={onBack}>
          <ArrowBackIosNewRoundedIcon className="fill-[#cfe6f2]" />
        </div>
      </div>
      <div className="flex justify-center mx-4 my-3 text-white font-semibold text-[18px]">
        <span className="truncate">{candidates?.[0]?.campaign_name}</span>
      </div>
      <div className="h-full cards">
        <div className="h-full cards-container">
          <AnimatePresence initial={false}>
            {candidates?.length >= 2 && (
              <NewMatchesCard
                key={index + 1}
                initial={{ scale: 0, y: 100 }}
                animate={{ scale: 0.95, y: 20 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 }
                }}
                data={candidates[1]}
              />
            )}
            {candidates?.length >= 1 && (
              <NewMatchesCard
                key={index}
                animate={{
                  scale: 1,
                  y: 0,
                  opacity: 1
                }}
                transition={{
                  y: { duration: 0.5 },
                  opacity: { duration: 0.2 }
                }}
                exitX={exitX}
                setExitX={setExitX}
                exitY={exitY}
                setExitY={setExitY}
                index={index}
                setIndex={setIndex}
                isLast={candidates?.length === 1}
                drag={true}
                data={candidates[0]}
                onDislike={voteDown}
                onLike={voteUp}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
