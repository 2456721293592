import './StatsSidebar.css';
import { motion } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CandidatesStatsPieChart from '../candidates/CandidatesStatsPieChart/CandidatesStatsPieChart';
import { useGetStatsQuery } from '../../../queries/Stats';
import { ReactComponent as CandidateIcon } from '../../../assets/images/candidate-icon.svg';
import { ReactComponent as CampaignIcon } from '../../../assets/images/campaign-icon.svg';
import { sidebarContentVariants } from '../../../utils/animation';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from './../../shared/CustomScrollBar/CustomScrollBar';
import CampaignsStatsCardList from './../campaigns/CampaignsStatsCardList/CampaignsStatsCardList';
import CandidatesStatsCardList from './../candidates/CandidatesStatsCardList/CandidatesStatsCardList';
import StatsSectionHeader from './../StatsSectionHeader/StatsSectionHeader';

const StatsSidebar = ({ selectedCampaign }) => {
  const queryParams = {
    campaign_id: selectedCampaign?.campaign_id || null
  };

  let { isSuccess, data } = useGetStatsQuery(queryParams);

  if (!isSuccess) {
    return null;
  }

  return (
    <Scrollbars
      id="statsSidebar"
      className="stats-sidebar"
      hideTracksWhenNotNeeded
      renderThumbVertical={ScrollThumbVertical}
      renderTrackVertical={ScrollTrackVertical}
      renderThumbHorizontal={() => <div />}
      renderTrackHorizontal={() => <div />}>
      <motion.div
        variants={sidebarContentVariants}
        initial={'hidden'}
        animate={'show'}
        exit={'hidden'}
        style={{
          padding: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}>
        <div>
          <StatsSectionHeader
            containerClassName="candidates-stats-header"
            icon={CandidateIcon}
            figure={data?.candidates_data?.total_matched}
            title={`Candidates\nMatched`}
          />
          <hr />
          <CandidatesStatsCardList
            containerClassName={'campaigns-stats-figures-list'}
            data={data?.candidates_data}
          />
        </div>
        <CandidatesStatsPieChart
          data={data?.candidates_data}
          containerClassName={'campaigns-stats-charts'}
        />
        <div>
          <hr />
          <StatsSectionHeader
            containerClassName="campaigns-stats-header"
            icon={CampaignIcon}
            figure={data?.campaigns_data?.total_campaigns}
            title={`All\nCampaigns`}
          />
          <CampaignsStatsCardList data={data?.campaigns_data} />
        </div>
      </motion.div>
    </Scrollbars>
  );
};

export default StatsSidebar;
