import { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import '../BottomPanelHeader/BottomPanelHeader.scss';
import styles from './BottomPanelContent.module.scss';

const data = [
  {
    name: 'Jan',
    total: 500
  },
  {
    name: 'Feb',
    total: 800
  },
  {
    name: 'Mar',
    total: 1000
  },
  {
    name: 'Apr',
    total: 2500
  },
  {
    name: 'May',
    total: 2000
  },
  {
    name: 'Jun',
    total: 1200
  },
  {
    name: 'Jul',
    total: 1600
  },
  {
    name: 'Aug',
    total: 1700
  },
  {
    name: 'Sep',
    total: 1400
  },
  {
    name: 'Oct',
    total: 2000
  },
  {
    name: 'Nov',
    total: 2200
  },
  {
    name: 'Dec',
    total: 2400
  }
];

const BottomPanelContentMobile = () => {
  const [focusBar, setFocusBar] = useState(null);

  return (
    <div className={styles.bottomPanelContent}>
      <div>
        <div className="bottom-panel-header">
          <div>Active Project Resources Per Month</div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 10,
              bottom: 20,
              left: -20
            }}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}>
            <CartesianGrid
              vertical={false}
              stroke="#1c4c6d"
              fill="#0f3c5a"
            />
            <XAxis
              dataKey="name"
              orientation="top"
              tick={{ fill: '#97cce8', fontSize: 12 }}
              tickFormatter={(value) => value.toUpperCase()}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tick={{ fill: '#fff', fontSize: 11 }}
              axisLine={false}
              tickLine={false}
              tickCount={6}
            />
            <Tooltip
              contentStyle={{
                borderRadius: 8
              }}
              cursor={false}
              labelFormatter={(value) => value.toUpperCase()}
              formatter={(value) => [
                <p key={value} className="text-black text-xs">
                  <span>Total Spend: </span>
                  <span className="font-bold">
                    ${value.toLocaleString()}
                  </span>
                </p>
              ]}
            />
            <Bar
              dataKey="total"
              barSize={16}
              radius={[5, 5, 0, 0]}
              activeBar={
                <Rectangle
                  style={{
                    brightness: 1.5
                  }}
                />
              }>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index
                      ? 'rgb(1, 213, 217)'
                      : 'rgb(8, 173, 172)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div>
        <div className="bottom-panel-header">
          <div>Total Spend Per Month</div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 10,
              bottom: 20,
              left: -20
            }}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}>
            <CartesianGrid
              vertical={false}
              stroke="#1c4c6d"
              fill="#0f3c5a"
            />
            <XAxis
              dataKey="name"
              orientation="top"
              tick={{ fill: '#97cce8', fontSize: 12 }}
              tickFormatter={(value) => value.toUpperCase()}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tick={{ fill: '#fff', fontSize: 11 }}
              axisLine={false}
              tickLine={false}
              tickCount={6}
            />
            <Tooltip
              contentStyle={{
                borderRadius: 8
              }}
              cursor={false}
              labelFormatter={(value) => value.toUpperCase()}
              formatter={(value) => [
                <p key={value} className="text-black text-xs">
                  <span>Total Spend: </span>
                  <span className="font-bold">
                    ${value.toLocaleString()}
                  </span>
                </p>
              ]}
            />
            <Bar
              dataKey="total"
              barSize={16}
              radius={[5, 5, 0, 0]}
              activeBar={
                <Rectangle
                  style={{
                    brightness: 1.5
                  }}
                />
              }>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index
                      ? 'rgb(1, 213, 217)'
                      : 'rgb(8, 173, 172)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BottomPanelContentMobile;
