/* eslint-disable react-hooks/exhaustive-deps */
import '../../../src/components/newMatches/NewMatchesList/NewMatchesList.css';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

import { useLocation } from 'react-router-dom';
import { ReactComponent as LikeIcon } from '../../../src/assets/images/big-like-icon.svg';
import { ReactComponent as DislikeIcon } from '../../../src/assets/images/big-dislike-icon.svg';
import {
  setNewMatchesAvailable,
  setShowNewMatches
} from '../../../src/store/slices/Candidate';
import {
  useGetNewMatchCandidatesQuery,
  useVoteDownCandidateQuery,
  useVoteUpCandidateQuery
} from '../../queries/NewMatches';
import HorizontalGraph from '../stats/HorizontalGraph/HorizontalGraph';
import { newMatchesVariants } from '../../utils/animation';
import NewMatchesCard from './NewMatchesCard/NewMatchesCard';

export const NewMatchData = () => {
  const dislikeControls = useAnimation();
  const likeControls = useAnimation();
  const { data: candidates } = useGetNewMatchCandidatesQuery();
  const { mutate: voteUp } = useVoteUpCandidateQuery();
  const { mutate: voteDown } = useVoteDownCandidateQuery();
  const [index, setIndex] = useState(0);
  const [exitX, setExitX] = useState('100%');
  const [exitY, setExitY] = useState(0);
  const [isFromEmail, setisFromEmail] = useState(false);
  const [isFromEmail1, setisFromEmail1] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.search;
  let candidate_profile_status_id = data.split('&')[0].split('=')[1];
  let profile_status = data.split('&')[1].split('=')[1];
  let final = [];
  const onBack = useCallback(
    () => dispatch(setShowNewMatches(false)),
    [dispatch]
  );

  const scaleIconAnimation = (controls) => {
    controls.stop();
    controls.start({ rotate: 0, scale: 2 });
  };

  const resetIconAnimation = async (controls) => {
    await controls.start({
      scale: 1
    });
  };

  const handleDislike = () => {
    setIndex(index + 1);
    voteDown(candidates[0]);
  };

  const handleLike = () => {
    setIndex(index + 1);
    voteUp(candidates[0]);
  };

  const onBackHandler = () => {
    window.location = '/';
  };

  useEffect(() => {
    if (candidates?.length === 0) {
      setTimeout(() => {
        onBack();
        dispatch(setNewMatchesAvailable(false));
      }, 400);
    }
    if (location.pathname.includes('candidate-profile-cards')) {
      setisFromEmail(true);
    }

    const foundCandidate = candidates?.find(
      (candidate) =>
        candidate.candidate_profile_status_id.toString() ===
        candidate_profile_status_id
    );

    if (!foundCandidate) {
      onBack();
      dispatch(setNewMatchesAvailable(false));
      onBackHandler();
      return;
    }

    final.push(foundCandidate);
    setisFromEmail1(foundCandidate);
    if (profile_status === '2') {
      setTimeout(() => {
        voteUp(foundCandidate);
      }, 1500);
    } else {
      setTimeout(() => {
        voteDown(foundCandidate);
      }, 1500);
    }
  }, [candidates, dispatch, onBack, location]);
  return (
    <div className="home d-flex">
      <motion.div className="home__content-wrapper">
        <motion.div className="home__content-container">
          <motion.div
            key="new-matches"
            variants={newMatchesVariants}
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            className="new-match">
            <motion.div className="new-matches-list">
              {isFromEmail ? (
                <div
                  className="back-button state-transition pointer"
                  onClick={onBackHandler}
                />
              ) : (
                <div
                  className="back-button state-transition pointer"
                  onClick={onBack}
                />
              )}
              <div className="angle-background">
                <svg preserveAspectRatio="none" viewBox="0 0 100 100">
                  <polygon
                    fill="#133b58"
                    points="100,100 0,0 0,100"
                    opacity="1"></polygon>
                </svg>
              </div>
              <div className="card-list-container">
                <AnimatePresence>
                  {candidates?.length && (
                    <motion.div
                      className="dislike-icon pointer"
                      initial={{
                        rotate: [0, 40, -40, 40, -40, 0],
                        transition: {
                          delay: 2,
                          duration: 1.5,
                          ease: 'easeInOut',
                          times: [0, 0.15, 0.3, 0.45, 0.75, 1],
                          repeat: Infinity,
                          repeatDelay: 4
                        }
                      }}
                      animate={dislikeControls}
                      exit={{ opacity: 0 }}
                      onClick={handleDislike}
                      onMouseEnter={() => {
                        scaleIconAnimation(dislikeControls);
                        if (exitX !== -2000) setExitX(-2000);
                        if (exitY !== 0) setExitY(0);
                      }}
                      onMouseLeave={() =>
                        resetIconAnimation(dislikeControls)
                      }>
                      <DislikeIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
                <div className="cards">
                  <div className="cards-container">
                    <AnimatePresence initial={false}>
                      {candidates?.length >= 1 && (
                        <NewMatchesCard
                          key={index}
                          animate={{
                            scale: 1,
                            y: 0,
                            opacity: 1
                          }}
                          transition={{
                            y: { duration: 0.5 },
                            opacity: { duration: 0.2 }
                          }}
                          exitX={exitX}
                          setExitX={setExitX}
                          exitY={exitY}
                          setExitY={setExitY}
                          index={index}
                          setIndex={setIndex}
                          isLast={candidates?.length === 1}
                          drag={true}
                          data={isFromEmail1}
                          onDislike={voteDown}
                          onLike={voteUp}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <AnimatePresence>
                  {candidates?.length && (
                    <motion.div
                      className="like-icon pointer"
                      initial={{
                        rotate: [0, 40, -40, 40, -40, 0],
                        transition: {
                          delay: 2,
                          duration: 1.5,
                          ease: 'easeInOut',
                          times: [0, 0.15, 0.3, 0.45, 0.75, 1],
                          repeat: Infinity,
                          repeatDelay: 4
                        }
                      }}
                      animate={likeControls}
                      exit={{ opacity: 0 }}
                      onClick={handleLike}
                      onMouseEnter={() => {
                        scaleIconAnimation(likeControls);
                        if (exitX !== 2000) setExitX(2000);
                        if (exitY !== 0) setExitY(0);
                      }}
                      onMouseLeave={() =>
                        resetIconAnimation(likeControls)
                      }>
                      <LikeIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
        <HorizontalGraph containerClassName="home__graph-container" />
      </motion.div>
    </div>
  );
};

export default NewMatchData;
