import PropTypes from 'prop-types';
import './CampaignsStatsCardListItem.css';
import { cn } from '@src/utils';

const CampaignsStatsCardListItem = ({
  selected,
  onClick,
  backgroundColorVar,
  title,
  figure = 'N/A'
}) => {
  const figureBoxStyle = {
    backgroundColor: `var(${backgroundColorVar})`
  };

  return (
    <div className="campaigns-stats-card__container min-w-[90px]">
      <div
        onClick={onClick}
        style={figureBoxStyle}
        className={cn(
          'campaigns-stats-card__figure-box cursor-pointer box-border pointer-events-none lg:pointer-events-auto',
          {
            'border-2 border-[#265d84]': selected
          }
        )}>
        <span className="campaigns-stats-card__figure">
          {+figure < 10 && +figure >= 0 ? `0${figure}` : figure}
        </span>
      </div>
      <span className="campaigns-stats-card__title">{title}</span>
    </div>
  );
};

CampaignsStatsCardListItem.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColorVar: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CampaignsStatsCardListItem;
