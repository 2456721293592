function getDaysThisMonth() {
  const currentDate = new Date();
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  return Array.from(Array(daysInMonth).keys()).map((day) => day + 1);
}

export const GRAPH_CATEGORY = {
  VOTE: 'Vote',
  WORKED_HOURS: 'Worked Hours'
};

export const GRAPH_PERIOD = {
  LAST_3_DAYS: 'Last 3 Days',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_YEAR: 'This Year',
  ALL_TIME: 'All Time'
};

export const toDayFragments = Array.from(Array(24).keys()).map((hour) => ({
  hours: hour
}));

export const thisWeekFragments = Array.from(Array(7).keys()).map(
  (day) => ({
    days: day + 1
  })
);

export const thisMonthFragments = getDaysThisMonth().map((day) => ({
  days: day
}));

export const thisYearFragments = Array.from(Array(12).keys()).map(
  (month) => ({
    months: month + 1
  })
);
