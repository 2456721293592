import { useRef, useState } from 'react';
import './styles.scss';
import Avatar from '../../shared/Avatar/Avatar';
import MilestoneInfoPopover from '../../project-management/MilestoneInfoPopover';
import TaskInfoPopover from '../../project-management/TaskInfoPopover';
import useClickOutside from '../../../hooks/useClickOutside';
import { extractCandidatesFromTasks } from '../../../utils/common';
import { ReactComponent as CircularUpArrow } from '../../../assets/images/circular-up-arrow.svg';
import { ReactComponent as CircularDownArrow } from '../../../assets/images/circular-down-arrow.svg';

import { useIfMobile } from '../../../utils/responsive';

const TaskInfo = ({ task, taskRef }) => {
  const isMobile = useIfMobile();
  const [referenceElement, setReferenceElement] = useState(null);
  const [isTaskPopoverOpened, setIsTaskPopoverOpened] = useState(false);
  const clickOutsideRef = useClickOutside(() => {
    setIsTaskPopoverOpened(false);
  });

  return (
    <div
      key={task.task_id}
      className="task"
      ref={(node) => {
        taskRef.current.push(node);
        setReferenceElement(node);
        clickOutsideRef.current = node;
      }}
      onMouseEnter={() => setIsTaskPopoverOpened(true)}
      onMouseLeave={() => setIsTaskPopoverOpened(false)}>
      {isTaskPopoverOpened && (
        <TaskInfoPopover
          task={task}
          referenceElement={referenceElement}></TaskInfoPopover>
      )}
      {!isMobile && (
        <>
          <span>{`Task ${task.task_order}: ${task.task_name}`}</span>
          <div className="task-assignees">
            {task?.candidates?.map((candidate) => (
              <div className="task-assignee" key={candidate.full_name}>
                <Avatar src={candidate.avatar} width={1.5} height={1.5} />
                <span>{candidate.full_name}</span>
              </div>
            ))}
          </div>
        </>
      )}
      {isMobile && <span>{`T${task.task_order}`}</span>}
    </div>
  );
};

const MilestoneInfo = ({ milestone, isTaskOpened, toggle }) => {
  const isMobile = useIfMobile();
  const taskRef = useRef([]);
  const [referenceElement, setReferenceElement] = useState(null);
  const [isMilestonePopoverOpened, setIsMilestonePopoverOpened] =
    useState(false);
  const clickOutsideRef = useClickOutside(() => {
    setIsMilestonePopoverOpened(false);
  });
  const { tasks } = milestone;

  const allAvatars = extractCandidatesFromTasks(
    milestone?.tasks ?? []
  ).map((x) => x.avatar);

  return (
    <div className="milestone">
      <div
        className="milestone-details"
        ref={(node) => {
          setReferenceElement(node);
          clickOutsideRef.current = node;
        }}
        onMouseEnter={() => setIsMilestonePopoverOpened(true)}
        onMouseLeave={() => setIsMilestonePopoverOpened(false)}>
        {isMilestonePopoverOpened && (
          <MilestoneInfoPopover
            milestone={milestone}
            referenceElement={referenceElement}></MilestoneInfoPopover>
        )}
        {!isMobile && (
          <>
            <div className="candidates">
              {allAvatars.slice(0, 2).map((avatar, index) => (
                <Avatar
                  key={avatar}
                  src={avatar}
                  alt={avatar}
                  width={1.5}
                  height={1.5}
                  style={{
                    zIndex: 999 - index,
                    right: `${-0.2 * index}rem`
                  }}
                />
              ))}
              {allAvatars.length - 2 > 0 && (
                <div className="avatar-extra-count">{`+${
                  allAvatars.length - 2
                }`}</div>
              )}
            </div>
            <div>{`Milestone ${milestone.milestone_order}: ${milestone.milestone_name}`}</div>
          </>
        )}
        {isMobile && (
          <div className="mobile-view-milestone-title">{`M${milestone.milestone_order}`}</div>
        )}
        {tasks?.length > 0 && (
          <div className="toggle-button" onClick={toggle}>
            {isTaskOpened ? <CircularDownArrow /> : <CircularUpArrow />}
          </div>
        )}
      </div>
      <div
        className="tasks"
        style={{
          maxHeight: isTaskOpened
            ? taskRef.current.reduce(
                (result, element) =>
                  element?.offsetHeight
                    ? result + element.offsetHeight
                    : result,
                0
              )
            : 0
        }}>
        {tasks?.map((task) => (
          <TaskInfo
            key={task.task_id}
            task={task}
            taskRef={taskRef}></TaskInfo>
        ))}
      </div>
    </div>
  );
};

export default MilestoneInfo;
