import './Congratulations.css';
import { useDispatch } from 'react-redux';
import { ReactComponent as CongratulationsCheckIcon } from '../../../../assets/images/congratulations-check.svg';
import { resetAuthData } from '../../../../store/slices/Auth';
import Button from './../../../shared/Button/Button';

const Congratulations = () => {
  const dispatch = useDispatch();
  const handleNavigateToLogin = () => dispatch(resetAuthData());

  return (
    <div className="congratulations text-center d-flex flex-column flex-grow-1">
      <div className="d-flex flex-column flex-grow-1 register-form-content">
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div className="message-container">
            <CongratulationsCheckIcon />
            <h2>Congratulations, welcome aboard!</h2>
            <p>
              Check your{' '}
              <u>
                <a href="mailto:">mailbox</a>
              </u>{' '}
              to finalize your registration.
            </p>
          </div>
          <div className="contact-us-container">
            <p>
              Any questions? <span>Contact Us:</span>
            </p>
            <a href="tel:4019693773">401-969-3773</a>
            <a href="mailto:support@trusteddev.ai">
              support@trusteddev.ai
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center px-4 footer">
        <Button
          type="button"
          title="BACK TO SITE"
          onClick={handleNavigateToLogin}
          backgroundColorVar="--color-batch-blue"
          className="mx-4"
        />
        <Button
          type="button"
          title="LOGIN"
          onClick={handleNavigateToLogin}
          backgroundColorVar="--color-super-rare-jade"
        />
      </div>
    </div>
  );
};

export default Congratulations;
