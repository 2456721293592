import request, { attachQueryParams } from '../utils/request';

const USER_API_URL = 'user/api';

const UserService = {
  createNewUserWithInvitationToken: (data) => {
    return request({
      url: `${USER_API_URL}/createNewUserWithInvitationToken`,
      method: 'post',
      data
    });
  },
  validateInvitationToken: (invitationToken) => {
    return request({
      url: attachQueryParams(`${USER_API_URL}/validateInvitationToken`, {
        invitation_token: invitationToken
      }),
      method: 'get'
    });
  },
  getUser: () => {
    let url = `${USER_API_URL}/getUserProfile`;

    if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
      const params = new URLSearchParams({
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      url += `?${params.toString()}`;
    }

    return request({
      url: url,
      method: 'get'
    });
  },
  uploadFile: (image) => {
    const formData = new FormData();
    formData.append('files', image);

    return request({
      url: `user/upload/file/Clients`,
      method: 'put',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  },
  removeUserProfilePicture: () =>
    request({
      url: `${USER_API_URL}/removeUserProfilePicture`,
      method: 'delete'
    }),
  updateUser: (
    {
      _avatar, // don't remove it, second argument of this function handles avatar's file name
      _country,
      _state,
      _city,
      _notes,
      _user_id,
      ...data
    },
    avatarFileName = null
  ) =>
    request({
      url: `${USER_API_URL}/updateUserProfile`,
      method: 'post',
      data: avatarFileName ? { ...data, avatar: avatarFileName } : data
    })
};

export default UserService;
