import './CandidatesStatsCardList.css';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CandidatesStatsCardListItem from '../CandidatesStatsCardListItem/CandidatesStatsCardListItem';
import { setCandidateFilterProfileStatus } from '../../../../store/slices/CandidateFilter';
import config from '../../../../config';

const CandidatesStatsCardList = ({
  containerClassName,
  data = undefined
}) => {
  const dispatch = useDispatch();
  const setProfileStatus = (status) =>
    dispatch(setCandidateFilterProfileStatus(status));

  return (
    <div
      className={
        'candidates-stats__card-list' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {data && (
        <>
          <CandidatesStatsCardListItem
            key={0}
            figure={data?.total_voted_up}
            indicatorColorVar="--color-brig"
            title="Upvoted"
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.VOTED_UP)
            }
          />
          <CandidatesStatsCardListItem
            key={1}
            figure={data?.total_voted_down}
            indicatorColorVar="--color-raspberry-glaze"
            title="Downvoted"
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.VOTED_DOWN)
            }
          />
          <CandidatesStatsCardListItem
            key={2}
            figure={data?.total_interviewed}
            indicatorColorVar="--color-blue-heaven"
            title="Interviewed"
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.INTERVIEWED)
            }
          />
          <CandidatesStatsCardListItem
            key={3}
            figure={data?.total_hired}
            indicatorColorVar="--color-super-rare-jade"
            title="Hired"
            onClick={() =>
              setProfileStatus(config.candidate.profileStatus.HIRED)
            }
          />
        </>
      )}
    </div>
  );
};

CandidatesStatsCardList.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.object
};

export default CandidatesStatsCardList;
