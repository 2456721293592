import Spline from '@splinetool/react-spline';
import { useRef } from 'react';

const NewMatch = () => {
  const splineObjectRef = useRef();

  function onLoad(spline) {
    splineObjectRef.current = spline.findObjectById(
      'c363aef7-c808-49a7-beea-8a2fc2d805e9'
    );
  }

  return (
    <div className="flex flex-col w-full h-full min-h-0">
      <div className="w-full h-full">
        <Spline
          scene="https://prod.spline.design/yIXevoaPvBcuiFCq/scene.splinecode"
          onLoad={onLoad}
        />
      </div>
    </div>
  );
};

export default NewMatch;
