import {
  Box,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import Checkbox from '../../shared/Checkbox';

const CampaignForm = ({ campaignInfo, updateCampaignInfo }) => {
  const checkCommentEnabling = (updatedCampaign) =>
    updatedCampaign.expected_week_count !== null &&
    updatedCampaign.expected_contributor_count !== null &&
    updatedCampaign.expected_week_hours !== null;

  const changeCheckValueOnQuestion = (event) => {
    const updatedValue = event.target.checked ? null : 0;
    const fieldName = event.target.name.replace('checkbox.', ''); //remove prefix

    const updatedCampaign = { ...campaignInfo };

    updatedCampaign[fieldName] = updatedValue;

    const isDisabledComment = checkCommentEnabling(updatedCampaign);

    updatedCampaign.comment = isDisabledComment
      ? ''
      : updatedCampaign.comment;

    updateCampaignInfo(updatedCampaign);
  };

  const handleTextChange = (event) => {
    updateCampaignInfo({
      ...campaignInfo,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container flexDirection="column" gap="16px">
      <Grid item>
        <Box>
          <Typography
            color="#509ec6"
            fontSize={14}
            fontWeight={600}
            marginBottom="4px">
            Campaign Name
          </Typography>
          <TextField
            sx={{ width: '100%' }}
            onChange={handleTextChange}
            name="campaign_name"
            value={campaignInfo.campaign_name ?? ''}></TextField>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Typography
            color="#509ec6"
            fontSize={14}
            fontWeight={600}
            marginBottom="4px">
            Campaign Description
          </Typography>
          <TextField
            multiline
            sx={{ width: '100%' }}
            rows={3}
            onChange={handleTextChange}
            name="campaign_description"
            value={campaignInfo.campaign_description ?? ''}></TextField>
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container flexDirection="column" alignItems="flex-start">
              <Grid item>
                <Typography
                  color="#509ec6"
                  fontSize={14}
                  fontWeight={600}
                  marginBottom="4px">
                  How many hours per week are you looking to run this
                  campaign for?
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={campaignInfo.expected_week_hours === null}
                      onChange={changeCheckValueOnQuestion}
                      name="checkbox.expected_week_hours"
                    />
                  }
                  label={
                    <Typography
                      color="#526c80"
                      fontSize={14}
                      fontWeight="600">
                      I don&apos;t know
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '50px' }}
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              disabled={campaignInfo.expected_week_hours === null}
              value={campaignInfo.expected_week_hours ?? 0}
              onChange={handleTextChange}
              name="expected_week_hours"></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container flexDirection="column" alignItems="flex-start">
              <Grid item>
                <Typography
                  color="#509ec6"
                  fontSize={14}
                  fontWeight={600}
                  marginBottom="4px">
                  How many contractors are you looking to hire for this
                  campaign?
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        campaignInfo.expected_contributor_count === null
                      }
                      onChange={changeCheckValueOnQuestion}
                      name="checkbox.expected_contributor_count"
                    />
                  }
                  label={
                    <Typography
                      color="#526c80"
                      fontSize={14}
                      fontWeight="600">
                      I don&apos;t know
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '50px' }}
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              disabled={campaignInfo.expected_contributor_count === null}
              value={campaignInfo.expected_contributor_count ?? 0}
              onChange={handleTextChange}
              name="expected_contributor_count"></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container flexDirection="column" alignItems="flex-start">
              <Grid item>
                <Typography
                  color="#509ec6"
                  fontSize={14}
                  fontWeight={600}
                  marginBottom="4px">
                  How many weeks do you plan to run the campaign for?
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={campaignInfo.expected_week_count === null}
                      onChange={changeCheckValueOnQuestion}
                      name="checkbox.expected_week_count"
                    />
                  }
                  label={
                    <Typography
                      color="#526c80"
                      fontSize={14}
                      fontWeight="600">
                      I don&apos;t know
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '50px' }}
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              disabled={campaignInfo.expected_week_count === null}
              value={campaignInfo.expected_week_count ?? 0}
              onChange={handleTextChange}
              name="expected_week_count"></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box>
          <TextField
            multiline
            sx={{ width: '100%' }}
            rows={2}
            disabled={checkCommentEnabling(campaignInfo)}
            value={campaignInfo.comment}
            onChange={handleTextChange}
            name="comment"></TextField>
        </Box>
      </Grid>
    </Grid>
  );
};

CampaignForm.propTypes = {
  campaignInfo: PropTypes.object,
  updateCampaignInfo: PropTypes.func
};

export default CampaignForm;
