import request from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';

const ProjectService = {
  getClientProjects: () =>
    request({
      url: `${RECRUITER_API_URL}/getClientProjects`,
      method: 'get'
    }),
  getClientProjectDetails: (data = {}) =>
    request({
      url: `${RECRUITER_API_URL}/getClientProject`,
      method: 'post',
      data
    })
};

export default ProjectService;
