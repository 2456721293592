import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { motion } from 'framer-motion';
import './styles.scss';
import CampaignPaymentForm from '../CampaignPaymentForm';
import config from '../../../config';
import { Box, CircularProgress } from '@mui/material';
import { useSetupCampaignPayment } from '../../../queries/Campaign';
import { createPortal } from 'react-dom';

const stripePromise = loadStripe(config.common.stripePublicKey);

export default function CampaignPaymentFormModal({
  campaignId,
  onClose,
  onSuccess
}) {
  const [campaignPayment, setCampaignPayment] = useState(null);
  const { mutateAsync: setupCampaignPayment } = useSetupCampaignPayment();

  useEffect(() => {
    const handleSetupCampaignPayment = async () => {
      const response = await setupCampaignPayment({
        campaign_id: campaignId
      });

      setCampaignPayment(response);
    };

    handleSetupCampaignPayment();
  }, []);

  return createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="campaign-payment-form-modal">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        className="content p-0">
        {stripePromise && campaignPayment ? (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: campaignPayment?.client_secret }}>
            <CampaignPaymentForm
              campaignId={campaignId}
              campaignPayment={campaignPayment}
              onCancel={onClose}
              onSuccess={onSuccess}
            />
          </Elements>
        ) : (
          <Box
            height="160px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </motion.div>
    </motion.div>,
    document.body
  );
}
