import { createSlice } from '@reduxjs/toolkit';
import { HOME_TABS } from '../../utils/misc';
import { getCookie } from '../../utils/cookies';

const initialState = {
  isAuth: Boolean(getCookie('token')),
  activeMobileTab: HOME_TABS.CANDIDATES,
  ai: {
    isActive: true,
    isModalVisible: false
  }
};

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveMobileTab(state, { payload }) {
      state.activeMobileTab = payload;
    },
    setIsAuthenticated(state, { payload }) {
      state.isAuth = payload;
    }
  }
});

export const { setActiveMobileTab, setIsAuthenticated } = AppSlice.actions;
export default AppSlice.reducer;
