import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { INTERVIEW_STATUSES } from '../../constants/common';
import { ReactComponent as Logo } from '../../assets/images/td-logo-original.svg';
import './styles.scss';
import {
  useGetInterviewInfoByHostRoomCodeQuery,
  useSubmitPostInterviewMutation
} from '../../queries/Interview';
import { LoadingButton } from '@mui/lab';

const Interview = () => {
  const history = useHistory();
  const [interviewComponent, setInterviewComponent] = useState();
  const [interviewNote, setInterviewNote] = useState('');
  const [isPostInterview, setIsPostInterview] = useState(false);
  const [interviewStatus, setInterviewStatus] = useState();
  const params = useParams();
  const { roomCode } = params;

  const { data } = useGetInterviewInfoByHostRoomCodeQuery({
    host_room_code: roomCode
  });

  const { mutateAsync: submitPostInterview } =
    useSubmitPostInterviewMutation();

  //if the interview id is present, the current room code is from the host, so we allow post interview process
  const isPostInterviewAllowed = Boolean(data?.id);

  useEffect(() => {
    const loadInterviewComponent = async () => {
      const module = await import('@100mslive/roomkit-react');
      const { HMSPrebuilt } = module;
      setInterviewComponent(HMSPrebuilt);
    };
    loadInterviewComponent();
  }, []);

  const goToDashboardPage = () => {
    history.push('/');
  };

  if (!interviewComponent) {
    return null;
  }

  if (isPostInterview) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100vw"
        height="100vh"
        padding="16px"
        className="interview-page"
        gap="1rem">
        <Box display="flex" justifyContent="center">
          <Logo className="logo w-75" />
        </Box>
        <Box>
          <Typography>What do you feel about the candidate?</Typography>
        </Box>
        <Grid container gap="1.5rem" justifyContent="center">
          <Grid
            item
            className={classNames('icon-container thumb-up', {
              active: interviewStatus === INTERVIEW_STATUSES.VOTED_UP
            })}
            onClick={() => {
              if (interviewStatus === INTERVIEW_STATUSES.VOTED_UP) {
                return;
              }

              setInterviewStatus(INTERVIEW_STATUSES.VOTED_UP);
            }}>
            <ThumbUpRoundedIcon className="styled-icon" />
          </Grid>
          <Grid
            item
            className={classNames('icon-container thumb-down', {
              active: interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN
            })}
            onClick={() => {
              if (interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN) {
                return;
              }

              setInterviewStatus(INTERVIEW_STATUSES.VOTED_DOWN);
            }}>
            <ThumbDownRoundedIcon className="styled-icon" />
          </Grid>
        </Grid>
        <Box>
          <TextField
            multiline
            sx={{ width: '320px' }}
            rows={3}
            onChange={(e) => setInterviewNote(e.target.value)}
          />
        </Box>
        <Box display="flex" gap="1.5rem">
          <Button
            width="150px"
            onClick={() => {
              goToDashboardPage();
            }}>
            SUBMIT LATER
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ width: '100px' }}
            onClick={async () => {
              await submitPostInterview({
                interview_id: data.id,
                client_id: data.client_id,
                status: interviewStatus,
                interview_note: interviewNote
              });
              goToDashboardPage();
            }}>
            SUBMIT
          </LoadingButton>
        </Box>
        <Box>
          <Typography>
            If you left the interview room by mistake, you can rejoin{' '}
            <Link
              to={`/interview/${roomCode}`}
              onClick={() => window.location.reload()}
              style={{ fontFamily: 'Lato' }}>
              here
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    );
  }

  const HMSPrebuilt = interviewComponent;

  return (
    <Box className="interview-page">
      <HMSPrebuilt
        roomCode={roomCode}
        onLeave={() => {
          if (isPostInterviewAllowed) {
            setIsPostInterview(true);
          }
        }}
      />
    </Box>
  );
};

export default Interview;
