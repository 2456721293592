import React from 'react';

import './BottomPanelHeader.scss';

const BottomPanelHeader = () => (
  <div className="bottom-panel-header grid-two-columns">
    <div>Total Spend Per Month</div>
    <div>Active Project Resources Per Month</div>
  </div>
);

export default BottomPanelHeader;
