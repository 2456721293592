import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { ACTIVITY_FEED_TAB } from '../../../constants/campaign-management';
import {
  useGetCampaignActivityList,
  useGetCampaignFeedList
} from '../../../queries/Campaign';

import './styles/campaign-management-activity-feed-tab.scss';
import { ROLE_TYPES } from '../../../constants/common';
import { Avatar, Box, CircularProgress } from '@mui/material';

export const CampaignManagementActivityFeedTab = ({ campaign }) => {
  const [activeTab, setActiveTab] = useState(ACTIVITY_FEED_TAB.FEED);

  const { data: activitiesData } = useGetCampaignActivityList({
    campaign_id: campaign.id
  });

  const { data: feedList, isLoading } = useGetCampaignFeedList({
    campaign_id: campaign.id
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="campaign-management-activity-feed-tab">
      {activeTab === ACTIVITY_FEED_TAB.FEED && (
        <div className="feed-container">
          {feedList?.map((feed) => (
            <div key={feed.id} className="feed">
              <div className="d-flex align-items-center">
                {feed.candidate_avatar ? (
                  <Avatar
                    src={feed.candidate_avatar}
                    alt={feed.candidate_full_name}
                    sx={{ width: 44, height: 44 }}
                  />
                ) : (
                  <span className="avatar" />
                )}
                <p className="name fw-bold m-0 ms-2">
                  {feed.candidate_full_name}
                </p>
              </div>
              <p className="text fw-bold">{feed.description}</p>
              <p className="time fw-bold">
                {format(
                  parseISO(feed.created_datetime),
                  'h:mm a MMM d, yyyy'
                )}
              </p>
            </div>
          ))}
        </div>
      )}
      {activeTab === ACTIVITY_FEED_TAB.HISTORY && (
        <div className="history-table-container">
          <table className="history-table text-start w-100">
            <thead>
              <tr>
                <th>Event</th>
                <th>Time</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {activitiesData?.map((item) => (
                <tr key={item}>
                  <td>{item.activity_description}</td>
                  <td>
                    {format(
                      new Date(item.created_datetime),
                      'MM/dd/yyyy HH:mm'
                    )}
                  </td>
                  <td>
                    {String(item.creator_role) ===
                    String(ROLE_TYPES.MYSELF.value)
                      ? 'Myself'
                      : 'Admin'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="footer-tabs d-flex w-100 text-center fw-bold pointer mt-auto">
        <p
          className={classNames('tab text-uppercase w-50 pointer m-0', {
            active: activeTab === ACTIVITY_FEED_TAB.FEED
          })}
          onClick={() => setActiveTab(ACTIVITY_FEED_TAB.FEED)}>
          Feed
        </p>
        <p
          className={classNames('tab text-uppercase w-50 pointer m-0', {
            active: activeTab === ACTIVITY_FEED_TAB.HISTORY
          })}
          onClick={() => setActiveTab(ACTIVITY_FEED_TAB.HISTORY)}>
          History
        </p>
      </div>
    </div>
  );
};
