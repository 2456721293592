import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import DateTimePicker from '../../shared/DateTimePicker';
import Modal from '../../shared/Modal';
import './styles.scss';

const InterviewSchedulingModal = ({
  onClose,
  candidateName,
  onSubmit,
  title,
  primaryCtaText = 'INVITE',
  noOfDates = 3
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [proposedDates, setProposedDates] = useState(
    Array(noOfDates).fill(null)
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      maxWidth="400px"
      width="100%"
      className="interview-rescheduling-modal">
      <Box>
        <Box textAlign="center" marginBottom="6px">
          <Typography
            as="h5"
            fontSize={14}
            textAlign="center"
            fontWeight="600"
            color="#509ec6">
            {title}
          </Typography>
          <Typography as="p" fontSize={14} textAlign="center">
            with <b>{candidateName}</b>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box width="220px">
            <Divider />
            <Box display="flex" gap="10px" flexDirection="column">
              <Box marginTop="6px">
                <Typography as="p" fontSize={14} textAlign="center">
                  Please propose <b>{noOfDates} different dates</b>:
                </Typography>
              </Box>
              {proposedDates.map((currentProposedDate, index) => (
                <Box key={index}>
                  <DateTimePicker
                    disablePast
                    shouldDisableTime={(date) => {
                      return proposedDates.some(
                        (proposedDate) =>
                          proposedDate &&
                          currentProposedDate !== proposedDate &&
                          new Date(proposedDate).getTime() ===
                            new Date(date).getTime()
                      );
                    }}
                    onChange={(value) => {
                      setProposedDates(
                        proposedDates.map((proposedDate, i) => {
                          if (i === index) {
                            return value;
                          }
                          return proposedDate;
                        })
                      );
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box marginTop={2}>
          <Grid container justifyContent="center" gap="40px">
            <Grid item>
              <Button
                sx={{ width: '7rem' }}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}>
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                sx={{ width: '7rem' }}
                onClick={async (e) => {
                  try {
                    e.stopPropagation();
                    setIsSubmitting(true);
                    await onSubmit(proposedDates);
                    setIsSubmitting(false);
                    onClose();
                  } finally {
                    setIsSubmitting(false);
                  }
                }}
                loading={isSubmitting}
                disabled={proposedDates.some(
                  (proposedDate) => !proposedDate
                )}>
                {primaryCtaText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default InterviewSchedulingModal;
