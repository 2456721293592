import './StatsMobile.scss';
import { motion } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CandidatesStatsPieChart from '../candidates/CandidatesStatsPieChart/CandidatesStatsPieChart';
import { useGetStatsQuery } from '../../../queries/Stats';
import { ReactComponent as CandidateIcon } from '../../../assets/images/candidate-icon.svg';
import { ReactComponent as CampaignIcon } from '../../../assets/images/campaign-icon.svg';
import HorizontalGraphMobile from '../HorizontalGraph/HorizontalGraphMobile';
import { tabContentMobileVariants } from './../../../utils/animation';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from './../../shared/CustomScrollBar/CustomScrollBar';
import CampaignsStatsCardList from './../campaigns/CampaignsStatsCardList/CampaignsStatsCardList';
import CandidatesStatsCardList from './../candidates/CandidatesStatsCardList/CandidatesStatsCardList';
import StatsSectionHeader from './../StatsSectionHeader/StatsSectionHeader';

const StatsMobile = ({ selectedCampaign }) => {
  const queryParams = {
    campaign_id: selectedCampaign?.campaign_id || null
  };

  const { isSuccess, data } = useGetStatsQuery(queryParams);

  return (
    isSuccess && (
      <motion.div
        variants={tabContentMobileVariants}
        initial={'hidden'}
        animate={'show'}
        exit={'hidden'}
        className="stats-mobile">
        <Scrollbars
          hideTracksWhenNotNeeded
          renderThumbVertical={ScrollThumbVertical}
          renderTrackVertical={ScrollTrackVertical}
          renderThumbHorizontal={() => <div />}
          renderTrackHorizontal={() => <div />}>
          <div className="px-5 pt-8 pb-16">
            <StatsSectionHeader
              containerClassName="candidates-stats-header"
              icon={CandidateIcon}
              figure={data?.candidates_data?.total_matched}
              title={`Candidates\nMatched`}
            />
            <hr />
            <CandidatesStatsCardList
              containerClassName={'campaigns-stats-figures-list'}
              data={data?.candidates_data}
            />
            <CandidatesStatsPieChart
              data={data?.candidates_data}
              containerClassName={'campaigns-stats-charts'}
            />
            <hr />
            <StatsSectionHeader
              containerClassName="campaigns-stats-header"
              icon={CampaignIcon}
              figure={data?.campaigns_data?.total_campaigns}
              title={`All\nCampaigns`}
            />
            <CampaignsStatsCardList data={data?.campaigns_data} />
            <hr />
            <HorizontalGraphMobile selectedCampaign={selectedCampaign} />
          </div>
        </Scrollbars>
      </motion.div>
    )
  );
};

export default StatsMobile;
