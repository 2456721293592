import config from '../config';

export const getMonthYear = (date, isShortMonth) => {
  if (!date || (date instanceof Date && isNaN(date))) {
    date = Date.now();
  }

  const formattedDate = new Date(date);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
    formattedDate
  );
  const month = new Intl.DateTimeFormat('en', {
    month: isShortMonth ? 'short' : 'long'
  }).format(formattedDate);

  return month + ' ' + year;
};

export const getDayMonthYear = (date, isShortMonth) => {
  const formattedDate = new Date(date);

  const day = formattedDate.getDate();

  return day + ' ' + getMonthYear(date, isShortMonth);
};

export const getWeekDay = (date) => {
  const formattedDate = new Date(date);
  return new Intl.DateTimeFormat('en', { weekday: 'short' }).format(
    formattedDate
  );
};

export const getDaysBetweenDates = (endDate, startDate) => {
  const diffInMs = new Date(endDate) - new Date(startDate);
  return diffInMs / (1000 * 60 * 60 * 24) + 1;
};

export const getDays = (date) => {
  const formattedDate = new Date(date);

  return new Date(
    formattedDate.getFullYear(),
    formattedDate.getMonth() + 1,
    0
  ).getDate();
};

export const getTimeStartPosition = (currentDate, elementGap) => {
  const currentDay = new Date(currentDate).getDate();
  const startPosition = elementGap * currentDay;

  return startPosition - elementGap / 2 + currentDay * 1.27;
};

export const getDisplayedTimeFrame = (
  startDate,
  endDate,
  timelineType
) => {
  switch (timelineType) {
    case config.projectTimelineType.MONTHLY:
      return getTimeFrame(startDate, endDate);
    case config.projectTimelineType.WEEKLY:
      return getWeeklyTimeFrame(startDate, endDate);
    case config.projectTimelineType.FULL:
    default:
      return getFullTimeFrame(startDate, endDate);
  }
};

const getFullTimeFrame = (startDate, endDate) => {
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  let internalStartDate = new Date(
    formattedStartDate.getFullYear(),
    formattedStartDate.getMonth(),
    1
  );
  const result = [];
  while (internalStartDate <= formattedEndDate) {
    const formattedDate = new Date(internalStartDate);
    const jumper = {
      text: getMonthYear(formattedDate, true),
      value: getTimeFrame(
        internalStartDate,
        new Date(
          internalStartDate.getFullYear(),
          internalStartDate.getMonth(),
          getDays(internalStartDate)
        )
      )
    };
    result.push(jumper);
    internalStartDate.setMonth(internalStartDate.getMonth() + 1);
  }
  return result;
};

export const getWeeklyTimeFrame = (startDate, endDate) => {
  let internalStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  const result = [];
  while (internalStartDate <= formattedEndDate) {
    const jumper = {
      text:
        getWeekDay(internalStartDate) + ' ' + internalStartDate.getDate(),
      value: new Date(internalStartDate)
    };
    result.push(jumper);
    internalStartDate.setDate(internalStartDate.getDate() + 1);
  }
  return result;
};

export const getTimeFrame = (startDate, endDate) => {
  let internalStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  const result = [];
  while (internalStartDate <= formattedEndDate) {
    const jumper = {
      text: internalStartDate.getDate(),
      value: new Date(internalStartDate)
    };
    result.push(jumper);
    internalStartDate.setDate(internalStartDate.getDate() + 1);
  }
  return result;
};

export const getDateWithoutTime = (datetime) =>
  new Date(
    datetime.getFullYear(),
    datetime.getMonth(),
    datetime.getDate()
  );

export const getCurrentWeekDateRange = () => {
  const today = new Date();
  const firstDate = today.getDate() - today.getDay() + 1;

  const startDate = new Date(today.setDate(firstDate));
  const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);

  return {
    startDate,
    endDate
  };
};

export const getTimePositionFromTimeFrame = (
  timeFrame,
  startDate,
  endDate
) => {
  let foundStartPosition = timeFrame.findIndex(
    ({ value }) =>
      value.toDateString() === new Date(startDate).toDateString()
  );

  let foundEndPosition = timeFrame.findIndex(
    ({ value }) =>
      value.toDateString() === new Date(endDate).toDateString()
  );

  //a task/milestone is not in this time frame
  if (foundStartPosition === -1 && foundEndPosition === -1) {
    const finalDay = new Date(endDate);
    for (
      let day = new Date(startDate);
      day <= finalDay;
      day.setDate(day.getDate() + 1)
    ) {
      const timeFrameOuterIndex = timeFrame.findIndex(
        ({ value }) =>
          value.toDateString() === new Date(day).toDateString()
      );
      if (timeFrameOuterIndex !== -1) {
        return [-1, timeFrame.length + 1];
      }
    }

    return [foundStartPosition, foundEndPosition];
  }

  //a task/milestone's end date is in next timeframe
  if (foundEndPosition === -1) {
    foundEndPosition = timeFrame.length + 1;
  }

  return [foundStartPosition, foundEndPosition];
};

export const checkToday = (value) =>
  (Array.isArray(value) &&
    value.some(
      (date) =>
        date.value.toDateString() ===
        getDateWithoutTime(new Date()).toDateString()
    )) ||
  (!Array.isArray(value) &&
    value.toDateString() ===
      getDateWithoutTime(new Date()).toDateString());
