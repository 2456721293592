import { useState } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import CampaignList from '../../components/campaign/CampaignList/CampaignList';
import UpcomingInterviewList from '../../components/interviews/UpcomingInterviewList/UpcomingInterviewList';
import CandidateList from '../../components/candidates/CandidateList/CandidateList';
import StatsSidebar from '../../components/stats/StatsSidebar/StatsSidebar';
import HorizontalGraph from '../../components/stats/HorizontalGraph/HorizontalGraph';
import {
  newMatchesVariants,
  sidebarVariants
} from '../../utils/animation';
import { selectShowNewMatches } from './../../store/selectors/Candidate';
import NewMatchesList from '../../components/newMatches/NewMatchesListV2';
import PermissionChecker from '@src/components/shared/PermissionChecker';
import HomeDesktopTabHeader from './HomeDesktopTabHeader';
import { HOME_TABS } from '../../constants/common';
import HomeDesktopActivityFeed from '../../components/activity-feed/home-desktop-activity-feed';

const HomeDesktopLayout = ({
  selectedCampaign,
  setSelectedCampaign,
  campaigns
}) => {
  const [activeTab, setActiveTab] = useState(HOME_TABS.CANDIDATES);

  const showNewMatches = useSelector(selectShowNewMatches);

  return (
    <div className="home d-flex">
      <PermissionChecker
        permissions={[
          'dashboard.campaign.read',
          'dashboard.interview.read'
        ]}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            variants={sidebarVariants}
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            className="home__left-sidebar d-flex flex-column">
            <PermissionChecker
              permissions={[
                'dashboard.campaign.read',
                'dashboard.campaign.write'
              ]}>
              <CampaignList
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
                campaigns={campaigns}
              />
            </PermissionChecker>
            <PermissionChecker
              permissions={[
                'dashboard.interview.read',
                'dashboard.interview.write'
              ]}>
              <UpcomingInterviewList />
            </PermissionChecker>
          </motion.div>
        </AnimatePresence>
      </PermissionChecker>
      <div className="home__content-wrapper">
        <div className="home__content-container">
          <div className="home__content relative d-flex flex-column flex-grow-1">
            <PermissionChecker
              permissions={[
                'dashboard.candidate.read',
                'dashboard.candidate.write'
              ]}>
              <HomeDesktopTabHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {activeTab === HOME_TABS.CANDIDATES && (
                <CandidateList selectedCampaign={selectedCampaign} />
              )}
              {activeTab === HOME_TABS.ACTIVITY_FEED && (
                <HomeDesktopActivityFeed />
              )}
            </PermissionChecker>
          </div>
          <PermissionChecker permissions={['dashboard.candidate.write']}>
            <AnimatePresence initial={false} mode="wait">
              {showNewMatches && (
                <motion.div
                  key="new-matches"
                  variants={newMatchesVariants}
                  initial={'hidden'}
                  animate={'show'}
                  exit={'hidden'}
                  className="new-match fixed top-0 left-0 w-full h-full z-[2]">
                  <NewMatchesList />
                </motion.div>
              )}
            </AnimatePresence>
          </PermissionChecker>
          <PermissionChecker permissions={['dashboard.chart.read']}>
            <AnimatePresence initial={false} mode="wait">
              <div className="home__right-sidebar">
                <StatsSidebar selectedCampaign={selectedCampaign} />
              </div>
            </AnimatePresence>
          </PermissionChecker>
        </div>
        <PermissionChecker permissions={['dashboard.chart.read']}>
          <HorizontalGraph
            containerClassName="home__graph-container"
            selectedCampaign={selectedCampaign}
          />
        </PermissionChecker>
      </div>
    </div>
  );
};

export default HomeDesktopLayout;
