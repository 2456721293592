import './Option.css';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';

const Option = ({
  id,
  title,
  name = '',
  checked = false,
  type = 'checkbox',
  children = false,
  disabled = false,
  ...rest
}) => (
  <label
    htmlFor={id}
    key={title}
    className={`pointer option no-tap-highlight ${
      disabled ? 'option__disabled' : ''
    }`}>
    {name ? (
      <Field
        id={id}
        type={type}
        name={name}
        {...rest}
        className="pointer state-transition no-tap-highlight"
      />
    ) : (
      <input
        id={id}
        type={type}
        checked={checked}
        disabled={disabled}
        {...rest}
        className="pointer state-transition no-tap-highlight"
      />
    )}
    {children ? <span>{children}</span> : title}
  </label>
);

Option.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio'])
};

export default React.memo(Option);
