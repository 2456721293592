import { useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ACTIVE_TAB } from '../../../constants/campaign-management';
import { ReactComponent as PlayIcon } from '../../../assets/images/play-icon.svg';
import { ReactComponent as PauseIcon } from '../../../assets/images/pause-icon.svg';
import { ReactComponent as XIcon } from '../../../assets/images/x-icon.svg';
import { ReactComponent as Pause } from '../../../assets/images/campaign-management/pause.svg';
import { ReactComponent as SourcingIcon } from '../../../assets/images/campaign-management/sourcing.svg';
import { ReactComponent as HiredCandidateIcon } from '../../../assets/images/campaign-management/hired-candidate.svg';
import { ReactComponent as PendingCandidateIcon } from '../../../assets/images/campaign-management/pending-candidate.svg';
import { ReactComponent as PendingInterviewIcon } from '../../../assets/images/campaign-management/pending-interview.svg';
import { ReactComponent as DepositUnpaidIcon } from '../../../assets/images/campaign-management/original-deposit-unpaid.svg';
import { ReactComponent as ActiveCandidateIcon } from '../../../assets/images/campaign-management/active-candidate.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/images/campaign-management/archive.svg';
import { useToggleCampaignStatusQuery } from '../../../queries/Campaign';
import { Box, Button, CircularProgress } from '@mui/material';
import { CampaignManagementTabs } from './campaign-management-tabs';

import './styles/campaign-management-modal.scss';
import { createPortal } from 'react-dom';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const ModalWrapper = ({ children }) =>
  createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="campaign-management-modal">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-0 content !w-[630px] !h-[630px]">
        {children}
      </motion.div>
    </motion.div>,
    document.body
  );

const CampaignManagementModal = ({
  campaign,
  closeModal,
  onPayDepositClick,
  onDeleteCampaignClick
}) => {
  const [activeTab, setActiveTab] = useState(ACTIVE_TAB.DETAILS);

  const { mutate: toggleCampaignStatus } = useToggleCampaignStatusQuery();

  const {
    id,
    campaign_name,
    campaign_start_date,
    deposit_paid,
    is_archived,
    is_active,
    campaign_statuses
  } = campaign || {};

  const isPaused = Boolean(!is_active);
  const isPaid = Boolean(deposit_paid);
  const isArchived = Boolean(is_archived);

  const isStatusDisplayed = !isPaused && isPaid;

  const isStatusDisplayedWithSourcing =
    isStatusDisplayed && !isArchived && campaign_statuses?.sourcing_status;

  const isRunningCampaign = isPaid && !isArchived;

  return (
    <ModalWrapper>
      {campaign ? (
        <>
          <Box
            className="p-2 mb-1 header d-flex align-items-center"
            height="54px">
            {isRunningCampaign && (
              <PermissionChecker
                permissions={['dashboard.campaign.write']}>
                {isPaused ? (
                  <PauseIcon
                    className="state-transition pointer"
                    onClick={() =>
                      toggleCampaignStatus({
                        campaign_id: id,
                        status: is_active
                      })
                    }
                  />
                ) : (
                  <PlayIcon
                    className="state-transition pointer"
                    onClick={() =>
                      toggleCampaignStatus({
                        campaign_id: id,
                        status: is_active
                      })
                    }
                  />
                )}
              </PermissionChecker>
            )}
            {isArchived && (
              <ArchiveIcon fill="#B3CCDB" className="status-display" />
            )}
            {!isArchived && !isPaid && (
              <DepositUnpaidIcon
                fill="#ff7bac"
                width={24}
                height={24}
                className="status-display"
              />
            )}
            <p className="m-0 ms-2 fw-bold">{campaign_name}</p>
            <div className="gap-2 mx-2 d-flex">
              {isStatusDisplayed && campaign_statuses?.sourcing_status && (
                <SourcingIcon
                  fill="#B3CCDB"
                  width={24}
                  height={24}
                  className="status-display"
                />
              )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_pending_candidates && (
                  <PendingCandidateIcon
                    fill="#B3CCDB"
                    width={24}
                    height={24}
                    className="status-display"
                  />
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_pending_interviews && (
                  <PendingInterviewIcon
                    fill="#B3CCDB"
                    width={24}
                    height={24}
                    className="status-display"
                  />
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_hired_candidates && (
                  <HiredCandidateIcon
                    fill="#B3CCDB"
                    width={24}
                    height={24}
                    className="status-display"
                  />
                )}
              {isStatusDisplayedWithSourcing &&
                campaign_statuses?.has_active_candidates && (
                  <ActiveCandidateIcon
                    fill="#B3CCDB"
                    width={24}
                    height={24}
                    className="status-display"
                  />
                )}
              {isPaused && isPaid && !isArchived && (
                <Pause className="status-display" />
              )}
            </div>
            <div className="gap-3 ms-auto d-flex align-items-center">
              {!isArchived && !isPaid && (
                <Box display="flex" gap="8px">
                  <Button
                    className="archive-btn"
                    width={110}
                    variant="contained"
                    onClick={onDeleteCampaignClick}>
                    DELETE
                  </Button>
                  <Button
                    className="archive-btn"
                    width={110}
                    variant="contained"
                    onClick={onPayDepositClick}
                    sx={{ backgroundColor: '#ff7bac !important' }}>
                    PAY DEPOSIT
                  </Button>
                </Box>
              )}
              {isPaid && campaign_start_date && (
                <p className="m-0 date fw-bold">
                  Start Date:{' '}
                  {new Date(campaign_start_date).toLocaleDateString()}
                </p>
              )}
              <XIcon className="pointer" onClick={closeModal} />
            </div>
          </Box>
          <div className="gap-2 tabs d-flex justify-content-between">
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.DETAILS
              })}
              onClick={() => setActiveTab(ACTIVE_TAB.DETAILS)}>
              <span>Details</span>
            </div>
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.METRICS
              })}
              onClick={() => setActiveTab(ACTIVE_TAB.METRICS)}>
              <span>Metrics</span>
            </div>
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.ACTIVITY_FEED
              })}
              onClick={() => setActiveTab(ACTIVE_TAB.ACTIVITY_FEED)}>
              <span>Activity Feed</span>
            </div>
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.CAMPAIGN_NOTES
              })}
              onClick={() => setActiveTab(ACTIVE_TAB.CAMPAIGN_NOTES)}>
              <span>Campaign Notes</span>
            </div>
          </div>
          <hr className="mb-3" />
          <div className="tabs-content">
            <CampaignManagementTabs
              activeTab={activeTab}
              onCloseModal={closeModal}
              campaign={campaign}
            />
          </div>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="1rem"
          minHeight="32.1875rem"
          width="39.375rem">
          <CircularProgress />
        </Box>
      )}
    </ModalWrapper>
  );
};

export default CampaignManagementModal;
