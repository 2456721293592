import { useState } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import Input from '../../../components/shared/Input';
import './styles.scss';

const NewTemplateModal = ({ onCreate, onCancel, isOpen }) => {
  const [templateName, setTemplateName] = useState('');
  return (
    <Modal isOpen={isOpen} className="new-template-modal" width="18.75rem">
      <Input
        label="Name your new template"
        placeholder="Template Name"
        onChange={(e) => setTemplateName(e.target.value)}
      />
      <div className="actions-container">
        <Button
          onClick={async () => {
            const result = await onCreate(templateName);
            if (result) {
              setTemplateName('');
            }
          }}
          disabled={!templateName}>
          CREATE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </div>
    </Modal>
  );
};

export default NewTemplateModal;
