import { useMutation, useQuery, useQueryClient } from 'react-query';
import UserService from '../services/User';
import { store } from '../store';
import { logSuccess } from '../utils/logging';
import { isFile } from '../utils/misc';
import queryStore from '.';

export const USER_QUERY_KEY = 'user';

queryStore.setQueryDefaults(USER_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  placeholderData: {
    avatar: undefined,
    email_id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    job_title: '',
    company_name: '',
    company_url: '',
    is_registered: true,
    permissions: []
  }
});

export const useGetUserQuery = (queryOptions) =>
  useQuery(
    USER_QUERY_KEY,
    async () => {
      const user = await UserService.getUser();

      return user;
    },
    queryOptions
  );

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const avatar = data?.avatar;
      const avatarAvailableForUpload = isFile(avatar);
      const avatarWasSet = !!store.getState()?.AppSlice?.user?.avatar;
      const avatarIsCurrentlyNotSet =
        avatar === null ||
        avatar === '' ||
        (!avatarWasSet && !avatarAvailableForUpload);
      const avatarIsUpdated = avatarWasSet && avatarAvailableForUpload;

      // deleting old avatar
      if (avatarWasSet && (avatarIsCurrentlyNotSet || avatarIsUpdated)) {
        await UserService.removeUserProfilePicture();
      }

      if (avatarAvailableForUpload) {
        const { file_url } = await UserService.uploadFile(avatar);
        await UserService.updateUser(data, file_url);
      } else {
        // update user profile
        await UserService.updateUser(data);
      }
    },
    {
      onSuccess: () => {
        logSuccess('Changes Successfully Saved');
      },
      onSettled: () => {
        queryClient.invalidateQueries(USER_QUERY_KEY);
      }
    }
  );
};
