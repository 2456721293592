import { useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ReactComponent as BluePenIcon } from '../../../assets/images/blue-pen.svg';
import { ReactComponent as FilledTrashBinIcon } from '../../../assets/images/filled-trash-bin.svg';
import {
  useCreateCampaignNote,
  useDeleteCampaignNote,
  useGetCampaignNoteList,
  useUpdateCampaignNote
} from '../../../queries/Campaign';

import './styles/campaign-management-notes-tab.scss';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import PermissionChecker from '@src/components/shared/PermissionChecker';

export const CampaignManagementNotesTab = ({ campaign }) => {
  const newNoteInputRef = useRef();
  const editNoteInputRef = useRef();
  const [editingIndex, setEditingIndex] = useState();
  const [isAddingNew, setIsAddingNew] = useState(false);

  const currentCampaignId = campaign.id;

  const {
    data: noteData,
    isLoading,
    isError
  } = useGetCampaignNoteList({
    campaign_id: currentCampaignId
  });

  const { mutate: createNote } = useCreateCampaignNote();
  const { mutate: updateNote } = useUpdateCampaignNote();
  const { mutate: deleteNote } = useDeleteCampaignNote();

  const handleOpenAdd = () => {
    setIsAddingNew(true);
    setEditingIndex(undefined);
  };

  const handleOpenEdit = (index) => {
    setEditingIndex(index);
    setIsAddingNew(false);
  };

  const handleSaveNote = () => {
    const newNote = newNoteInputRef.current.value;

    createNote({ campaign_id: currentCampaignId, note: newNote });

    setIsAddingNew(false);
  };

  const handleUpdateNote = (noteId) => {
    const updatedNote = editNoteInputRef.current.value;

    updateNote({ campaign_id: currentCampaignId, noteId, updatedNote });

    setEditingIndex();
  };

  const handleDeleteNote = (noteId) => {
    deleteNote({ campaign_id: currentCampaignId, noteId });

    setEditingIndex();
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Box>
    );

  if (isError) return null;

  return (
    <div className="campaign-management-notes-tab overflow-y-scroll !max-h-[500px]">
      {!isAddingNew && (
        <PermissionChecker permissions={['dashboard.campaign.write']}>
          <Button
            className="new-note-btn w-25 mb-2 text-uppercase"
            sx={{ margin: '0 2rem' }}
            onClick={handleOpenAdd}
            variant="contained">
            New Note
          </Button>
        </PermissionChecker>
      )}
      {isAddingNew && (
        <>
          <div className="d-flex">
            <textarea
              ref={newNoteInputRef}
              className="note-edit-input w-100"
              rows={5}
            />
          </div>
          <div className="gap-4 action-buttons d-flex justify-content-center align-items-center">
            <Button
              className="w-25"
              onClick={() => setIsAddingNew(false)}
              variant="outlined">
              Cancel
            </Button>
            <Button
              className="w-25"
              onClick={handleSaveNote}
              variant="contained">
              Save
            </Button>
          </div>
        </>
      )}
      {(!noteData || !noteData.length) && !isAddingNew && (
        <Box margin="1rem 2rem">
          <Typography sx={{ fontWeight: 'bold' }}>
            You can add notes about your campaign in this section.
          </Typography>
        </Box>
      )}
      {noteData?.map((noteItem, index) => (
        <div className="d-flex flex-column" key={noteItem.id}>
          {editingIndex !== index && (
            <>
              <PermissionChecker
                permissions={['dashboard.campaign.write']}>
                <BluePenIcon
                  className="edit-pen pointer"
                  onClick={() => handleOpenEdit(index)}
                />
              </PermissionChecker>
              <p className="note">{noteItem.note}</p>
              <p className="date">
                {format(
                  parseISO(noteItem.updated_datetime),
                  'h:mm a MMM d, yyyy'
                )}
              </p>
            </>
          )}
          {editingIndex === index && (
            <>
              <div className="d-flex">
                <textarea
                  ref={editNoteInputRef}
                  className="note-edit-input w-100"
                  defaultValue={noteItem.note}
                  rows={5}
                />
              </div>
              <div className="gap-4 action-buttons d-flex justify-content-center align-items-center">
                <Button
                  className="w-25"
                  onClick={() => setEditingIndex()}
                  variant="outlined">
                  Cancel
                </Button>
                <Button
                  className="w-25"
                  onClick={() => handleUpdateNote(noteItem.id)}
                  variant="contained">
                  Save
                </Button>
                <FilledTrashBinIcon
                  className="delete-btn pointer"
                  onClick={() => handleDeleteNote(noteItem.id)}
                />
              </div>
            </>
          )}
          <hr className="w-auto divider" />
        </div>
      ))}
    </div>
  );
};
