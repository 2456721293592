import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  LabelList
} from 'recharts';

const CandidatesStatsPieChart = ({
  containerClassName,
  data = undefined
}) => {
  const barChartData = [
    {
      name: 'Interviewed',
      totalInterviewed: data?.total_interviewed
    },
    {
      name: 'Hired',
      totalHired: data?.total_hired
    }
  ];

  const pieChartData = [
    { name: 'Total Voted Up', value: data?.total_voted_up },
    { name: 'Total Voted Down', value: data?.total_voted_down }
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent === 0) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const PIE_CHART_COLORS = ['#509EC6', '#FF7BAC'];

  const CustomPieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const color = payload[0].payload.fill;

      return (
        <div
          style={{
            backgroundColor: color,
            borderRadius: '10px',
            padding: '4px 8px',
            color: '#fff'
          }}>
          <p>{`${name}: ${value}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomBarTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          {payload.map((entry, index) => {
            const name =
              entry.name === 'totalInterviewed' ? 'Interviewed' : 'Hired';
            const color =
              entry.name === 'totalInterviewed' ? '#5B7E95' : '#13B9B7';
            return (
              <p
                style={{
                  backgroundColor: color,
                  borderRadius: '10px',
                  padding: '4px 8px',
                  color: '#fff'
                }}
                key={`bar-tooltip-${index}`}>{`${name}: ${entry.value}`}</p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={
        'flex min-h-[250px] py-8' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {pieChartData && (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="h-[200px]">
          <PieChart width={400} height={400}>
            <Tooltip content={<CustomPieTooltip />} />
            <Pie
              data={pieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value">
              {pieChartData.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
      {barChartData && (
        <ResponsiveContainer
          width="80%"
          height="100%"
          className="h-[200px]">
          <BarChart
            width={200}
            height={150}
            data={barChartData}
            barSize={100}
            margin={{ top: 20, right: 30, left: 30, bottom: 13 }}>
            <Tooltip
              formatter={(value, name) => {
                switch (name) {
                  case 'totalInterviewed':
                    return [`Total Interviewed: ${value}`];
                  case 'totalHired':
                    return [`Total Hired: ${value}`];
                  default:
                    return value;
                }
              }}
              content={<CustomBarTooltip />}
            />
            <Bar
              dataKey="totalInterviewed"
              fill="#5B7E95"
              isAnimationActive={true}>
              <LabelList
                dataKey="totalInterviewed"
                position="top"
                fontWeight={600}
              />
            </Bar>
            <Bar
              dataKey="totalHired"
              fill="#13B9B7"
              isAnimationActive={true}>
              <LabelList
                dataKey="totalHired"
                position="top"
                fontWeight={600}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

CandidatesStatsPieChart.propTypes = {
  containerClassName: PropTypes.string
};

export default memo(CandidatesStatsPieChart);
