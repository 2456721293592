import { useMutation, useQuery, useQueryClient } from 'react-query';
import CampaignService from './../services/Campaign';
import queryStore from '.';

export const CAMPAIGN_LIST_QUERY_KEY = 'campaign_list_query_key';
export const CAMPAIGN_DETAIL_QUERY_KEY = 'campaign_detail_query_key';
export const CAMPAIGN_ACTIVITY_QUERY_KEY = 'campaign_activity_query_key';
export const CAMPAIGN_NOTE_LIST_QUERY_KEY = 'campaign_note_list_query_key';
export const CAMPAIGN_FEED_LIST_QUERY_KEY = 'campaign_feed_list_query_key';

queryStore.setQueryDefaults(CAMPAIGN_LIST_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

queryStore.setQueryDefaults(CAMPAIGN_DETAIL_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

queryStore.setQueryDefaults(CAMPAIGN_ACTIVITY_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

queryStore.setQueryDefaults(CAMPAIGN_NOTE_LIST_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

queryStore.setQueryDefaults(CAMPAIGN_FEED_LIST_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0
});

export const useConfirmCampaignPayment = () =>
  useMutation(
    async ({ campaign_id, payment_method }) =>
      await CampaignService.confirmCampaignPayment({
        campaign_id,
        payment_method
      })
  );

export const useSetupCampaignPayment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaign_id }) =>
      await CampaignService.setupCampaignPayment({ campaign_id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CAMPAIGN_LIST_QUERY_KEY]);
      },
      onError: (_error, _candidate, context) => {
        queryClient.setQueryData(
          CAMPAIGN_LIST_QUERY_KEY,
          context.previousCampaign
        );
      }
    }
  );
};

export const useGetCampaignsQuery = (queryParams, queryOptions) =>
  useQuery(
    CAMPAIGN_LIST_QUERY_KEY,
    async () => {
      const { campaigns } =
        await CampaignService.getCampaigns(queryParams);

      return campaigns;
    },
    queryOptions
  );

export const useGetCampaignDetailQuery = (queryParams, queryOptions) =>
  useQuery(
    [CAMPAIGN_DETAIL_QUERY_KEY, queryParams.campaign_id],
    async () => {
      const data = await CampaignService.getCampaignDetail(queryParams);

      return data;
    },
    queryOptions
  );

export const useGetCampaignDetailDataQuery = (
  campaign_id,
  queryOptions
) => {
  const queryClient = useQueryClient();
  return useQuery({
    ...queryOptions,
    queryKey: [CAMPAIGN_LIST_QUERY_KEY, campaign_id],
    queryFn: async () => {
      const { campaigns } = await CampaignService.getCampaigns({
        campaign_id
      });
      return campaigns?.find((d) => d.id === campaign_id);
    },
    initialData: () => {
      const result = queryClient
        .getQueryData([CAMPAIGN_LIST_QUERY_KEY])
        ?.find((d) => d.id === campaign_id);
      return result;
    }
  });
};

export const useGetCampaignActivityList = (queryParams, queryOptions) =>
  useQuery(
    [CAMPAIGN_ACTIVITY_QUERY_KEY, queryParams],
    async () => {
      const { campaign_activities } =
        await CampaignService.getCampaignActivityList(queryParams);

      return campaign_activities;
    },
    queryOptions
  );

export const useGetCampaignFeedList = (queryParams, queryOptions) =>
  useQuery(
    [CAMPAIGN_FEED_LIST_QUERY_KEY, queryParams],
    async () => {
      const { campaign_feeds } =
        await CampaignService.getCampaignFeedList(queryParams);

      return campaign_feeds;
    },
    queryOptions
  );

export const useGetCampaignNoteList = (queryParams, queryOptions) =>
  useQuery(
    [CAMPAIGN_NOTE_LIST_QUERY_KEY, queryParams.campaign_id],
    async () => {
      const { campaign_notes } =
        await CampaignService.getCampaignNoteList(queryParams);

      return campaign_notes;
    },
    queryOptions
  );

export const useToggleCampaignStatusQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, status }) =>
      CampaignService.updateCampaignStatus(
        campaign_id,
        status === 0 ? '1' : '0'
      ),
    {
      onMutate: async (variables) => {
        await queryClient.cancelQueries(CAMPAIGN_LIST_QUERY_KEY);

        const previousCampaign = queryClient.getQueryData([
          CAMPAIGN_LIST_QUERY_KEY
        ]);

        queryClient.setQueryData(CAMPAIGN_LIST_QUERY_KEY, (old) =>
          old.map((oldCampaign) => {
            if (oldCampaign?.campaign_id === variables?.campaign_id) {
              return {
                ...oldCampaign,
                is_active: variables?.status === 0 ? 1 : 0
              };
            } else {
              return oldCampaign;
            }
          })
        );

        queryClient.setQueriesData(
          [CAMPAIGN_DETAIL_QUERY_KEY, variables.campaign_id],
          (old) => ({
            ...old,
            campaign: {
              ...old.campaign,
              is_active: variables.status === 0 ? 1 : 0
            }
          })
        );

        return { previousCampaign };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGN_DETAIL_QUERY_KEY);
      },
      onError: (_error, _candidate, context) => {
        queryClient.setQueryData(
          CAMPAIGN_LIST_QUERY_KEY,
          context.previousCampaign
        );
      }
    }
  );
};

export const useUpdateCampaignDescription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, description }) =>
      CampaignService.updateCampaignDescription(campaign_id, description),
    {
      onMutate: async (variables) => {
        const previousQueryKey = [
          CAMPAIGN_DETAIL_QUERY_KEY,
          variables.campaign_id
        ];

        await queryClient.cancelQueries(previousQueryKey);

        const previousCampaign = queryClient.getQueryData([
          CAMPAIGN_LIST_QUERY_KEY
        ]);

        queryClient.setQueryData(previousQueryKey, (old) => ({
          ...old,
          campaign: {
            ...old.campaign,
            campaign_description: variables.description
          }
        }));

        return { previousCampaign };
      },
      onError: (_error, _candidate, context) => {
        queryClient.setQueryData(
          CAMPAIGN_DETAIL_QUERY_KEY,
          context.oldCampaigns
        );
      }
    }
  );
};

export const useUpdateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, payload }) =>
      CampaignService.updateCampaign(campaign_id, payload),
    {
      onMutate: async (variables) => {
        const previousQueryKey = [
          CAMPAIGN_DETAIL_QUERY_KEY,
          variables.campaign_id
        ];

        await queryClient.cancelQueries(previousQueryKey);

        const previousCampaign = queryClient.getQueryData([
          CAMPAIGN_LIST_QUERY_KEY
        ]);

        queryClient.setQueryData(previousQueryKey, (old) => ({
          ...old,
          campaign: {
            ...old.campaign,
            ...variables.payload
          }
        }));

        return { previousCampaign };
      },
      onError: (_error, _candidate, context) => {
        queryClient.setQueryData(
          CAMPAIGN_DETAIL_QUERY_KEY,
          context.oldCampaigns
        );
      }
    }
  );
};

export const useCreateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => CampaignService.createCampaign(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CAMPAIGN_LIST_QUERY_KEY]);
      },
      onError: (_error, _candidate, context) => {
        queryClient.setQueryData(
          CAMPAIGN_DETAIL_QUERY_KEY,
          context.oldCampaigns
        );
      }
    }
  );
};

export const useUpdateCampaignFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, rating, feedback }) =>
      CampaignService.updateCampaignFeedback(
        campaign_id,
        rating,
        feedback
      ),
    {
      onMutate: async (variables) => {
        const previousQueryKey = [
          CAMPAIGN_DETAIL_QUERY_KEY,
          variables.campaign_id
        ];

        await queryClient.cancelQueries(previousQueryKey);

        const previousCampaign =
          queryClient.getQueryData(previousQueryKey);

        queryClient.setQueryData(previousQueryKey, (old) => ({
          ...old,
          campaign: {
            ...old.campaign,
            rating: variables.rating,
            feedback: variables.feedback
          }
        }));

        return { previousCampaign };
      },
      onError: (_error, variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_DETAIL_QUERY_KEY, variables.campaign_id],
          context.previousCampaign
        );
      }
    }
  );
};

export const useCreateCampaignNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, note }) =>
      CampaignService.createCampaignNote(campaign_id, note),
    {
      onMutate: async (variables) => {
        const previousQuery = [
          CAMPAIGN_NOTE_LIST_QUERY_KEY,
          variables.campaign_id
        ];

        const newNote = {
          note: variables.note,
          updated_datetime: new Date().toISOString()
        };

        await queryClient.cancelQueries(previousQuery);

        const previousNotes = queryClient.getQueryData(previousQuery);

        queryClient.setQueryData(previousQuery, (old) => [
          ...old,
          newNote
        ]);

        return { previousNotes };
      },
      onError: (_error, variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id],
          context.previousNotes
        );
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries({
          queryKey: [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id]
        });
      }
    }
  );
};

export const useUpdateCampaignNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ noteId, updatedNote }) =>
      CampaignService.updateCampaignNote(noteId, updatedNote),
    {
      onMutate: async (variables) => {
        const previousQuery = [
          CAMPAIGN_NOTE_LIST_QUERY_KEY,
          variables.campaign_id
        ];

        const updatedNoteItem = {
          note: variables.updatedNote,
          updated_datetime: new Date().toISOString()
        };

        await queryClient.cancelQueries(previousQuery);

        const previousNotes = queryClient.getQueryData(previousQuery);

        queryClient.setQueryData(previousQuery, (old) => {
          const updatedNoteItems = old.map((noteItem) => {
            if (noteItem.id === variables.noteId) {
              return { ...noteItem, ...updatedNoteItem };
            }

            return noteItem;
          });

          return updatedNoteItems;
        });

        return { previousNotes };
      },
      onError: (_error, variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id],
          context.previousNotes
        );
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries({
          queryKey: [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id]
        });
      }
    }
  );
};

export const useDeleteCampaignNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ noteId }) =>
      CampaignService.deleteCampaignNote({ campaign_note_id: noteId }),
    {
      onMutate: async (variables) => {
        const previousQuery = [
          CAMPAIGN_NOTE_LIST_QUERY_KEY,
          variables.campaign_id
        ];

        const previousNotes = queryClient.getQueryData(previousQuery);

        await queryClient.cancelQueries(previousQuery);

        queryClient.setQueryData(previousQuery, (old) => {
          const updatedNoteItems = old.filter(
            (noteItem) => noteItem.id !== variables.noteId
          );

          return updatedNoteItems;
        });

        return previousNotes;
      },
      onError: (_error, variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id],
          context.updatedNoteItems
        );
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries({
          queryKey: [CAMPAIGN_NOTE_LIST_QUERY_KEY, variables.campaign_id]
        });
      }
    }
  );
};

export const useUpdateCampaignArchival = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, is_archived }) =>
      CampaignService.updateCampaignArchival(campaign_id, is_archived),
    {
      onMutate: async (variables) => {
        const previousQuery = [CAMPAIGN_LIST_QUERY_KEY];

        const updatedCampaign = {
          requested_archival: 1
        };

        await queryClient.cancelQueries(previousQuery);

        const previousCampaigns = queryClient.getQueryData(previousQuery);

        queryClient.setQueryData(previousQuery, (old) => {
          const updatedCampaigns = old.map((campaign) => {
            if (campaign.campaign_id === variables.campaign_id) {
              return { ...campaign, ...updatedCampaign };
            }

            return campaign;
          });

          return updatedCampaigns;
        });

        return { previousCampaigns };
      },
      onError: (_error, _variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_LIST_QUERY_KEY],
          context.previousCampaigns
        );
      },
      onSettled: (_data, _error) => {
        queryClient.invalidateQueries({
          queryKey: [CAMPAIGN_LIST_QUERY_KEY]
        });
      }
    }
  );
};

export const useDeleteCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id }) => CampaignService.deleteCampaign({ campaign_id }),
    {
      onMutate: async (variables) => {
        const previousQuery = [CAMPAIGN_LIST_QUERY_KEY];

        await queryClient.cancelQueries(previousQuery);

        const previousCampaigns = queryClient.getQueryData(previousQuery);

        queryClient.setQueryData(previousQuery, (old) => {
          const updatedCampaigns = old.filter(
            (campaign) => campaign.campaign_id !== variables.campaign_id
          );

          return updatedCampaigns;
        });

        return { previousCampaigns };
      },
      onError: (_error, _variables, context) => {
        queryClient.setQueryData(
          [CAMPAIGN_LIST_QUERY_KEY],
          context.previousCampaigns
        );
      },
      onSettled: (_data, _error) => {
        queryClient.invalidateQueries({
          queryKey: [CAMPAIGN_LIST_QUERY_KEY]
        });
      }
    }
  );
};

export const useGetCampaignList = () => {
  return useQuery(CAMPAIGN_LIST_QUERY_KEY, async () => {
    const { campaigns } = await CampaignService.getCampaignList();

    return campaigns;
  });
};
