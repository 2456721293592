import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useIfMobile } from '../../../utils/responsive';
import { navigate } from '../../../utils/history';
import { getAccessibleRoutes, routes } from '../../../router/routes';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import AIModal from '../../shared/AIModal/AIModal';
import { ReactComponent as TrustedDevLogo } from '../../../assets/images/td-logo.svg';
import { ReactComponent as AiIcon } from '../../../assets/images/ai-icon.svg';
import { ReactComponent as BackArrowIcon } from '../../../assets/images/back-arrow-icon.svg';
import { ReactComponent as HambugerIcon } from '../../../assets/images/hambuger-icon.svg';
import Avatar from '../Avatar/Avatar';
import { logoutRequest } from '../../../store/slices/Auth';
import HeaderPopover from '../HeaderPopover/HeaderPopover';

import './Navigation.scss';
import BasicInfoUpdateModal from '../BasicInfoUpdateModal';

const Navigation = ({ userData, isUserDataFetched }) => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const isMobile = useIfMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isAIModalVisible, setIsAIModalVisible] = useState(false);
  const [isAIActive, setIsAIActive] = useState(false);
  const [isBasicInfoUpdateModalOpen, setIsBasicInfoUpdateModalOpen] =
    useState(false);

  useEffect(() => {
    setIsMenuShown(false);
  }, [location.pathname]);

  useEffect(() => {
    if (userData && !userData.is_registered) {
      setIsBasicInfoUpdateModalOpen(true);
    }
  }, [userData]);

  const isAccountPage = location.pathname.includes('account');

  const isComplianceManagementContractorPage = location.pathname.includes(
    '/compliance-management/contractors'
  );

  const isComplianceManagementContractorSubPage =
    isComplianceManagementContractorPage &&
    (location.pathname.includes('/checklist') ||
      location.pathname.includes('/files'));

  const toggle = (e) => {
    setIsAIActive(e.target.checked);
    if (!e.target.checked) {
      setIsAIModalVisible(true);
    }
  };

  const handleLogout = () => {
    dispatch(logoutRequest());
    navigate(routes.login);
  };

  const getNavigationPaths = () => {
    return getAccessibleRoutes(userData).filter(
      (path) => path.defaultNavigatedPath
    );
  };

  return (
    <>
      {isBasicInfoUpdateModalOpen && (
        <BasicInfoUpdateModal
          isOpen={true}
          userData={userData}
          onClose={() => setIsBasicInfoUpdateModalOpen(false)}
        />
      )}
      <nav className="top-navigation container-fluid">
        <div className="top-navigation__left">
          {isAccountPage && (
            <BackArrowIcon
              className="back-icon pointer no-tap-highlight"
              onClick={() => navigate('/')}
            />
          )}
          {isComplianceManagementContractorSubPage && (
            <BackArrowIcon
              className="back-icon pointer no-tap-highlight"
              onClick={() =>
                navigate('/compliance-management/contractors')
              }
            />
          )}
          {!isAccountPage && !isComplianceManagementContractorSubPage && (
            <div className="hambuger-icon-container">
              <HambugerIcon
                onClick={() => {
                  setIsMenuShown(true);
                }}></HambugerIcon>
            </div>
          )}
          {!isMobile && (
            <TrustedDevLogo
              className="state-transition pointer no-tap-highlight ms-4"
              onClick={() => navigate(routes.home)}
            />
          )}
        </div>
        <div className="page-title">
          {getNavigationPaths()
            .find(
              (path) =>
                path.defaultNavigatedPath === location.pathname ||
                (path.primaryPath &&
                  location.pathname.includes(path.primaryPath))
            )
            ?.text.toUpperCase()}
        </div>
        {!isMobile && (
          <>
            <div className="top-navigation__right">
              <div className="top-navigation__toggle">
                <AiIcon />
                <ToggleSwitch
                  active={isAIActive}
                  onToggle={toggle}
                  className="ai-switch no-tap-highlight"
                  label="AI"
                />
              </div>
              {isUserDataFetched ? (
                <HeaderPopover.Wrapper>
                  <div className="top-navigation__profile">
                    <Avatar
                      src={userData?.avatar}
                      alt="User Avatar"
                      backgroundColorVar="--color-suez-canal"
                    />
                    {!isMobile &&
                      Boolean(
                        userData?.first_name || userData?.last_name
                      ) && (
                        <span>{`${userData?.first_name} ${userData?.last_name}`}</span>
                      )}
                  </div>
                </HeaderPopover.Wrapper>
              ) : (
                <div className="loading">Loading...</div>
              )}
            </div>
          </>
        )}
      </nav>
      <div
        onClick={() => {
          setIsMenuShown(false);
        }}
        className={classNames('full-navigation-backdrop', {
          'side-menu-active': isMenuShown
        })}>
        <div
          className="side-navigation"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {isMobile && (
            <>
              <TrustedDevLogo
                className="my-5 state-transition pointer no-tap-highlight"
                onClick={() => navigate(routes.home)}
              />
              <div className="p-0.5 rounded-full border-1 border-td-brig">
                <Avatar
                  className="avatar-container"
                  src={userData?.avatar}
                  alt="User Avatar"
                  width={7.35}
                  height={7.35}
                />
              </div>
              <p className="mt-3 profile-name">{`${userData?.first_name} ${userData?.last_name}`}</p>
            </>
          )}
          <div className="my-auto">
            {getNavigationPaths().map(
              ({ text, defaultNavigatedPath }, index) => (
                <Link
                  key={index}
                  to={defaultNavigatedPath}
                  className={classNames('button', 'custom-button', {
                    active: location.pathname === defaultNavigatedPath
                  })}>
                  {text.toUpperCase()}
                </Link>
              )
            )}
            {isMobile && (
              <>
                <Link
                  to={routes.account}
                  className={classNames('button', 'custom-button', {
                    active: location.pathname === routes.account
                  })}>
                  SETTINGS
                </Link>
                <button
                  onClick={handleLogout}
                  className="button custom-button">
                  LOGOUT
                </button>
              </>
            )}
          </div>
          <hr className="divider" />
          {isMobile && (
            <div className="gap-3 ai-switch-group d-flex align-items-center">
              <p className="ai-label">AI Support</p>
              <AiIcon />
              <ToggleSwitch
                active={isAIActive}
                onToggle={toggle}
                className="ai-switch no-tap-highlight"
                label="AI"
              />
            </div>
          )}
        </div>
      </div>
      <AIModal
        isOpen={isAIModalVisible}
        onClose={() => setIsAIModalVisible(false)}
      />
    </>
  );
};

export default Navigation;
