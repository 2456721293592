import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { copyTextToClipboard } from '../../../utils/common';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { INTERVIEW_STATUSES } from '../../../constants/common';
import { usePermissionChecker } from '@src/components/shared/PermissionChecker';

export default function InterviewMenu({
  data,
  openInterviewModal,
  openInterviewReschedulingModal,
  openInterviewCancellationModal
}) {
  const {
    interviewLink,
    status,
    isDisabledMeetingLaunch,
    isHiddenMeetingLaunch
  } = data;
  const hasWritePermission = usePermissionChecker([
    'dashboard.interview.write'
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyLink = () => {
    copyTextToClipboard(window.location.host + interviewLink);
    toast.success('Copied to clipboard');
    handleClose();
  };

  const handleOpenInterviewModal = () => {
    openInterviewModal();
    handleClose();
  };

  const handleOpenInterviewReschedulingModal = () => {
    openInterviewReschedulingModal();
    handleClose();
  };

  const handleOpenInterviewCancellationModal = () => {
    openInterviewCancellationModal();
    handleClose();
  };

  const isInterviewReady =
    (status === INTERVIEW_STATUSES.SCHEDULED ||
      status === INTERVIEW_STATUSES.RESCHEDULED) &&
    !isDisabledMeetingLaunch &&
    !isHiddenMeetingLaunch;

  const isInterviewed =
    status === INTERVIEW_STATUSES.INTERVIEWED ||
    status === INTERVIEW_STATUSES.VOTED_UP ||
    status === INTERVIEW_STATUSES.VOTED_DOWN;

  const isCancelled = status === INTERVIEW_STATUSES.CANCELLED;

  const isDropdownMenuVisible = hasWritePermission || isInterviewed;

  if (!isDropdownMenuVisible) {
    return null;
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon sx={{ fill: '#509ec6' }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {isInterviewReady && (
          <MenuItem
            component={Link}
            to={interviewLink}
            target="_blank"
            onClick={handleClose}>
            Launch Meeting
          </MenuItem>
        )}
        {isInterviewReady && (
          <MenuItem onClick={copyLink}>Copy Link</MenuItem>
        )}
        {!isInterviewed ? (
          [
            <MenuItem
              onClick={handleOpenInterviewReschedulingModal}
              key="reschedule">
              Reschedule
            </MenuItem>,
            !isCancelled && (
              <MenuItem
                onClick={handleOpenInterviewCancellationModal}
                key="cancel-interview">
                Cancel Interview
              </MenuItem>
            )
          ]
        ) : (
          <MenuItem onClick={handleOpenInterviewModal}>
            Interview Data
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
