import React, { useState } from 'react';
import classNames from 'classnames';
import { endOfMonth, format as formatDate, startOfMonth } from 'date-fns';

import { useIfMobile } from '../../utils/responsive';
import InvoicesTable from '../../components/budget-management/InvoicesTable/InvoicesTable';
import BottomPanelHeader from '../../components/budget-management/BottomPanelHeader/BottomPanelHeader';
import BottomPanelContentMobile from '../../components/budget-management/BottomPanelContent/BottomPanelContentMobile';
import BottomPanelContent from '../../components/budget-management/BottomPanelContent/BottomPanelContent';
import LoadingOverlay from '../../components/budget-management/LoadingOverlay/LoadingOverlay';
import {
  useGetInvoicesQuery,
  useGetInvoiceStatQuery,
  usePayInvoicesMutation
} from '../../queries/Invoice';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import styles from './BudgetManagement.module.scss';

const BudgetManagement = () => {
  const isMobile = useIfMobile();
  const [dateRange, setDateRange] = useState({
    fromDate: startOfMonth(new Date()).getTime(),
    toDate: endOfMonth(new Date()).getTime()
  });

  const getInvoicesQuery = useGetInvoicesQuery({
    due_date: {
      gte: formatDate(dateRange.fromDate, 'dd-MM-yyyy'),
      lte: formatDate(dateRange.toDate, 'dd-MM-yyyy')
    }
  });
  const getInvoiceStatQuery = useGetInvoiceStatQuery({});
  const payInvoicesMutation = usePayInvoicesMutation();

  const invoices = getInvoicesQuery.data || [];
  const isLoading =
    getInvoicesQuery.isFetching ||
    payInvoicesMutation.isLoading ||
    getInvoiceStatQuery.isLoading;

  if (getInvoicesQuery.isError) return null;

  const handlePayInvoices = (invoices) => {
    payInvoicesMutation.mutate(invoices);
  };

  return (
    <>
      <LoadingOverlay open={isLoading} />
      {isMobile ? (
        <Mobile
          data={invoices}
          handlePayInvoices={handlePayInvoices}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      ) : (
        <Desktop
          data={invoices}
          handlePayInvoices={handlePayInvoices}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      )}
    </>
  );
};

const Desktop = ({ data, handlePayInvoices, setDateRange, dateRange }) => {
  const [showBottomPanel, setShowBottomPanel] = useLocalStorage(
    'showButtomPanel',
    true
  );

  return (
    <div className={styles.budgetManagement}>
      <div className={styles.tableSection}>
        <InvoicesTable
          data={data}
          handlePayInvoices={handlePayInvoices}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>

      <div onClick={() => setShowBottomPanel(!showBottomPanel)}>
        <BottomPanelHeader />
      </div>

      <div
        className={classNames(styles.bottomPanelSection, {
          [styles.bottomPanelSectionShow]: showBottomPanel
        })}>
        <BottomPanelContent />
      </div>
    </div>
  );
};

const MOBILE_TAB = {
  BUDGET: 'BUDGET',
  CHART: 'CHART'
};

const Mobile = ({ data, handlePayInvoices, setDateRange, dateRange }) => {
  const [activeTab, setActiveTab] = useState(MOBILE_TAB.CHART);

  const renderActiveTAb = () => {
    switch (activeTab) {
      case MOBILE_TAB.BUDGET:
        return (
          <InvoicesTable
            data={data}
            setDateRange={setDateRange}
            dateRange={dateRange}
            handlePayInvoices={handlePayInvoices}
          />
        );
      case MOBILE_TAB.CHART:
        return (
          <>
            <BottomPanelContentMobile />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.budgetManagement}>
      <div
        className={classNames(styles.selectedTabSection, {
          [styles.selectedTab1]: activeTab === MOBILE_TAB.CHART
        })}>
        {renderActiveTAb()}
      </div>
      <div className={styles.bottomTab}>
        <button
          className={classNames(styles.tabBtn, {
            [styles.tabBtnActive]: activeTab === MOBILE_TAB.BUDGET
          })}
          onClick={() => setActiveTab(MOBILE_TAB.BUDGET)}>
          {MOBILE_TAB.BUDGET}
        </button>
        <button
          className={classNames(styles.tabBtn, {
            [styles.tabBtnActive]: activeTab === MOBILE_TAB.CHART
          })}
          onClick={() => setActiveTab(MOBILE_TAB.CHART)}>
          {MOBILE_TAB.CHART}
        </button>
      </div>
    </div>
  );
};

export default BudgetManagement;
