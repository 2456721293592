import './UnderlinedInputField.css';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as EyeIcon } from '../../../../assets/images/eye-icon.svg';
import { ReactComponent as EyeHideIcon } from '../../../../assets/images/eye-hide-icon.svg';
import 'react-phone-input-2/lib/style.css';

export const UnderlinedTelephoneField = ({
  field,
  form: { errors, touched },
  form,
  label,
  containerClassName,
  minHeight = 48,
  ...meta
}) => {
  const { name, value, onBlur } = field;
  return (
    <div
      className={
        'underlined-input-field underlined-telephone-field' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {!!label && <span className="label">{label}</span>}
      <PhoneInput
        disableCountryCode={true}
        onlyCountries={['us']}
        country={'us'}
        showDropdown={false}
        placeholder="(123) 456-7890"
        disableSearchIcon
        inputProps={{
          name,
          type: meta.type,
          style: { minHeight: `${minHeight}px` }
        }}
        value={value}
        onChange={(phone) => form.setFieldValue([name], phone)}
        className={`test state-transition${
          touched[field.name] && !!errors[field.name]
            ? ' invalid'
            : touched[field.name] && errors[field.name] === undefined
            ? ' valid'
            : ''
        }`}
        onBlur={onBlur}
        {...meta}
      />
      <div className="error-msg">
        {touched[field.name] && errors[field.name]}
      </div>
    </div>
  );
};

UnderlinedTelephoneField.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  minHeight: PropTypes.number
};

const UnderlinedInputField = ({
  field,
  form: { errors, touched },
  label,
  containerClassName,
  minHeight = 48,
  ...meta
}) => (
  <div
    className={
      'underlined-input-field' +
      (containerClassName ? ` ${containerClassName}` : '')
    }>
    {!!label && <span className="label">{label}</span>}
    <input
      className={`state-transition${
        touched[field.name] && !!errors[field.name]
          ? ' invalid'
          : touched[field.name] && errors[field.name] === undefined
          ? ' valid'
          : ''
      }`}
      style={{ minHeight: `${minHeight}px` }}
      {...field}
      {...meta}
    />
    <div className="error-msg">
      {touched[field.name] && errors[field.name]}
    </div>
  </div>
);

UnderlinedInputField.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  minHeight: PropTypes.number
};

export const UnderlinedPasswordInputField = ({
  field,
  form: { errors, touched },
  label,
  containerClassName,
  minHeight = 48,
  ...meta
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  return (
    <div
      className={
        'underlined-input-field' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {!!label && <span className="label">{label}</span>}
      <div className="d-flex align-items-center password-field-container">
        <input
          className={`state-transition${
            touched[field.name] && !!errors[field.name]
              ? ' invalid'
              : touched[field.name] && errors[field.name] === undefined
              ? ' valid'
              : ''
          }`}
          style={{ minHeight: `${minHeight}px` }}
          {...field}
          {...meta}
          type={isPasswordVisible ? 'text' : 'password'}
        />
        <div
          className="visibility-icon"
          onClick={togglePasswordVisibility}>
          {isPasswordVisible ? (
            <EyeIcon className="state-transition pointer" />
          ) : (
            <EyeHideIcon className="state-transition pointer" />
          )}
        </div>
      </div>
      <div className="error-msg">
        {touched[field.name] && errors[field.name]}
      </div>
    </div>
  );
};

UnderlinedPasswordInputField.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  minHeight: PropTypes.number
};

export default connect(UnderlinedInputField);
