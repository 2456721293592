import { Flip } from 'react-toastify';

const config = {
  api: {
    baseUrl: import.meta.env.VITE_BASE_URL,
    encryptKey: import.meta.env.VITE_ENCRYPT_KEY
  },
  routePrefix: {
    recruiter: 'recruiter/api',
    candidate: 'candidate/api',
    admin: 'admin/api'
  },
  pagePermissions: {
    dashboard: 'Dashboard',
    project_management: 'Project Management',
    budget_management: 'Budget Management',
    compliance_management: 'Compliance Management'
  },
  candidate: {
    profileStatus: {
      UNMATCHED: 0,
      MATCHED: 1,
      VOTED_UP: 2,
      VOTED_DOWN: 3,
      INTERVIEWED: 4,
      HIRED: 6,
      REJECTED: 7
    }
  },
  projectStatus: {
    ON_TRACK: {
      text: 'On track',
      value: 'on_track'
    },
    AT_RISK: {
      text: 'At risk',
      value: 'at_risk'
    },
    BEHIND_SCHEDULE: {
      text: 'Behind schedule',
      value: 'behind_schedule'
    },
    COMPLETE: {
      text: 'Completed',
      value: 'complete'
    }
  },
  projectTimelineType: {
    FULL: 'full',
    MONTHLY: 'monthly',
    WEEKLY: 'weekly'
  },
  common: {
    stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
    tdWebsiteUrl: 'https://dcg.dev/', //process.env.REACT_APP_TD_WEBSITE_URL,
    isQueryDevToolEnabled: import.meta.env.VITE_QUERY_DEV_TOOL === 'true'
  },
  query: {
    staleTime: 10000, // 10s
    cacheTime: 86400000 // 24h
  },
  toast: {
    position: 'bottom-right',
    autoClose: 3000,
    newestOnTop: true,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: true,
    pauseOnHover: true,
    transition: Flip
  },
  calendly: {
    url: 'https://calendly.com/ryandev'
  }
};

export default config;
