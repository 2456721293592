export const ACTIVE_TAB = {
  DETAILS: 'DETAILS',
  METRICS: 'METRICS',
  ACTIVITY_FEED: 'ACTIVITY_FEED',
  CAMPAIGN_NOTES: 'CAMPAIGN_NOTES'
};

export const ACTIVITY_FEED_TAB = {
  FEED: 'FEED',
  HISTORY: 'HISTORY'
};

export const RATING = {
  GOOD: 1,
  NORMAL: 2,
  BAD: 3
};
