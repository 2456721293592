import React from 'react';
import { useFormContext } from 'react-hook-form';

import './ChangePassword.scss';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import OutlinedInputWithLabel from '../OutlinedInputWithLabel/OutlinedInputWithLabel';
import TwoStepVerificationForm from '../../forms/account/TwoStepVerification/TwoStepVerificationForm';
import Button from '../../shared/Button/Button';

const ChangePassword = ({
  isChangePassword = true,
  phone,
  onSubmitVerification,
  onResendVerification,
  onSubmit
}) => {
  const { register, handleSubmit } = useFormContext();

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  return (
    <SectionWrapper title={'Change Password'}>
      {!isChangePassword ? (
        <TwoStepVerificationForm
          submitLabel="CONFIRM"
          phone={phone}
          onSubmit={onSubmitVerification}
          onResend={onResendVerification}
          submitBackgroundColorVar="--color-brig"
        />
      ) : (
        <form className="change-password" onSubmit={handleFormSubmit}>
          <OutlinedInputWithLabel
            label="New Password"
            name="password"
            type="password"
            register={register}
            required
          />
          <OutlinedInputWithLabel
            label="Confirm Password"
            name="repeatPassword"
            type="password"
            register={register}
            required
          />

          <div className="change-password__submit">
            <Button title="Change Password" type="submit" />
          </div>
        </form>
      )}
    </SectionWrapper>
  );
};

export default ChangePassword;
