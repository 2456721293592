import { useState, useEffect } from 'react';
import Modal from '../../../components/shared/Modal';
import Button from '../../../components/shared/Button/Button';
import Input from '../../../components/shared/Input';
import './styles.scss';

const UpdateTemplateModal = ({
  onUpdate,
  onCancel,
  isOpen,
  templateName
}) => {
  const [templateNameState, setTemplateNameState] = useState(templateName);

  useEffect(() => {
    if (templateNameState !== templateName) {
      setTemplateNameState(templateName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);

  return (
    <Modal
      isOpen={isOpen}
      className="update-template-modal"
      width="18.75rem">
      <Input
        label="Update your template name"
        placeholder="Template Name"
        value={templateNameState}
        onChange={(e) => setTemplateNameState(e.target.value)}
      />
      <div className="actions-container">
        <Button
          onClick={() => {
            onUpdate(templateNameState);
          }}
          disabled={!templateName}>
          UPDATE
        </Button>
        <Button onClick={onCancel}>CANCEL</Button>
      </div>
    </Modal>
  );
};

export default UpdateTemplateModal;
