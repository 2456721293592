import './styles.scss';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as XIcon } from '../../../assets/images/x-icon.svg';
import { ReactComponent as OpenEye } from '../../../assets/images/open-eye.svg';
import { ReactComponent as ClosedEye } from '../../../assets/images/closed-eye.svg';
import config from '../../../config';
import { extractCandidatesFromTasks } from '../../../utils/common';
import CustomCheckbox from '@src/components/shared/CustomCheckbox';

const SearchBox = ({
  closeSearchBox,
  filterOutValues,
  filterOuts,
  milestones
}) => {
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);

  useEffect(() => {
    setIsAnimationRunning(true);
  }, []);

  const handleClose = () => {
    setIsAnimationRunning(false);
    setTimeout(() => {
      closeSearchBox && closeSearchBox();
    }, 200);
  };

  const candidates =
    extractCandidatesFromTasks(
      milestones?.flatMap((milestone) => milestone.tasks)
    ) ?? [];

  return (
    <div
      className={classNames('search-box-container', {
        active: isAnimationRunning
      })}
      onClick={handleClose}>
      <div className="search-box" onClick={(e) => e.stopPropagation()}>
        <div className="search-bar">
          <span className="search-label">Search</span>
          <input
            className="search-input"
            placeholder="Search name, milestone, task"
            onChange={(e) => {
              filterOutValues({
                searchValue: e.target.value
              });
            }}
            value={filterOuts.searchValue}></input>
          <span className="close-button">
            <XIcon onClick={handleClose}></XIcon>
          </span>
        </div>
        <div className="filter-box-container">
          <div className="filter-box">
            <div className="project-filter-container">
              <div>
                <p className="filter-title">Status</p>
                <div className="mt-2 checkboxes">
                  <div className="checkbox-container">
                    <CustomCheckbox
                      type="checkbox"
                      id="status-all"
                      checked={filterOuts?.statuses?.length === 0}
                      onChange={() => {
                        if (filterOuts?.statuses?.length > 0) {
                          filterOutValues({
                            statuses: []
                          });
                        } else {
                          filterOutValues({
                            statuses: Object.values(
                              config.projectStatus
                            ).map((status) => status.value)
                          });
                        }
                      }}
                      label="All"
                    />
                  </div>
                  {Object.values(config.projectStatus).map(
                    ({ text, value }) => (
                      <div className="checkbox-container" key={value}>
                        <CustomCheckbox
                          id={`status-${value}`}
                          checked={!filterOuts?.statuses?.includes(value)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              filterOutValues({
                                statuses: filterOuts?.statuses.filter(
                                  (status) => status !== value
                                )
                              });
                            } else {
                              filterOutValues({
                                statuses: [
                                  ...(filterOuts?.statuses ?? []),
                                  value
                                ]
                              });
                            }
                          }}
                          label={text}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="mt-4">
                <p className="filter-title">
                  Show tasks/milestones assigned to
                </p>
                <div className="mt-2 checkboxes">
                  <div className="checkbox-container">
                    <CustomCheckbox
                      id="milestone-all"
                      checked={filterOuts?.assignees?.length === 0}
                      onChange={() => {
                        if (filterOuts?.assignees?.length > 0) {
                          filterOutValues({
                            assignees: []
                          });
                        } else {
                          filterOutValues({
                            assignees: candidates.map(
                              (candidate) => candidate.candidate_id
                            )
                          });
                        }
                      }}
                      label="All"
                    />
                  </div>
                  {candidates?.map(({ full_name, candidate_id }) => (
                    <div className="checkbox-container" key={candidate_id}>
                      <CustomCheckbox
                        id={candidate_id}
                        checked={
                          !filterOuts?.assignees?.includes(candidate_id)
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            filterOutValues({
                              assignees: filterOuts?.assignees.filter(
                                (assignee) => assignee !== candidate_id
                              )
                            });
                          } else {
                            filterOutValues({
                              assignees: [
                                ...(filterOuts?.assignees ?? []),
                                candidate_id
                              ]
                            });
                          }
                        }}
                      />
                      <label htmlFor={candidate_id}>{full_name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="timeline-filter-container">
              <div className="header">
                <span
                  onClick={() => {
                    filterOutValues({
                      milestones: []
                    });
                  }}
                  className="show-all-button">
                  Show All
                </span>
              </div>
              <div className="body">
                {milestones?.map((milestone) => (
                  <div
                    key={milestone.milestone_id}
                    className="milestone"
                    onClick={() => {
                      const updatedFilteredOutMilestones =
                        filterOuts.milestones.includes(
                          milestone.milestone_id
                        )
                          ? filterOuts.milestones.filter(
                              (milestoneId) =>
                                milestone.milestone_id !== milestoneId
                            )
                          : [
                              ...filterOuts.milestones,
                              milestone.milestone_id
                            ];
                      filterOutValues({
                        milestones: updatedFilteredOutMilestones
                      });
                    }}>
                    <span className="eye-icon-container">
                      {filterOuts.milestones.includes(
                        milestone.milestone_id
                      ) ? (
                        <ClosedEye></ClosedEye>
                      ) : (
                        <OpenEye></OpenEye>
                      )}
                    </span>
                    <span>{`Milestone ${milestone.milestone_order}: ${milestone.milestone_name}`}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
