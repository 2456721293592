import * as Yup from 'yup';
import { regex } from '../utils/regex';

export const registerValidationRules = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone_number: Yup.string().required('Phone number is required'),
  company_name: Yup.string().required('Company name is required'),
  company_url: Yup.string().matches(regex.url, {
    message: 'Invalid format of Company URL'
  }),
  job_title: Yup.string().required('Job title is required'),
  email_id: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  accept: Yup.boolean().isTrue(
    'Please read and accept Terms of Service and Privacy Policy'
  )
});

export const questionnaireValidator = (values) => {
  const errors = {};

  Object.values(values).forEach((question) => {
    let isError = true;

    for (const [, option] of Object.entries(question.options)) {
      if (option.checked) {
        isError = false;
        break;
      }
    }

    if (isError) {
      errors[question.id] = isError;
    }
  });

  return errors;
};
