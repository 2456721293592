import {
  getDateRangeOfWeek,
  getStartAndEndTimeOfDay
} from './../utils/dateTime';

export const REQUIREMENT_TYPE = {
  ONE_WAY: {
    value: 'ONE_WAY',
    text: 'One Way Upload'
  },
  TWO_WAY: {
    value: 'TWO_WAY',
    text: 'Two Ways Upload'
  },
  URL: {
    value: 'URL',
    text: 'URL'
  }
};

export const UPLOAD_TYPE = {
  MYSELF: {
    value: 1,
    text: 'Myself'
  },
  ADMIN: {
    value: 0,
    text: 'Admin'
  }
};

export const TWO_WAY_UPLOAD_TYPE = {
  MYSELF: {
    value: 1,
    text: 'Myself First'
  },
  ADMIN: {
    value: 0,
    text: 'Admin First'
  }
};

export const ROLE_TYPES = {
  MYSELF: {
    value: 1,
    text: 'Myself'
  },
  ADMIN: {
    value: 0,
    text: 'Admin'
  }
};

export const PIE_CHART_COLORS = [
  '#509EC6',
  '#DDE8EE',
  '#FF7BAC',
  '#206CB9',
  '#A349D7',
  '#FF7DED',
  '#1491B7',
  '#FFD600',
  '#116468',
  '#E03F79',
  '#5EC2DD',
  '#15B9B8'
];

export const HORIZONTAL_BAR_COLORS = ['#509ec6', '#5ec2dd', '#a349d7'];

export const INTERVIEW_STATUSES = {
  AWAITING: 'AWAITING',
  SLOT_REQUESTED: 'SLOT_REQUESTED',
  NO_SHOW: 'NO_SHOW',
  SCHEDULED: 'SCHEDULED',
  RESCHEDULED: 'RESCHEDULED',
  INTERVIEWED: 'INTERVIEWED',
  VOTED_UP: 'VOTED_UP',
  VOTED_DOWN: 'VOTED_DOWN',
  CANCELLED: 'CANCELLED'
};

export const INTERVIEW_DATE_FILTER_OPTIONS = {
  TODAY: {
    value: 'today',
    label: 'Today',
    getDateRange: () => {
      const today = new Date();
      const { startDate, endDate } = getStartAndEndTimeOfDay(today);
      return { startDate, endDate };
    }
  },
  THIS_WEEK: {
    value: 'thisWeek',
    label: 'This week',
    getDateRange: () => {
      const { startDate, endDate } = getDateRangeOfWeek(new Date());
      return {
        startDate: startDate,
        endDate: endDate
      };
    }
  },
  ALL_TIME: {
    value: 'allTime',
    label: 'All',
    getDateRange: () => ({})
  }
};

export const INTERVIEW_SENTIMENTS = {
  GOOD: 'GOOD',
  NORMAL: 'NORMAL',
  BAD: 'BAD'
};

export const HOME_TABS = {
  CANDIDATES: 'CANDIDATES',
  ACTIVITY_FEED: 'ACTIVITY_FEED'
};

export const ACTIVITY_FEED_TABS = {
  FEED: 'FEED',
  HISTORY: 'HISTORY'
};
