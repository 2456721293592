import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({
  title,
  children,
  className,
  onClick = null,
  backgroundColorVar = '--color-brig',
  titleColorVar = '--color-white',
  disabled = false,
  icon = null,
  width = 'unset',
  margin = '0',
  ...meta
}) => {
  const Icon = icon;

  return (
    <button
      className={
        'button state-transition flex gap-2' +
        (className ? ' ' + className : '')
      }
      style={{
        color: `var(${titleColorVar})`,
        backgroundColor: `var(${backgroundColorVar})`,
        margin,
        width
      }}
      onClick={onClick}
      disabled={disabled}
      {...meta}>
      {Icon && <Icon />}
      {children || title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  backgroundColorVar: PropTypes.string,
  titleColorVar: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  width: PropTypes.number,
  margin: PropTypes.string
};

export default Button;
