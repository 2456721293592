import './styles.scss';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import format from 'date-fns/format';

import { getDaysBetweenDates } from '../../../utils/dateTimeHelper';
import config from '../../../config';
import Avatar from '../../shared/Avatar/Avatar';

const TaskInfoPopover = ({ referenceElement, task, milestoneOrder }) => {
  const {
    task_order,
    task_name,
    candidates,
    start_date,
    end_date,
    status
  } = task;

  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement
  );

  const startDate = new Date(start_date ?? Date.now());
  const endDate = new Date(end_date ?? Date.now());

  return ReactDOM.createPortal(
    <div
      ref={setPopperElement}
      className="time-frame-bar-popover"
      style={styles.popper}
      {...attributes.popper}>
      <div className="headline">
        <p className="milestone-name">
          <b>
            Task {task_order} {task_name}
          </b>
        </p>
        <p>in Milestone {milestoneOrder}</p>
      </div>
      <div className="milestone-date-range">
        <p>
          <b>{`${format(
            startDate,
            startDate.getFullYear() !== endDate.getFullYear()
              ? 'MMMM d, yyyy'
              : 'MMMM d'
          )} - ${format(endDate, 'MMMM d, yyyy')}`}</b>
        </p>
      </div>
      <div>
        <div>
          <span className="title">
            <b>Duration:</b>
          </span>
          <span>{`${getDaysBetweenDates(
            end_date,
            start_date
          )} days`}</span>
        </div>
        <div className="status-container">
          <span className="title">
            <b>Status:</b>
          </span>
          <span className="status">
            <i className={classNames('legend-icon', status)}></i>
            <span>{config.projectStatus[status.toUpperCase()]?.text}</span>
          </span>
        </div>
      </div>
      {candidates?.length > 0 && (
        <div>
          <div>
            <p>
              <b>Resources:</b>
            </p>
          </div>
          <div className="resources">
            {candidates.map((candidate) => (
              <div key={candidate.candidate_id} className="candidate">
                <Avatar src={candidate.avatar} width={1.5} height={1.5} />
                <span>{candidate.full_name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>,
    document.body
  );
};

export default TaskInfoPopover;
