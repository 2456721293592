import React, { useRef } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import './VideoPlayerThumbnail.scss';

const VideoPlayerThumbnail = ({ toggleMedia, requestFullscreen }) => {
  const fullscreenRef = useRef(null);

  return (
    <div ref={fullscreenRef} className="video-player-thumbnail">
      <div
        onClick={() => {
          toggleMedia();
          requestFullscreen(fullscreenRef.current);
        }}
        className="video-thumbnail"
      />
      <div className="play-icon-wrapper">
        <PlayArrowRoundedIcon
          onClick={() => {
            toggleMedia();
            requestFullscreen(fullscreenRef.current);
          }}
          className="play-icon"
        />
      </div>
    </div>
  );
};

export default VideoPlayerThumbnail;
