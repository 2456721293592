import {
  Tooltip as OriginalTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@src/components/ui/tooltip';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const Tooltip = ({ children, content }) => {
  return (
    <TooltipProvider>
      <OriginalTooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipPrimitive.Portal>
          <TooltipContent className="bg-white">{content}</TooltipContent>
        </TooltipPrimitive.Portal>
      </OriginalTooltip>
    </TooltipProvider>
  );
};

export default Tooltip;
