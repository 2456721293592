import { createSelector } from 'reselect';

const campaignSlice = (state) => state.CampaignSlice;

export const selectCampaignFilter = createSelector(
  campaignSlice,
  (state) => {
    return state.campaignFilter;
  }
);
