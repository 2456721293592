import React, { useState } from 'react';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth
} from 'date-fns';

import Select from '../Select/Select';
import DatePicker from '../DatePicker/DatePicker';
import Button from '../../shared/Button/Button';
import styles from './HeaderPanel.module.scss';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const HeaderPanel = ({
  onSort,
  onFilter,
  onPayClick,
  dateRange,
  setDateRange
}) => {
  const [sortBy, setSortBy] = useState('');
  const [filter, setFilter] = useState('');
  const [dateRangeOption, setDateRangeOption] = useState('custom');
  const { fromDate, toDate } = dateRange;

  const handleSort = (value) => {
    setSortBy(value);
    onSort(value);
  };

  const handleFilter = (value) => {
    setFilter(value);
    onFilter(value);
  };

  const handleDateRangeOptionChange = (value) => {
    setDateRangeOption(value);
    switch (value) {
      case 'thisWeek': {
        setDateRange({
          fromDate: startOfWeek(new Date()).getTime(),
          toDate: endOfWeek(new Date()).getTime()
        });
        break;
      }
      case 'thisMonth': {
        setDateRange({
          fromDate: startOfMonth(new Date()).getTime(),
          toDate: endOfMonth(new Date()).getTime()
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFromDateChange = (date) => {
    setDateRangeOption('custom');
    setDateRange((prev) => ({
      ...prev,
      fromDate: date.getTime()
    }));
  };

  const handleToDateChange = (date) => {
    setDateRangeOption('custom');
    setDateRange((prev) => ({
      ...prev,
      toDate: date.getTime()
    }));
  };

  return (
    <div className={styles.headerPanel}>
      <div className={styles.headerPanelContainer}>
        <div className={styles.headerPanelDatePicker}>
          <DatePicker
            label="From"
            value={fromDate}
            onChange={handleFromDateChange}
          />
          <DatePicker
            label="To"
            value={toDate}
            onChange={handleToDateChange}
          />
          <Select
            options={[
              { label: 'This Week', value: 'thisWeek' },
              { label: 'This Month', value: 'thisMonth' },
              { label: 'Custom', value: 'custom' }
            ]}
            value={dateRangeOption}
            onChange={handleDateRangeOptionChange}
          />
        </div>
        <div className={styles.headerPanelFilterOptions}>
          <Select
            label="Status"
            options={[
              { label: 'All', value: 'All' },
              { label: 'Paid', value: 'Paid' },
              { label: 'Unpaid', value: 'Unpaid' },
              { label: 'Past Due', value: 'Past Due' }
            ]}
            value={filter}
            onChange={handleFilter}
          />
          <Select
            label="Sort by"
            options={[
              { label: 'Due Date', value: 'dueDate' },
              { label: 'Amount', value: 'amount' },
              { label: 'Invoice Name', value: 'invoiceName' }
            ]}
            value={sortBy}
            onChange={handleSort}
          />
        </div>
        <div className={styles.headerPanelButtons}>
          <PermissionChecker
            permissions={['budget_management.common.write']}>
            <Button title="Pay" onClick={onPayClick} />
          </PermissionChecker>
        </div>
      </div>
    </div>
  );
};

export default HeaderPanel;
