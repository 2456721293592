import Scrollbars from 'react-custom-scrollbars-2';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../shared/CustomScrollBar/CustomScrollBar';
import LoadingOverlay from '@src/components/budget-management/LoadingOverlay/LoadingOverlay';
import { useGetCampaignFeedList } from '@src/queries/Campaign';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectActivityFeedFilter } from '@src/store/selectors/Dashboard';

const FeedList = () => {
  const { data, isLoading } = useGetCampaignFeedList();

  const activityFeedFilter = useSelector(selectActivityFeedFilter);

  const dateRange = activityFeedFilter.dateRange;
  const campaignId = activityFeedFilter.campaignId;
  const candidateId = activityFeedFilter.candidateId;

  const filteredList = (() => {
    if (!data) return [];

    let filteredList = [...data];

    if (dateRange.from && dateRange.to) {
      const startDate = dayjs(dateRange.from, 'YYYY-MM-DD');
      const endDate = dayjs(dateRange.to, 'YYYY-MM-DD');

      filteredList = filteredList.filter((feed) => {
        const createdDate = dayjs(feed.created_datetime);

        return (
          createdDate.isAfter(startDate) && createdDate.isBefore(endDate)
        );
      });
    }

    if (campaignId) {
      filteredList = filteredList.filter(
        (feed) => feed.campaign_id === campaignId
      );
    }

    if (candidateId) {
      filteredList = filteredList.filter(
        (feed) => feed.candidate_id === candidateId
      );
    }

    return filteredList;
  })();

  return (
    <Scrollbars
      className="-mb-[12px]"
      renderThumbVertical={ScrollThumbVertical}
      renderTrackVertical={ScrollTrackVertical}
      renderThumbHorizontal={() => <div />}
      renderTrackHorizontal={() => <div />}>
      <div className="flex flex-col bg-white">
        <LoadingOverlay open={isLoading} />
        {filteredList.map((feed) => {
          const {
            id,
            campaign_name,
            description,
            candidate_avatar,
            created_datetime,
            candidate_full_name
          } = feed;
          return (
            <div key={id} className="flex gap-8 px-7 py-6 pr-12">
              <div className="w-full">
                <div className="flex gap-4 w-full items-center mb-5">
                  <img
                    src={candidate_avatar}
                    className="h-11 w-11 bg-[#88a6b8] rounded-full"
                  />
                  <p className="text-[17px] text-td-boyzone font-semibold">
                    {candidate_full_name}
                  </p>
                  <p className="ml-auto text-base bg-[#edf3f6] p-1 px-2 rounded-2xl">
                    {campaign_name}
                  </p>
                </div>
                <p className="mb-5 font-semibold text-base">
                  {description}
                </p>
                <p>
                  {dayjs(created_datetime).format('h:mm A MMM D, YYYY')}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Scrollbars>
  );
};

export default FeedList;
