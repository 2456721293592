import React, { useState, useRef, useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper
} from '@tanstack/react-table';
import { format } from 'date-fns';
import classnames from 'classnames';

import { useIfMobile } from '../../../utils/responsive';
import Button from '../../shared/Button/Button';
import Select from '../Select/Select';
import HeaderPanel from '../HeaderPanel/HeaderPanel';
import { ReactComponent as OutlinedCheckCircleIcon } from '../../../assets/images/outlined-check-circle-icon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/filter-icon.svg';
import styles from './InvoicesTable.module.scss';

const columnHelper = createColumnHelper();

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const useColumns = ({ handlePayInvoices }) => {
  const isMobile = useIfMobile();

  const columnsDesktop = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      )
    },
    columnHelper.accessor((row) => row.name, {
      id: 'invoiceName',
      header: 'Invoice Name',
      cell: ({ getValue, row }) => (
        <a href={row.original.pdf} target="_blank" rel="noreferrer">
          {getValue()}
        </a>
      )
    }),
    columnHelper.accessor((row) => row.amount, {
      id: 'amount',
      header: 'Amount',
      cell: (info) => currencyFormatter.format(info.getValue())
    }),
    columnHelper.accessor((row) => row.dueDate, {
      id: 'dueDate',
      header: 'Due Date',
      cell: (info) =>
        info.getValue() ? format(info.getValue(), 'MM/dd/yyyy') : '—'
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: 'Status',
      cell: (info) => info.getValue(),
      filterFn: 'equalsString'
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'actions',
      header: () => null,
      cell: ({ getValue, row }) => (
        <>
          {{
            Paid: <OutlinedCheckCircleIcon />,
            Unpaid: (
              <Button
                title="Pay Invoice"
                width="10rem"
                onClick={() => handlePayInvoices([row.original.raw])}
              />
            ),
            'Past Due': (
              <Button
                title="Pay Invoice"
                className={styles.btnPastDue}
                width="10rem"
                onClick={() => handlePayInvoices([row.original.raw])}
              />
            )
          }[getValue()] || null}
        </>
      )
    })
  ];

  const columnsMobile = [
    columnHelper.display({
      id: 'all',
      header: ({ table }) => (
        <div className={styles.mobileHeader}>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
          <Select
            options={[{ label: 'Action A', value: 'Action A' }]}
            fullWidth
            className={styles.mobileHeaderSelect}
          />
          <Button className={styles.mobileHeaderFilterButton}>
            <FilterIcon />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const rowData = row.original;

        return (
          <div className={styles.mobileCell}>
            <div className={styles.mobileCellHeader}>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler()
                }}
              />
              <a href={rowData.pdf} target="_blank" rel="noreferrer">
                {rowData.name}
              </a>
            </div>

            <div className={styles.mobileCellDetail}>
              <span>Amount</span>
              <span>{currencyFormatter.format(rowData.amount)}</span>
              <span>Due date</span>
              <span>
                {rowData.dueDate
                  ? format(rowData.dueDate, 'MM/dd/yyyy')
                  : '—'}
              </span>
              <span>Status</span>
              <span>{rowData.status}</span>
            </div>

            <div className={styles.mobileCellStatus}>
              {{
                Paid: (
                  <OutlinedCheckCircleIcon
                    className={styles.mobileCellStatusSuccessIcon}
                  />
                ),
                Unpaid: (
                  <Button
                    title="Pay Invoice"
                    width="10rem"
                    onClick={() => handlePayInvoices([rowData.raw])}
                  />
                ),
                'Past Due': (
                  <Button
                    title="Pay Invoice"
                    className={styles.btnPastDue}
                    width="10rem"
                    onClick={() => handlePayInvoices([rowData.raw])}
                  />
                )
              }[rowData.status] || null}
            </div>
          </div>
        );
      }
    })
  ];

  return isMobile ? columnsMobile : columnsDesktop;
};

const IndeterminateCheckbox = ({ indeterminate, ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...rest} />;
};

const InvoicesTable = ({
  data,
  handlePayInvoices,
  setDateRange,
  dateRange
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const isMobile = useIfMobile();
  const columns = useColumns({ handlePayInvoices });

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  const handlePayInBulk = () => {
    const selectedInvoices = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original.raw);
    handlePayInvoices(selectedInvoices);
  };

  const handleSort = (value) => {
    table.getColumn(value).toggleSorting('desc', false);
  };

  const handleFilter = (value) => {
    const valueToFilter = !value || value === 'All' ? undefined : value;
    table.getColumn('status').setFilterValue(valueToFilter);
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerMobile]: isMobile
      })}>
      <table>
        <thead>
          {!isMobile ? (
            <tr>
              <td colSpan={6}>
                <HeaderPanel
                  onSort={handleSort}
                  onFilter={handleFilter}
                  onPayClick={handlePayInBulk}
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                />
              </td>
            </tr>
          ) : null}

          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
