import config from '../../../../config';
import CandidateListItem from '../../CandidateListItem/CandidateListItem';
import PlaceholderListItem from '../../PlaceholderListItem';

const CandidateListItems = ({
  showData,
  onCandidatePreview,
  onFavorite,
  isLoading
}) => {
  if (isLoading) {
    return null;
  }

  return (
    <>
      {showData
        ?.filter(
          (candidate) =>
            candidate.profile_status !==
              config.candidate.profileStatus.VOTED_DOWN &&
            candidate.profile_status !==
              config.candidate.profileStatus.REJECTED &&
            candidate.profile_status !==
              config.candidate.profileStatus.MATCHED
        )
        .map((candidate) => (
          <CandidateListItem
            key={candidate?.candidate_profile_status_id}
            data={candidate}
            onClick={() => onCandidatePreview(candidate)}
            onFavorite={(event) => onFavorite(event, candidate)}
          />
        ))}
      <PlaceholderListItem numbListCars={showData?.length} />
    </>
  );
};

export default CandidateListItems;
