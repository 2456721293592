import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box
} from '@mui/material';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';

const CampaignExistingPaymentMethods = ({
  paymentMethods,
  onChange,
  selectedPaymentMethod
}) => (
  <FormControl component="fieldset">
    <RadioGroup
      aria-label="payment-method"
      name="payment-method"
      onChange={onChange}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%'
      }}>
      {paymentMethods.map((method) => (
        <Box
          border={1}
          borderRadius={2}
          borderColor="rgba(25, 118, 210, 0.5)"
          key={method.id}>
          <FormControlLabel
            sx={{ width: '100%' }}
            value={method.id}
            control={<Radio />}
            checked={selectedPaymentMethod?.id === method.id}
            label={
              <Box display="flex" alignItems="center">
                {method.type === 'card' ? (
                  <CreditCardRoundedIcon />
                ) : (
                  <AccountBalanceRoundedIcon />
                )}
                <span style={{ marginLeft: 8 }}>{method.name}</span>
              </Box>
            }
          />
        </Box>
      ))}
    </RadioGroup>
  </FormControl>
);

export default CampaignExistingPaymentMethods;
