import React, { useEffect, useState, useRef } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal';

const UnsavedChangeHandler = ({
  when,
  shouldBlockNavigation,
  message,
  onConfirm
}) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const beforeUnloadEventRef = useRef((e) => {
    (e || window.event).returnValue = message;
    return message;
  });

  const onCancel = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    onConfirm?.();
  };

  useEffect(() => {
    const currentBeforeUnloadEventRef = beforeUnloadEventRef.current;

    if (when) {
      setConfirmedNavigation(false);
      window.addEventListener(
        'beforeunload',
        currentBeforeUnloadEventRef,
        true
      );
    } else {
      window.removeEventListener(
        'beforeunload',
        currentBeforeUnloadEventRef,
        true
      );
    }

    return () => {
      window.removeEventListener(
        'beforeunload',
        currentBeforeUnloadEventRef,
        true
      );
    };
  }, [when]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        className="deletion-confirmation-modal"
        width="18.75rem">
        <div>
          <p className="header">INFORMATION</p>
        </div>
        <div className="message-container">{message}</div>
        <div className="actions-container">
          <Button onClick={handleConfirmNavigationClick}>CONFIRM</Button>
          <Button onClick={onCancel}>CANCEL</Button>
        </div>
      </Modal>
    </>
  );
};
export default UnsavedChangeHandler;
