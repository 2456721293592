import config from '../config';

export const extractCandidatesFromTasks = (tasks) =>
  tasks
    ?.flatMap((task) => task.candidates)
    .filter(
      (v, i, a) =>
        a.findIndex((v2) => v2.candidate_id === v.candidate_id) === i
    );

export const countCompletedMilestones = (milestones) =>
  milestones?.filter(
    (milestone) =>
      milestone.milestone_status === config.projectStatus.COMPLETE.value
  )?.length ?? 0;

export const countCompletedTasks = (milestones) =>
  milestones?.reduce(
    (result, milestone) =>
      result +
        milestone?.tasks?.filter(
          (task) => task.status === config.projectStatus.COMPLETE.value
        )?.length ?? 0,
    0
  ) ?? 0;

export const countUniqueCandidates = (data) =>
  [
    ...new Set(
      data
        ?.flatMap((milestone) => milestone.tasks)
        ?.flatMap((task) => task.candidates)
        .map((candidate) => candidate.candidate_id)
    )
  ]?.length ?? 0;

export const filterOutBasedOnConditions = (milestones, filterOuts) => {
  if (!milestones) return [];

  return milestones.reduce((filteredMilestones, milestone) => {
    const { milestone_id, milestone_name, milestone_status, tasks } =
      milestone;

    if (filterOuts.milestones.includes(milestone_id))
      return filteredMilestones;

    const matchesSearch =
      filterOuts.searchValue &&
      milestone_name?.includes(filterOuts.searchValue);
    const matchesStatus = !filterOuts.statuses.includes(milestone_status);
    const noAssigneesFilter = !filterOuts.assignees?.length;

    if (
      matchesSearch &&
      filterOuts.statuses?.length &&
      matchesStatus &&
      noAssigneesFilter
    ) {
      return [...filteredMilestones, milestone];
    }

    const filteredTasks = tasks?.filter((task) => {
      const taskMatchesStatus = !filterOuts.statuses.includes(task.status);
      const taskMatchesSearch = task.task_name.includes(
        filterOuts.searchValue
      );
      const candidateMatchesSearch = task.candidates?.some(
        (candidate) =>
          candidate.full_name.includes(filterOuts.searchValue) &&
          !filterOuts.assignees.includes(candidate.candidate_id)
      );

      return (
        taskMatchesStatus && (taskMatchesSearch || candidateMatchesSearch)
      );
    });

    if (
      (!filteredTasks?.length && tasks?.length > 0) ||
      (tasks?.length === 0 &&
        filterOuts.statuses?.length &&
        filterOuts.statuses.includes(milestone_status))
    ) {
      return filteredMilestones;
    }

    return [...filteredMilestones, { ...milestone, tasks: filteredTasks }];
  }, []);
};

export const getUrlParamsFromQueryString = (queryString) =>
  Object.fromEntries(new URLSearchParams(queryString));

export const extractUpdatePayloadFromRequirements = (requirements) => {
  const removedRequirementIds = requirements
    .filter(
      (item) =>
        item &&
        item.isDeleted &&
        item.id &&
        !String(item.id).includes('new_')
    )
    .map((item) => item.id);
  const updatedRequirements = requirements
    .filter((item) => !item.isDeleted)
    .map((item) => {
      if (
        (typeof item.id === 'string' && item.id?.includes('new_')) ||
        item.id === null
      ) {
        delete item.id;
      }
      return item;
    });
  const payload = {
    requirements: updatedRequirements,
    to_remove: removedRequirementIds ?? []
  };
  return payload;
};

export const showFullDateTime = (stringDate) => {
  const date = new Date(stringDate || new Date());
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const checkValidUrl = (url) => {
  // Regular expression for a valid URL with an optional protocol
  const urlPattern = /^(?:(?:https?|ftp):\/\/)?[^\s/$.?#].[^\s]*$/i;

  // Test the URL against the pattern
  return urlPattern.test(url);
};

export const copyTextToClipboard = (text) => {
  try {
    if (
      navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText
    ) {
      navigator.clipboard.writeText(text);
      return;
    }

    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  } catch (e) {
    console.error('Copy to clipboard did not work!', e);
  }
};

export const openInNewTab = (url) => {
  const linkElement = document.createElement('a');
  linkElement.href = url;
  linkElement.target = '_blank';
  linkElement.rel = 'noopener noreferrer';
  linkElement.click();
};

export const getFullName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return '—';
  }

  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
};
