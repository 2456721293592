import { Tooltip, Typography } from '@mui/material';

const PendingRequirementTooltip = ({
  pendingRequirements,
  totalRequirementCount,
  children
}) => {
  if (!pendingRequirements || pendingRequirements.length === 0) {
    return children;
  }

  return (
    <Tooltip
      title={
        <div>
          <Typography
            color="#2e9dd7"
            fontWeight="600"
            marginBottom="6px">{`Pending requirements (${pendingRequirements.length}/${totalRequirementCount})`}</Typography>
          {pendingRequirements.map(({ requirement }) => (
            <Typography key={requirement}>{requirement}</Typography>
          ))}
        </div>
      }
      placement="top">
      {children}
    </Tooltip>
  );
};

export default PendingRequirementTooltip;
