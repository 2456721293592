import { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';
import './styles.scss';
import { extractCandidatesFromTasks } from '../../../utils/common';
import { getDayMonthYear } from '../../../utils/dateTimeHelper';
import Avatar from '../../shared/Avatar/Avatar';
import { ReactComponent as XIcon } from '../../../assets/images/x-icon.svg';

const ProjectInfoModal = ({ onClose, projectDetails }) => {
  const [isOpened, setIsOpened] = useState(true);
  const modalContentElementRef = useRef();

  const {
    project_description,
    project_name,
    milestones,
    project_start_date,
    project_end_date
  } = projectDetails;

  const candidates =
    extractCandidatesFromTasks(
      milestones?.flatMap((milestone) => milestone.tasks)
    ) ?? [];

  useEffect(() => {
    if (!isOpened) {
      setTimeout(() => {
        onClose && onClose();
      }, 100);
    }
  }, [isOpened, onClose]);

  return (
    <AnimatePresence>
      {isOpened && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="project-info-modal"
          onClick={(e) => {
            if (!modalContentElementRef.current.contains(e.target)) {
              setIsOpened(false);
            }
          }}>
          <div className="modal-content" ref={modalContentElementRef}>
            <div className="modal-header">
              <p className="title">{project_name}</p>
              <XIcon onClick={() => setIsOpened(false)}></XIcon>
            </div>
            <div className="modal-body">
              <div className="basic-project-info horizontal-item">
                <div className="vertical-item">
                  <p className="section-headline">
                    <b>Project Objective</b>
                  </p>
                  <p>{project_description}</p>
                </div>
                <div className="vertical-item">
                  <p className="section-headline">
                    <b>Project Timeline</b>
                  </p>
                  <p>{`${getDayMonthYear(
                    project_start_date ?? Date.now()
                  )} - ${getDayMonthYear(
                    project_end_date ?? Date.now()
                  )}`}</p>
                </div>
              </div>
              <div className="milestones horizontal-item">
                <div>
                  <p className="section-headline">
                    <b>Project Milestones</b>
                  </p>
                </div>
                {milestones?.map(
                  ({
                    milestone_order,
                    milestone_id,
                    milestone_name,
                    milestone_start_date,
                    milestone_end_date
                  }) => (
                    <div className="milestone-info" key={milestone_id}>
                      <div className="milestone-name">
                        <p>
                          <b>Milestone {milestone_order}:</b>{' '}
                          {milestone_name}
                        </p>
                      </div>
                      <div>
                        <p>{`${format(
                          new Date(milestone_start_date ?? Date.now()),
                          'MM.dd.yy'
                        )} - ${format(
                          new Date(milestone_end_date ?? Date.now()),
                          'MM.dd.yy'
                        )}`}</p>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="resource-info horizontal-item">
                <p className="section-headline">
                  <b>Resources Working on Project</b>
                </p>
                <div className="candidate-container">
                  {candidates?.map(({ avatar, full_name, task_id }) => (
                    <div key={task_id} className="candidate">
                      <Avatar src={avatar} width={1.5} height={1.5} />
                      <p>{full_name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProjectInfoModal;
