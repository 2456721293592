import { Box, Typography, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useState } from 'react';
import CampaignForm from '../CampaignForm';
import Modal from '../../shared/Modal';
import { useCreateCampaign } from '../../../queries/Campaign';
import CampaignPaymentFormModal from '../CampaignPaymentFormModal';
import { toast } from 'react-toastify';

const CampaignCreationModal = ({ isOpen, onClose }) => {
  const defaultCampaignInfo = {
    expected_contributor_count: null,
    expected_week_count: null,
    expected_week_hours: null
  };

  const [campaignInfo, setCampaignInfo] = useState(defaultCampaignInfo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: onCreateCampaign } = useCreateCampaign();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const data = await onCreateCampaign(campaignInfo);
      setCampaignInfo(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (campaignInfo?.id && isOpen) {
    return (
      <CampaignPaymentFormModal
        campaignId={campaignInfo.id}
        onClose={onClose}
        onSuccess={() => {
          onClose();
          toast.success('Campaign created successfully');
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth="630px" width="100%">
      <Box>
        <Box textAlign="center">
          <Typography
            as="h5"
            fontSize={14}
            textAlign="center"
            fontWeight="600">
            Campaign Creation
          </Typography>
        </Box>
        <Box>
          <CampaignForm
            campaignInfo={campaignInfo}
            updateCampaignInfo={setCampaignInfo}
          />
        </Box>
        <Box marginTop={2}>
          <Grid container justifyContent="center" gap="40px">
            <Grid item>
              <Button
                sx={{ width: '7rem' }}
                variant="outlined"
                onClick={onClose}>
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                sx={{ width: '7rem' }}
                onClick={handleSubmit}>
                CREATE
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CampaignCreationModal;
