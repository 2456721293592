const muiTheme = {
  typography: {
    fontFamily:
      '"Lato", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: 14,
    color: '#526c80',
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          '&-item.Mui-selected': {
            backgroundColor: '#526c80'
          }
        }
      }
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          color: '#18395d'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: 'auto'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: '#526c80',
          border: '1px solid #b2c8d8'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: '#526c80'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          '& .MuiCheckbox-root': {
            paddingRight: '4px'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#18395d',
          fontSize: 14,
          '& fieldset': {
            transition: 'all .2s ease-in-out'
          },
          '&:not(.Mui-focused):hover > fieldset': {
            border: '2px solid #b2c8d8'
          },
          '&.MuiOutlinedInput-root': {
            padding: '8px',
            '& .MuiOutlinedInput-input': {
              padding: 0
            }
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          flexWrap: 'unset'
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          textAlign: 'left'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          fontSize: 14,
          '&.Mui-selected': {
            color: '#ffffff'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#386892',
          '& .MuiTabs-indicator': {
            backgroundColor: '#14b9b8'
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: 'center'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#dde8ee',
          padding: '8px',
          fontSize: 14,
          '&.MuiTableCell-head': {
            color: '#509ec6',
            borderBottom: '1px solid #81b9d5'
          },
          '&.MuiTableCell-body': {
            color: '#526c80',
            borderBottom: 'none'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: '#526c80',
          textAlign: 'left'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.65
          },
          '&.MuiButton-contained': {
            transition: 'all .2s ease-in-out',
            color: '#ffffff',
            backgroundColor: '#509ec6',
            '&:hover': {
              backgroundColor: '#509ec6',
              filter: 'brightness(110%)'
            },
            boxShadow: 'none'
          },
          '&.MuiButton-sizeSmall': {
            fontSize: 12,
            padding: '0.25rem 0.5rem',
            height: 30
          },
          color: '#509EC6',
          padding: '0.65rem 0.75rem',
          fontSize: '0.875rem',
          height: '2.375rem',
          fontWeight: '700',
          borderRadius: '0.1875rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem'
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          width: '100%'
        },
        root: {
          '&.MuiCollapse-entered': {
            width: 'auto !important'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#509ec6 !important',
          '&.Mui-disabled': {
            opacity: '0.75 !important'
          }
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#14b9b8 !important'
          },
          '&.Mui-checked.Mui-disabled': {
            opacity: '0.75 !important'
          }
        },
        track: {
          backgroundColor: '#bdd3df',
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: '#bdd3df'
          },
          '.Mui-disabled + &': {
            opacity: '0.5 !important'
          }
        }
      }
    }
  }
};

export default muiTheme;
