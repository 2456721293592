import { Button, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import PendingRequirementTooltip from '../shared/PendingRequirementTooltip';

const ComplianceRedirectionBar = ({
  requirements,
  candidateProfileStatusId
}) => {
  const history = useHistory();

  const pendingRequirements = requirements?.filter(
    ({ is_answered }) => !is_answered
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      gap="1rem"
      padding="0.75rem"
      bgcolor="#509ec6">
      <Grid item>
        <Typography color="white" fontWeight={600}>
          Candidate is hired and the compliance documents are{' '}
          <PendingRequirementTooltip
            pendingRequirements={pendingRequirements}
            totalRequirementCount={requirements?.length}>
            <Typography
              color="white"
              fontWeight={600}
              sx={{ textDecoration: 'underline', cursor: 'default' }}
              as="span">
              pending
            </Typography>
          </PendingRequirementTooltip>
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{
            color: '#509ec6 !important',
            bgcolor: 'white !important'
          }}
          onClick={() =>
            history.push(
              `/compliance-management/contractors/${candidateProfileStatusId}/checklist`
            )
          }>
          MANAGE COMPLIANCE
        </Button>
      </Grid>
    </Grid>
  );
};

export default ComplianceRedirectionBar;
