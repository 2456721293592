import request, { attachQueryParams } from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';

const InterviewService = {
  getInterviews: (queryParams = {}) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getMyInterviews`,
        queryParams
      ),
      method: 'get'
    }),
  getSingleInterview: ({ interview_id }) =>
    request({
      url: attachQueryParams(`${RECRUITER_API_URL}/getClientInterview`, {
        interview_id
      }),
      method: 'get'
    }),
  updateClientInterviewStatus: ({ interview_id, status }) =>
    request({
      url: `${RECRUITER_API_URL}/updateClientInterviewStatus`,
      method: 'put',
      data: { interview_id, status }
    }),
  initializeInterview: ({ campaign_id, candidate_id, dates }) =>
    request({
      url: `${RECRUITER_API_URL}/initializeInterview`,
      method: 'post',
      data: { campaign_id, candidate_id, dates }
    }),
  rescheduleInterview: ({ campaign_id, candidate_id, dates }) =>
    request({
      url: `${RECRUITER_API_URL}/rescheduleInterview`,
      method: 'post',
      data: { campaign_id, candidate_id, dates }
    }),
  confirmInterviewDate: ({
    campaign_id,
    candidate_id,
    timeslot,
    client_id
  }) =>
    request({
      url: `${RECRUITER_API_URL}/confirmInterviewDate`,
      method: 'post',
      data: { campaign_id, candidate_id, timeslot, client_id }
    }),
  requestNewInterviewSlots: ({ campaign_id, candidate_id, client_id }) =>
    request({
      url: `${RECRUITER_API_URL}/requestNewInterviewSlots`,
      method: 'post',
      data: { campaign_id, candidate_id, client_id }
    }),
  getInterviewInfoByHostRoomCode: ({ host_room_code }) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getInterviewInfoByHostRoomCode`,
        { host_room_code }
      ),
      method: 'get'
    }),
  submitPostInterview: ({
    status,
    interview_note,
    client_id,
    interview_id
  }) =>
    request({
      url: `${RECRUITER_API_URL}/submitPostInterview`,
      method: 'post',
      data: { status, interview_note, client_id, interview_id }
    })
};

export default InterviewService;
