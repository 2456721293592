import { Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import config from '../../../../../config';

const ManageComplianceButton = ({
  profileStatus,
  candidateProfileStatusId
}) => {
  const history = useHistory();
  const isDisabled =
    profileStatus !== config.candidate.profileStatus.HIRED;

  const button = () => (
    <Button
      sx={{ width: '200px' }}
      variant="contained"
      onClick={() => {
        if (isDisabled) {
          return;
        }

        history.push(
          `/compliance-management/contractors/${candidateProfileStatusId}/checklist`
        );
      }}>
      MANAGE COMPLIANCE
    </Button>
  );

  if (!isDisabled) {
    return button();
  }

  return (
    <Tooltip
      title="This button is only available when the candidate is hired"
      placement="top">
      {button()}
    </Tooltip>
  );
};

export default ManageComplianceButton;
