import { toast } from 'react-toastify';
import { useState } from 'react';
import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button/Button';
import {
  REQUIREMENT_TYPE,
  UPLOAD_TYPE,
  TWO_WAY_UPLOAD_TYPE
} from '../../../constants/common';

import './styles.scss';
import SelectInput from '../../shared/SelectInput';

const RequirementBottomBar = ({ onAdd }) => {
  const [requirementType, setRequirementType] = useState();

  const currentUploadType =
    requirementType === REQUIREMENT_TYPE.TWO_WAY.value
      ? TWO_WAY_UPLOAD_TYPE
      : UPLOAD_TYPE;

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const formValues = Object.fromEntries(formData.entries());

    if (!formValues.requirement) {
      toast.error('Custom requirement cannot be empty');
      return;
    }

    if (!formValues.requirement_type) {
      formValues.requirement_type = REQUIREMENT_TYPE.ONE_WAY.value;
    }

    if (!formValues.upload_by) {
      formValues.upload_by = UPLOAD_TYPE.MYSELF.value;
    }

    formValues.upload_by = parseInt(formValues.upload_by);

    onAdd(formValues);

    event.target.reset();
  };

  return (
    <form className="requirement-bottom-bar" onSubmit={submitForm}>
      <div className="flex-items">
        <Input
          placeholder="Custom requirement"
          label="Add custom requirement"
          isSideLabel
          name="requirement"
        />
        <SelectInput
          label="Requirement type"
          name="requirement_type"
          options={Object.values(REQUIREMENT_TYPE).map((item) => ({
            value: item.value,
            text: item.text
          }))}
          onChange={(e) => {
            setRequirementType(e.target.value);
          }}
        />
        <SelectInput
          label="Upload by"
          name="upload_by"
          options={Object.values(currentUploadType).map((item) => ({
            value: item.value,
            text: item.text
          }))}
        />
        <Button>+ ADD REQUIREMENT</Button>
      </div>
    </form>
  );
};

export default RequirementBottomBar;
