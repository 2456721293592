import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useGetStatsQuery } from '../../../queries/Stats';
import { getChartDataByVote } from '../../../utils/horizontal-graph';
import { GRAPH_PERIOD } from '../../../constants/horizontal-graph';

import './styles/HorizontalGraphMobile.scss';

const HorizontalGraphMobile = ({
  containerClassName,
  selectedCampaign
}) => {
  const [hiddenLine, setHiddenLine] = useState(null);

  const queryParams = {
    campaign_id: selectedCampaign?.campaign_id || null
  };

  const { isSuccess, data } = useGetStatsQuery(queryParams);

  const { series } = isSuccess
    ? getChartDataByVote(
        GRAPH_PERIOD.THIS_YEAR,
        data.candidates_data.records
      )
    : {};

  return (
    <div
      className={
        'horizontal-graph-mobile h-[300px]' +
        (containerClassName ? ` ${containerClassName}` : '')
      }>
      {isSuccess && series.length > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            height={300}
            data={series}
            margin={{
              left: -35,
              right: 6
            }}>
            <defs>
              <linearGradient
                id="voted-up-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#509ec6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#509ec6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="voted-down-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#FF7BAC" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FF7BAC" stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="matched-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#21D4D2" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#21D4D2" stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="interviewed-gradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="5%" stopColor="#b2c8d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#b2c8d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              stroke="#265d84"
              strokeWidth={1}
            />
            <XAxis dataKey="name" />
            <YAxis
              axisLine={false}
              tickFormatter={(tick) => Math.round(tick)}
            />
            <Tooltip
              contentStyle={{
                borderRadius: 8
              }}
              labelFormatter={(value) => `Time: ${value}`}
              formatter={(value, name) => {
                switch (name) {
                  case 'votedUp':
                    return [`Voted Up: ${value}`];
                  case 'votedDown':
                    return [`Voted Down: ${value}`];
                  default:
                    return value;
                }
              }}
            />
            <Legend
              onMouseEnter={(e) => setHiddenLine(e.value)}
              onMouseLeave={() => setHiddenLine(null)}
              iconType="circle"
              iconSize={12}
              verticalAlign="top"
              align="right"
              height={60}
              formatter={(value) => {
                switch (value) {
                  case 'votedUp':
                    return 'Voted Up';
                  case 'votedDown':
                    return 'Voted Down';
                  default:
                    return value;
                }
              }}
              wrapperStyle={{
                cursor: 'pointer',
                display: 'inline-flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 8
              }}
            />
            <Area
              style={{
                opacity: hiddenLine === 'votedDown' ? 0.1 : 1,
                transition: 'opacity 0.3s'
              }}
              type="monotone"
              dataKey="votedUp"
              stroke="#509ec6"
              strokeWidth={2}
              fill="url(#voted-up-gradient)"
              dot={{
                r: 5,
                fill: '#509ec6',
                strokeWidth: 1,
                stroke: '#fff',
                fillOpacity: 1
              }}
              activeDot={{
                r: 8,
                fill: '#509ec6',
                strokeWidth: 2,
                fillOpacity: 1
              }}
            />
            <Area
              style={{
                opacity: hiddenLine === 'votedUp' ? 0.1 : 1,
                transition: 'opacity 0.3s'
              }}
              type="monotone"
              dataKey="votedDown"
              stroke="#FF7BAC"
              strokeWidth={2}
              fill="url(#voted-down-gradient)"
              dot={{
                r: 5,
                fill: '#FF7BAC',
                strokeWidth: 1,
                stroke: '#fff',
                fillOpacity: 1
              }}
              activeDot={{
                r: 8,
                fill: '#FF7BAC',
                strokeWidth: 2,
                fillOpacity: 1
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

HorizontalGraphMobile.propTypes = {
  containerClassName: PropTypes.string
};

export default HorizontalGraphMobile;
