import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackArrowIcon } from '../../../assets/images/back-arrow-icon.svg';
import { tabContentMobileVariants } from '../../../utils/animation';

import PermissionChecker from '@src/components/shared/PermissionChecker';
import {
  useGetCandidatesQuery,
  useToggleCandidateFavoriteStatusQuery
} from '../../../queries/Candidate';
import { useGetNewMatchCandidatesQuery } from '../../../queries/NewMatches';
import { selectPreviewCandidate } from '../../../store/selectors/Candidate';
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
  selectCandidateSearchText
} from '../../../store/selectors/CandidateFilter';
import { setPreviewCandidate } from '../../../store/slices/Candidate';
import { clearWhiteSpacesAndConvertToLowerCase } from '../../../utils/misc';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../../shared/CustomScrollBar/CustomScrollBar';
import CandidatePreviewCard from '../CandidatePreviewCard/CandidatePreviewCard';
import NewMatchBar from './../NewMatchBar/NewMatchBar';
import SearchFilterBar from './../SearchFilterBar/SearchFilterBar';
import CandidateListItems from './CandidateItems';

import './CandidateList.css';
import config from '@src/config';

const CandidateList = ({ containerClassName, selectedCampaign }) => {
  const [previewExited, setPreviewExited] = useState(true);
  const [showArrow, setShowArrow] = useState(false);

  const isFavorites = useSelector(selectCandidateFilterFavorites);
  const profileStatus = useSelector(selectCandidateFilterProfileStatus);
  const searchText = useSelector(selectCandidateSearchText);
  const previewCandidate = useSelector(selectPreviewCandidate);

  const dispatch = useDispatch();

  const candidateFilterParams = {
    campaign_id: selectedCampaign?.campaign_id || null,
    profile_status: profileStatus,
    is_favourite: isFavorites
  };

  const { data: newMatches } = useGetNewMatchCandidatesQuery();
  const { isLoading, data } = useGetCandidatesQuery(candidateFilterParams);

  const [localData, setLocalData] = useState(data);

  const { mutate: toggleFavoriteStatus } =
    useToggleCandidateFavoriteStatusQuery(candidateFilterParams);

  const selectCandidate = useCallback(
    (candidate) => {
      dispatch(setPreviewCandidate(candidate));
    },
    [dispatch, previewExited]
  );

  const onFavorite = useCallback(
    (event, candidate) => {
      event.stopPropagation();
      toggleFavoriteStatus(candidate);
    },
    [toggleFavoriteStatus]
  );

  const onCandidatePreview = useCallback(
    (candidate) => {
      if (previewExited && !window.getSelection().toString().length) {
        setPreviewExited(false);
        selectCandidate(candidate);
      }
    },
    [previewExited, selectCandidate]
  );

  const showData = useMemo(
    () =>
      (searchText ? localData : data)?.filter(
        (candidate) =>
          candidate.profile_status !==
            config.candidate.profileStatus.VOTED_DOWN &&
          candidate.profile_status !==
            config.candidate.profileStatus.REJECTED &&
          candidate.profile_status !==
            config.candidate.profileStatus.MATCHED
      ), //not voted down;
    [data, localData, searchText]
  );

  useEffect(() => {
    const newData = data?.filter((candidate) => {
      const search = clearWhiteSpacesAndConvertToLowerCase(searchText);
      const firstName = clearWhiteSpacesAndConvertToLowerCase(
        candidate?.first_name
      );
      const lastName = clearWhiteSpacesAndConvertToLowerCase(
        candidate?.last_name
      );
      const techStack = clearWhiteSpacesAndConvertToLowerCase(
        candidate?.tech_stack
      );
      const state = clearWhiteSpacesAndConvertToLowerCase(
        candidate?.state
      );
      const city = clearWhiteSpacesAndConvertToLowerCase(candidate?.city);
      const country = clearWhiteSpacesAndConvertToLowerCase(
        candidate?.country
      );

      return (
        (firstName + lastName).includes(search) ||
        (lastName + firstName).includes(search) ||
        techStack.includes(search) ||
        state.includes(search) ||
        city.includes(search) ||
        country.includes(search)
      );
    });

    setLocalData(newData);
    setShowArrow(newData?.length > 4);
  }, [searchText, data, setLocalData]);

  useEffect(() => {
    const listWrapper = document.querySelector('.candidate-list > div');
    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;

      const isScrolledToBottom =
        scrollTop + clientHeight >= scrollHeight - 50;

      if (isScrolledToBottom) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };
    listWrapper.addEventListener('scroll', handleScroll);

    return () => {
      listWrapper.removeEventListener('scroll', handleScroll);
    };
  }, [showArrow]);

  const handleScrollToNextCards = () => {
    const candidateListItemElement = document.querySelector(
      '#candidate-list-item'
    );

    const rect = candidateListItemElement.getBoundingClientRect();

    const candidateListItemElementHeight =
      rect.height +
      parseInt(
        window.getComputedStyle(candidateListItemElement).marginBottom
      );

    const listWrapper = document.querySelector('.candidate-list > div');

    const scrollHeight =
      listWrapper.scrollTop + (candidateListItemElementHeight + 24) * 2;

    listWrapper.scrollTo({
      top: scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <SearchFilterBar total={showData?.length} />
      <PermissionChecker permissions={['dashboard.candidate.write']}>
        <NewMatchBar data={newMatches} />
      </PermissionChecker>
      <Scrollbars
        id="candidateList"
        className="candidate-list"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}>
        <LayoutGroup id="candidateGridLayout" type="crossfade">
          <motion.div
            id="candidateGrid"
            className={
              'candidate-list__grid' +
              (containerClassName ? ` ${containerClassName}` : '')
            }>
            <CandidateListItems
              showData={showData}
              onCandidatePreview={onCandidatePreview}
              onFavorite={onFavorite}
              isLoading={isLoading}
            />
          </motion.div>
          <AnimatePresence onExitComplete={() => setPreviewExited(true)}>
            {previewCandidate && (
              <CandidatePreviewCard
                key={previewCandidate?.candidate_profile_status_id}
                data={previewCandidate}
                onClose={() => selectCandidate(null)}
                onFavorite={(event) => onFavorite(event, previewCandidate)}
              />
            )}
          </AnimatePresence>
        </LayoutGroup>
      </Scrollbars>
      <AnimatePresence onExitComplete={() => setPreviewExited(true)}>
        {showArrow && (
          <motion.div
            onClick={handleScrollToNextCards}
            variants={tabContentMobileVariants}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="cursor-pointer candidate-list__arrow">
            <BackArrowIcon className="first-back-arrow-icon" />
            <BackArrowIcon className="second-back-arrow-icon" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

CandidateList.propTypes = {
  containerClassName: PropTypes.string
};

export default CandidateList;
