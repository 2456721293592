import { useGetUserQuery } from '@src/queries/User';
import { createContext, useContext } from 'react';

export const UserContext = createContext({
  userData: {}
});

export const UserProvider = ({ children }) => {
  const { isFetched: isUserDataFetched, data: userData } =
    useGetUserQuery();

  const values = {
    userData,
    isUserDataFetched
  };

  return (
    <UserContext.Provider value={values}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
