import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactTooltip from 'react-tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import muiTheme from '@src/muiTheme';
import AppRouter from './router/AppRouter';
import { store } from './store';
import config from './config';
import 'react-toastify/dist/ReactToastify.css';
import queryStore from './queries';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createRoot } from 'react-dom/client';
import ViewportUnitProvider from './providers/units';
const container = document.getElementById('root');

import './index.scss';

//customize MUI theme
const theme = createTheme(muiTheme);

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryStore}>
        <Provider store={store}>
          <ViewportUnitProvider>
            <AppRouter />
            <ReactTooltip
              delayShow={400}
              delayHide={400}
              delayUpdate={100}
              effect="solid"
              type="light"
              className="custom__tooltip state-transition"
            />
            <ToastContainer {...config.toast} />
          </ViewportUnitProvider>
        </Provider>
        {config.common.isQueryDevToolEnabled && <ReactQueryDevtools />}
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
