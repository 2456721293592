import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const DateTimePicker = ({ onChange, disablePast, shouldDisableTime }) => {
  const handleChange = (newValue) => {
    if (!newValue) {
      onChange('');

      return;
    }

    onChange(newValue.toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDateTimePicker
        onChange={handleChange}
        disablePast={disablePast}
        closeOnSelect={false}
        slotProps={{ popper: { placement: 'right' } }}
        shouldDisableTime={shouldDisableTime}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
