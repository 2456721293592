import './styles.scss';
import Table from '../../compliance-management/Table';
import { ReactComponent as FilledTrashBinIcon } from '../../../assets/images/filled-trash-bin.svg';
import Button from '../../shared/Button/Button';
import { showFullDateTime } from '../../../utils/common';
import { UPLOAD_TYPE } from '../../../constants/common';

const download = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = fileName;
  link.click();
};

const FileTable = ({
  fileListItems,
  isLoading,
  setUpcomingDeletedFile
}) => (
  <div className="file-table-container">
    <div className="table-wrapper">
      <Table className="file-table">
        <Table.Header>
          <tr>
            <th>File name</th>
            <th>Requirement</th>
            <th>Uploaded time</th>
            <th>Uploaded by</th>
            <th>Comments</th>
            <th></th>
          </tr>
        </Table.Header>
        <Table.Body>
          {isLoading ? (
            <></>
          ) : (
            fileListItems?.map((fileListItem) => (
              <tr key={fileListItem.id}>
                <td>
                  <b>{fileListItem.uploaded_document_name}</b>
                </td>
                <td>
                  <span>{fileListItem.requirement}</span>
                </td>
                <td>
                  <span>
                    {showFullDateTime(fileListItem.created_datetime)}
                  </span>
                </td>
                <td>
                  <span>
                    {Object.values(UPLOAD_TYPE).find(
                      ({ value }) => value === fileListItem.creator_role
                    )?.text || 'N/A'}
                  </span>
                </td>
                <td>
                  <textarea className="form-control custom-textarea"></textarea>
                </td>
                <td>
                  <div className="actions">
                    <Button
                      className="custom-button"
                      onClick={() => {
                        download(
                          fileListItem.uploaded_document,
                          fileListItem.uploaded_document_name
                        );
                      }}>
                      DOWNLOAD
                    </Button>
                    <FilledTrashBinIcon
                      onClick={() => setUpcomingDeletedFile(fileListItem)}
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </Table.Body>
      </Table>
    </div>
  </div>
);

export default FileTable;
