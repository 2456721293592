import './Avatar.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as UserPhotoIcon } from '../../../assets/images/user-photo-icon.svg';

const Avatar = ({
  alt,
  style,
  src = '',
  width = 1.875,
  height = 1.875,
  backgroundColorVar = '--color-blissful-blue',
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const markAsLoaded = () => setIsLoading(false);

  const markAsError = () => setIsError(true);
  return (
    <div
      className="avatar"
      style={{
        backgroundColor: src
          ? 'transparent'
          : `var(${backgroundColorVar})`,
        minWidth: `${width}rem`,
        height: `${height}rem`,
        borderRadius: 999,
        ...style
      }}>
      {src ? (
        <motion.img
          src={src}
          initial={{ opacity: 0 }}
          animate={{
            opacity: isLoading && !isError ? 0 : 1
          }}
          style={{
            width: `${width}rem`,
            height: `${height}rem`
          }}
          alt={alt}
          draggable={false}
          {...rest}
          onLoad={markAsLoaded}
          onError={markAsError}
        />
      ) : (
        <UserPhotoIcon
          width={`${width}rem`}
          height={`${height}rem`}
          onLoad={markAsLoaded}
          onError={markAsError}
        />
      )}
    </div>
  );
};

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColorVar: PropTypes.string
};

export default Avatar;
