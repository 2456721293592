import { motion, AnimatePresence } from 'framer-motion';

import styles from './LoadingOverlay.module.scss';

const LoadingOverlay = ({ open = false }) => {
  const size = 44;
  const thickness = 3.6;

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.container}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <span className={styles.circularRoot}>
            <svg
              viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
              className={styles.circularSvg}
              style={{ width: size, height: size }}>
              <circle
                className={styles.circularCircle}
                cx={size}
                cy={size}
                r={(size - thickness) / 2}
                fill="none"
                strokeWidth={thickness}></circle>
            </svg>
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingOverlay;
