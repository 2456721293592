import React from 'react';
import { useFormContext } from 'react-hook-form';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import OutlinedInputWithLabel from '../OutlinedInputWithLabel/OutlinedInputWithLabel';
import OutlinedSelectWithLabel from '../OutlinedSelectWithLabel/OutlinedSelectWithLabel';
import Button from '../../shared/Button/Button';
import states from '../../../constants/states';

import './PaymentDetails.scss';

const PaymentDetails = () => {
  const { register, handleSubmit } = useFormContext();

  const handleFormSubmit = handleSubmit((formData) => {
    console.log(formData);
  });

  return (
    <SectionWrapper title={'Payment Details'}>
      <form className="payment-details" onSubmit={handleFormSubmit}>
        <OutlinedInputWithLabel
          label="Billing Name"
          name="billingName"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Billing Email"
          name="billingEmail"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Address Line 1"
          name="addressLine1"
          register={register}
          required
        />
        <OutlinedInputWithLabel
          label="Address Line 2"
          name="addressLine2"
          register={register}
        />
        <OutlinedInputWithLabel
          label="City"
          name="city"
          register={register}
          required
          className="payment-details__field-city"
        />
        <OutlinedInputWithLabel
          label="ZIP"
          name="zip"
          register={register}
          required
          className="payment-details__field-zip"
        />
        <OutlinedSelectWithLabel
          label="State"
          name="state"
          register={register}
          options={states}
          required
          className="payment-details__field-state"
        />
        <OutlinedInputWithLabel
          label="EIN"
          name="ein"
          register={register}
          className="payment-details__field-ein"
        />

        <div className="payment-details__submit">
          <Button title="Add Payment Method" type="submit" />
        </div>
      </form>
    </SectionWrapper>
  );
};

export default PaymentDetails;
