import { ReactComponent as BoxIcon } from './box.svg';
import { ReactComponent as TickIcon } from './tick.svg';

const CustomCheckbox = ({ checked, onChange, id, label }) => {
  return (
    <div className="inline-block">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="sr-only"
      />
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <span className="relative w-4 h-4">
          <BoxIcon className="absolute inset-0 w-full h-full " />
          {checked && (
            <div className="absolute inset-0 flex items-center justify-center w-full h-full">
              <TickIcon className="w-3 h-3 " />
            </div>
          )}
        </span>
        {label && <span className="ml-2">{label}</span>}
      </label>
    </div>
  );
};

export default CustomCheckbox;
