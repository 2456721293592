import { createSlice } from '@reduxjs/toolkit';
import { questions } from './../../utils/questionnaire';

const initialState = {
  isLoginPending: false,
  registration: {
    isRegisterPending: false,
    step: 0,
    isTouched: false
  },
  isLogoutPending: false,
  isResetPasswordPending: false,
  isCreateNewPassword: false,
  isCreateNewPasswordPending: false,
  isChangePassword: true,
  otp: {
    isPending: false,
    isResendPending: false,
    email: '',
    phone: ''
  },
  questionnaire: {
    isQuestionnairePending: false,
    partOne: questions.partOne,
    partTwo: questions.partTwo
  },
  basicInfo: {
    first_name: '',
    last_name: '',
    phone_number: '',
    company_name: '',
    company_url: '',
    job_title: '',
    email_id: '',
    user_role: 1,
    accept: false,
    access_token: ''
  },
  isCreatePasswordPending: false
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    markRegistrationAsTouched(state) {
      state.registration.isTouched = true;
    },
    registerRequest(state) {
      state.registration.isRegisterPending = true;
    },
    registerSuccess(state, { payload }) {
      state.registration.isRegisterPending = false;
      state.basicInfo = payload;
      state.registration.step = 1;
    },
    registerError(state) {
      state.registration.isRegisterPending = false;
    },
    resendOTPRequest(state) {
      state.otp.isResendPending = true;
    },
    resendOTPSuccess(state) {
      state.otp.isResendPending = false;
    },
    resendOTPError(state) {
      state.otp.isResendPending = false;
    },
    logoutRequest(state) {
      state.isLogoutPending = true;
    },
    logoutSuccess(state) {
      state.isLogoutPending = false;
    },
    logoutError(state) {
      state.isLogoutPending = false;
    },
    resetPasswordOTPRequest(state) {
      state.isResetPasswordPending = true;
    },
    resetPasswordOTPSuccess(state) {
      state.isResetPasswordPending = false;
    },
    resetPasswordOTPError(state) {
      state.isResetPasswordPending = false;
    },
    verifyResetPasswordOTPRequest(state) {
      state.otp.isPending = true;
    },
    verifyResetPasswordOTPSuccess(state) {
      state.isCreateNewPassword = true;
    },
    verifyResetPasswordOTPError(state) {
      state.otp.isPending = false;
    },
    setIsChangePassword(state, { payload }) {
      state.isChangePassword = payload;
    },
    changePasswordRequest(state) {
      state.isChangePassword = false;
    },
    changePasswordSuccess(state) {
      state.isChangePassword = true;
    },
    changePasswordError(state) {
      state.isChangePassword = false;
    },

    createNewPasswordRequest(state) {
      state.isCreateNewPasswordPending = true;
    },
    createNewPasswordSuccess(state) {
      state.isCreateNewPasswordPending = false;
      state.otp = initialState.otp;
      state.isCreateNewPassword = false;
    },
    createNewPasswordError(state) {
      state.isCreateNewPasswordPending = false;
    },
    saveQuestionnaire(
      state,
      { payload: { partialQuestionnaire, goBack = false } }
    ) {
      const isQuestionnairePartOne = state.registration.step === 1;
      if (isQuestionnairePartOne) {
        state.questionnaire.partOne = partialQuestionnaire;
        state.registration.step = goBack ? 0 : 2;
      } else {
        state.questionnaire.partTwo = partialQuestionnaire;
        if (goBack) {
          state.registration.step = 1;
        }
      }
    },
    submitQuestionnaireRequest(state) {
      state.questionnaire.isQuestionnairePending = true;
    },
    submitQuestionnaireSuccess(state) {
      state.questionnaire.isQuestionnairePending = false;
      state.registration.step = 3;
    },
    submitQuestionnaireError(state) {
      state.questionnaire.isQuestionnairePending = false;
    },
    registrationGoBack(state) {
      if (state.registration.step) {
        state.registration.step--;
      }
    },
    createPasswordRequest(state) {
      state.isCreatePasswordPending = true;
    },
    createPasswordSuccess(state) {
      state.isCreatePasswordPending = false;
      state.registration.step = 4;
      state.questionnaire = initialState.questionnaire;
      state.basicInfo = initialState.basicInfo;
    },
    createPasswordError(state) {
      state.isCreatePasswordPending = false;
    },
    resetAuthData() {
      return initialState;
    }
  }
});

export const {
  markRegistrationAsTouched,
  registerRequest,
  registerSuccess,
  registerError,
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPError,
  logoutRequest,
  logoutSuccess,
  logoutError,
  resetPasswordOTPRequest,
  resetPasswordOTPSuccess,
  resetPasswordOTPError,
  verifyResetPasswordOTPRequest,
  verifyResetPasswordOTPSuccess,
  verifyResetPasswordOTPError,
  setIsChangePassword,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  createNewPasswordRequest,
  createNewPasswordSuccess,
  createNewPasswordError,
  saveQuestionnaire,
  submitQuestionnaireRequest,
  submitQuestionnaireSuccess,
  submitQuestionnaireError,
  registrationGoBack,
  createPasswordRequest,
  createPasswordSuccess,
  createPasswordError,
  resetAuthData
} = AuthSlice.actions;
export default AuthSlice.reducer;
