import './SectionWrapper.scss';
import React from 'react';

const SectionWrapper = ({ title, children }) => (
  <div className="section-wrapper">
    <h3 className="section-wrapper__title">{title}</h3>
    <div className="section-wrapper__content">{children}</div>
  </div>
);

export default SectionWrapper;
