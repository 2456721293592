import React, { useEffect, useRef } from 'react';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player-react/dist/controls.css';
import './shaka-video.scss';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { IconButton } from '@mui/material';

const FullscreenVideoPlayer = ({ exitFullscreen, src }) => {
  const videoRef = useRef(null);

  const handleKeyDown = (event) => {
    // Check if the right arrow key is pressed (key code 39)
    if (event.keyCode === 39) {
      // Fast forward by 5 seconds
      if (videoRef.current) {
        videoRef.current.currentTime += 5;
      }
    }
    // Check if the left arrow key is pressed (key code 37)
    else if (event.keyCode === 37) {
      // Rewind by 5 seconds
      if (videoRef.current) {
        videoRef.current.currentTime -= 5;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="shaka-video">
      <ShakaPlayer
        autoPlay
        src={src}
        onEnded={exitFullscreen}
        style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
        ref={videoRef}>
        Your browser does not support the video tag.
      </ShakaPlayer>
      <div className="exit-icon-wrapper" onClick={exitFullscreen}>
        <IconButton>
          <ClearRoundedIcon
            className="exit-icon"
            width="5rem"
            height="5rem"
          />
        </IconButton>
      </div>
    </div>
  );
};

export default FullscreenVideoPlayer;
