import { AnimatePresence, motion } from 'framer-motion';
import Button from '../Button/Button';
import {
  modalBackdropVariants,
  modalContentVariants
} from '../../../utils/animation';

import './AIModal.scss';

const AIModal = ({ onClose, isOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={modalBackdropVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="ai-modal">
          <motion.div
            variants={modalContentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="text-center d-flex flex-column justify-content-center content">
            <h1>AI OFF</h1>
            <p>
              You have turned off the machine learning algorithm for your
              account
            </p>
            <Button
              className="align-self-center"
              title="OK"
              onClick={onClose}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AIModal;
