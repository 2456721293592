import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaignFilter: {
    onlyActive: false,
    onlyPaused: false,
    actionRequired: false
  }
};

const CampaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignFilter(state, { payload }) {
      state.campaignFilter = {
        ...state.campaignFilter,
        ...payload
      };
    }
  }
});

export const { setCampaignFilter } = CampaignSlice.actions;

export default CampaignSlice.reducer;
