import React from 'react';
import {
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/selectors/App';
import { setCookie } from '../utils/cookies';
import { routes } from './routes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();
  const history = useHistory();
  const queryParams = location.search;

  if (isAuthenticated) {
    return (
      <Route {...rest} render={(props) => <Component {...props} />} />
    );
  }

  if (!queryParams.includes('access_token')) {
    return (
      <Route {...rest} render={() => <Redirect to={routes.login} />} />
    );
  }

  //to remain the token string consistent, we should use JSON.parse to do it
  const search = queryParams.substring(1);
  const params = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );

  setCookie('token', params.access_token);

  //remove access_token from the URL after token is set in the cookies
  delete params.access_token;
  history.push({
    pathname: location.pathname,
    search: '?' + new URLSearchParams(params).toString()
  });

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
