import Table from '../../compliance-management/Table';
import Avatar from '../../shared/Avatar/Avatar';
import {
  useGetManagedUsersQuery,
  useGetRolesQuery,
  useUpdateManagedUserRoleMutation
} from '../../../queries/PermissionRole';
import Dropdown from '../../shared/Dropdown';
import { toast } from 'react-toastify';
import UserInvitationBottomBar from '../UserInvitationBottomBar';
import { format } from 'date-fns';

const ManagedUserTable = () => {
  const {
    data: managedUsers,
    isFetching,
    isRefetching
  } = useGetManagedUsersQuery();

  const { data: roles } = useGetRolesQuery();

  const { mutateAsync: updateManagedUserRole } =
    useUpdateManagedUserRoleMutation();

  const getStatusText = (isPending, isActive) => {
    if (isPending) {
      return 'Pending';
    }
    return isActive ? 'Active' : 'Inactive';
  };

  return (
    <div>
      <Table>
        <Table.Header>
          <tr>
            <th>User</th>
            <th>Permission Type</th>
            <th>Status</th>
            <th>Joined</th>
            <th></th>
          </tr>
        </Table.Header>
        <Table.Body>
          {isFetching && !isRefetching ? (
            <tr>
              <td colSpan={5}>Loading...</td>
            </tr>
          ) : (
            managedUsers?.map((user) => (
              <tr key={user.id}>
                <td className="flex items-center gap-2 text-left">
                  <Avatar src={user.avatar} alt="User Avatar" />
                  <b>
                    {user.first_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.email_id}
                  </b>
                </td>
                <td>
                  <Dropdown
                    options={roles}
                    selectedOption={roles?.find(
                      (role) => String(user.role_id) === String(role.id)
                    )}
                    onChange={async (option) => {
                      await updateManagedUserRole({
                        role_id: option.id,
                        client_id: user.id
                      });
                      toast.success('User role updated successfully');
                    }}
                    className="w-40"
                    labelKey="role_name"
                    disabled={user.is_pending}
                  />
                </td>
                <td>
                  <b>{getStatusText(user.is_pending, user.is_active)}</b>
                </td>
                <td>
                  <span>
                    {user.registration_datetime
                      ? format(
                          new Date(user.registration_datetime),
                          'MM/dd/yyyy'
                        )
                      : '-'}
                  </span>
                </td>
              </tr>
            ))
          )}
        </Table.Body>
      </Table>
      <UserInvitationBottomBar roles={roles} />
    </div>
  );
};

export default ManagedUserTable;
