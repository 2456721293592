import request, { attachQueryParams } from '../utils/request';

const RECRUITER_API_URL = 'recruiter/api';

const CandidateService = {
  getCandidates: (queryParams) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getMyCandidates`,
        queryParams
      ),
      method: 'get'
    }),
  getNewMatchCandidates: () =>
    request({
      url: `${RECRUITER_API_URL}/getNewMatchedCandidates`,
      method: 'get'
    }),
  getCandidateProfileTemplateDetail: (queryParams) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getCandidateProfileTemplateDetail`,
        queryParams
      ),
      method: 'get'
    }),
  updateCandidateRequirements: ({
    candidate_profile_status_id,
    requirements,
    to_remove,
    template_name
  }) => {
    const data = {
      candidate_profile_status_id,
      requirements,
      to_remove
    };

    if (template_name) {
      data.template_name = template_name;
    }

    return request({
      url: `${RECRUITER_API_URL}/updateCandidateRequirements`,
      method: 'put',
      data
    });
  },
  getComplianceResponseDocs: (queryParams) =>
    request({
      url: attachQueryParams(
        `${RECRUITER_API_URL}/getComplianceResponseDocs`,
        queryParams
      ),
      method: 'get'
    }),
  toggleCandidateFavoriteStatus: (
    candidate_profile_status_id,
    is_favourite
  ) =>
    request({
      url: `${RECRUITER_API_URL}/addCandidateToFavorite/${candidate_profile_status_id}`,
      method: 'put',
      data: { is_favourite }
    }),
  updateCandidateProfileStatus: (
    candidate_profile_status_id,
    profile_status
  ) =>
    request({
      url: `${RECRUITER_API_URL}/updateCandidateProfileStatus/${candidate_profile_status_id}`,
      method: 'put',
      data: { profile_status: profile_status.toString() } // API requires status to be a string, but sends back integer when fetched
    })
};

export default CandidateService;
