import PropTypes from 'prop-types';
import './UpcomingInterviewListItem.scss';
import { Avatar, Box, Typography } from '@mui/material';
import InterviewMenu from '../InterviewMenu';
import { INTERVIEW_STATUSES } from '../../../constants/common';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import InterviewModal from '../InterviewModal';
import { useEffect, useState } from 'react';
import CampaignDetailsModal from '../../campaign/CampaignDetailsModal';
import InterviewSchedulingModal from '../InterviewSchedulingModal';
import {
  useRescheduleInterviewMutation,
  useUpdateClientInterviewStatusQuery
} from '../../../queries/Interview';
import ConfirmationModal from '../../shared/ConfirmationModal';
import LaunchMeetingButton from './LaunchMeetingButton';
import { openInNewTab } from '../../../utils/common';
import { differenceInMinutes, format } from 'date-fns';

const UpcomingInterviewListItem = ({ data }) => {
  const [isInterviewModalOpened, setIsInterviewModalOpened] =
    useState(false);
  const [isCampaignDetailsModalOpened, setIsCampaignDetailsModalOpened] =
    useState(false);
  const [
    isInterviewReschedulingModalOpen,
    setIsInterviewReschedulingModalOpen
  ] = useState(false);
  const [
    isInterviewCancellationModalOpen,
    setIsInterviewCancellationModalOpen
  ] = useState(false);
  const [interviewStatus, setInterviewStatus] = useState(data?.status);
  const {
    firstName,
    lastName,
    date,
    avatar,
    host_room_code,
    campaign_name,
    campaign_id,
    candidate_id,
    interview_date
  } = data || {};

  useEffect(() => {
    setInterviewStatus(data?.status);
  }, [data?.status]);

  const { mutateAsync: updateInterviewStatusInServer } =
    useUpdateClientInterviewStatusQuery();

  const { mutateAsync: rescheduleInterview } =
    useRescheduleInterviewMutation();

  const interviewLink = '/interview/' + host_room_code;

  const dateObj = new Date(date);

  const handleOpenInterviewModal = () => {
    setIsInterviewModalOpened(true);
  };

  const handleOpenInterviewReschedulingModal = () => {
    setIsInterviewReschedulingModalOpen(true);
  };

  const handleOpenInterviewCancellationModal = () => {
    setIsInterviewCancellationModalOpen(true);
  };

  const timeDifferenceOfNowAndInterview = differenceInMinutes(
    new Date(interview_date),
    new Date()
  );

  //if the interview time has passed 2 hours, we should NOT SHOW the launch meeting button
  const isHiddenMeetingLaunch = timeDifferenceOfNowAndInterview <= -120;

  //if the interview is not coming soon within 30 mins, we should DISABLE the launch meeting button
  const isDisabledMeetingLaunch = timeDifferenceOfNowAndInterview > 30;

  return (
    <div
      className="upcoming-interview-list-item"
      style={{
        opacity: interviewStatus === INTERVIEW_STATUSES.CANCELLED ? 0.5 : 1
      }}>
      <div className="upcoming-interview-list-item__header">
        <Avatar
          alt={firstName + ' ' + lastName}
          src={avatar}
          sx={{
            marginRight: '0.5rem'
          }}
        />
        <span className="w-full upcoming-interview-list-item__name text-truncated">
          {firstName}
          <br />
          {lastName}
        </span>
        {interviewStatus === INTERVIEW_STATUSES.SCHEDULED && (
          <span className="upcoming-interview-list-item__date">
            {format(dateObj, 'MM/dd/yy')}
            <br />
            {format(dateObj, 'hh:mm a')}
          </span>
        )}
        {(!interviewStatus ||
          interviewStatus === INTERVIEW_STATUSES.AWAITING) && (
          <Box
            bgcolor="#509ec6"
            padding="4px"
            borderRadius="2rem"
            display="flex">
            <AccessTimeRoundedIcon />
          </Box>
        )}
        {interviewStatus === INTERVIEW_STATUSES.SLOT_REQUESTED && (
          <Box
            bgcolor="#ff7bac"
            padding="4px"
            borderRadius="2rem"
            display="flex">
            <CloseRoundedIcon sx={{ width: '20px', height: '20px' }} />
          </Box>
        )}
        {interviewStatus === INTERVIEW_STATUSES.VOTED_UP && (
          <Box display="flex" gap="8px" marginLeft="auto">
            <Box
              bgcolor="#0a2a46"
              padding="6px"
              borderRadius="2rem"
              display="flex">
              <ThumbUpRoundedIcon
                sx={{ width: '20px', height: '20px', color: '#14b9b8' }}
              />
            </Box>
            <span className="upcoming-interview-list-item__date passed-date">
              {format(dateObj, 'MM/dd/yy')}
              <br />
              {format(dateObj, 'hh:mm a')}
            </span>
          </Box>
        )}
        {interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN && (
          <Box display="flex" gap="8px" marginLeft="auto">
            <Box
              bgcolor="#0a2a46"
              padding="6px"
              borderRadius="2rem"
              display="flex">
              <ThumbDownRoundedIcon
                sx={{ width: '20px', height: '20px', color: '#ff7bac' }}
              />
            </Box>
            <span className="upcoming-interview-list-item__date passed-date">
              {format(dateObj, 'MM/dd/yy')}
              <br />
              {format(dateObj, 'hh:mm a')}
            </span>
          </Box>
        )}
        <InterviewMenu
          openInterviewModal={handleOpenInterviewModal}
          openInterviewReschedulingModal={
            handleOpenInterviewReschedulingModal
          }
          openInterviewCancellationModal={
            handleOpenInterviewCancellationModal
          }
          data={{
            interviewLink,
            status: interviewStatus,
            isDisabledMeetingLaunch,
            isHiddenMeetingLaunch
          }}
        />
      </div>
      <p className="mt-2 upcoming-interview-list-item__description">
        {campaign_name}
      </p>
      {(interviewStatus === INTERVIEW_STATUSES.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUSES.RESCHEDULED) &&
        !isHiddenMeetingLaunch && (
          <div className="upcoming-interview-list-item__button-wrapper">
            <LaunchMeetingButton
              onClick={() => openInNewTab(interviewLink)}
              isDisabled={isDisabledMeetingLaunch}></LaunchMeetingButton>
          </div>
        )}
      <ConfirmationModal
        message={
          <Typography>
            Do you want to cancel the interview with the candidate
            <b>{` ${firstName} ${lastName}`}</b>?
          </Typography>
        }
        isOpen={isInterviewCancellationModalOpen}
        onConfirm={async () => {
          setIsInterviewCancellationModalOpen(false);
          setInterviewStatus(INTERVIEW_STATUSES.CANCELLED);
          updateInterviewStatusInServer({
            interview_id: data.id,
            status: INTERVIEW_STATUSES.CANCELLED
          });
        }}
        onCancel={() => {
          setIsInterviewCancellationModalOpen(false);
        }}
      />
      {isInterviewModalOpened && (
        <InterviewModal
          onClose={() => setIsInterviewModalOpened(false)}
          interviewId={data.id}
          openCampaignDetails={() => {
            setIsInterviewModalOpened(false);
            setIsCampaignDetailsModalOpened(true);
          }}
          updateInterviewStatus={(status) => setInterviewStatus(status)}
        />
      )}
      {isCampaignDetailsModalOpened && (
        <CampaignDetailsModal
          campaignId={campaign_id}
          onClose={() => setIsCampaignDetailsModalOpened(false)}
        />
      )}
      {isInterviewReschedulingModalOpen && (
        <InterviewSchedulingModal
          onClose={() => {
            setIsInterviewReschedulingModalOpen(false);
          }}
          noOfDates={2}
          primaryCtaText="RESCHEDULE"
          title="RESCHEDULE THE INTERVIEW"
          candidateName={`${firstName} ${lastName}`}
          onSubmit={async (dates) => {
            setInterviewStatus(INTERVIEW_STATUSES.AWAITING);
            return await rescheduleInterview({
              dates,
              candidate_id,
              campaign_id
            });
          }}
        />
      )}
    </div>
  );
};

UpcomingInterviewListItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default UpcomingInterviewListItem;
