export const clearWhiteSpacesAndConvertToLowerCase = (text = '') =>
  text ? text.replace(/\s/g, '').toLowerCase() : '';

export const isFile = (input) => 'File' in window && input instanceof File;

export const HOME_TABS = {
  CAMPAIGNS: 'CAMPAIGNS',
  INTERVIEWS: 'INTERVIEWS',
  CANDIDATES: 'CANDIDATES',
  STATS: 'STATS'
};
