import './styles.scss';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import Tab from '../../components/compliance-management/Tab';
import FileTable from '../../components/compliance-management/FileTable';
import {
  useGetCandidateProfileTemplateDetailQuery,
  useGetComplianceResponseDocsQuery,
  useUpdateCandidateRequirementsQuery
} from '../../queries/Candidate';
import {
  useGetTemplateDetailQuery,
  useUpdateResponseDocQuery
} from '../../queries/Compliance';
import TemplateTable from '../../components/compliance-management/TemplateTable';
import LoadTemplateModal from '../../components/compliance-management/LoadTemplateModal';
import {
  extractUpdatePayloadFromRequirements,
  showFullDateTime
} from '../../utils/common';
import ConfirmationModal from '../../components/shared/ConfirmationModal';
import UnsavedChangeHandler from '../../components/shared/UnsavedChangeHandler';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

const CONTRACTOR_DETAIL_TABS = {
  Checklist: 'checklist',
  Files: 'files'
};

const ContractorDetail = () => {
  const { contractorId, tabName } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    tabName ?? CONTRACTOR_DETAIL_TABS.Checklist
  );
  const [checkListItems, setCheckListItems] = useState([]);
  const [
    unsavedChangeWithSelectedTemplateId,
    setUnsavedChangeWithSelectedTemplateId
  ] = useState(false);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [candidateDetail, setCandidateDetail] = useState();
  const [loadTemplateModalOpen, setLoadTemplateModalOpen] =
    useState(false);
  const [upcomingDeletedFile, setUpcomingDeletedFile] = useState();

  const { mutateAsync: updateCandidateRequirements } =
    useUpdateCandidateRequirementsQuery();

  const { mutateAsync: updateResponseDoc } = useUpdateResponseDocQuery();

  const responseDocsRequest = useGetComplianceResponseDocsQuery({
    candidate_profile_status_id: contractorId
  });

  const { data: templateDetail, isFetching: isFetchingTemplate } =
    useGetTemplateDetailQuery(selectedTemplateId, {
      enabled: !!selectedTemplateId
    });

  useEffect(() => {
    if (templateDetail) {
      const newlyAddedRequirements = templateDetail?.requirements.map(
        (item) => ({
          requirement: item.requirement,
          upload_by: item.upload_by,
          requirement_type: item.requirement_type,
          display_id: v4()
        })
      );
      const currentDeletedRequirements = checkListItems.map(
        (checkListItem) => ({ ...checkListItem, isDeleted: true })
      );
      setCheckListItems([
        ...newlyAddedRequirements,
        ...currentDeletedRequirements
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDetail]);

  const profileTemplateDetailRequest =
    useGetCandidateProfileTemplateDetailQuery({
      candidate_profile_status_id: contractorId,
      template_id: 1
    });

  useEffect(() => {
    if (
      location.pathname.includes(
        `/${contractorId}/${CONTRACTOR_DETAIL_TABS.Files}`
      )
    ) {
      setActiveTab(CONTRACTOR_DETAIL_TABS.Files);
    }
    if (
      location.pathname.includes(
        `/${contractorId}/${CONTRACTOR_DETAIL_TABS.Checklist}`
      )
    ) {
      setActiveTab(CONTRACTOR_DETAIL_TABS.Checklist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (profileTemplateDetailRequest?.data) {
      setCheckListItems(profileTemplateDetailRequest.data.requirements);
      setCandidateDetail(
        profileTemplateDetailRequest.data.candidate_detail
      );
    }
  }, [profileTemplateDetailRequest?.data]);

  const submitUpdateRequirements = async () => {
    const payload = {
      ...extractUpdatePayloadFromRequirements(checkListItems),
      candidate_profile_status_id: contractorId
    };
    await updateCandidateRequirements(payload);
    toast.success('Updated the requirements successfully');
    setHasUnsavedData(false);
  };

  const loadTemplate = (templateId) => {
    if (hasUnsavedData) {
      setUnsavedChangeWithSelectedTemplateId(templateId);
    } else {
      setSelectedTemplateId(templateId);
    }
    setLoadTemplateModalOpen(false);
  };

  const onRemove = async (upcomingDeletedFile) => {
    setUpcomingDeletedFile(null);
    await updateResponseDoc({
      to_remove: [upcomingDeletedFile.id]
    });
    responseDocsRequest?.refetch();
  };

  const unsavedChangeMessage =
    'You have unsaved changes in your requirements. You still want to leave the page without saving changes?';

  const unsavedChangeWithTemplateChangeMessage =
    'You have unsaved changes in your requirements. You still want to change the template?';

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        className="contractor-detail"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <ConfirmationModal
          key="unsaved-change-confirmation-modal"
          message={unsavedChangeWithTemplateChangeMessage}
          isOpen={unsavedChangeWithSelectedTemplateId}
          onCancel={() => {
            setUnsavedChangeWithSelectedTemplateId(null);
          }}
          onConfirm={() => {
            setSelectedTemplateId(unsavedChangeWithSelectedTemplateId);
            setUnsavedChangeWithSelectedTemplateId(null);
            setHasUnsavedData(false);
          }}
        />
        <UnsavedChangeHandler
          onConfirm={() => {
            setHasUnsavedData(false);
            setCheckListItems(
              profileTemplateDetailRequest.data.requirements
            );
          }}
          shouldBlockNavigation={() => true}
          when={hasUnsavedData}
          message={unsavedChangeMessage}
        />
        <ConfirmationModal
          message="Your are about removing your file, are you sure you want to do that? All attached files with requirements can be affected."
          isOpen={Boolean(upcomingDeletedFile)}
          onCancel={() => setUpcomingDeletedFile(null)}
          onConfirm={() => onRemove(upcomingDeletedFile)}
        />
        <div className="header">
          <Tab.Label
            isActive={activeTab === CONTRACTOR_DETAIL_TABS.Checklist}
            onClick={() => {
              if (activeTab !== CONTRACTOR_DETAIL_TABS.Checklist) {
                history.push({
                  pathname: `/compliance-management/contractors/${contractorId}/${CONTRACTOR_DETAIL_TABS.Checklist}`
                });
              }
            }}>
            {CONTRACTOR_DETAIL_TABS.Checklist.toUpperCase()}
          </Tab.Label>
          <Tab.Label
            isActive={activeTab === CONTRACTOR_DETAIL_TABS.Files}
            onClick={() => {
              if (activeTab !== CONTRACTOR_DETAIL_TABS.Files) {
                history.push({
                  pathname: `/compliance-management/contractors/${contractorId}/${CONTRACTOR_DETAIL_TABS.Files}`
                });
              }
            }}>
            {CONTRACTOR_DETAIL_TABS.Files.toUpperCase()}
          </Tab.Label>
          {candidateDetail && (
            <div className="flex items-center gap-4 ml-8 mr-4">
              <p className="text-lg font-semibold">{`${candidateDetail.first_name} ${candidateDetail.last_name}`}</p>
              <p>{candidateDetail.tech_stack}</p>
            </div>
          )}
          <div className="button-container min-w-fit">
            {activeTab === CONTRACTOR_DETAIL_TABS.Checklist && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    setLoadTemplateModalOpen(true);
                  }}>
                  LOAD TEMPLATE
                </Button>
                <LoadTemplateModal
                  isOpen={loadTemplateModalOpen}
                  defaultTemplateId={selectedTemplateId}
                  onCancel={() => setLoadTemplateModalOpen(false)}
                  onUpdate={loadTemplate}></LoadTemplateModal>
                <div className="vertical-divider"></div>
                <div className="header-date">
                  <span>
                    {showFullDateTime(candidateDetail?.updated_datetime)}
                  </span>
                </div>
                <Button
                  variant="contained"
                  onClick={submitUpdateRequirements}>
                  UPDATE
                </Button>
              </>
            )}
            {activeTab === CONTRACTOR_DETAIL_TABS.Files && (
              <div className="header-date">
                <span>
                  {showFullDateTime(candidateDetail?.updated_datetime)}
                </span>
              </div>
            )}
          </div>
        </div>
        <div>
          {activeTab === CONTRACTOR_DETAIL_TABS.Checklist && (
            <TemplateTable
              requirements={checkListItems}
              isLoading={
                profileTemplateDetailRequest.isFetching ||
                isFetchingTemplate
              }
              updateRequirements={(updatedRequirements) => {
                setCheckListItems(updatedRequirements);
                setHasUnsavedData(true);
              }}
              isChecklist
            />
          )}
          {activeTab === CONTRACTOR_DETAIL_TABS.Files && (
            <FileTable
              isLoading={responseDocsRequest.isFetching}
              fileListItems={responseDocsRequest.data?.requirements}
              setUpcomingDeletedFile={setUpcomingDeletedFile}></FileTable>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContractorDetail;
