import { useState } from 'react';

import classNames from 'classnames';
import './TristateToggleMobile.scss';
import { ReactComponent as DownArrowIcon } from '../../../assets/images/down-arrow-icon.svg';
import config from '../../../config';

const TristateToggleMobile = ({ timelineType, setTimelineType }) => {
  const [
    isMobileTimelineTypeToggleOpened,
    setIsMobileTimelineTypeToggleOpened
  ] = useState(false);

  return (
    <div
      className={classNames('mobile-view-tristate-toggle', {
        active: isMobileTimelineTypeToggleOpened
      })}>
      <div
        onClick={() => setIsMobileTimelineTypeToggleOpened(true)}
        className="main-button">
        <span>{timelineType.toUpperCase()}</span>
        <DownArrowIcon></DownArrowIcon>
      </div>
      {isMobileTimelineTypeToggleOpened && (
        <div
          className="tristate-toggle-buttons-container"
          onClick={() => setIsMobileTimelineTypeToggleOpened(false)}>
          <div
            className="tristate-toggle-buttons"
            onClick={(e) => e.stopPropagation()}>
            <button
              className={classNames('button', 'custom-button', {
                active: timelineType === config.projectTimelineType.FULL
              })}
              onClick={() => {
                setTimelineType(config.projectTimelineType.FULL);
                setIsMobileTimelineTypeToggleOpened(false);
              }}>
              FULL
            </button>
            <button
              className={classNames('button', 'custom-button', {
                active: timelineType === config.projectTimelineType.MONTHLY
              })}
              onClick={() => {
                setTimelineType(config.projectTimelineType.MONTHLY);
                setIsMobileTimelineTypeToggleOpened(false);
              }}>
              MONTHLY
            </button>
            <button
              className={classNames('button', 'custom-button', {
                active: timelineType === config.projectTimelineType.WEEKLY
              })}
              onClick={() => {
                setTimelineType(config.projectTimelineType.WEEKLY);
                setIsMobileTimelineTypeToggleOpened(false);
              }}>
              WEEKLY
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TristateToggleMobile;
